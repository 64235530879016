import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Attachment } from 'src/app/core/models/attachments.models';
import { StringBooleanMap, StringStringMap } from 'src/app/core/models/common.models';
import { AttachEvent, AttachmentErrorEvent, AttachmentSuccessEvent, AttachService } from 'src/app/core/services/backend/attaches-backend.service';
import { UploadProgressEvent, UploadService } from 'src/app/core/services/upload.service';
import { IdeasAttachesStoreService } from 'src/app/core/store/services/ideas-store-attaches.service';
import { IdeasStoreService } from 'src/app/core/store/services/ideas-store.service';
import { DialogManagerService } from 'src/app/shared/dialogs/dialog-manager.service';

const UPLOAD_WINDOW_CLOSE_TIMEOUT = 5000;

@Component({
  selector: 'craft-upload-window',
  templateUrl: './upload-window.component.html',
  styleUrls: ['./upload-window.component.scss'],
})
export class UploadWindowComponent implements OnInit, OnDestroy {
  private closeTimerId: number;

  public isReduced = false;
  public attachEvents: AttachEvent[] = [];
  public readonly destroy$ = new Subject<void>();

  public deletedIdeas: StringBooleanMap = {};
  public progress = new Map<string, number>();
  public errors = new Map<string, AttachmentErrorEvent>();
  public uploadsMapping: StringStringMap = { '=1': '1 file uploading', other: '# files uploading' };
  public completedMapping: StringStringMap = { '=1': '1 upload completed', other: '# uploads completed' };

  constructor(
    private router: Router,
    private attachService: AttachService,
    private ideaStore: IdeasStoreService,
    private uploadService: UploadService,
    private dialogManager: DialogManagerService,
    private ideaAttacheStore: IdeasAttachesStoreService,
  ) {}

  public ngOnInit() {
    this.attachService.attach$
      .pipe(
        filter((event: AttachEvent) => !!event.idea.id),
        takeUntil(this.destroy$),
      )
      .subscribe(this.onAttachAdded);

    this.attachService.success$
      .pipe(
        filter((event: AttachmentSuccessEvent) => !!event.idea),
        takeUntil(this.destroy$),
      )
      .subscribe(this.onAttachFileSuccess);

    this.attachService.error$
      .pipe(
        filter((event: AttachmentErrorEvent) => !!event.idea.id),
        takeUntil(this.destroy$),
      )
      .subscribe(this.onAttachFileError);

    this.attachService.cancel$
      .pipe(
        filter((event: AttachEvent) => !!event.idea.id),
        takeUntil(this.destroy$),
      )
      .subscribe(this.onAttachCanceled);

    this.uploadService.progressUpload$
      .pipe(
        filter((event: UploadProgressEvent) => this.attachEvents.some((ae) => !!ae.attachment.guid && event.guids.has(ae.attachment.guid))),
        takeUntil(this.destroy$),
      )
      .subscribe(this.onUploadProgress);

    this.ideaStore.deleted$.pipe(takeUntil(this.destroy$)).subscribe(this.onIdeaDeleted);
  }

  public onClose() {
    if (this.progress.size === 0) {
      this.destroy$.next();
    } else {
      this.dialogManager
        .showCancelUpload()
        .pipe(
          filter((res) => !!res?.done),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          this.attachService.cancelAll();
          this.destroy$.next();
        });
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    window.clearTimeout(this.closeTimerId);
  }

  public identify(index: number, ae?: AttachEvent): string | number {
    return ae?.guid || index;
  }

  public cancelUpload(attachEvent: AttachEvent) {
    const a = attachEvent.attachment;

    if (a.guid && this.errors.has(a.guid)) {
      this.progress.delete(a.guid);
      this.errors.delete(a.guid);
      this.attachEvents = this.attachEvents.filter((ae) => ae.attachment.guid !== a.guid);
    } else {
      this.dialogManager
        .showCancelUpload()
        .pipe(
          filter((res) => !!res?.done),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          if (!a.id && a.guid) {
            this.attachService.cancel(a.guid);
            this.progress.delete(a.guid);
          }
        });
    }
  }

  public toggleReduce() {
    this.isReduced = !this.isReduced;
  }

  public showIdea(attachEvent: AttachEvent) {
    const shortId = attachEvent.idea.shortId;
    const url = this.router.createUrlTree(['/', shortId]);
    window.open(url.toString(), '_blank');
  }

  private onAttachAdded = (e: AttachEvent) => {
    if (!e.idea.id) return;

    this.attachEvents = [e, ...this.attachEvents];
    this.progress.set(e.guid, 0);
    window.clearTimeout(this.closeTimerId);
  };

  private onAttachFileSuccess = (e: AttachmentSuccessEvent) => {
    if (!e.idea.id || this.deletedIdeas[e.idea.id]) return;

    const attachment: Attachment = { url: e.url, id: e.fileId, name: e.fileName, guid: e.guid };
    const attach = this.attachEvents.find((ae) => ae.attachment.guid === e.guid);
    if (attach) {
      Object.assign(attach.attachment, attachment);
    }

    this.progress.delete(e.guid);
    this.ideaAttacheStore.attachFile(e.idea.id, attachment).pipe(takeUntil(this.destroy$)).subscribe();

    if (this.progress.size === 0) {
      this.closeTimerId = window.setTimeout(() => this.onClose(), UPLOAD_WINDOW_CLOSE_TIMEOUT);
    }
  };

  private onAttachFileError = (e: AttachmentErrorEvent) => {
    if (!e.idea.id || this.deletedIdeas[e.idea.id]) return;

    this.errors.set(e.guid, e);
    this.progress.delete(e.guid);
  };

  private onAttachCanceled = (e: AttachEvent) => {
    if (!e.idea.id || this.deletedIdeas[e.idea.id]) return;
    this.attachEvents = this.attachEvents.filter((ae) => ae.attachment.guid !== e.guid);

    this.progress.delete(e.guid);
    this.errors.delete(e.guid);
  };

  private onIdeaDeleted = (ideaId: string) => {
    this.deletedIdeas[ideaId] = true;

    this.attachEvents = this.attachEvents.filter((ae) => {
      const guid = ae.attachment.guid;
      if (ae.idea.id === ideaId && guid) {
        this.attachService.cancel(guid);
        this.progress.delete(guid);
        this.errors.delete(guid);
        return false;
      }

      return true;
    });

    if (this.attachEvents.length === 0) {
      this.destroy$.next();
    }
  };

  private onUploadProgress = (e: UploadProgressEvent) => {
    for (const guid of e.guids) {
      this.progress.set(guid, e.progress);
      this.errors.delete(guid);
    }
  };
}
