import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  constructor(
    private title: Title,
    private portalStoreService: PortalStoreService,
  ) { }

  public subscribe() {
    return this.portalStoreService.portal$.subscribe(portal => {
      this.title.setTitle(portal ? portal.name : 'Craft Idea Portal');
    });
  }

}
