import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, Observable, switchMap, take, tap } from 'rxjs';
import { CSV_IMPORT_COMPLETE_EVENT } from 'src/app/core/dictionaries/socket-events';
import { StronglyKeyedMap } from 'src/app/core/models/common.models';
import { SocketMessage, WebSocketService } from 'src/app/core/services/web-socket.service';
import { openInNewTab } from 'src/app/core/utils/dom-utils';
import { csvFeatureKey, CSVState } from 'src/app/settings/modules/csv-import/store/state';
import { NotificationService } from 'src/app/shared/dialogs/notifications/notifications.service';

type ProcessFn = (msg: SocketMessage) => void;

@Injectable({
  providedIn: 'root',
})
export class GlobalSocketEventsService {
  private readonly message$: Observable<SocketMessage>;
  private readonly events: StronglyKeyedMap<string, ProcessFn> = {
    [CSV_IMPORT_COMPLETE_EVENT]: this.csvImportComplete.bind(this),
  } as const;

  constructor(
    ws: WebSocketService, //
    private readonly store: Store,
    private readonly ns: NotificationService,
  ) {
    this.message$ = ws.message$.pipe(
      tap((m) => {
        this.events[m.event]?.call(this, m);
      }),
    );
  }

  public subscribe() {
    return this.message$.subscribe();
  }

  public csvImportComplete(msg: SocketMessage) {
    this.store
      .pipe(
        take(1),
        map((store) => ((store as any)[csvFeatureKey] as CSVState)?.isShowed), //
        filter((isShowed) => !isShowed),
        map(() => msg?.data?.user_log_url),
        switchMap((logUrl) => {
          return this.ns
            .show({
              message: 'CSV Import completed.',
              actions: !logUrl ? void 0 : [{ text: 'Download import log', command: 'download', autoClose: true }],
            })
            .pipe(map((action) => ({ action, logUrl })));
        }),
      )
      .subscribe(({ action, logUrl }) => {
        if (action?.command === 'download' && logUrl) {
          openInNewTab(logUrl);
        }
      });
  }
}
