import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StringAnyMap, StringStringMap } from 'src/app/core/models/common.models';
import { CompaniesStoreService } from 'src/app/core/store/services/companies-store.service';
import { BaseMultiSelectComponent } from 'src/app/shared/components/craft-multi-select/craft-multi-select.component';

@Component({
  selector: 'craft-category-privacy-dropdown',
  templateUrl: './category-privacy-dropdown.component.html',
  styleUrls: ['./category-privacy-dropdown.component.scss'],
})
export class CategoryPrivacyDropdownComponent extends BaseMultiSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public items: ReadonlyArray<any> | Readonly<StringAnyMap>;
  @Input() public isPrivate: boolean | undefined;

  @Output() public privateChange = new EventEmitter<boolean>();
  @Output() public companiesChange = new EventEmitter<readonly string[]>();

  @ViewChild(MatMenuTrigger) public trigger: MatMenuTrigger;

  public companiesSize = 0;
  public isPrivateControl = new UntypedFormControl();
  public selectedCountMapping: StringStringMap = { '=0': 'Specific companies...', other: 'Specific companies (#)' };

  private destroy$ = new Subject<void>();

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private companiesStoreService: CompaniesStoreService,
  ) {
    super(changeDetectorRef);
    this.idFieldName = 'id';
    this.labelFieldName = 'name';
  }

  public ngOnInit() {
    this.companiesStoreService.map$.pipe(takeUntil(this.destroy$)).subscribe((companies) => {
      this.setItems(companies, this.selectedIds);
    });

    this.companiesStoreService.ids$.pipe(takeUntil(this.destroy$)).subscribe((ids) => {
      this.companiesSize = ids.length;
    });

    this.isPrivateControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.privateChange);
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.isPrivate) {
      this.isPrivateControl.setValue(changes.isPrivate.currentValue, { emitEvent: false });
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public clearSelected(e: MouseEvent) {
    e.stopPropagation();
    this.selectedSet.clear();
  }

  public applySelected() {
    this.selectedIds = Array.from(this.selectedSet.keys());
    this.companiesChange.next(this.selectedIds);
    this.trigger.closeMenu();
  }
}
