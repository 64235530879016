import { Product } from 'src/app/core/models/products.models';

export const csvFeatureKey = 'csvImport';

export type CSVUploadStep = {
  readonly fileId: string;
  readonly productId: string;
};

export type CSVMappingStep = CSVUploadStep & {
  readonly titleColumn: string;
  readonly descrColumn: string;
  readonly labelColumn: string;
};

export type CSVCompleteStep = {
  readonly errors: number;
  readonly success: number;
  readonly categoryId: string;
  readonly labelId: string;
  readonly productKey: string;
  readonly logUrl: string;
};

export type CSVState = CSVMappingStep &
  CSVCompleteStep & {
    readonly step: number;
    readonly isShowed: boolean;
    readonly products: readonly Product[];
  };

export type CSVColumn = {
  readonly id: string;
  readonly name: string;
};

export type CSVMapData = Partial<CSVMappingStep> & {
  readonly titleColumns: readonly CSVColumn[];
  readonly optionalColumns: readonly CSVColumn[];
};

export type CSVMappingRequest = CSVMappingStep;
export type ImportCompleteEvent = CSVCompleteStep;
export type ImportProgressEvent = { readonly progress: number };
