<form novalidate="" [formGroup]="form" (submit)="submit()" class="new-category-dialog">
  <button type="button" (click)="close()" class="btn btn-close text-size-15">
    <craft-icon class="svg-icon" name="general--close-md"></craft-icon>
  </button>

  <h1 class="dialog-title">
    {{ 'common.text28' | craftTerm }}
  </h1>

  <div mat-dialog-content="" class="dialog-content">
    <div class="title-wrap">
      <textarea
        class="new-category-title textarea-input"
        [attr.placeholder]="'common.text16' | craftTerm"
        formControlName="name"
        craftAutoresize=""
        [disableLineBreak]="true"
        maxlength="255"
        #nameInput
      ></textarea>
      <mat-error *ngIf="isSubmitted && form.controls['name'].invalid">
        {{ 'common.text13' | craftTerm }}
      </mat-error>
    </div>

    <craft-text-editor class="new-category-desc textarea-input w-100" formControlName="description" [maxlength]="10000" placeholder="Add short description">
    </craft-text-editor>
    <mat-error *ngIf="isSubmitted && form.controls['description'].invalid">Please enter description – it's required. </mat-error>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex">
    <button class="btn dialog-btn shrink-0" type="button" (click)="close()" mat-button="">Cancel</button>
    <button class="btn btn-blue dialog-btn shrink-0" type="submit" mat-button="">Save</button>
  </div>
</form>
