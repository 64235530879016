<div class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title">
    {{ 'common.text1' | craftTerm }}
  </h1>

  <div mat-dialog-content="" class="dialog-content">
    <div class="dialog-desc">
      {{ 'common.text36' | craftTerm }}
    </div>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <button class="btn btn-cancel dialog-btn" type="button" (click)="close()" mat-button="">Cancel</button>
    <button class="btn btn-blue dialog-btn" type="button" (click)="done()" mat-button="">Done</button>
  </div>
</div>
