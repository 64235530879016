import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { FiltersApplyAction, FiltersApplyNextPageAction, FILTERS_APPLY, FILTERS_NEXT_PAGE } from 'src/app/core/store/actions/filters';
import { IdeasSetStatusAction } from 'src/app/core/store/actions/ideas';
import { IdeasStateStatus } from 'src/app/core/store/reducers/ideas';

@Injectable()
export class FiltersEffects {
  constructor(private actions$: Actions) {}

  public ideasStatusChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<FiltersApplyAction | FiltersApplyNextPageAction>(FILTERS_APPLY, FILTERS_NEXT_PAGE),
      map(() => {
        return new IdeasSetStatusAction(IdeasStateStatus.loading);
      }),
    );
  });
}
