import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  private sprites: Map<string, Document> = new Map();
  private requests: Map<string, Promise<Document>> = new Map();

  constructor(private config: ConfigService) {
  }

  public fetchDefaultSprite(path = this.config.env.spriteUrl) {
    const request = this.getSvgSprite(path);
    this.requests.set(path, request);
    return request;
  }

  public async getSprite(path: string) {
    let sprite = this.sprites.get(path);
    if (sprite !== void 0) { return sprite; }

    let request = this.requests.get(path);
    if (request !== void 0) {
      sprite = await request;
    } else {
      request = this.getSvgSprite(path);
      this.requests.set(path, request);
      sprite = await request;
    }

    this.sprites.set(path, sprite);
    this.requests.delete(path);
    return sprite;
  }

  private async getSvgSprite(path: string) {
    const res = await fetch(path);
    const text = await res.text();

    const sprite = document.implementation.createHTMLDocument('');
    sprite.body.innerHTML = text;

    return sprite;
  }
}
