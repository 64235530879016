import { Injectable } from '@angular/core';

export enum StorageType {
  Local = 1,
  Session
}

@Injectable({
  providedIn: 'root'
})
export class WebStorageService {

  constructor() {
    if (!this.test()) {
      throw new Error('Web storage is not supported');
    }
  }

  public storage(key: string, data?: any): any {
    return this.set(key, data, StorageType.Local);
  }

  public session(key: string, data?: any): any {
    return this.set(key, data, StorageType.Session);
  }

  private set(key: string, data?: any, storageType: StorageType = StorageType.Local): any {
    const storage: Storage = this.getStorage(storageType);

    if (data === void (0)) {
      const val = storage.getItem(key);
      return val ? JSON.parse(val) : null;
    } else if (data === null) {
      storage.removeItem(key);
      return;
    } else {
      storage.setItem(key, JSON.stringify(data));
      return data;
    }
  }

  private getStorage(storageType: StorageType): Storage {
    return (storageType === StorageType.Local) ? window.localStorage : window.sessionStorage;
  }

  private test(storageType: StorageType = StorageType.Session): boolean {
    const storage: Storage = this.getStorage(storageType);

    if (!storage) { return false; }

    const test = 'test';
    try {
      storage.setItem(test, test);
      storage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }
}
