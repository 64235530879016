import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';

@Injectable({
  providedIn: 'root',
})
export class RouterExtService {
  private previousUrl: string;
  private currentUrl: string;
  private backPoint: string;

  constructor(
    public readonly router: Router,
    private portalStore: PortalStoreService,
  ) {
    this.backPoint = this.router.url;
    this.currentUrl = this.router.url;

    this.router.events
      .pipe(
        filter(
          (e): e is NavigationEnd =>
            e instanceof NavigationEnd || //
            e instanceof NavigationCancel ||
            e instanceof NavigationError,
        ),
      )
      .subscribe((event: RouterEvent) => {
        if (event instanceof NavigationError) {
          console.error(event);
        }

        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      });
  }

  public setBackPoint() {
    this.backPoint = this.currentUrl;
  }

  public goToSettings() {
    this.setBackPoint();
    this.router.navigate(['/settings']);
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public goToBackPoint() {
    this.router.navigateByUrl(this.backPoint);
  }

  public goPreviousRoute() {
    this.router.navigateByUrl(this.previousUrl);
  }

  public getDefaultProductRoute(productKey: string): string[] {
    const portal = this.portalStore.portalSnapshot;
    if (!portal || portal.defaultProductPage === 'ideas') {
      return ['/', productKey];
    }

    return ['/', productKey, 'categories'];
  }

  public getIdeasListRoute(productKey: string) {
    return ['/', productKey];
  }
}
