import { ChangeDetectorRef, Pipe } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { TerminologyService } from 'src/app/core/services/terminology.service';

@Pipe({
  name: 'craftTerm',
  pure: false, // required to update the value when the promise is resolved
})
export class CraftTerminologyPipe extends TranslatePipe {
  constructor(
    translate: TranslateService, //
    private ref: ChangeDetectorRef, //
    private terminologyService: TerminologyService,
  ) {
    super(translate, ref);
  }

  public updateValue(key: string, interpolateParams?: Object): void {
    this.terminologyService.translate(key, interpolateParams).subscribe((res: string) => {
      this.value = res !== undefined ? res : key;
      this.lastKey = key;
      this.ref.markForCheck();
    });
  }
}
