import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringTMap } from 'src/app/core/models/common.models';
import { DBInnerStatus, InnerStatus, InnerStatusDelRequest, InnerStatusesListResponse } from 'src/app/core/models/inner-statuses.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';

@Injectable({
  providedIn: 'root',
})
export class InnerStatusesBackendService {
  constructor(private bs: BackendService) {}

  public getList(): Observable<InnerStatusesListResponse> {
    return this.bs.get('portal/inner_status/').pipe(
      map((result) => {
        const innerStatuses: StringTMap<DBInnerStatus> = {};

        const ids = result.list.reduce((acc: string[], status: DBInnerStatus) => {
          innerStatuses[status.id] = this.mapInnerStatus(status);
          acc.push(status.id);
          return acc;
        }, []);

        return { ids, innerStatuses };
      }),
    );
  }

  public update(status: DBInnerStatus): Observable<DBInnerStatus> {
    return this.bs.put(`portal/inner_status/${status.id}`, status).pipe(
      map((imp) => {
        return this.mapInnerStatus(imp);
      }),
    );
  }

  public add(status: InnerStatus): Observable<DBInnerStatus> {
    return this.bs.post(`portal/inner_status/`, status).pipe(
      map((imp) => {
        return this.mapInnerStatus(imp);
      }),
    );
  }

  public delete(req: InnerStatusDelRequest): Observable<string> {
    return this.bs
      .delete(`portal/inner_status/${req.deletedStatusId}`, {
        newIdeasInnerStatusId: req.newIdeasStatusId,
      })
      .pipe(
        map(() => {
          return req.deletedStatusId;
        }),
      );
  }

  public mapInnerStatus(status: any): DBInnerStatus {
    status.orderIndex = Number.parseFloat(status.orderIndex);
    return status as DBInnerStatus;
  }
}
