import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Destroyer } from 'src/app/core/abstract/destroyer';
import { PromoteItemType } from 'src/app/core/models/craft.models';
import { DBIdea } from 'src/app/core/models/idea.models';

export type CraftItemEditDialogInput = {
  idea: DBIdea;
  promoteItemType: PromoteItemType;
};

@Component({
  templateUrl: './craft-item-edit.component.html',
  styleUrls: ['./craft-item-edit.component.scss'],
})
export class CraftItemEditComponent extends Destroyer implements OnInit {
  public idea: DBIdea;
  public promoteItemType: PromoteItemType;

  public form = new UntypedFormGroup({
    name: new UntypedFormControl(void 0, { validators: Validators.required }),
    description: new UntypedFormControl(),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) data: CraftItemEditDialogInput,
    private dialogRef: MatDialogRef<CraftItemEditComponent, DBIdea | undefined>, //
  ) {
    super();
    this.idea = data.idea;
    this.promoteItemType = data.promoteItemType;
  }

  public ngOnInit() {
    this.form.patchValue(this.idea);
  }

  public close() {
    this.dialogRef.close();
  }

  public submit() {
    if (this.form.invalid) return;
    this.dialogRef.close({ ...this.idea, ...this.form.value });
  }
}
