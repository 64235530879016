<button mat-button [matMenuTriggerFor]="mainMenu" type="button" class="btn btn-gray more-menu-btn">
  <craft-icon class="svg-icon text-size-15" name="subnav--more-b-md"></craft-icon>
</button>

<mat-menu #mainMenu="matMenu" xPosition="before">
  <ng-template matMenuContent="">
    <button mat-menu-item="" type="button" (click)="privacySelect.next()" class="d-flex align-items-center">
      <craft-icon class="svg-icon text-size-15 mr-8" name="custom--lock-md"></craft-icon>
      <span>
        {{ 'common.text24' | craftTerm }}
      </span>
    </button>
  </ng-template>
</mat-menu>
