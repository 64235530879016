import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomField } from 'src/app/core/models/custom-fields.models';
import { CustomFieldsStoreService } from 'src/app/core/store/services/custom-fields-store.service';
import { DialogResult } from 'src/app/shared/dialogs/models';

@Component({
  templateUrl: './delete-custom-field-dialog.component.html',
  styleUrls: ['./delete-custom-field-dialog.component.scss'],
})
export class DeleteCustomFieldDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public field: CustomField,
    private customFieldsService: CustomFieldsStoreService,
    private dialogRef: MatDialogRef<DeleteCustomFieldDialogComponent, DialogResult>,
  ) {}

  public getFormNames() {
    return this.field.formNames?.split(', ');
  }

  public close() {
    this.dialogRef.close({ done: false });
  }

  public done() {
    this.customFieldsService.delete(this.field.id).subscribe();
    this.dialogRef.close({ done: true });
  }
}
