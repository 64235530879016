import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DBIdea } from 'src/app/core/models/idea.models';
import { IdeasStoreService } from 'src/app/core/store/services/ideas-store.service';

@Component({
  templateUrl: './delete-idea-dialog.component.html',
  styleUrls: ['./delete-idea-dialog.component.scss'],
})
export class DeleteIdeaDialogComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private ideasStore: IdeasStoreService,
    @Inject(MAT_DIALOG_DATA) public idea: DBIdea,
    private dialogRef: MatDialogRef<DeleteIdeaDialogComponent, DBIdea>,
  ) {}

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public done(): void {
    this.ideasStore
      .delete(this.idea.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef.close(this.idea);
      });
  }
}
