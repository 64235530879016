<div *ngIf="state" class="text-editor-toolbar" [ngStyle]="{ top: top + 'px', left: left + 'px' }">
  <button type="button" (click)="applyChange('bold')">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="11" height="11">
      <path d="M3 2h2.5C6.327 2 7 2.666 7 3.5 7 4.328 6.321 5 5.5 5H3V2zm0 3h2.995a2 2 0 0 1 0 4H3V5z" fill="none" stroke="currentColor" stroke-width="2" />
    </svg>
  </button>

  <button type="button" (click)="applyChange('underline')">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="11" height="11">
      <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round">
        <path stroke-linejoin="round" d="M8.5 1.5v4c0 1.657-1.347 3-3 3-1.657 0-3-1.349-3-3v-4" />
        <path d="M2.5 10.5h6" />
      </g>
    </svg>
  </button>

  <button type="button" (click)="applyChange('italic')">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="11" height="11">
      <path d="M3.5 9.5h2m0-8h2m-1 0l-2 8" fill="none" stroke="currentColor" stroke-linecap="round" />
    </svg>
  </button>

  <button type="button" (click)="applyChange('strikeThrough')">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="11" height="11">
      <path
        d="M1.5 5.5h8M7.333 2.698A2.004 2.004 0 0 0 5.5 1.5a2 2 0 1 0 0 4m-2 2.802A2.004 2.004 0 0 0 5.333 9.5a2 2 0 1 0 0-4"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
      />
    </svg>
  </button>

  <button type="button" (click)="incrementPadding()">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="11" height="10">
      <path d="M.5 8.5h10m-3-4h3m-6 0l-2-2m2 2l-2 2m2-2h-4m0-4h10" fill="none" stroke="currentColor" stroke-linecap="round" />
    </svg>
  </button>

  <button type="button" (click)="decrementPadding()">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="11" height="10">
      <path d="M.5 8.5h10m-3-4h3m-10 0l2-2m-2 2l2 2m-2-2h4m-4-4h10" fill="none" stroke="currentColor" stroke-linecap="round" />
    </svg>
  </button>

  <button type="button" (click)="applyChange('insertOrderedList')">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="11" height="11">
      <path d="M.5 2.5v-2m0 0h1m-1 4h1m0 0l-1 2m0 0h1m-1 2h1m-1 0v2m0 0h1m2-1h7m-7-4h7m-7-4h7" fill="none" stroke="currentColor" stroke-linecap="round" />
    </svg>
  </button>

  <button type="button" (click)="applyChange('insertUnorderedList')">
    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="11" height="10">
      <path d="M.5.5h1m-1 4h1m-1 4h1m2 0h7m-7-4h7m-7-4h7" fill="none" stroke="currentColor" stroke-linecap="round" />
    </svg>
  </button>
</div>
