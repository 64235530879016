import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IdeasVisibility } from 'src/app/core/enums/IdeaVisibility';
import { Category } from 'src/app/core/models/categories.models';
import { Portal } from 'src/app/core/models/portal.models';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';

@Component({
  selector: 'craft-category-privacy-settings',
  templateUrl: './category-privacy-settings.component.html',
  styleUrls: ['./category-privacy-settings.component.scss'],
})
export class CategoryPrivacySettingsComponent implements OnInit {
  public portal: Portal | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public category: Category,
    private dialogRef: MatDialogRef<CategoryPrivacySettingsComponent, Category>,
    private portalStore: PortalStoreService,
  ) {}

  public ngOnInit() {
    this.portal = this.portalStore.portalSnapshot;
  }

  public close() {
    this.dialogRef.close();
  }

  public done() {
    this.dialogRef.close(this.category);
  }

  public companiesChange(ids: readonly string[]) {
    this.category = { ...this.category, companies: ids };
  }

  public privateChange(isPrivate: boolean) {
    let companies = this.category.companies;
    let ideaVisibility = this.category.ideaVisibility;
    if (!this.category.isPrivate && this.category.ideaVisibility !== IdeasVisibility.visibleToCompanies) {
      companies = [];
    } else if (this.category.isPrivate && this.category.ideaVisibility === IdeasVisibility.visibleToAllUsers) {
      ideaVisibility = IdeasVisibility.visibleToCreators;
    }

    this.category = { ...this.category, isPrivate, companies, ideaVisibility };
  }

  public visibilityChange(ideaVisibility: IdeasVisibility) {
    let companies = this.category.companies;
    if (!this.category.isPrivate && this.category.ideaVisibility !== IdeasVisibility.visibleToCompanies) {
      companies = [];
    }

    this.category = { ...this.category, companies, ideaVisibility };
  }
}
