import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomField } from 'src/app/core/models/custom-fields.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';

@Injectable({
  providedIn: 'root',
})
export class CustomFieldsBackendService {
  constructor(private bs: BackendService) {}

  public save(field: Partial<CustomField>): Observable<CustomField> {
    return field.id ? this.update(field as CustomField) : this.add(field);
  }

  public update(field: CustomField): Observable<CustomField> {
    return this.bs.put(`iportal/idea_custom/${field.id}`, field);
  }

  public add(field: Partial<CustomField>): Observable<CustomField> {
    return this.bs.post(`iportal/idea_custom/`, field);
  }

  public delete(id: string): Observable<void> {
    return this.bs.delete(`iportal/idea_custom/${id}`).pipe(map(() => {}));
  }
}
