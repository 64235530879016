import { Action } from '@ngrx/store';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { Product, ProductsFetchDataRequest, ProductsListRequest, ProductsListResponse, ProductUpdateLogoRequest } from 'src/app/core/models/products.models';

export const PRODUCTS_LIST = '[Products] list';
export const PRODUCTS_LIST_SUCCESS = '[Products] list success';
export const PRODUCTS_LIST_ERROR = '[Products] list error';

export const PRODUCTS_SAVE = '[Products] save one';
export const PRODUCTS_SAVE_ERROR = '[Products] save error';
export const PRODUCTS_SAVE_SUCCESS = '[Products] save success';

export const PRODUCTS_SAVE_LOGO = '[Products] save logo';
export const PRODUCTS_SAVE_LOGO_ERROR = '[Products] save logo error';
export const PRODUCTS_SAVE_LOGO_SUCCESS = '[Products] save logo success';

export const PRODUCTS_SELECT_BY_ID = '[Products] Select by id';
export const PRODUCTS_FETCH_DATA = '[Products] Fetch data';

export class ProductsReqListAction implements Action {
  readonly type = PRODUCTS_LIST;
  constructor(public payload: ProductsListRequest) {}
}
export class ProductsReqListErrorAction implements Action {
  readonly type = PRODUCTS_LIST_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class ProductsReqListSuccessAction implements Action {
  readonly type = PRODUCTS_LIST_SUCCESS;
  constructor(public payload: ProductsListResponse) {}
}

export class ProductsReqSaveAction implements Action {
  readonly type = PRODUCTS_SAVE;
  constructor(public payload: Product) {}
}
export class ProductsReqSaveErrorAction implements Action {
  readonly type = PRODUCTS_SAVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class ProductsReqSaveSuccessAction implements Action {
  readonly type = PRODUCTS_SAVE_SUCCESS;
  constructor(public payload: Product) {}
}

export class ProductsReqSaveLogoAction implements Action {
  readonly type = PRODUCTS_SAVE_LOGO;
  constructor(public payload: ProductUpdateLogoRequest) {}
}
export class ProductsReqSaveLogoErrorAction implements Action {
  readonly type = PRODUCTS_SAVE_LOGO_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class ProductsReqSaveLogoSuccessAction implements Action {
  readonly type = PRODUCTS_SAVE_LOGO_SUCCESS;
  constructor(public payload?: void) {}
}

export class ProductsSelectByIdAction implements Action {
  readonly type = PRODUCTS_SELECT_BY_ID;
  constructor(public payload: string) {}
}

export class ProductFetchDataAction implements Action {
  readonly type = PRODUCTS_FETCH_DATA;
  constructor(public payload: ProductsFetchDataRequest) {}
}

export type ProductAction =
  | ProductsReqListAction
  | ProductsReqListSuccessAction
  | ProductsReqListErrorAction
  | ProductsReqSaveAction
  | ProductsReqSaveErrorAction
  | ProductsReqSaveSuccessAction
  | ProductsReqSaveLogoAction
  | ProductsReqSaveLogoErrorAction
  | ProductsReqSaveLogoSuccessAction
  | ProductsSelectByIdAction
  | ProductFetchDataAction;
