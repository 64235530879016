import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { USER_ROLES_DISPLAYED_LIST, UserRoleView } from 'src/app/core/enums/UserRole';
import { DialogResult } from 'src/app/shared/dialogs/models';

@Component({
  templateUrl: './roles-helper-dialog.component.html',
  styleUrls: ['./roles-helper-dialog.component.scss'],
})
export class RolesHelperDialogComponent {
  public readonly roles: ReadonlyArray<UserRoleView> = USER_ROLES_DISPLAYED_LIST;

  constructor(private dialogRef: MatDialogRef<RolesHelperDialogComponent, DialogResult>) {}

  public close(): void {
    this.dialogRef.close({ done: false });
  }
}
