import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, takeUntil } from 'rxjs/operators';
import { Destroyer } from 'src/app/core/abstract/destroyer';
import { DBTag } from 'src/app/core/models/tags.models';
import { TagsStoreService } from 'src/app/core/store/services/tags-store.service';
import { CraftTagsBulkCreateComponent } from 'src/app/shared/components/craft-tags-bulk-create/craft-tags-bulk-create.component';
import { DialogResult } from 'src/app/shared/dialogs/models';

export type CreateTagsDialogInput = {
  readonly productId: string;
  readonly existsTags: readonly DBTag[];
  readonly autocompleteTags?: readonly DBTag[];
};

export type CreateTagsDialogResult = DialogResult & {
  readonly createdTags?: readonly DBTag[];
};

@Component({
  templateUrl: './tags-create.component.html',
  styleUrls: ['./tags-create.component.scss'],
})
export class TagsCreateDialogComponent extends Destroyer {
  public isSaving = false;

  constructor(
    private tagsStore: TagsStoreService, //
    @Inject(MAT_DIALOG_DATA) public data: CreateTagsDialogInput,
    public dialogRef: MatDialogRef<TagsCreateDialogComponent, CreateTagsDialogResult>,
  ) {
    super();
  }

  public close(): void {
    this.dialogRef.close({ done: false });
  }

  public done(tagsControl: CraftTagsBulkCreateComponent): void {
    tagsControl.addDraft();
    if (tagsControl.tags.length === 0) return;

    this.isSaving = true;
    this.tagsStore
      .addBulk(this.data.productId, tagsControl.tags)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isSaving = false;
        }),
      )
      .subscribe((res) => {
        this.dialogRef.close({ done: true, createdTags: Object.values(res.tags) });
      });
  }
}
