import { Injectable, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { StringAnyMap } from 'src/app/core/models/common.models';
import { CraftUserIconComponent } from 'src/app/shared/components/craft-user-icon/craft-user-icon.component';

const COMPONENTS = {
  'user-icon': CraftUserIconComponent,
};

@Injectable({ providedIn: 'root' })
export class DynamicComponentsService {
  constructor(private injector: Injector) {}

  public register(): void {
    Object.entries(COMPONENTS).forEach(([key, component]: [string, any]) => {
      const CustomElement = createCustomElement(component, { injector: this.injector });
      customElements.define(key, CustomElement);
    });
  }

  public create(tagName: string, data: StringAnyMap = {}): HTMLElement {
    const customEl = document.createElement(tagName);

    Object.entries(data).forEach(([key, value]: [string, any]) => {
      (customEl as any)[key] = value;
    });

    return customEl;
  }
}
