import { AnalyticsEffects } from 'src/app/core/store/effects/AnalyticsEffects';
import { ErrorsEffects } from 'src/app/core/store/effects/ErrorsEffects';
import { FormsEffects } from 'src/app/core/store/effects/FormsEffects';
import { ProductEffects } from 'src/app/core/store/effects/ProductEffects';
import { AuthEffects } from './AuthEffects';
import { CategoriesEffects } from './CategoriesEffects';
import { CompaniesEffects } from './CompaniesEffects';
import { FiltersEffects } from './FilterEffects';
import { IdeasEffects } from './IdeasEffects';
import { PortalEffects } from './PortalEffects';

export { AnalyticsEffects, AuthEffects, CategoriesEffects, ErrorsEffects, FiltersEffects, FormsEffects, IdeasEffects, PortalEffects, ProductEffects };

export const effects = [
  AnalyticsEffects, //
  CategoriesEffects,
  FiltersEffects,
  IdeasEffects,
  PortalEffects,
  FormsEffects,
  AuthEffects,
  CompaniesEffects,
  ProductEffects,
  ErrorsEffects,
];
