<div class="header">
  <div class="title">Content</div>
  <button class="btn-add-option" type="button" (click)="add()">
    <craft-icon class="svg-icon text-size-13 mr-5" name="general--plus-sm"></craft-icon>
    <span>Add</span>
  </button>
</div>
<div class="list" cdkDropList (cdkDropListDropped)="dropOption($event)">
  <craft-custom-field-option-item-form
    class="option"
    *ngFor="let control of controls"
    [formControl]="control"
    [isSubmitted]="isSubmitted"
    (remove)="remove(control)"
    cdkDrag=""
  ></craft-custom-field-option-item-form>
</div>
