import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DBImportance } from 'src/app/core/models/importances.models';
import { ImportancesStoreService } from 'src/app/core/store/services/importances-store.service';

@Component({
  templateUrl: './delete-importance-dialog.component.html',
  styleUrls: ['./delete-importance-dialog.component.scss'],
})
export class DeleteImportanceDialogComponent implements OnInit, OnDestroy {
  public selectedImp: DBImportance;
  public importances: DBImportance[];

  private destroy$ = new Subject<void>();

  constructor(
    private importancesStore: ImportancesStoreService,
    @Inject(MAT_DIALOG_DATA) public imp: DBImportance,
    private dialogRef: MatDialogRef<DeleteImportanceDialogComponent, DBImportance>,
  ) {}

  public ngOnInit() {
    this.importancesStore.list$
      .pipe(
        takeUntil(this.destroy$), //
      )
      .subscribe((importances) => {
        this.importances = importances
          .filter((s) => s.id !== this.imp.id) //
          .sort((a, b) => a.orderIndex - b.orderIndex);
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public done(): void {
    this.importancesStore
      .delete(
        this.imp.id, //
        this.selectedImp.id,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef.close(this.selectedImp);
      });
  }
}
