import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { IdeadAddAttachmentRequest, IdeadDelAttachmentRequest } from 'src/app/core/models/idea.models';
import { IdeasBackendService } from 'src/app/core/services/backend/ideas-backend.service';
import {
    IdeasReqAttachFileAction,
    IdeasReqAttachFileErrorAction,
    IdeasReqAttachFileSuccessAction,
    IdeasReqDetachFileAction,
    IdeasReqDetachFileErrorAction,
    IdeasReqDetachFileSuccessAction
} from 'src/app/core/store/actions/ideas';
import { Attachment } from '../../models/attachments.models';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class IdeasAttachesStoreService {
  constructor(
    private bs: IdeasBackendService, //
    private store$: Store<CoreState>,
  ) {}

  public attachFile(ideaId: string, attachment: Attachment): Observable<IdeadAddAttachmentRequest> {
    const req: IdeadAddAttachmentRequest = { ideaId, attachment };
    this.store$.dispatch(new IdeasReqAttachFileAction(req));

    return this.bs.attachFile(req).pipe(
      map((res) => {
        this.store$.dispatch(new IdeasReqAttachFileSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new IdeasReqAttachFileErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public detachFile(ideaId: string, fileId: string): Observable<IdeadDelAttachmentRequest> {
    const req: IdeadDelAttachmentRequest = { ideaId, fileId };
    this.store$.dispatch(new IdeasReqDetachFileAction(req));

    return this.bs.detachFile(req).pipe(
      map((res) => {
        this.store$.dispatch(new IdeasReqDetachFileSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new IdeasReqDetachFileErrorAction(err));
        return throwError(() => err);
      }),
    );
  }
}
