import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationAction, NotificationMessage, NotificationService } from 'src/app/shared/dialogs/notifications/notifications.service';

@Component({
  selector: 'craft-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsListComponent implements OnInit {
  public readonly list$: Observable<NotificationMessage[]>;
  constructor(private readonly notifyService: NotificationService) {
    this.list$ = this.notifyService.notifications$;
  }

  public ngOnInit(): void {}

  public close(msg: NotificationMessage) {
    this.notifyService.close(msg);
  }

  public action(action: NotificationAction) {
    this.notifyService.action(action);
  }
}
