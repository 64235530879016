<button
  type="button"
  class="btn"
  mat-button=""
  #btn
  [disabled]="disabled"
  [disableRipple]="disableRipple"
  (menuOpened)="onMenuOpened(btn)"
  (menuClosed)="onMenuClosed()"
  [matMenuTriggerFor]="singleSelectMenu"
>
  <ng-content></ng-content>
</button>

<mat-menu #singleSelectMenu="matMenu" class="craft-menu-panel">
  <ng-template matMenuContent="">
    <div *ngIf="hasSearch" class="menu-search mat-mdc-menu-item d-flex align-items-center shrink-0">
      <craft-icon class="svg-icon text-size-13 mr-10" name="search--search"></craft-icon>
      <input type="text" #searchInput (click)="$event.stopPropagation()" [placeholder]="searchPlaceholder" [formControl]="searchControl" />
    </div>

    <div class="menu-scroll">
      <button
        *ngFor="let item of filteredItems | async | slice: 0 : 100; trackBy: identify"
        mat-menu-item=""
        [ngClass]="{ selected: selectedId === item[idFieldName] }"
        (click)="selectItem(item)"
        craftDisableFocus=""
        type="button"
      >
        {{ item[labelFieldName] }}
      </button>
    </div>
  </ng-template>
</mat-menu>
