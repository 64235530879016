import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StringTMap } from 'src/app/core/models/common.models';
import { DBTag } from 'src/app/core/models/tags.models';
import { TagsAction, TAGS_PRODUCT_LIST_SUCCESS, TAG_ADD_BULK_SUCCESS, TAG_ADD_SUCCESS, TAG_DELETE_SUCCESS, TAG_UPDATE } from 'src/app/core/store/actions/tags';

export type TagsState = {
  readonly ids: readonly string[];
  readonly tags: StringTMap<DBTag>;
};

export const initialState: TagsState = {
  ids: [],
  tags: {},
};

export function reducer(state = initialState, action: TagsAction): TagsState {
  switch (action.type) {
    case TAGS_PRODUCT_LIST_SUCCESS: {
      const { tags, ids } = action.payload;
      return {
        ...state,
        tags,
        ids,
      };
    }

    case TAG_ADD_SUCCESS: {
      const tag = action.payload;
      return {
        ...state,
        ids: [...state.ids, tag.id],
        tags: {
          ...state.tags,
          [tag.id]: tag,
        },
      };
    }

    case TAG_ADD_BULK_SUCCESS: {
      let { tags, ids } = action.payload;
      ids = Array.from(new Set([...state.ids, ...ids]).values());

      return {
        ...state,
        ids,
        tags: {
          ...state.tags,
          ...tags,
        },
      };
    }

    case TAG_UPDATE: {
      const tag = action.payload;
      if (!state.tags[tag.id]) return state;

      return {
        ...state,
        tags: {
          ...state.tags,
          [tag.id]: tag,
        },
      };
    }

    case TAG_DELETE_SUCCESS: {
      const tagId = action.payload;
      delete state.tags[tagId];
      return {
        ...state,
        ids: state.ids.filter((id) => id !== tagId),
        tags: {
          ...state.tags,
        },
      };
    }

    default:
      return state;
  }
}

const getTags = (state: TagsState) => state.tags;
const getTagsIdsList = (state: TagsState) => state.ids;

export const getTagsStateSelector = createFeatureSelector<TagsState>('tagsState');
export const getTagsSelector = createSelector(getTagsStateSelector, getTags);
export const getTagsIdsSelector = createSelector(getTagsStateSelector, getTagsIdsList);
export const getTagsListSelector = createSelector(getTagsStateSelector, getTagsIdsSelector, (state, ids) => ids.map((id) => state.tags[id]));
