import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthAccount, AuthErrorResponse, AuthUser, PaidStatusString } from 'src/app/core/models/auth.models';
import {
  AuthAction,
  AUTH_CHECKTOKEN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_RESET_REDIRECT,
  AUTH_SIGNIN,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNUP,
  AUTH_TOKEN_SIGNIN,
} from 'src/app/core/store/actions/auth';

export enum AuthStateStatus {
  anonymous,
  processing,
  logged,
}

export type AuthState = {
  readonly authStatus: AuthStateStatus;
  readonly serverError?: AuthErrorResponse;
  readonly user?: AuthUser;
  readonly backRedirect?: string;
  readonly accounts: Partial<Record<PaidStatusString, AuthAccount>>;
};

export const initialState: AuthState = {
  authStatus: AuthStateStatus.anonymous,
  serverError: void 0,
  user: void 0,
  backRedirect: void 0,
  accounts: {},
};

export function reducer(state = initialState, action: AuthAction): AuthState {
  switch (action.type) {
    case AUTH_LOGOUT_SUCCESS: {
      const backRedirect = action.payload;
      return { ...initialState, backRedirect };
    }

    case AUTH_RESET_REDIRECT: {
      return {
        ...state,
        backRedirect: void 0,
      };
    }

    case AUTH_SIGNIN:
    case AUTH_TOKEN_SIGNIN:
    case AUTH_SIGNUP:
      return {
        ...state,
        serverError: void 0,
        authStatus: AuthStateStatus.processing,
      };

    case AUTH_SIGNIN_SUCCESS:
    case AUTH_CHECKTOKEN_SUCCESS: {
      const { user, accounts } = action.payload;
      return {
        ...state,
        serverError: void 0,
        authStatus: AuthStateStatus.logged,
        user,
        accounts,
      };
    }
    default:
      return state;
  }
}

const getUser = (state: AuthState) => state.user;
const getAccounts = (state: AuthState) => state.accounts;
const getAuthStatus = (state: AuthState) => state.authStatus;
const getServerError = (state: AuthState) => state.serverError;
const getBackRedirect = (state: AuthState) => state.backRedirect;

export const getAuthStateSelector = createFeatureSelector<AuthState>('authState');
export const getCurrentUserSelector = createSelector(getAuthStateSelector, getUser);
export const getAccountsSelector = createSelector(getAuthStateSelector, getAccounts);
export const getAuthStatusSelector = createSelector(getAuthStateSelector, getAuthStatus);
export const getServerErrorSelector = createSelector(getAuthStateSelector, getServerError);
export const getBackRedirectSelector = createSelector(getAuthStateSelector, getBackRedirect);
