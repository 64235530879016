import { AbstractControl, ValidatorFn } from '@angular/forms';
import { StringAnyMap, StronglyKeyedMap } from 'src/app/core/models/common.models';
import { CUSTOM_FIELDS_VALIDATIONS, CustomFieldValidation, CustomValidationType } from 'src/app/core/models/custom-fields.models';
import { IdeaCustomFieldValue } from 'src/app/core/models/idea.models';

type ValidationFn = (value: any, v: CustomFieldValidation) => CustomFieldValidation | undefined;

export const geFieldValidator = (field: { isRequired: boolean }): ValidatorFn => {
  return (control: AbstractControl): StringAnyMap | null => {
    if (!field.isRequired) return null;

    const result = VALIDATIONS.required(control.value, CUSTOM_FIELDS_VALIDATIONS.required);
    if (!result) return null;

    return {
      fieldValidation: {
        ...result,
        value: control.value,
        message: CUSTOM_FIELDS_VALIDATIONS[result.type].message,
      },
    };
  };
};

const isRequired = (val: IdeaCustomFieldValue, v: CustomFieldValidation) => {
  return !val || !val.value ? v : void 0;
};

const isUrl = (val: IdeaCustomFieldValue, v: CustomFieldValidation) => {
  if (!val) return v;
  if (!val.value || typeof val.value !== 'string') return;

  const urlReg = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
  if (urlReg.test(val.value)) return v;

  return;
};

const VALIDATIONS: StronglyKeyedMap<CustomValidationType, ValidationFn> = {
  required: isRequired,
  url: isUrl,
};
