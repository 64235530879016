<div class="dialog-wrap">
  <h1 mat-dialog-title
      class="dialog-title">{{data.title}}</h1>
  <div mat-dialog-content
       class="dialog-content">
    <div class="dialog-desc">
      {{data.text}}
    </div>
  </div>
  <div mat-dialog-actions
       class="dialog-actions d-flex justify-end">
    <button mat-button=""
            class="btn btn-blue dialog-btn"
            cdkFocusInitial=""
            (click)="close()">OK</button>
  </div>
</div>
