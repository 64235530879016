import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { TextToolbarComponent } from './components/text-toolbar/text-toolbar.component';

import { TextToolbarService } from './services/text-toolbar.service';

@NgModule({
    declarations: [
        TextEditorComponent,
        TextToolbarComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        TextEditorComponent,
        TextToolbarComponent
    ],
    providers: [
        TextToolbarService
    ]
})
export class EditorsModule { }
