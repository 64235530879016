import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Category } from 'src/app/core/models/categories.models';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { BaseSingleSelectComponent } from 'src/app/shared/components/craft-single-select/craft-single-select.component';

export enum DeleteOption {
  moveTo = 1,
  deleteAll = 2,
}

export type DeleteCategoryOptions = {
  deleteOption: DeleteOption;
  category?: Category;
};

@Component({
  selector: 'craft-delete-categories-option-select',
  templateUrl: './delete-categories-option-select.component.html',
  styleUrls: ['./delete-categories-option-select.component.scss'],
})
export class DeleteCategoriesOptionSelectComponent extends BaseSingleSelectComponent implements OnInit, OnDestroy {
  @Input() public deletedCategory: Category;
  @Output() public change = new EventEmitter<DeleteCategoryOptions>();
  public optionControl = new UntypedFormControl(void 0, { validators: Validators.required });
  public DeleteOption = DeleteOption;

  private readonly destroy$ = new Subject<void>();

  constructor(changeDetectorRef: ChangeDetectorRef, private categoriesStore: CategoriesStoreService) {
    super(changeDetectorRef);
    this.idFieldName = 'id';
    this.labelFieldName = 'name';
  }

  public ngOnInit() {
    this.categoriesStore.list$.pipe(takeUntil(this.destroy$)).subscribe((categories) => {
      categories = categories.filter((c) => c.id !== this.deletedCategory.id);
      this.setItems(categories, categories.length > 0 ? categories[0].id : void 0);
    });

    this.optionControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((deleteOption) => {
      this.change.next({
        deleteOption,
        category: deleteOption === DeleteOption.deleteAll ? void 0 : this.selectedItem,
      });
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public selectItem(item: any) {
    super.selectItem(item);
    this.optionControl.setValue(DeleteOption.moveTo);
  }
}
