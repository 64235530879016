import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRole } from 'src/app/core/enums/UserRole';
import { StringAnyMap, StringTMap } from 'src/app/core/models/common.models';
import { PortalUser } from 'src/app/core/models/portal.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';
import { getSortByString } from 'src/app/core/utils/sort-utils';
import { getFullName } from 'src/app/core/utils/text-utils';

@Injectable({
  providedIn: 'root',
})
export class UsersBackendService {
  constructor(private bs: BackendService) {}

  public getCraftUsersMap(portalId: string): Observable<StringTMap<PortalUser>> {
    return this.bs.get('portal/getusers/', { portalId }).pipe(
      map((teams) => {
        return this.getUsersFromTeams(portalId, teams || {});
      }),
    );
  }

  public searchInCreators(search: string, limit = 300, page = 1): Observable<PortalUser[]> {
    const req: any = { limit, page };
    if (search) {
      req.search = search;
    }

    return this.bs.get('iportal/creators/', req).pipe(map((res) => res.list));
  }

  public searchInAdmins(search: string, otherParams = {}, limit = 300, page = 1): Observable<PortalUser[]> {
    const req: any = { ...otherParams, limit, page };
    if (search) {
      req.search = search;
    }

    return this.bs.get('portal/find_admin/', req).pipe(map((res) => res.list));
  }

  public resolveLikes(ideaId: string, limit = 100): Observable<PortalUser[]> {
    return this.bs.get('iportal/vote', { ideaId, limit }).pipe(map((result) => result.list as PortalUser[]));
  }

  public getPortalUsers(portalId: string): Observable<PortalUser[]> {
    return this.bs.get('portal/admin/', { portalId }).pipe(
      map((res) => {
        const invites: PortalUser[] = (res.invites || []).map((inv: any) => {
          return {
            portalId: inv.portalId,
            email: inv.email,
            iportalRole: Number.parseInt(inv.permission, 10) || 1,
            created: new Date(inv.invitedDt),
          } as PortalUser;
        });

        const users: PortalUser[] = (res.list || []).map((user: any) => this.mapServerData(user, portalId));

        return invites.concat(users).sort(getSortByString('fullName'));
      }),
    );
  }

  public getNotesMentions(portalId: string): Observable<PortalUser[]> {
    return this.bs.get('portal/admin/', { portalId, isNotes: true }).pipe(
      map((res) => {
        const users: PortalUser[] = (res.list || []).map((user: any) => this.mapServerData(user, portalId));
        return users.sort(getSortByString('fullName'));
      }),
    );
  }

  public getCommentsMentions(portalId: string, ideaId: string, query: string): Observable<PortalUser[]> {
    return this.bs.get('iportal/available_users', { ideaId, query }).pipe(
      map((res: any) => {
        const list: PortalUser[] = (res.list || []).map((user: any) => this.mapServerData(user, portalId));
        return list;
      }),
    );
  }

  public sendInvite(email: string): Observable<void> {
    return this.bs.post('portal/resend_invite', { email });
  }

  public mapServerData(user: any, portalId: string): PortalUser {
    user.id = user.userId;
    user.portalId = portalId;
    user.created = new Date(user.addedDt);
    user.iportalRole = Number.parseInt(user.iportalRole, 10) || 1;
    user.fullName = getFullName(user);

    delete user.userId;
    delete user.addedDt;
    return user as PortalUser;
  }

  private getUsersFromTeams(portalId: string, productTeams: StringAnyMap) {
    return Object.keys(productTeams)
      .reduce((res, productId) => {
        productTeams[productId].forEach((team: any) =>
          team.users.forEach((user: any) => {
            const puser: PortalUser = {
              portalId,
              color: team.color,
              id: user.userId,
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              fullName: user.fullName || getFullName(user),
              fileId: user.fileId,
              iportalRole: UserRole.Reviewer,
            };
            res.push(puser);
          }),
        );
        return res;
      }, [] as PortalUser[])
      .sort(getSortByString('fullName'))
      .reduce((res, user: PortalUser) => {
        res[user.id!] = user;
        return res;
      }, {} as StringTMap<PortalUser>);
  }
}
