import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StringTMap } from 'src/app/core/models/common.models';
import { PortalUser } from 'src/app/core/models/portal.models';
import {
  PermissionsAction,
  PERMISSIONS_ADD_SUCCESS,
  PERMISSIONS_DEL,
  PERMISSIONS_LIST_SUCCESS,
  PERMISSIONS_SAVE
} from 'src/app/core/store/actions/permissions';

export type PermissionsState = {
  readonly emails: readonly string[];
  readonly users: StringTMap<PortalUser>;
};

export const initialState: PermissionsState = {
  emails: [],
  users: {},
};

export function reducer(state = initialState, action: PermissionsAction): PermissionsState {
  switch (action.type) {
    case PERMISSIONS_LIST_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case PERMISSIONS_ADD_SUCCESS: {
      const user = action.payload;

      return {
        ...state,
        emails: [user.email, ...state.emails],
        users: { [user.email]: { ...user }, ...state.users },
      };
    }

    case PERMISSIONS_SAVE: {
      const user = action.payload;
      state.users[user.email] = { ...user };
      return { ...state };
    }

    case PERMISSIONS_DEL: {
      const user = action.payload;
      const emails = state.emails.filter((e) => e !== user.email);
      delete state.users[user.email];
      return {
        ...state,
        emails: emails,
        users: { ...state.users },
      };
    }

    default:
      return state;
  }
}

const getPermissionsEmails = (state: PermissionsState) => state.emails;
const getPermissionsUsers = (state: PermissionsState) => state.users;

export const getPermissionsStateSelector = createFeatureSelector<PermissionsState>('permissionsState');
export const getPermissionsEmailsSelector = createSelector(getPermissionsStateSelector, getPermissionsEmails);
export const getPermissionsUsersSelector = createSelector(getPermissionsStateSelector, getPermissionsUsers);
export const getPermissionsUsersListSelector = createSelector(getPermissionsUsersSelector, (users) => Object.keys(users).map((key) => users[key]));
