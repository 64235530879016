import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({ selector: '[craftAutoresize]' })
export class CraftAutoresizeDirective implements AfterViewInit {
  @Input() public disableLineBreak = false;
  @Input() public input?: string;

  constructor(private hostElement: ElementRef) {}

  public ngAfterViewInit(): void {
    this.autoresize();
  }

  @HostListener('ngModelChange') onNgModelChange() {
    this.autoresize();
  }

  @HostListener('input')
  public onInput() {
    this.autoresize();
  }

  @HostListener('keydown', ['$event'])
  public onKeydownHandler(e: KeyboardEvent) {
    if (e.key === 'Enter' && this.disableLineBreak) {
      e.preventDefault();
    }
  }

  private autoresize() {
    const el: HTMLTextAreaElement = this.hostElement.nativeElement;
    if (!el) return;

    el.style.height = `1px`;
    el.style.height = `${el.scrollHeight}px`;
    el.style.overflowY = `hidden`;
  }
}
