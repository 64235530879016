import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { DBWorkflowStatus, WorkflowStatus, WorkflowStatusDelRequest, WorkflowStatusesListResponse } from 'src/app/core/models/workflow-statuses.models';
import { WorkflowStatusesBackendService } from 'src/app/core/services/backend/workflow-statuses-backend.service';
import {
    WorkflowStatusesReqAddAction,
    WorkflowStatusesReqAddErrorAction,
    WorkflowStatusesReqAddSuccessAction,
    WorkflowStatusesReqDelAction,
    WorkflowStatusesReqDelErrorAction,
    WorkflowStatusesReqDelSuccessAction,
    WorkflowStatusesReqListAction,
    WorkflowStatusesReqListErrorAction,
    WorkflowStatusesReqListSuccessAction,
    WorkflowStatusesReqSaveAction,
    WorkflowStatusesReqSaveErrorAction,
    WorkflowStatusesReqSaveSuccessAction
} from 'src/app/core/store/actions/workflow-statuses';
import { getWorkflowStatusesListSelector, getWorkflowStatusesSelector } from 'src/app/core/store/reducers/workflow-statuses';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class WorkflowStatusesStoreService {
  public readonly map$ = this.store$.pipe(select(getWorkflowStatusesSelector));
  public readonly list$ = this.store$.pipe(select(getWorkflowStatusesListSelector));

  constructor(
    private store$: Store<CoreState>, //
    private bs: WorkflowStatusesBackendService,
  ) {}

  public loadList(): Observable<WorkflowStatusesListResponse> {
    this.store$.dispatch(new WorkflowStatusesReqListAction());

    return this.bs.getList().pipe(
      map((res) => {
        this.store$.dispatch(new WorkflowStatusesReqListSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new WorkflowStatusesReqListErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public add(status: WorkflowStatus): Observable<DBWorkflowStatus> {
    this.store$.dispatch(new WorkflowStatusesReqAddAction(status));

    return this.bs.add(status).pipe(
      map((res) => {
        this.store$.dispatch(new WorkflowStatusesReqAddSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new WorkflowStatusesReqAddErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public update(status: DBWorkflowStatus): Observable<DBWorkflowStatus> {
    this.store$.dispatch(new WorkflowStatusesReqSaveAction(status));

    return this.bs.update(status).pipe(
      map((res) => {
        this.store$.dispatch(new WorkflowStatusesReqSaveSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new WorkflowStatusesReqSaveErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public delete(deletedStatusId: string, newIdeasStatusId: string): Observable<string> {
    const req: WorkflowStatusDelRequest = { deletedStatusId, newIdeasStatusId };
    this.store$.dispatch(new WorkflowStatusesReqDelAction(req));

    return this.bs.delete(req).pipe(
      map((res) => {
        this.store$.dispatch(new WorkflowStatusesReqDelSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new WorkflowStatusesReqDelErrorAction(err));
        return throwError(() => err);
      }),
    );
  }
}
