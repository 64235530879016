<div class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title ellipsis">Delete Field</h1>

  <div mat-dialog-content="" class="dialog-content">
    <div class="dialog-subtitle">
      <span> Are you certain you want to delete the {{ field.label }} field? </span>
      <div *ngIf="field.formNames">
        <div>
          <span>Please be aware that this field is associated with the following forms:</span>
          <ul>
            <li *ngFor="let form of getFormNames()">{{ form }}</li>
          </ul>
        </div>
        <br />
        <span> Deleting this field will remove it from those forms, affecting new feedback submissions. </span>
      </div>
    </div>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <span class="warning">Warning: this cannot be undone.</span>
    <div class="right-part d-flex align-items-center justify-end">
      <button class="btn btn-cancel dialog-btn" type="button" (click)="close()" mat-button="">Cancel</button>
      <button class="btn btn-blue dialog-btn" type="button" (click)="done()" mat-button="">Delete</button>
    </div>
  </div>
</div>
