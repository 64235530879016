<div class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title">Remove Domain</h1>

  <div mat-dialog-content="" class="dialog-content">
    <div class="dialog-desc">Are you sure you want to remove domain invite link?</div>
    <mat-checkbox [formControl]="deleteUsersControl" class="checkbox-label small">
      Remove existing portal users added using this domain invite link
    </mat-checkbox>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <div class="right-part d-flex align-items-center justify-end">
      <button (click)="close()" [disabled]="isRequestSending" mat-button="" class="btn btn-cancel dialog-btn" type="button">Cancel</button>
      <button (click)="done()" [disabled]="isRequestSending" mat-button="" class="btn btn-blue dialog-btn" type="button">Delete</button>
    </div>
  </div>
</div>
