import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { SortField } from 'src/app/core/models/filters.models';
import { DBIdea, Idea, IdeasListRequest, IdeasListResponse } from 'src/app/core/models/idea.models';
import { IdeasBackendService } from 'src/app/core/services/backend/ideas-backend.service';
import { IdeasSortFiltersService } from 'src/app/core/services/ideas-sort-filters.service';
import { FiltersApplyAction, FiltersApplyErrorAction, FiltersApplyNextPageAction, FiltersResetPageAction } from 'src/app/core/store/actions/filters';
import {
  IdeasReqAddAction,
  IdeasReqAddErrorAction,
  IdeasReqAddSuccessAction,
  IdeasReqDelAction,
  IdeasReqDelErrorAction,
  IdeasReqDelSuccessAction,
  IdeasReqSaveAction,
  IdeasReqSaveErrorAction,
  IdeasReqSaveSuccessAction,
  IdeasSetListAction,
  IdeasSetPageAction,
  IdeasSetSelectedByIdAction,
  IDEAS_DEL
} from 'src/app/core/store/actions/ideas';
import {
  getIdeasIdsSelector,
  getIdeasListSelector,
  getIdeasSelectedIdSelector,
  getIdeasSelector,
  getIdeasStateSelector,
  getIdeasStatusSelector,
  getIdeasTotal,
  getIdeasTotalsSelector,
  getSelectedIdeaSelector
} from 'src/app/core/store/reducers/ideas';
import { ProductsStoreService } from 'src/app/core/store/services/products-store.service';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class IdeasStoreService {
  public readonly deleted$: Observable<string>;
  public readonly total$ = this.store$.pipe(select(getIdeasTotal));
  public readonly map$ = this.store$.pipe(select(getIdeasSelector));
  public readonly ids$ = this.store$.pipe(select(getIdeasIdsSelector));
  public readonly list$ = this.store$.pipe(select(getIdeasListSelector));
  public readonly state$ = this.store$.pipe(select(getIdeasStateSelector));
  public readonly totals$ = this.store$.pipe(select(getIdeasTotalsSelector));
  public readonly status$ = this.store$.pipe(select(getIdeasStatusSelector));
  public readonly selected$ = this.store$.pipe(select(getSelectedIdeaSelector));
  public readonly selectedId$ = this.store$.pipe(select(getIdeasSelectedIdSelector));

  constructor(
    private actions$: Actions, //
    private bs: IdeasBackendService,
    private store$: Store<CoreState>,
    private productsStore: ProductsStoreService,
    private filtersService: IdeasSortFiltersService,
  ) {
    this.deleted$ = this.actions$.pipe(
      ofType<IdeasReqDelAction>(IDEAS_DEL),
      map((action) => action.payload),
    );
  }

  public selectById(id: string | undefined): void {
    this.store$.dispatch(new IdeasSetSelectedByIdAction(id));
  }

  public load(request: IdeasListRequest): Observable<IdeasListResponse> {
    return this.filtersService.makeIdeasRequest(request).pipe(
      mergeMap((req) => {
        this.store$.dispatch(new FiltersApplyAction(req));
        return this.bs.getList(req);
      }),
      map((res) => {
        this.store$.dispatch(new IdeasSetListAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new FiltersApplyErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public nextPage(): Observable<IdeasListResponse> {
    return this.productsStore.selectedId$.pipe(
      take(1),
      filter((id): id is string => !!id),
      switchMap((productId) => this.filtersService.makeIdeasRequest({ productId })),
      mergeMap((req) => {
        req = { ...req, page: (req.page as number) + 1 };
        this.store$.dispatch(new FiltersApplyNextPageAction(req));
        return this.bs.getList(req);
      }),
      map((res) => {
        this.store$.dispatch(new IdeasSetPageAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new FiltersApplyErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public resetPage(): void {
    this.store$.dispatch(new FiltersResetPageAction());
  }

  public applySort(sortType: SortField): Observable<IdeasListResponse> {
    const sort = `${sortType.revert ? '-' : ''}${sortType.field}`;
    return this.productsStore.selectedId$.pipe(
      take(1),
      filter((id): id is string => !!id),
      switchMap((productId) => this.load({ productId, sort, page: 1 })),
    );
  }

  public update(idea: DBIdea): Observable<DBIdea> {
    this.store$.dispatch(new IdeasReqSaveAction(idea));

    return this.bs.update(idea).pipe(
      map((res) => {
        this.store$.dispatch(new IdeasReqSaveSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new IdeasReqSaveErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public add(idea: Idea): Observable<Idea> {
    this.store$.dispatch(new IdeasReqAddAction(idea));

    return this.bs.add(idea).pipe(
      map((res) => {
        this.store$.dispatch(new IdeasReqAddSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new IdeasReqAddErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public delete(id: string): Observable<string> {
    this.store$.dispatch(new IdeasReqDelAction(id));

    return this.bs.delete(id).pipe(
      map((res) => {
        this.store$.dispatch(new IdeasReqDelSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new IdeasReqDelErrorAction(err));
        return throwError(() => err);
      }),
    );
  }
}
