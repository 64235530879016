import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompanyUser } from 'src/app/core/models/companies.models';
import { PortalUser } from 'src/app/core/models/portal.models';
import { UsersBackendService } from 'src/app/core/services/backend/users-backend.service';
import { CompaniesUsersStoreService } from 'src/app/core/store/services/companies-store-users.service';
import { NotificationService } from '../../dialogs/notifications/notifications.service';
import { switchMap } from 'rxjs';

@Component({
  selector: 'craft-invited-user',
  templateUrl: './craft-invited-user.component.html',
  styleUrls: ['./craft-invited-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CraftInvitedUserComponent {
  @Input() public user: PortalUser | CompanyUser;
  constructor(
    private usersService: UsersBackendService,
    private cuss: CompaniesUsersStoreService,
    private notifyService: NotificationService,
  ) {}

  public resendInvite(event: MouseEvent) {
    event.preventDefault();
    const message = `Email invite successfully sent to ${this.user.email}`;
    if ((this.user as CompanyUser).status) {
      this.cuss
        .resendInvite(this.user as CompanyUser)
        .pipe(switchMap(() => this.notifyService.show({ message })))
        .subscribe();
    } else if (this.user.email) {
      this.usersService
        .sendInvite(this.user.email)
        .pipe(switchMap(() => this.notifyService.show({ message })))
        .subscribe();
    }
  }
}
