import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'craft-text-toolbar',
  templateUrl: './text-toolbar.component.html',
  styleUrls: ['./text-toolbar.component.scss'],
})
export class TextToolbarComponent implements OnInit {
  @Input() public left = 0;
  @Input() public top = 0;
  @Input() public state = false;

  constructor() {}

  public ngOnInit() {}

  public applyChange(type: string, valueArgument?: string) {
    document.execCommand(type, false, valueArgument);
  }

  public incrementPadding() {
    const selection = window.getSelection();
    if (!selection || !selection.focusNode) return;
    let paragraph: HTMLElement | null = this.getParagraph(selection.focusNode.parentElement);

    if (!paragraph) {
      document.execCommand('formatblock', false, 'p');
      paragraph = this.getParagraph(selection.focusNode.parentElement);
    }

    if (!paragraph) return;

    let padding = paragraph.style.paddingLeft ? Number.parseInt(paragraph.style.paddingLeft, 10) || 0 : 0;

    padding += 30;
    paragraph.style.paddingLeft = padding + 'px';

    this.updatePosition(selection);
  }

  public decrementPadding() {
    const selection = window.getSelection();
    if (!selection || !selection.focusNode) return;
    const paragraph: HTMLElement | null = this.getParagraph(selection.focusNode.parentElement!);
    if (!paragraph) return;

    let padding = paragraph.style.paddingLeft ? Number.parseInt(paragraph.style.paddingLeft, 10) || 0 : 0;
    if (padding <= 0) return;

    padding -= 30;
    paragraph.style.paddingLeft = padding + 'px';

    this.updatePosition(selection);
  }

  private getParagraph(element: HTMLElement | null) {
    while (element && !element.hasAttribute('contenteditable')) {
      if (this.isParagrpah(element)) {
        return element;
      }
      element = element.parentElement;
    }

    return null;
  }

  private isParagrpah(element: HTMLElement | null) {
    return element ? element.tagName === 'P' : false;
  }

  public updatePosition(selection: Selection) {
    const rect = selection.getRangeAt(0).getBoundingClientRect();
    const top = rect.top - 40;
    const left = rect.left;

    this.left = left;
    this.top = top;
    this.state = true;
  }
}
