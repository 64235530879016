import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringAnyMap } from 'src/app/core/models/common.models';
import { IdeasSortFiltersService } from 'src/app/core/services/ideas-sort-filters.service';
import { TerminologyService } from 'src/app/core/services/terminology.service';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { FiltersStoreService } from 'src/app/core/store/services/filters-store.service';
import { IdeasStoreService } from 'src/app/core/store/services/ideas-store.service';
import { TagsStoreService } from 'src/app/core/store/services/tags-store.service';

@Injectable({
  providedIn: 'root',
})
export class ProductDataService {
  constructor(
    private tagsStore: TagsStoreService,
    private ideasStore: IdeasStoreService,
    private terminologyService: TerminologyService,
    private categoriesStore: CategoriesStoreService,
    private filtersStoreService: FiltersStoreService,
    private ideasFiltersService: IdeasSortFiltersService,
  ) {}

  public loadProductRelatedData(productId: string, params?: StringAnyMap): Observable<boolean[]> {
    return forkJoin([
      this.loadTags(productId), //
      this.loadTerminology(productId),
      this.loadIdeas(productId, params),
      this.loadCategories(productId, params),
    ]);
  }

  public loadIdeas(productId: string, params?: StringAnyMap): Observable<boolean> {
    if (params?.categoriesList) {
      this.filtersStoreService.reset();
      return of(true);
    }

    const routeParams = this.ideasFiltersService.routeParamsToIdeasReq({ ...params });
    return this.ideasStore.load({ ...routeParams, productId }).pipe(map(() => true));
  }

  public loadTags(productId: string): Observable<boolean> {
    return this.tagsStore.loadProductList({ productId }).pipe(map(() => true));
  }

  public loadCategories(productId: string, params?: StringAnyMap): Observable<boolean> {
    const categoryId: string | undefined = params?.categoryId;

    return this.categoriesStore.loadList(productId).pipe(
      map((res) => {
        if (!categoryId) {
          this.categoriesStore.selectById(void 0);
          return true;
        }

        if (!res.categories[categoryId]) {
          return false;
        }

        this.categoriesStore.selectById(categoryId);
        return true;
      }),
    );
  }

  public loadTerminology(productId: string): Observable<boolean> {
    return this.terminologyService.load(productId).pipe(map(() => true));
  }
}
