import { Action } from '@ngrx/store';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { CraftForm, DBCraftForm, FormsListResponse } from 'src/app/core/models/forms.models';

export const FORM_SELECT = '[Forms] Select';

export const FORM_GET_LIST = '[Forms] get list';
export const FORM_GET_LIST_ERROR = '[Forms] get list error';
export const FORM_GET_LIST_SUCCESS = '[Forms] get list success';

export const FORM_GET_ONE = '[Forms] get one';
export const FORM_GET_ONE_ERROR = '[Forms] get one error';
export const FORM_GET_ONE_SUCCESS = '[Forms] get one success';

export const FORM_ADD = '[Forms] add';
export const FORM_ADD_ERROR = '[Forms] add error';
export const FORM_ADD_SUCCESS = '[Forms] add success';

export const FORM_SAVE = '[Forms] save';
export const FORM_SAVE_ERROR = '[Forms] save error';
export const FORM_SAVE_SUCCESS = '[Forms] save success';

export const FORM_DEL = '[Forms] delete';
export const FORM_DEL_ERROR = '[Forms] delete error';
export const FORM_DEL_SUCCESS = '[Forms] delete success';

// SELECT
export class FormsSelectAction implements Action {
  readonly type = FORM_SELECT;
  constructor(public payload: string | undefined) {}
}

// LIST
export class FormsReqListAction implements Action {
  readonly type = FORM_GET_LIST;
  constructor(public payload: void) {}
}
export class FormsReqListErrorAction implements Action {
  readonly type = FORM_GET_LIST_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class FormsReqListSuccessAction implements Action {
  readonly type = FORM_GET_LIST_SUCCESS;
  constructor(public payload: FormsListResponse) {}
}

// ONE
export class FormsReqOneAction implements Action {
  readonly type = FORM_GET_ONE;
  constructor(public payload: string) {}
}
export class FormsReqOneErrorAction implements Action {
  readonly type = FORM_GET_ONE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class FormsReqOneSuccessAction implements Action {
  readonly type = FORM_GET_ONE_SUCCESS;
  constructor(public payload: DBCraftForm) {}
}

// ADD
export class FormsReqAddAction implements Action {
  readonly type = FORM_ADD;
  constructor(public payload: CraftForm) {}
}
export class FormsReqAddErrorAction implements Action {
  readonly type = FORM_ADD_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class FormsReqAddSuccessAction implements Action {
  readonly type = FORM_ADD_SUCCESS;
  constructor(public payload: DBCraftForm) {}
}

// SAVE
export class FormsReqSaveAction implements Action {
  readonly type = FORM_SAVE;
  constructor(public payload: DBCraftForm) {}
}
export class FormsReqSaveErrorAction implements Action {
  readonly type = FORM_SAVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class FormsReqSaveSuccessAction implements Action {
  readonly type = FORM_SAVE_SUCCESS;
  constructor(public payload: DBCraftForm) {}
}

// DELETE
export class FormsReqDelAction implements Action {
  readonly type = FORM_DEL;
  constructor(public payload: string) {}
}
export class FormsReqDelErrorAction implements Action {
  readonly type = FORM_DEL_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class FormsReqDelSuccessAction implements Action {
  readonly type = FORM_DEL_SUCCESS;
  constructor(public payload: string) {}
}

export type FormsAction =
  | FormsSelectAction
  //
  | FormsReqListAction
  | FormsReqListErrorAction
  | FormsReqListSuccessAction
  //
  | FormsReqOneAction
  | FormsReqOneErrorAction
  | FormsReqOneSuccessAction
  //
  | FormsReqAddAction
  | FormsReqAddErrorAction
  | FormsReqAddSuccessAction
  //
  | FormsReqSaveAction
  | FormsReqSaveErrorAction
  | FormsReqSaveSuccessAction
  //
  | FormsReqDelAction
  | FormsReqDelErrorAction
  | FormsReqDelSuccessAction;
