import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { AccessLevel } from 'src/app/core/enums/AccessLevels';
import { UserRole } from 'src/app/core/enums/UserRole';
import {
  AuthErrorResponse,
  AuthResponse,
  AuthUser,
  CheckResetPasswordRequest,
  CheckUsernameRequest,
  ResetPasswordRequest,
  SigninRequest,
  SigninTokenRequest,
  SignupRequest,
} from 'src/app/core/models/auth.models';
import { AuthBackendService } from 'src/app/core/services/backend/auth-backend.service';
import {
  AuthCheckResetPasswordAction,
  AuthCheckResetPasswordErrorAction,
  AuthCheckResetPasswordSuccessAction,
  AuthChekInviteAction,
  AuthChekInviteErrorAction,
  AuthChekInviteSucessAction,
  AuthChekUsernameAction,
  AuthChekUsernameErrorAction,
  AuthChekUsernameSucessAction,
  AuthLogoutAction,
  AuthLogoutSuccessAction,
  AuthResetPasswordAction,
  AuthResetPasswordErrorAction,
  AuthResetPasswordSuccessAction,
  AuthSigninAction,
  AuthSigninErrorAction,
  AuthSigninSuccessAction,
  AuthSignupAction,
  AuthSignupConfirmAction,
  AuthSignupConfirmErrorAction,
  AuthSignupConfirmSuccessAction,
  AuthSignupErrorAction,
  AuthSignupSuccessAction,
  AuthTokenSigninAction,
  AuthTokenSigninErrorAction,
  AuthTokenSigninSuccessAction,
} from 'src/app/core/store/actions/auth';
import {
  AuthStateStatus,
  getAccountsSelector,
  getAuthStatusSelector,
  getBackRedirectSelector,
  getCurrentUserSelector,
  getServerErrorSelector,
} from 'src/app/core/store/reducers/auth';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class AuthStoreService {
  public readonly backRedirect$ = this.store$.pipe(select(getBackRedirectSelector));
  public readonly currentUser$ = this.store$.pipe(select(getCurrentUserSelector));
  public readonly accounts$ = this.store$.pipe(select(getAccountsSelector));
  public readonly authStatus$ = this.store$.pipe(select(getAuthStatusSelector));
  public readonly serverError$ = this.store$.pipe(select(getServerErrorSelector));
  public readonly isAuthenticated$ = this.store$.pipe(
    select(getAuthStatusSelector),
    map((status) => status === AuthStateStatus.logged),
  );

  private currentUser: Readonly<AuthUser> | undefined;

  constructor(
    private abs: AuthBackendService, //
    private store$: Store<CoreState>,
  ) {
    this.store$.pipe(select(getCurrentUserSelector)).subscribe((user) => {
      this.currentUser = user;
    });
  }

  public get userSnapshot(): Readonly<AuthUser> | undefined {
    return this.currentUser;
  }

  public authorize(accessLevel: AccessLevel, role: UserRole = UserRole.Anonymous): boolean {
    return (accessLevel & role) > 0;
  }

  public signIn(req: SigninRequest): Observable<AuthResponse> {
    this.store$.dispatch(new AuthSigninAction(req));

    return this.abs.signin(req).pipe(
      map((res) => {
        this.store$.dispatch(new AuthSigninSuccessAction(res));
        return res;
      }),
      catchError((err: AuthErrorResponse) => {
        this.store$.dispatch(new AuthSigninErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public signInByToken(req: SigninTokenRequest): Observable<AuthResponse> {
    this.store$.dispatch(new AuthTokenSigninAction(req));

    return this.abs.signinByToken(req).pipe(
      map((res) => {
        this.store$.dispatch(new AuthTokenSigninSuccessAction(res));
        return res;
      }),
      catchError((err: AuthErrorResponse) => {
        this.store$.dispatch(new AuthTokenSigninErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public signUp(req: SignupRequest): Observable<AuthResponse | undefined> {
    this.store$.dispatch(new AuthSignupAction(req));

    return this.abs.signup(req).pipe(
      map((res) => {
        this.store$.dispatch(new AuthSignupSuccessAction(res));
        return res;
      }),
      catchError((err: AuthErrorResponse) => {
        this.store$.dispatch(new AuthSignupErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public confirmSignup(sid: string): Observable<AuthResponse | undefined> {
    this.store$.dispatch(new AuthSignupConfirmAction(sid));

    return this.abs.confirmSignup(sid).pipe(
      map((res) => {
        this.store$.dispatch(new AuthSignupConfirmSuccessAction(res));
        return res;
      }),
      catchError((err: AuthErrorResponse) => {
        this.store$.dispatch(new AuthSignupConfirmErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public checkUsername(username: string, inviteId?: string, samlId?: string): Observable<string | undefined> {
    const req: CheckUsernameRequest = { username, inviteId, samlId };
    this.store$.dispatch(new AuthChekUsernameAction(req));

    return this.abs.checkUserName(req).pipe(
      map((url) => {
        this.store$.dispatch(new AuthChekUsernameSucessAction(url));
        return url;
      }),
      catchError((err: AuthErrorResponse) => {
        this.store$.dispatch(new AuthChekUsernameErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public checkInvite(inviteId: string): Observable<string> {
    this.store$.dispatch(new AuthChekInviteAction(inviteId));

    return this.abs.checkInvite(inviteId).pipe(
      map((email) => {
        this.store$.dispatch(new AuthChekInviteSucessAction(email));
        return email;
      }),
      catchError((err: AuthErrorResponse) => {
        this.store$.dispatch(new AuthChekInviteErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public resetPassword(username: string, captcha: string): Observable<void> {
    const req: ResetPasswordRequest = { username, captcha };
    this.store$.dispatch(new AuthResetPasswordAction(req));

    return this.abs.resetPassword(req).pipe(
      map(() => {
        this.store$.dispatch(new AuthResetPasswordSuccessAction());
      }),
      catchError((err: AuthErrorResponse) => {
        this.store$.dispatch(new AuthResetPasswordErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public checkResetPassword(password: string, resetId: string): Observable<void> {
    const req: CheckResetPasswordRequest = { password, resetId };
    this.store$.dispatch(new AuthCheckResetPasswordAction(req));

    return this.abs.checkResetPassword(req).pipe(
      map(() => {
        this.store$.dispatch(new AuthCheckResetPasswordSuccessAction());
      }),
      catchError((err: AuthErrorResponse) => {
        this.store$.dispatch(new AuthCheckResetPasswordErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public logout(backRedirect: string = window.location.href.replace(window.location.origin, '')): Observable<boolean> {
    return this.isAuthenticated$.pipe(
      take(1),
      mergeMap((isAuthenticated) => {
        this.store$.dispatch(new AuthLogoutAction(backRedirect));
        return isAuthenticated ? this.abs.signout().pipe(map(() => true)) : of(false);
      }),
      catchError(() => of(false)),
      map((res) => {
        this.store$.dispatch(new AuthLogoutSuccessAction(backRedirect));
        return res;
      }),
    );
  }
}
