import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CraftItem } from 'src/app/core/models/craft.models';
import { DBIdea } from 'src/app/core/models/idea.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';

@Injectable({
  providedIn: 'root',
})
export class CraftItemsBackendService {
  constructor(private bs: BackendService) {}

  public getLinkedItemsMap(productId: string, ideaId: string): Observable<Map<string, CraftItem>> {
    return this.getLinks(productId, ideaId).pipe(
      map((list) => {
        return list.reduce((m, item) => {
          m.set(item.guid, item);
          return m;
        }, new Map<string, CraftItem>());
      }),
    );
  }

  public getLinkedItemsList(productId: string, ideaId: string): Observable<readonly CraftItem[]> {
    return this.getLinks(productId, ideaId);
  }

  public getItemsList(productId: string, parentGuid?: string): Observable<CraftItem[]> {
    return this.getItems(productId, void 0, parentGuid);
  }

  public getItemsTree(productId: string, search?: string, onlyWithChildren?: boolean): Observable<CraftItem[]> {
    return this.getItems(productId, search, void 0, onlyWithChildren).pipe(
      map((list) => {
        return this.buildTree(list);
      }),
    );
  }

  public promoteIdea(itemId: string, idea: DBIdea): Observable<void> {
    return this.bs
      .post('iportal/promote', {
        itemId,
        feedback: {
          id: idea.id,
          name: idea.name,
          description: idea.description,
        },
      })
      .pipe(
        map(() => {
          return;
        }),
      );
  }

  public linkItem(productId: string, ideaId: string, itemId: string): Observable<void> {
    return this.bs.post('portal/item', { productId, ideaId, itemId }).pipe(
      map(() => {
        return;
      }),
    );
  }

  public unlinkItem(productId: string, ideaId: string, itemId: string): Observable<void> {
    return this.bs.delete('portal/item', { productId, ideaId, itemId }).pipe(
      map(() => {
        return;
      }),
    );
  }

  public getItemsByIds(productId: string, ids: string[]): Observable<Map<string, CraftItem>> {
    return this.bs.post('portal/getitemsbyids', { productId, ids }).pipe(
      map((data: any) => {
        return data.list.reduce((m: Map<string, CraftItem>, item: CraftItem) => {
          m.set(item.id, this.mapItem(item));
          return m;
        }, new Map<string, CraftItem>());
      }),
    );
  }

  private getLinks(productId: string, ideaId: string): Observable<CraftItem[]> {
    return this.bs.get('portal/idea_items', { productId, ideaId }).pipe(
      map((data) => {
        return data.list.map((item: any) => {
          return { ...this.mapItem(item), productId };
        });
      }),
    );
  }

  private getItems(productId: string, search?: string, parentGuid?: string, onlyWithChildren = false): Observable<CraftItem[]> {
    const req: any = {};

    if (search) {
      req.search = search;
    }

    if (parentGuid) {
      req.parentGuid = parentGuid;
    }

    if (onlyWithChildren) {
      req.onlyWithChildren = true;
    }

    return this.bs.get('portal/getitems', { productId, ...req }).pipe(
      map((data) => {
        return data.list.map((item: any) => {
          return { ...this.mapItem(item), productId };
        });
      }),
    );
  }

  private buildTree(items: any[]): CraftItem[] {
    const imap = new Map<string, CraftItem>();

    return items
      .map((item) => {
        imap.set(item.guid, item);
        return item;
      })
      .reduce((res, item) => {
        if (!item.parentGuid) {
          res.push(item);
        } else {
          const parent = imap.get(item.parentGuid);
          if (parent) {
            parent.children.push(item);
          }
        }
        return res;
      }, [] as CraftItem[]);
  }

  private mapItem(item: any): CraftItem {
    item.itemTypeId = Number.parseInt(item.itemTypeId, 10) || 0;
    item.children = [];
    return item as CraftItem;
  }
}
