import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  public transform(value: string, stringLength: number, charsFromEnd: number = 9): string {
    const separator = '...';

    if (value.length <= stringLength) {
      return value;
    }

    const end = value.substring(value.length - charsFromEnd);
    const start = value.substring(0, stringLength - (charsFromEnd + separator.length));

    return start + separator + end;
  }

}
