<div class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title">
    {{ 'common.text29' | craftTerm }}
  </h1>

  <div mat-dialog-content="" class="dialog-content" [ngSwitch]="category.ideasCnt > 0">
    <div *ngSwitchCase="true" class="dialog-subtitle">
      {{ category.ideasCnt | i18nPlural: { '=1': 'common.text30', other: 'common.text31' } | craftTerm: { count: category.ideasCnt } }}
    </div>

    <craft-delete-categories-option-select
      *ngSwitchCase="true"
      [deletedCategory]="category"
      (change)="optionsChange($event)"
    ></craft-delete-categories-option-select>

    <div *ngSwitchCase="false" class="dialog-subtitle">
      {{ 'common.text35' | craftTerm }}
    </div>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <button class="btn btn-cancel dialog-btn" type="button" (click)="close()" [disabled]="isRequestSending" mat-button="">Cancel</button>
    <div matTooltip="Choose one of the two options above" [matTooltipDisabled]="isSubmitEnabled" matTooltipPosition="above">
      <button class="btn btn-blue dialog-btn" type="button" [disabled]="!isSubmitEnabled || isRequestSending" (click)="done()" mat-button="">Delete</button>
    </div>
  </div>
</div>
