import { Injectable } from '@angular/core';
import { Observable, Observer, Subject, Subscription } from 'rxjs';
import { UPLOAD_ERRORS } from 'src/app/core/dictionaries/upload-errors';
import { Attachment } from 'src/app/core/models/attachments.models';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';
import { createGuid } from 'src/app/core/utils/crypto-utils';
import { Idea } from '../../models/idea.models';

export type AttachEvent = {
  idea: Idea;
  guid: string;
  attachment: Attachment;
};

export type AttachmentSuccessEvent = {
  fileName: string;
  fileId: string;
  url: string;
  idea: Idea;
  guid: string;
};

export type AttachmentErrorEvent = {
  fileName: string;
  message: string;
  code: number;
  idea: Idea;
  guid: string;
};

type AttachSubscription = {
  subscription: Subscription;
  attachEvent: AttachEvent;
};

@Injectable({
  providedIn: 'root',
})
export class AttachService {
  private cancelSubj = new Subject<AttachEvent>();
  private successSubj = new Subject<AttachmentSuccessEvent>();
  private errorSubj = new Subject<AttachmentErrorEvent>();
  private attachSubj = new Subject<AttachEvent>();

  private uploadingMap = new Map<string, AttachSubscription>();
  private uploadErrors = UPLOAD_ERRORS;

  public readonly attach$ = this.attachSubj.asObservable();
  public readonly success$ = this.successSubj.asObservable();
  public readonly error$ = this.errorSubj.asObservable();
  public readonly cancel$ = this.cancelSubj.asObservable();

  constructor(
    private bs: BackendService, //
    private portalStore: PortalStoreService,
  ) {}

  public get uploadingSize() {
    return this.uploadingMap.size;
  }

  public attachFiles(files: FileList | File[], idea: Idea): Observable<AttachmentSuccessEvent> {
    return new Observable((observer: Observer<AttachmentSuccessEvent>) => {
      const batch = new Map<string, Subscription>();
      const portalId = this.portalStore.portalSnapshot!.id!;

      const completeUpload = (guid: string) => {
        batch.delete(guid);
        this.uploadingMap.delete(guid);
        if (batch.size === 0 || this.uploadingMap.size === 0) {
          observer.complete();
        }
      };

      Array.from(files).forEach((file) => {
        const fileName = file.name;
        const guid = createGuid();
        const attachEvent: AttachEvent = { idea, guid, attachment: { name: fileName, guid } };

        this.attachSubj.next(attachEvent);

        const subscription = this.bs.upload({ portalId, file, guid }).subscribe({
          next: ({ fileId, url }) => {
            const event: AttachmentSuccessEvent = {
              fileId,
              fileName,
              url: url as string,
              guid,
              idea,
            };

            observer.next(event);
            this.successSubj.next(event);

            completeUpload(guid);
          },
          error: (error: ErrorResponse) => {
            const event: AttachmentErrorEvent = {
              fileName,
              message: this.errorMessageHandler(error),
              code: error.status,
              idea,
              guid,
            };

            this.errorSubj.next(event);
            completeUpload(guid);
          },
        });

        batch.set(guid, subscription);
        this.uploadingMap.set(guid, { subscription, attachEvent });
      });

      return () => {
        for (const [guid, subscription] of batch) {
          this.uploadingMap.delete(guid);
          subscription.unsubscribe();
        }
        batch.clear();
      };
    });
  }

  public cancel(guid: string) {
    const value = this.uploadingMap.get(guid);
    if (!value) return;

    this.uploadingMap.delete(guid);
    value.subscription.unsubscribe();
    this.cancelSubj.next(value.attachEvent);
  }

  public cancelAll() {
    for (const [, { subscription, attachEvent }] of this.uploadingMap) {
      subscription.unsubscribe();
      this.cancelSubj.next(attachEvent);
    }
    this.uploadingMap.clear();
  }

  public getUploadingAttaches(ideaId: string) {
    const attachments: Attachment[] = [];
    for (const [, { attachEvent }] of this.uploadingMap) {
      if (attachEvent.idea.id === ideaId) {
        attachments.push(attachEvent.attachment);
      }
    }
    return attachments;
  }

  private errorMessageHandler(res: ErrorResponse): string {
    if (res.status === 400) {
      return this.uploadErrors[res.message] || res.message;
    }

    if (res.status === 413) {
      return this.uploadErrors['errors.fileIsLarge'];
    }

    return res.message;
  }
}
