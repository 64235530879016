import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';
import { safeJSONParse } from 'src/app/core/utils/text-utils';

export type UploadProgressEvent = {
  readonly progress: number;
  readonly guids: Set<string>;
  readonly event: ProgressEvent;
};

export type UploadParams = {
  readonly url: string;
  readonly files: FileList | File[] | File;
  readonly data?: any;
  readonly headers?: HttpHeaders;
};

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private readonly progress$ = new Subject<UploadProgressEvent>();

  public readonly progressUpload$ = this.progress$.asObservable();

  constructor() {}

  public upload(params: UploadParams): Observable<any> {
    const { url, files, data, headers } = params;
    const filesList = files instanceof File ? [files] : Array.from(files);

    return new Observable((observer: Observer<any>) => {
      const fd = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest(),
        guids = new Set<string>();

      if (data) {
        Object.keys(data).forEach((key) => {
          if (data[key] !== void 0) {
            fd.append(key, data[key]);
          }
        });
      }

      Array.from(filesList).forEach((file, idx) => {
        const guid = Array.isArray(data?.guids) ? data.guids[idx] : file.name;
        guids.add(guid);
        fd.append('file', file);
      });

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(safeJSONParse(xhr.response));
          } else {
            const error = new HttpErrorResponse({
              error: safeJSONParse(xhr.response),
              status: xhr.status,
              statusText: xhr.statusText,
              url: xhr.responseURL,
            });

            observer.error(error);
          }
          observer.complete();
        }
      };

      xhr.upload.onprogress = (event) => {
        const progress = Math.round((event.loaded / event.total) * 100);
        this.progress$.next({ progress, event, guids });
      };

      xhr.open('POST', url, true);

      if (headers) {
        headers.keys().forEach((key) => {
          const val = headers.get(key);
          if (val) {
            xhr.setRequestHeader(key, val);
          }
        });
      }

      xhr.send(fd);

      return () => xhr.abort();
    });
  }
}
