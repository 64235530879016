import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigService } from 'src/app/core/services/config.service';
import { IconService } from 'src/app/core/services/icon.service';
import { clearElement } from 'src/app/core/utils/dom-utils';

@Component({
  selector: 'craft-icon',
  template: '<svg></svg>',
  styleUrls: ['./craft-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CraftIconComponent implements AfterViewInit, OnChanges {
  @Input() public name: string;
  @Input() public sprite: string;
  @Input() public defaultName: string;

  constructor(
    private element: ElementRef,
    private config: ConfigService,
    private iconService: IconService,
  ) {}

  private get id() {
    return this.name || this.defaultName;
  }

  private get path() {
    return this.sprite || this.config.env.spriteUrl;
  }

  public ngAfterViewInit() {
    if (!this.id) return;
    this.update();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!changes.name.firstChange) {
      this.update();
    }
  }

  private async update() {
    const svg = this.element.nativeElement.firstChild;
    clearElement(svg);
    if (!this.id) return;

    const sprite = await this.iconService.getSprite(this.path);
    const icon = sprite.getElementById(this.id);
    if (!icon) return;

    const clone = icon.cloneNode(true);
    const fragment = document.createDocumentFragment();
    while (clone.lastChild) {
      fragment.appendChild(clone.lastChild);
    }

    svg.setAttribute('viewBox', icon.getAttribute('viewBox'));
    svg.appendChild(fragment);
  }
}
