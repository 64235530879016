import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { concatWith, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Category } from 'src/app/core/models/categories.models';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { EntityFactory } from 'src/app/core/store/services/entity.factory';

@Component({
  selector: 'craft-categories-autocomplete',
  templateUrl: './craft-categories-autocomplete.component.html',
  styleUrls: ['./craft-categories-autocomplete.component.scss'],
})
export class CraftCategoriesAutocompleteComponent implements OnInit, OnDestroy {
  @Input() public selectedId?: string;
  @Input() public newCategoryId? = '-1';
  @Output() public select = new EventEmitter<Readonly<Category>>();
  @Output() public enterPressed = new EventEmitter<KeyboardEvent>();
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  public disabled = false;
  public showAutoComplete = false;
  public searchControl = new UntypedFormControl('');
  public selectedCategory: Readonly<Category> | undefined;
  public categories$: Observable<ReadonlyArray<Readonly<Category>>>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private entityFactory: EntityFactory, //
    private categoriesStore: CategoriesStoreService,
  ) {}

  public ngOnInit() {
    combineLatest([
      this.categoriesStore.map$, //
      this.select.pipe(startWith(void 0)),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([categoriesMap, selected]) => {
        if (selected) {
          this.selectedId = selected.id;
          this.selectedCategory = selected;
        } else if (this.selectedId) {
          this.selectedCategory = categoriesMap[this.selectedId];
        }
      });

    this.categories$ = of('').pipe(
      concatWith(this.searchControl.valueChanges),
      map((value) => (typeof value === 'string' ? value : value.name)),
      switchMap((searchText: string) =>
        this.categoriesStore.list$.pipe(
          map((categories) => {
            if (!searchText) return categories;

            const text = searchText.toLowerCase();
            return categories.filter((c) => c.name!.toLowerCase().includes(text));
          }),
        ),
      ),
    );
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public selectCategory(c?: Readonly<Category>) {
    const cat$ = c && c.id ? of(c) : this.createCategory();
    cat$.pipe(takeUntil(this.destroy$)).subscribe((cat) => {
      this.select.next(cat);
    });
  }

  public showInput() {
    this.showAutoComplete = true;
    this.searchControl.setValue('');
  }

  public menuClosed() {
    this.showAutoComplete = false;
  }

  public onEnterKey(e: any, categories: ReadonlyArray<Readonly<Category>>) {
    if (categories.length > 0) {
      this.selectCategory(categories[0]);
    } else {
      this.enterPressed.next(e);
      this.selectCategory(void 0);
    }

    if (this.autocomplete) {
      this.autocomplete.closePanel();
    }
  }

  public displayFn(category?: Readonly<Category>): string {
    return category && category.name ? category.name : '';
  }

  private createCategory() {
    const val = this.searchControl.value;
    const name = typeof val === 'string' ? val : val.name;
    return this.entityFactory.createCategory({ id: this.newCategoryId, name });
  }
}
