import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Category } from 'src/app/core/models/categories.models';
import { Portal } from 'src/app/core/models/portal.models';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { EntityFactory } from 'src/app/core/store/services/entity.factory';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';
import { CategoryPrivacySettingsComponent } from 'src/app/shared/dialogs/category-privacy-settings/category-privacy-settings.component';
import { IdeaCreateDialogComponent } from 'src/app/shared/dialogs/idea-create/idea-create.component';

@Component({
  templateUrl: './category-create.component.html',
  styleUrls: ['./category-create.component.scss'],
})
export class CategoryCreateComponent implements AfterViewInit, OnInit, OnDestroy {
  public isSubmitted = false;
  public isSending = false;

  @ViewChild('nameInput') public nameInputRef: ElementRef;

  public readonly form = new UntypedFormGroup({
    name: new UntypedFormControl(void 0, { validators: [Validators.required] }),
    description: new UntypedFormControl(void 0),
    companies: new UntypedFormControl(void 0),
    isPrivate: new UntypedFormControl(void 0),
    ideaVisibility: new UntypedFormControl(void 0),
  });

  private readonly destroy$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private entityFactory: EntityFactory,
    private portalStore: PortalStoreService,
    private categoriesStore: CategoriesStoreService,
    private dialogRef: MatDialogRef<CategoryCreateComponent, Category>,
  ) {}

  public ngOnInit() {
    const portal = this.portalStore.portalSnapshot as Portal;

    if (portal.categoryDescriptionMandatory) {
      this.form.controls['description'].setValidators(Validators.required);
    }
  }

  public ngAfterViewInit() {
    this.setNameFocus();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public close() {
    this.dialogRef.close();
  }

  public saveAndExit() {
    this.isSubmitted = true;
    if (this.form.invalid) return;

    this.entityFactory
      .createCategory(this.form.value)
      .pipe(
        switchMap((category) => {
          return this.categoriesStore.add(category);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((category) => {
        this.dialogRef.close(category);
      });
  }

  public saveAndCreateIdea() {
    this.isSubmitted = true;
    if (this.form.invalid) return;

    this.isSending = true;
    this.entityFactory
      .createCategory(this.form.value)
      .pipe(
        switchMap((category) => {
          return this.categoriesStore.add(category);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((category) => {
        this.dialog.open(IdeaCreateDialogComponent, { autoFocus: false, maxWidth: '100vw', data: { categoryId: category.id } });
        this.dialogRef.close(category);
      });
  }

  public showPrivacyDialog() {
    this.entityFactory
      .createCategory(this.form.value)
      .pipe(
        switchMap((category) => this.openPrivacyDialog(category)),
        filter((category): category is Category => !!category),
        takeUntil(this.destroy$),
      )
      .subscribe((category) => {
        this.form.patchValue(category, { emitEvent: false });
      });
  }

  private openPrivacyDialog(category: Category): Observable<Category | undefined> {
    return this.dialog
      .open(CategoryPrivacySettingsComponent, {
        backdropClass: 'night-backdrop',
        data: category,
      })
      .afterClosed();
  }

  private setNameFocus() {
    if (this.nameInputRef) {
      this.nameInputRef.nativeElement.focus();
    }
  }
}
