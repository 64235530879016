import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';
import { UPLOAD_COMPLETE_EVENT } from 'src/app/core/dictionaries/socket-events';
import { ErrorResponse, StringStringMap } from 'src/app/core/models/common.models';
import { UploadRequest, UploadResponse } from 'src/app/core/models/upload-params';
import { ConfigService } from 'src/app/core/services/config.service';
import { UploadService } from 'src/app/core/services/upload.service';
import { WebSocketService } from 'src/app/core/services/web-socket.service';
import { AuthLogoutSuccessAction } from 'src/app/core/store/actions/auth';
import { CoreState } from 'src/app/core/store/reducers';
import { isObject } from 'src/app/core/utils/app-utils';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private store$: Store<CoreState>,
    private webSocket: WebSocketService,
    private uploadService: UploadService,
  ) {}

  public get<R = any>(path: string, params?: any, raw = false, responseType = 'json', returnResponse = false): Observable<R> {
    return this.getHeaders().pipe(
      mergeMap((headers) => {
        const url = this.getUrl(path);

        const options: any = {
          params: isObject(params) ? JSON.parse(JSON.stringify(params)) : params, // remove undefined
          headers,
          responseType,
        };

        if (returnResponse) {
          options.observe = 'response';
        }

        return this.http.get(url, options);
      }),
      map((res) => (raw ? res : this.getResponsePayload(res))),
      catchError((res) => this.catchErrors(res)),
    );
  }

  public post<R = any>(path: string, data?: any, raw = false): Observable<R> {
    return this.getHeaders().pipe(
      mergeMap((headers) => {
        const url = this.getUrl(path);
        return this.http.post(url, data, { headers });
      }),
      map((res) => (raw ? res : this.getResponsePayload(res))),
      catchError((res) => this.catchErrors(res)),
    );
  }

  public put<R = any>(path: string, data?: any, raw = false): Observable<R> {
    return this.getHeaders().pipe(
      mergeMap((headers) => {
        const url = this.getUrl(path);
        return this.http.put(url, data, { headers });
      }),
      map((res) => (raw ? res : this.getResponsePayload(res))),
      catchError((res) => this.catchErrors(res)),
    );
  }

  public delete<R = any>(path: string, params?: any, raw = false): Observable<R> {
    return this.getHeaders().pipe(
      mergeMap((headers) => {
        const url = this.getUrl(path);
        return this.http.delete(url, { params, headers });
      }),
      map((res) => (raw ? res : this.getResponsePayload(res))),
      catchError((res) => this.catchErrors(res)),
    );
  }

  public upload(req: UploadRequest): Observable<UploadResponse> {
    const { file, portalId, guid, url = 'file/upload', socket = true } = req;
    return this.getHeaders().pipe(
      mergeMap((headers) => {
        const finalUrl = this.getUrl(url);
        return this.uploadService
          .upload({
            headers,
            url: finalUrl,
            files: file,
            data: {
              productId: portalId,
              guids: guid ? [guid] : void 0,
            },
          })
          .pipe(
            map((res) => res.data),
            mergeMap((data: any) => {
              const { id: fileId, url } = data;
              if (!socket) return of({ fileId, url });

              return this.webSocket.message$.pipe(
                filter((msg) => msg.event === UPLOAD_COMPLETE_EVENT && msg.data.fileId === fileId),
                take(1),
                map((msg) => ({ fileId, url: msg.data.fileHref })),
              );
            }),
            catchError((res) => this.catchErrors(res)),
          );
      }),
    );
  }

  private getHeaders() {
    const headers: StringStringMap = {
      'X-Domain-Url': this.config.hostname,
    };

    if (this.config.clientId) {
      headers[this.config.env.clientIdHeader] = this.config.clientId;
    }

    return of(new HttpHeaders(headers));
  }

  private getUrl(path: string) {
    return `${this.config.env.apiRoot}/${path}`;
  }

  private getResponsePayload(responce: any) {
    return responce ? responce.data : void 0;
  }

  private catchErrors(res: HttpErrorResponse) {
    let data: any;
    let code = res.status + '';
    let message = res.message || res.statusText;

    const errorData = res.error;
    if (errorData) {
      if (typeof errorData === 'object') {
        data = errorData.data;
        code = errorData.code || code;
        if (typeof errorData.error === 'string') {
          message = errorData.error;
        }
      } else if (typeof errorData === 'string') {
        message = errorData;
      }
    }

    const result: ErrorResponse = { status: res.status, code, message, data };

    if (result.status === 401) {
      this.store$.dispatch(new AuthLogoutSuccessAction());
    }

    return throwError(() => result);
  }
}
