import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[craftAutoFocus]',
})
export class CraftAutoFocusDirective implements OnChanges, OnDestroy, AfterViewInit {
  @Input() public focusDelay = 0;
  @Input() public focusToggle?: boolean;

  private timerId: number;

  constructor(private host: ElementRef) {}

  public ngOnDestroy() {
    window.clearTimeout(this.timerId);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.focusToggle && !changes.focusToggle.firstChange) {
      this.applyFocus();
    }
  }

  public ngAfterViewInit() {
    this.applyFocus();
  }

  private applyFocus() {
    if (this.focusToggle === false) return;

    if (this.focusDelay > 0) {
      this.timerId = window.setTimeout(this.setFocus, this.focusDelay);
    } else {
      this.setFocus();
    }
  }

  private setFocus = () => {
    const el = this.host.nativeElement;
    if (!el || typeof el.focus !== 'function') return;
    el.focus();
  };
}
