import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, switchMap } from 'rxjs/operators';
import { CategoriesReqDelSuccessAction, CATEGORIES_DEL_SUCCESS } from 'src/app/core/store/actions/categories';
import { ProductsStoreService } from 'src/app/core/store/services/products-store.service';
import {
  IdeasReqAddSuccessAction,
  IdeasReqDelSuccessAction,
  IdeasReqSaveSuccessAction,
  IDEAS_ADD_SUCCESS,
  IDEAS_DEL_SUCCESS,
  IDEAS_SAVE_SUCCESS,
} from '../actions/ideas';
import { CategoriesStoreService } from '../services/categories-store.service';

type IdeasListChageAction = IdeasReqAddSuccessAction | IdeasReqDelSuccessAction | IdeasReqSaveSuccessAction;

@Injectable()
export class CategoriesEffects {
  constructor(
    private actions$: Actions, //
    private productsStore: ProductsStoreService,
    private categoriesStore: CategoriesStoreService,
  ) {}

  public ideasListChange$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasListChageAction>(
          IDEAS_ADD_SUCCESS, //
          IDEAS_DEL_SUCCESS,
          IDEAS_SAVE_SUCCESS,
        ),
        switchMap(() => {
          return this.productsStore.selectedId$;
        }),
        filter((productId): productId is string => !!productId),
        switchMap((productId) => {
          return this.categoriesStore.loadList(productId);
        }),
      );
    },
    { dispatch: false },
  );
}
