<div class="dialog-wrap" [formGroup]="form">
  <div mat-dialog-title class="dialog-title">
    <h1>{{ field?.id ? 'Edit' : 'New' }} number field</h1>
    <div class="title-btns">
      <craft-custom-presets-dropdown class="presets" [filterByType]="'NUMBER'" (select)="selectPreset($event)"></craft-custom-presets-dropdown>
      <craft-icon class="svg-icon" name="general--close-md" (click)="close()"></craft-icon>
    </div>
  </div>

  <div mat-dialog-content="" class="dialog-content">
    <textarea placeholder="Field name" class="label-input" formControlName="label" maxlength="255" craftAutoresize=""></textarea>
    <craft-custom-fields-add-description-button [control]="form.get('description')" class="d-block mt-15"></craft-custom-fields-add-description-button>

    <div class="options">
      <h3>More Options</h3>
      <label class="max-val">
        Max value
        <craft-number-input formControlName="max" [allowDecimals]="false" [allowSign]="false" class="ml-8"></craft-number-input>
      </label>

      <h3>Format</h3>
      <mat-button-toggle-group formControlName="format">
        <mat-button-toggle *ngFor="let format of numberFormats" [value]="format.id">{{ format.label }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end mt-20">
    <div class="right-part d-flex align-items-center justify-end">
      <button class="btn-cancel" type="button" (click)="close()">Cancel</button>
      <button class="btn-save" type="submit" (click)="done()" [disabled]="form.invalid">Save</button>
    </div>
  </div>
</div>
