<div class="uploading-dialog" [ngClass]="{ reduced: isReduced }">
  <h1 (click)="toggleReduce()" [ngSwitch]="progress.size > 0" class="dialog-title">
    <ng-container *ngSwitchCase="true">{{ progress.size | i18nPlural: uploadsMapping }}</ng-container>
    <ng-container *ngSwitchCase="false">{{ this.attachEvents.length - errors.size | i18nPlural: completedMapping }} </ng-container>
    <button type="button" class="btn btn-close" (click)="onClose()">
      <craft-icon class="svg-icon icon-close" name="general--close-md"></craft-icon>
    </button>
  </h1>

  <div mat-dialog-content="" class="dialog-content">
    <div class="upload-item" *ngFor="let ae of attachEvents; trackBy: identify">
      <div class="upload" [ngSwitch]="errors.has(ae.attachment.guid!)">
        <!-- error -->
        <div class="left-side err" *ngSwitchCase="true">
          <div class="err-block">
            <craft-icon class="svg-icon icon-warn" name="general--warning-current-color"></craft-icon>
            <span class="file-name ellipsis">{{ ae.attachment.name | truncate: 25 }}</span>
          </div>
          Failed
        </div>

        <!-- no error -->
        <div class="left-side" *ngSwitchCase="false">
          <craft-icon class="svg-icon icon-attach" name="attachments--attach-unknown-lg"></craft-icon>
          <div class="right-side" [ngSwitch]="!!ae.attachment.id">
            <span class="file-name ellipsis">{{ ae.attachment.name | truncate: 25 }}</span>
            <!-- in progress -->
            <div class="progress-wrap" *ngSwitchCase="false">
              <mat-progress-bar mode="determinate" [value]="progress.get(ae.attachment.guid!)"></mat-progress-bar>
              <span class="progress-value">{{ progress.get(ae.attachment.guid!) }}%</span>
            </div>
            <!-- success -->
            <span class="upload-status" *ngSwitchCase="true">Uploaded successfully</span>
          </div>
        </div>

        <!-- no error -->
        <div class="right-side" *ngSwitchCase="false" [ngSwitch]="!!ae.attachment.id">
          <button *ngSwitchCase="false" class="btn btn-remove" type="button" matTooltip="Cancel upload" matTooltipPosition="right" (click)="cancelUpload(ae)">
            <craft-icon class="svg-icon icon-close" name="general--close-md"></craft-icon>
          </button>
          <button
            *ngSwitchCase="true"
            class="btn btn-idea"
            type="button"
            matTooltip="{{ 'common.text46' | craftTerm }}"
            matTooltipPosition="right"
            (click)="showIdea(ae)"
          >
            <craft-icon class="svg-icon icon-viewin" name="general--viewin-md"></craft-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
