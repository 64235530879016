import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, of, switchMap } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { StaticFilterType } from 'src/app/core/enums/StaticFilterType';
import { UserPermissionsService } from 'src/app/core/services/user-permissions.service';
import { WebStorageService } from 'src/app/core/services/web-storage.service';
import { IdeasStateStatus } from 'src/app/core/store/reducers/ideas';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { FiltersStoreService } from 'src/app/core/store/services/filters-store.service';
import { IdeasStoreService } from 'src/app/core/store/services/ideas-store.service';
import { ProductsStoreService } from 'src/app/core/store/services/products-store.service';

export enum EmptyStateType {
  notEmpty = 1,
  emptyIdeas,
  emptyMyIdeas,
  emptyCategoryIdeas,
  emptyTrackedIdeas,
  emptyArchivedIdeas,
  emptyWithFilters,
}
const SKIP_NAME = 'craft:empty:skipped';

@Injectable({
  providedIn: 'root',
})
export class EmptyStateService {
  public readonly isShowEmptyCategoryDialog$: Observable<boolean>;
  public readonly emptyType$: Observable<EmptyStateType>;

  constructor(
    private ups: UserPermissionsService,
    private webStorage: WebStorageService,
    private ideasStore: IdeasStoreService,
    private filtersStore: FiltersStoreService,
    private productStore: ProductsStoreService,
    private categoriesStore: CategoriesStoreService,
  ) {
    this.emptyType$ = combineLatest([
      this.ideasStore.status$, //
      this.ideasStore.total$,
      this.filtersStore.state$,
    ]).pipe(
      filter(([status]) => status == IdeasStateStatus.loaded),
      map(([, total, filtersState]) => {
        if (total > 0) return EmptyStateType.notEmpty;

        const { categoryId, staticFilter } = filtersState;

        if (this.filtersStore.isDynamicFiltersExists(filtersState)) {
          return EmptyStateType.emptyWithFilters;
        }

        if (categoryId) {
          return EmptyStateType.emptyCategoryIdeas;
        }

        if (staticFilter === StaticFilterType.my) {
          return EmptyStateType.emptyMyIdeas;
        }

        if (staticFilter === StaticFilterType.tracked) {
          return EmptyStateType.emptyTrackedIdeas;
        }

        if (staticFilter === StaticFilterType.archived) {
          return EmptyStateType.emptyArchivedIdeas;
        }

        return EmptyStateType.emptyIdeas;
      }),
    );
  }

  public isNeedToShowEmptyCategoriesDialog(): Observable<boolean> {
    return this.productStore.selectedId$.pipe(
      switchMap((productId) => {
        return forkJoin([
          this.categoriesStore.isEmpty$.pipe(take(1)), //
          this.filtersStore.state$.pipe(take(1)),
          of(productId),
        ]);
      }),
      map(([isCategoriesEmpty, filtersState, productId]) => {
        return (
          isCategoriesEmpty &&
          filtersState.staticFilter === StaticFilterType.all &&
          this.ups.allowCreateCategory &&
          !this.isProductIdSkipped(productId) &&
          !this.filtersStore.isDynamicFiltersExists(filtersState)
        );
      }),
    );
  }

  public addProductToSkipped(productId?: string) {
    if (!productId) return;

    const skipped: string[] = this.webStorage.storage(SKIP_NAME) || [];
    if (!skipped.includes(productId)) {
      skipped.push(productId);
    }

    this.webStorage.storage(SKIP_NAME, skipped);
  }

  public isProductIdSkipped(productId?: string): boolean {
    if (!productId) return false;

    const skipped: string[] = this.webStorage.storage(SKIP_NAME);
    return Array.isArray(skipped) && skipped.includes(productId);
  }
}
