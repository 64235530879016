import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { concatWith, distinctUntilChanged, map } from 'rxjs/operators';
import { StringAnyMap } from 'src/app/core/models/common.models';
import { getSortByString } from 'src/app/core/utils/sort-utils';

type SortPriority = {
  selected: any[];
  other: any[];
};

@Component({
  template: '',
})
export abstract class BaseMultiSelectComponent implements OnChanges, ControlValueAccessor {
  @Input() public items: ReadonlyArray<any> | Readonly<StringAnyMap>;
  @Input() public idFieldName: string;
  @Input() public labelFieldName: string;
  @Input() public selectedIds: readonly string[] = [];
  @Input() public searchVisibleCount = 10;
  @Input() public disabled = false;
  @Input() public disableRipple = false;
  @Input() public searchPlaceholder = 'Search';

  @Output() public opened = new EventEmitter<void>();
  @Output() public closed = new EventEmitter<void>();
  @Output() public select = new EventEmitter<any>();
  @Output() public unselect = new EventEmitter<any>();

  @ViewChild('searchInput') public searchInput: ElementRef;

  public itemsCount = 0;
  public selectedText: string;
  public filteredItems: Observable<any[]>;
  public selectedSet = new Set<string>();

  public searchControl = new UntypedFormControl();

  protected onTouched: () => void = () => {};
  protected onChange: (_: any) => void = (_) => {};

  constructor(protected changeDetectorRef: ChangeDetectorRef) {
    this.filteredItems = of('').pipe(
      concatWith(this.searchControl.valueChanges.pipe(distinctUntilChanged())),
      map((searchText) => this.sortItems(this.filterItems(searchText))),
    );
  }

  public get hasSearch(): boolean {
    return this.itemsCount >= this.searchVisibleCount;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.setItemsCount();
    }

    if (changes.selectedIds) {
      this.setSelectedSet();
    }
  }

  public identify(index: number, item: any): string | number {
    return item?.[this.idFieldName] || index;
  }

  public selectItem(item: any, event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.changeSelectedItems(item);
    this.onValueChange(Array.from(this.selectedSet.keys()));
  }

  public onMenuOpened() {
    this.onTouched();
    this.opened.next();
  }

  public onMenuClosed() {
    this.searchControl.setValue('');
    this.closed.next();
  }

  public writeValue(value: any): void {
    this.selectedIds = value || [];
    this.setSelectedSet();
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  protected filterItems(searchText: string) {
    const items = this.getItemsAsArray();

    if (!searchText) {
      return items;
    } else {
      const text = searchText.toLowerCase();
      return items.filter((i) => (i[this.labelFieldName] + '').toLowerCase().includes(text));
    }
  }

  protected setItems(items: any[] | StringAnyMap, selectedIds: readonly string[]) {
    this.items = items;
    this.selectedIds = selectedIds || [];
    this.setItemsCount();
    this.setSelectedSet();
  }

  protected setSelectedSet() {
    this.selectedSet = new Set(this.selectedIds);
  }

  protected setItemsCount() {
    const items = this.items;
    this.itemsCount = items ? (Array.isArray(items) ? items.length : Object.keys(items).length) : 0;
  }

  protected changeSelectedItems(item: any) {
    const itemId = item[this.idFieldName];

    if (!this.selectedSet.has(itemId)) {
      this.selectedSet.add(itemId);
      this.select.next(item);
    } else {
      this.selectedSet.delete(itemId);
      this.unselect.next(item);
    }
  }

  protected sortItems(items: any[]): any[] {
    let res: SortPriority;

    if (this.selectedSet.size === 0) {
      res = { selected: [], other: items };
    } else {
      res = items.reduce(
        (acc: SortPriority, item) => {
          if (this.selectedSet.has(item[this.idFieldName])) {
            acc.selected.push(item);
          } else {
            acc.other.push(item);
          }
          return acc;
        },
        { selected: [], other: [] } as SortPriority,
      );
    }

    const sortFn = getSortByString(this.labelFieldName);
    return res.selected.sort(sortFn).concat(res.other);
  }

  protected onValueChange(items: any[]) {
    this.onChange(items);
  }

  protected getItemsAsArray() {
    return Array.isArray(this.items) ? this.items : typeof this.items === 'object' && this.items !== null ? Object.values(this.items) : [];
  }

  protected getSelectedValues() {
    return this.getItemsAsArray().filter((item) => this.selectedSet.has(item[this.idFieldName]));
  }
}

@Component({
  selector: 'craft-multi-select',
  templateUrl: './craft-multi-select.component.html',
  styleUrls: ['./craft-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CraftMultiSelectComponent),
      multi: true,
    },
  ],
})
export class CraftMultiSelectComponent extends BaseMultiSelectComponent {
  constructor(protected changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }
}
