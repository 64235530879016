import { Pipe, PipeTransform } from '@angular/core';
import { getMediumName } from 'src/app/core/utils/text-utils';

@Pipe({
  name: 'mediumName'
})
export class MediumNamePipe implements PipeTransform {

  public transform(user: any): string {
    return getMediumName(user);
  }

}
