import { Component } from '@angular/core';
import { CraftFileSelectButtonComponent } from 'src/app/shared/components/craft-file-select-button/craft-file-select-button.component';

@Component({
  selector: 'craft-attach-select-button',
  templateUrl: './craft-attach-select-button.component.html',
  styleUrls: ['./craft-attach-select-button.component.scss'],
})
export class CraftAttachSelectButtonComponent extends CraftFileSelectButtonComponent {
  constructor() {
    super();
  }
}
