<form novalidate="" [formGroup]="form" (submit)="saveAndExit()" class="new-category-dialog">
  <craft-category-dialog-menu
    class="d-contents"
    *craftPermissions="'ProcessOwner'; restricted: true; operator: 'and'"
    (privacySelect)="showPrivacyDialog()"
  ></craft-category-dialog-menu>

  <button type="button" (click)="close()" class="btn btn-close text-size-15">
    <craft-icon class="svg-icon" name="general--close-md"></craft-icon>
  </button>

  <h1 class="dialog-title">
    {{ 'common.text12' | craftTerm }}
  </h1>

  <div mat-dialog-content="" class="dialog-content">
    <div class="title-wrap">
      <textarea
        class="new-category-title textarea-input"
        [attr.placeholder]="'common.text16' | craftTerm"
        formControlName="name"
        maxlength="255"
        craftAutoresize=""
        [disableLineBreak]="true"
        #nameInput
      ></textarea>
      <mat-error *ngIf="isSubmitted && form.controls['name'].invalid">
        {{ 'common.text13' | craftTerm }}
      </mat-error>
    </div>

    <craft-text-editor class="new-category-desc textarea-input w-100" formControlName="description" [maxlength]="10000" placeholder="Add short description">
    </craft-text-editor>
    <mat-error *ngIf="isSubmitted && form.controls['description'].invalid">Please enter description – it's required. </mat-error>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex">
    <button class="btn btn-border dialog-btn shrink-0" type="submit" mat-button="">Save</button>
    <button
      class="btn btn-blue dialog-btn shrink-0 ml-12"
      type="button"
      (click)="saveAndCreateIdea()"
      [disabled]="isSending"
      [matTooltip]="'common.text38' | craftTerm"
      matTooltipPosition="above"
      mat-button=""
    >
      <craft-icon class="svg-icon text-size-11 mr-4" name="general--plus-sm"></craft-icon>
      <span class="capitalize">
        {{ 'activity.idea' | craftTerm }}
      </span>
    </button>
  </div>
</form>
