import { Pipe, PipeTransform } from '@angular/core';
import { getFullName } from 'src/app/core/utils/text-utils';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {

  public transform(user: any): string {
    return getFullName(user);
  }

}
