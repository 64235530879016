<ng-container *ngIf="user?.id; else noUser">
  <ng-container *ngIf="!user.fileId; then shortNameTpl; else avatarTmpl"></ng-container>
</ng-container>

<ng-template #shortNameTpl>
  <span class="circle short-name" [style.borderColor]="user.color" [style.backgroundColor]="user.color">
    {{ user | shortName }}
  </span>
</ng-template>

<ng-template #avatarTmpl>
  <span class="circle" [style.backgroundImage]="user.fileId | thumbBkg: 40" [style.borderColor]="user.color"></span>
</ng-template>

<ng-template #noUser>
  <span class="circle no-user">
    <craft-icon class="svg-icon text-size-11" name="subnav--teamember-sm"></craft-icon>
  </span>
</ng-template>
