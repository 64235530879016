import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StringBooleanMap } from 'src/app/core/models/common.models';
import { Portal } from 'src/app/core/models/portal.models';
import {
  PortalAction,
  PORTAL_CLEAR_DATA_CACHE,
  PORTAL_GET_ERROR,
  PORTAL_GET_SUCCESS,
  PORTAL_SAVE_SUCCESS,
  PORTAL_SET_DATA_CACHE
} from 'src/app/core/store/actions/portal';

export type PortalState = {
  readonly portal?: Portal;
  readonly relatedDataCache: StringBooleanMap;
};

export const initialState: PortalState = {
  portal: void 0,
  relatedDataCache: {},
};

export function reducer(state = initialState, action: PortalAction): PortalState {
  switch (action.type) {
    case PORTAL_GET_SUCCESS: {
      return {
        ...state,
        portal: { ...action.payload.portal },
        relatedDataCache: {},
      };
    }

    case PORTAL_GET_ERROR: {
      return {
        ...state,
        portal: void 0,
        relatedDataCache: {},
      };
    }

    case PORTAL_SET_DATA_CACHE: {
      return {
        ...state,
        relatedDataCache: { ...state.relatedDataCache, ...action.payload },
      };
    }

    case PORTAL_CLEAR_DATA_CACHE: {
      return {
        ...state,
        relatedDataCache: {},
      };
    }

    case PORTAL_SAVE_SUCCESS: {
      return {
        ...state,
        portal: { ...action.payload },
        relatedDataCache: {},
      };
    }

    default:
      return state;
  }
}

const getPortal = (state: PortalState) => state.portal;
const getCache = (state: PortalState) => state.relatedDataCache;

export const getPortalStateSelector = createFeatureSelector<PortalState>('portalState');
export const getPortalSelector = createSelector(getPortalStateSelector, getPortal);
export const getRelatedDataCacheSelector = createSelector(getPortalStateSelector, getCache);
