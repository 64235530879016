<div
  *ngFor="let a of attachments; trackBy: identify"
  [ngClass]="{ 'upload-loading': !a.id, 'upload-error': errors.has(a.guid!) }"
  class="upload upload-loading mt-10 d-flex align-items-center justify-between"
  matTooltipPosition="above"
  matTooltipClass="error"
  [matTooltipDisabled]="!errors.has(a.guid!)"
  [matTooltip]="errors.get(a.guid!)?.message || ''"
>
  <div class="d-flex align-items-center left-side">
    <craft-icon class="svg-icon text-size-15 mr-8" name="formatbar--attach-md"></craft-icon>
    <span class="file-name ellipsis">{{ a.name }}</span>
    <div *ngIf="!a.id" class="progress-wrap d-flex align-items-center justify-between ml-16">
      <mat-progress-bar mode="determinate" [value]="progress.get(a.guid!)"></mat-progress-bar>
      <span class="progress-value ml-8">{{ progress.get(a.guid!) }}%</span>
    </div>
  </div>
  <div class="d-flex align-items-center right-side">
    <button class="btn btn-remove" type="button" [disabled]="disabled" (click)="remove(a)">
      <craft-icon class="svg-icon text-size-15" name="general--delete-md"></craft-icon>
    </button>
  </div>
</div>
