import { Injectable } from '@angular/core';
import * as atatus from 'atatus-spa';
import { AuthUser } from 'src/app/core/models/auth.models';
import { StringAnyMap } from 'src/app/core/models/common.models';
import { getFullName } from 'src/app/core/utils/text-utils';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class ThirdPartyScriptsService {
  constructor(private config: ConfigService) {}

  public setupIntercom(user?: AuthUser) {
    const ic = (window as any).Intercom;

    let settings: StringAnyMap = {
      app_id: this.config.env.intercomId,
      alignment: 'right',
      hide_default_launcher: true,
      custom_launcher_selector: '#contact-support-button',
    };

    if (user) {
      settings = {
        ...settings,
        email: user.email,
        name: getFullName(user),
        user_id: user.id,
        created_at: Math.floor(new Date(user.created).getTime() / 1000),
      };

      if (!user.personId) {
        settings.clearbit_company_tech_29 = 'idea_portal_user';
      }
    }

    (window as any).intercomSettings = settings;

    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', (window as any).intercomSettings);
    } else {
      const i = function () {
        (i as any)['c'](arguments);
      };
      (i as any)['c'] = function (args: any) {
        (i as any)['q'].push(args);
      };
      (i as any)['q'] = [];
      (window as any).Intercom = i;

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://widget.intercom.io/widget/${this.config.env.intercomId}`;
      document.body.appendChild(script);
    }
  }

  public setupGoogleTag() {
    const dataLayer = 'dataLayer';
    const ID = this.config.env.googleTagId;

    (window as any)[dataLayer] = (window as any)[dataLayer] || [];
    (window as any)[dataLayer].push({ 'gtm.start': Date.now(), event: 'gtm.js' });

    const script: HTMLScriptElement = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtm.js?id=${ID}`;
    document.body.appendChild(script);
  }

  public setupSegment() {
    // / Create a queue, but don't obliterate an existing one!
    const analytics: any = ((window as any).analytics = (window as any).analytics || []);

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) return;

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
      if (window.console && console.error) {
        console.error('Segment snippet included twice.');
      }
      return;
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;

    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
    ];

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function (method: any) {
      return function () {
        var args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };

    // For each of our methods, generate a queueing stub.
    for (var i = 0; i < analytics.methods.length; i++) {
      var key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = function (key: any, options: any) {
      // Create an async script element based on your key.
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';

      // Insert our script next to the first script element.
      var first = document.getElementsByTagName('script')[0];
      first?.parentNode?.insertBefore(script, first);
      analytics._loadOptions = options;
    };

    // Add a version to keep track of what's in the wild.
    analytics.SNIPPET_VERSION = '4.1.0';

    // Load Analytics.js with your key, which will automatically
    // load the tools you've enabled for your account. Boosh!
    analytics.load(this.config.env.segmentKey);
  }

  public setupAtatus(user?: AuthUser) {
    if (atatus && user) {
      atatus.setUser(user.id, user.email, getFullName(user));
    }
  }
}
