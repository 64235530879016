<div class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title ellipsis">Delete Form</h1>

  <div mat-dialog-content="" class="dialog-content">
    <div class="dialog-desc">
      Are you sure you wish to delete this form? This action will result in reconnecting all the categories currently associated with this form to the default
      form.
    </div>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <span class="warning">Warning: This cannot be undone.</span>
    <div class="right-part d-flex align-items-center justify-end">
      <button class="btn btn-cancel dialog-btn" type="button" (click)="close()" [disabled]="isRequestSending" mat-button="">Cancel</button>
      <button class="btn btn-blue dialog-btn" type="button" [disabled]="isRequestSending" (click)="done()" mat-button="">Delete</button>
    </div>
  </div>
</div>
