import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Destroyer } from 'src/app/core/abstract/destroyer';

@Component({
  templateUrl: './company-permissions-dialog.component.html',
  styleUrls: ['./company-permissions-dialog.component.scss'],
})
export class CompanyPermissionsDialogComponent extends Destroyer {
  constructor(private dialogRef: MatDialogRef<CompanyPermissionsDialogComponent, void>) {
    super();
  }

  public close() {
    this.dialogRef.close();
  }
}
