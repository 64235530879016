import { Action } from '@ngrx/store';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { DBInnerStatus, InnerStatus, InnerStatusDelRequest, InnerStatusesListResponse } from 'src/app/core/models/inner-statuses.models';

export const INNER_STATUSES_LIST = '[InnerStatuses] list';
export const INNER_STATUSES_LIST_ERROR = '[InnerStatuses] list error';
export const INNER_STATUSES_LIST_SUCCESS = '[InnerStatuses] list success';

export const INNER_STATUSES_ADD = '[InnerStatuses] add';
export const INNER_STATUSES_ADD_ERROR = '[InnerStatuses] add error';
export const INNER_STATUSES_ADD_SUCCESS = '[InnerStatuses] add success';

export const INNER_STATUSES_SAVE = '[InnerStatuses] save';
export const INNER_STATUSES_SAVE_ERROR = '[InnerStatuses] save error';
export const INNER_STATUSES_SAVE_SUCCESS = '[InnerStatuses] save success';

export const INNER_STATUSES_DELETE = '[InnerStatuses] delete';
export const INNER_STATUSES_DELETE_ERROR = '[InnerStatuses] delete error';
export const INNER_STATUSES_DELETE_SUCCESS = '[InnerStatuses] delete success';

// LIST
export class InnerStatusesReqListAction implements Action {
  readonly type = INNER_STATUSES_LIST;
  constructor(public payload?: void) {}
}
export class InnerStatusesReqListErrorAction implements Action {
  readonly type = INNER_STATUSES_LIST_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class InnerStatusesReqListSuccessAction implements Action {
  readonly type = INNER_STATUSES_LIST_SUCCESS;
  constructor(public payload: InnerStatusesListResponse) {}
}

// ADD
export class InnerStatusesReqAddAction implements Action {
  readonly type = INNER_STATUSES_ADD;
  constructor(public payload: InnerStatus) {}
}
export class InnerStatusesReqAddErrorAction implements Action {
  readonly type = INNER_STATUSES_ADD_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class InnerStatusesReqAddSuccessAction implements Action {
  readonly type = INNER_STATUSES_ADD_SUCCESS;
  constructor(public payload: DBInnerStatus) {}
}

// SAVE
export class InnerStatusesReqSaveAction implements Action {
  readonly type = INNER_STATUSES_SAVE;
  constructor(public payload: DBInnerStatus) {}
}
export class InnerStatusesReqSaveErrorAction implements Action {
  readonly type = INNER_STATUSES_SAVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class InnerStatusesReqSaveSuccessAction implements Action {
  readonly type = INNER_STATUSES_SAVE_SUCCESS;
  constructor(public payload: DBInnerStatus) {}
}

// DELETE
export class InnerStatusesReqDelAction implements Action {
  readonly type = INNER_STATUSES_DELETE;
  constructor(public payload: InnerStatusDelRequest) {}
}
export class InnerStatusesReqDelErrorAction implements Action {
  readonly type = INNER_STATUSES_DELETE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class InnerStatusesReqDelSuccessAction implements Action {
  readonly type = INNER_STATUSES_DELETE_SUCCESS;
  constructor(public payload: string) {}
}

export type InnerStatusesAction =
  | InnerStatusesReqListAction
  | InnerStatusesReqListErrorAction
  | InnerStatusesReqListSuccessAction
  //
  | InnerStatusesReqAddAction
  | InnerStatusesReqAddErrorAction
  | InnerStatusesReqAddSuccessAction
  //
  | InnerStatusesReqSaveAction
  | InnerStatusesReqSaveErrorAction
  | InnerStatusesReqSaveSuccessAction
  //
  | InnerStatusesReqDelAction
  | InnerStatusesReqDelErrorAction
  | InnerStatusesReqDelSuccessAction;
