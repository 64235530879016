import { AbstractControl, ValidatorFn } from '@angular/forms';
import { StringAnyMap } from 'src/app/core/models/common.models';
import { CustomField } from 'src/app/core/models/custom-fields.models';
import { IdeaCustomFieldValue } from 'src/app/core/models/idea.models';
import { isEmpty, isNumber, toNumber } from 'src/app/core/utils/app-utils';

export const getNumberValidator = (field: CustomField): ValidatorFn => {
  return (control: AbstractControl): StringAnyMap | null => {
    if (field.type !== 'NUMBER') return null;

    const val: IdeaCustomFieldValue = control.value;
    if (isEmpty(val.value)) return null;

    const num = toNumber(val.value);

    let message = '';
    if (!isNumber(num)) {
      message = 'Value must be a number';
    } else if (typeof field.max === 'number' && num > field.max) {
      message = 'Value cannot be larger than ' + field.max;
    } else if (typeof field.min === 'number' && num < field.min) {
      message = 'Value cannot be less than ' + field.min;
    }

    return message ? { number: { message } } : null;
  };
};
