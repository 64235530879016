import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { CraftDateAdapter } from 'src/app/core/factories/craft-date-adapter';
import AtatusErrorHandler from 'src/app/core/services/atatus-handler.service';
import { CraftTranslateLoaderService } from 'src/app/core/services/traslate-loader.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { UploadModule } from 'src/app/upload/upload.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { metaReducers } from './app.store';
import { effects } from './core/store/effects';
import { reducers } from './core/store/reducers';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
  declarations: [
    AppComponent, //
    NotFoundComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    HttpClientModule,
    SharedModule,
    UploadModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false, //
      },
    }),
    EffectsModule.forRoot(effects),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CraftTranslateLoaderService,
      },
    }),
  ],
  providers: [
    Title, //
    { provide: DateAdapter, useClass: CraftDateAdapter },
    { provide: ErrorHandler, useClass: AtatusErrorHandler },
    { provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 1000, hideDelay: 1000, touchendHideDelay: 1000 } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
