import { AnalyticsProviderService } from 'src/app/core/services/analytics.service';
import { AnalyticsSegmentProviderService } from 'src/app/core/services/backend/analytics-segment-provider.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { AnalyticsConsoleProviderService } from 'src/app/core/services/mock/analytics-console-provider.service';

export function createAnalyticsProvider(config: ConfigService): AnalyticsProviderService {
  if (config.env.production || config.env.preProduction) {
    return new AnalyticsSegmentProviderService();
  }

  return new AnalyticsConsoleProviderService();
}
