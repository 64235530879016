import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { getViewPortWidth } from 'src/app/core/utils/dom-utils';

export enum DeviceSize {
  XXS,
  XS,
  SM,
  MD,
  LG,
  DESKTOP,
  XL,
  XXL,
}

@Injectable({
  providedIn: 'root',
})
export class MediaQueryService {
  public readonly deviceSize$: BehaviorSubject<DeviceSize>;

  constructor() {
    this.deviceSize$ = new BehaviorSubject<DeviceSize>(this.deviceSize);

    fromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe(() => this.deviceSize$.next(this.deviceSize));
  }

  public get deviceSize(): DeviceSize {
    const width = getViewPortWidth();

    if (width <= 320) return DeviceSize.XXS;
    if (width <= 576) return DeviceSize.XS;
    if (width <= 768) return DeviceSize.SM;
    if (width <= 992) return DeviceSize.MD;
    if (width <= 1250) return DeviceSize.LG;
    if (width <= 1400) return DeviceSize.DESKTOP;
    if (width <= 1600) return DeviceSize.XL;

    return DeviceSize.XXL;
  }
}
