import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'craft-portal-domain-dialog',
  templateUrl: './change-portal-domain-dialog.component.html',
  styleUrls: ['./change-portal-domain-dialog.component.scss'],
})
export class ChangePortalDomainAlertDialogComponent {
  constructor(public dialogRef: MatDialogRef<ChangePortalDomainAlertDialogComponent, void>) {}

  public close(): void {
    this.dialogRef.close();
  }
}
