import { Injectable } from '@angular/core';
import { createGuid } from 'src/app/core/utils/crypto-utils';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public readonly env = environment;
  public readonly clientId: string;
  public hostname: string;

  constructor() {
    this.clientId = createGuid();
    this.hostname = window.location.hostname;
  }

  public getSocketUrl(userId: string) {
    const url = this.env.socketUrl.replace('@userid', userId);

    const protocol = window.location.protocol.replace('http', 'ws');
    return `${protocol}//${window.location.host}/${url}`;
  }
}
