import { Component, Inject, InjectionToken, Input, Optional } from '@angular/core';

export const CRAFT_PRELOADER_OPTIONS = new InjectionToken<{}>('CRAFT_PRELOADER_OPTIONS');

export type CraftPreloaderOptions = {
  color?: boolean;
  customClass?: string;
};

@Component({
  selector: 'craft-preloader',
  templateUrl: './craft-preloader.component.html',
  styleUrls: ['./craft-preloader.component.scss'],
})
export class CraftPreloaderComponent {
  @Input() customClass: string;
  @Input() color = false;

  constructor(@Optional() @Inject(CRAFT_PRELOADER_OPTIONS) public options?: CraftPreloaderOptions) {
    if (options) {
      if (options.color) {
        this.color = options.color;
      }
      if (options.customClass) {
        this.customClass = options.customClass;
      }
    }
  }
}
