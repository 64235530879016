import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TERMINOLOGY_CRAFT_ITEMS, TERMINOLOGY_DEFAULT, TERMINOLOGY_SAP } from 'src/app/core/dictionaries/terminology';
import { StringStringMap } from 'src/app/core/models/common.models';
import { TerminologyItem } from 'src/app/core/models/terminology.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';
import { indexBy } from 'src/app/core/utils/app-utils';

@Injectable({
  providedIn: 'root',
})
export class TerminologyBackendService {
  constructor(private bs: BackendService) {}

  public getTerms(lang: string, productId?: string): Observable<StringStringMap> {
    return forkJoin([
      this.getPortalTerms(lang), //
      this.getProductTerms(productId),
    ]).pipe(
      map(
        ([portalTerms, productTerms]): StringStringMap => {
          const map = indexBy([...TERMINOLOGY_CRAFT_ITEMS, ...productTerms, ...portalTerms], 'single');

          return Array.from(map.values()).reduce((acc, item) => {
            return {
              ...acc,
              ...this.getCaseForms(item.single, item.translateSingle, item.skipTransform),
              ...this.getCaseForms(item.plural, item.translatePlural, item.skipTransform),
            };
          }, {});
        },
      ),
    );
  }

  private getProductTerms(productId?: string): Observable<readonly TerminologyItem[]> {
    if (!productId) return of([]);

    return this.bs.get(`iportal/terminology`, { productId }).pipe(
      map((res) => {
        return res?.terminology || [];
      }),
    );
  }

  private getPortalTerms(lang: string): Observable<readonly TerminologyItem[]> {
    return of(lang === 'sap' ? TERMINOLOGY_SAP : TERMINOLOGY_DEFAULT);
  }

  private getCaseForms(label = '', word = '', skipTransform = false): StringStringMap {
    const lLower = label.toLocaleLowerCase();
    const lUpper = label.toLocaleUpperCase();
    const lFirst = lUpper.charAt(0) + lLower.substr(1);

    let wLower = word;
    let wUpper = word;
    let wFirst = word;

    if (!skipTransform) {
      wLower = word.toLocaleLowerCase();
      wUpper = word.toLocaleUpperCase();
      wFirst = wUpper.charAt(0) + wLower.substr(1);
    }

    return {
      [lLower]: wLower,
      [lFirst]: wFirst,
      [lUpper]: wUpper,
    };
  }
}
