<button *ngIf="presets.length > 0" class="btn" type="button" [matMenuTriggerFor]="presetsMenu">
  <craft-icon class="svg-icon guru-icon" name="guru--guru-op-4"></craft-icon>
  Guru Questions
  <craft-icon class="svg-icon ml-4 text-size-11" name="arrows--arrow-head-down-sm"></craft-icon>
</button>

<mat-menu #presetsMenu="matMenu" class="presets-sub-dd-menu">
  <ng-template matMenuContent="">
    <craft-custom-presets-menu [presets]="presets" (select)="select.next($event)"></craft-custom-presets-menu>
  </ng-template>
</mat-menu>
