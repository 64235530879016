import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DBCategory } from 'src/app/core/models/categories.models';
import { Portal } from 'src/app/core/models/portal.models';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';

@Component({
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss'],
})
export class CategoryEditComponent implements OnInit, OnDestroy {
  public isSubmitted = false;

  public readonly form = new UntypedFormGroup({
    name: new UntypedFormControl(void 0, { validators: [Validators.required] }),
    description: new UntypedFormControl(void 0),
    companies: new UntypedFormControl(void 0),
    isPrivate: new UntypedFormControl(void 0),
    ideaVisibility: new UntypedFormControl(void 0),
  });

  private readonly destroy$ = new Subject<void>();

  constructor(
    private portalStore: PortalStoreService,
    private categoriesStore: CategoriesStoreService,
    private dialogRef: MatDialogRef<CategoryEditComponent, DBCategory>,
    @Inject(MAT_DIALOG_DATA) public category: DBCategory,
  ) {}

  public ngOnInit() {
    const portal = this.portalStore.portalSnapshot as Portal;

    if (portal.categoryDescriptionMandatory) {
      this.form.controls['description'].setValidators(Validators.required);
    }

    this.form.patchValue(this.category, { emitEvent: false });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public close() {
    this.dialogRef.close();
  }

  public submit() {
    this.isSubmitted = true;
    if (this.form.invalid) return;

    const category: DBCategory = { ...this.category, ...this.form.value };

    this.categoriesStore
      .update(category)
      .pipe(
        takeUntil(this.destroy$), //
      )
      .subscribe(() => {
        this.dialogRef.close(category);
      });
  }
}
