import { compareStrings } from './text-utils';

export function getSortByString(key: string, revert = false, ignoreCase = false): (a: any, b: any) => number {
  const options: Intl.CollatorOptions = { sensitivity: ignoreCase === true ? 'base' : 'case' };
  return !revert ?
    (a: any, b: any) => compareStrings(a[key], b[key], options) :
    (a: any, b: any) => compareStrings(b[key], a[key], options);
}

export function getSortByDate(key: string, revert = false): (a: any, b: any) => number {
  return !revert ?
    (a: any, b: any) => a[key] - b[key] :
    (a: any, b: any) => b[key] - a[key];
}

export function getSortByNumber(key: string, revert = false): (a: any, b: any) => number {
  return !revert ?
    (a: any, b: any) => a[key] - b[key] :
    (a: any, b: any) => b[key] - a[key];
}
export function getSortFunction(field: string, fieldType: string, revert = false, ignoreCase = false): (a: any, b: any) => number {
  switch (fieldType) {
    case 'string':
      return getSortByString(field, revert, ignoreCase);
    default:
      return getSortByNumber(field, revert);
  }
}
