import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResult } from 'src/app/shared/dialogs/models';

export type AlertDialogInput = {
  text: string;
  title: string;
};

@Component({
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogInput, //
    private ngZone: NgZone,
    private dialogRef: MatDialogRef<AlertDialogComponent, DialogResult>,
  ) {}

  public close(): void {
    this.ngZone.run(() => {
      this.dialogRef.close({ done: true });
    });
  }
}
