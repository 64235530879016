import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Destroyer } from 'src/app/core/abstract/destroyer';
import { CustomField, CustomFieldType, FrendlyCustomFieldTypes } from 'src/app/core/models/custom-fields.models';
import { CustomPresetsService, PresetItem } from 'src/app/shared/components/custom-fileds/custom-presets/custom-presets.service';

@Component({
  selector: 'craft-custom-presets-menu',
  templateUrl: './custom-presets-menu.component.html',
  styleUrls: ['./custom-presets-menu.component.scss'],
})
export class CustomPresetsMenuComponent extends Destroyer implements OnInit {
  @Input() public showTypes = false;
  @Input() public presets: PresetItem[] | undefined;
  @Input() public filterByType: CustomFieldType | undefined;
  @Output() public select = new EventEmitter<Partial<CustomField>>();

  public humanTypes = FrendlyCustomFieldTypes;
  public isLoading = false;

  constructor(
    private presetsStore: CustomPresetsService,
    private changeDetectionRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (!Array.isArray(this.presets)) {
      this.isLoading = true;
      this.presetsStore
        .load(this.filterByType)
        .pipe(takeUntil(this.destroy$))
        .subscribe((presets) => {
          this.presets = presets;
          this.isLoading = false;
          this.changeDetectionRef.detectChanges();
        });
    }
  }

  public identify(index: number, item?: PresetItem): string | number {
    return item?.id || index;
  }
}
