<div class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title">Oops! Something went wrong.</h1>
  <div mat-dialog-content class="dialog-content">
    An error occurred while saving your data. <br />
    Please check your internet connection and try again. <br />
    If the problem persists, contact our support team via email:
    <a class="email" href="mailto:support@craft.io">support@craft.io</a>
  </div>
  <div mat-dialog-actions class="dialog-actions d-flex justify-end">
    <button mat-button="" class="btn btn-text-blue" cdkFocusInitial="" (click)="close()">OK</button>
  </div>
</div>
