import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DBInnerStatus } from 'src/app/core/models/inner-statuses.models';
import { InnerStatusesStoreService } from 'src/app/core/store/services/inner-statuses-store.service';

@Component({
  templateUrl: './delete-inner-status-dialog.component.html',
  styleUrls: ['./delete-inner-status-dialog.component.scss'],
})
export class DeleteInnerStatusDialogComponent implements OnInit, OnDestroy {
  public selectedStatus: DBInnerStatus;
  public statuses: DBInnerStatus[];

  private destroy$ = new Subject<void>();

  constructor(
    private innerStatusesStore: InnerStatusesStoreService,
    @Inject(MAT_DIALOG_DATA) public status: DBInnerStatus,
    private dialogRef: MatDialogRef<DeleteInnerStatusDialogComponent, DBInnerStatus>,
  ) {}

  public ngOnInit() {
    this.innerStatusesStore.list$
      .pipe(
        takeUntil(this.destroy$), //
      )
      .subscribe((statuses) => {
        this.statuses = statuses
          .filter((s) => s.id !== this.status.id) //
          .sort((a, b) => a.orderIndex - b.orderIndex);
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public identify(index: number, item?: DBInnerStatus): string | number {
    return item?.id || index;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public done(): void {
    this.innerStatusesStore
      .delete(
        this.status.id, //
        this.selectedStatus.id,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef.close(this.selectedStatus);
      });
  }
}
