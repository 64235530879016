import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Category } from 'src/app/core/models/categories.models';
import { UserPermissionsService } from 'src/app/core/services/user-permissions.service';

@Component({
  selector: 'craft-idea-category-dropdown',
  templateUrl: './idea-category-dropdown.component.html',
  styleUrls: ['./idea-category-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IdeaCategoryDropdownComponent),
      multi: true,
    },
  ],
})
export class IdeaCategoryDropdownComponent implements OnInit, ControlValueAccessor {
  @Output() public newCategoryId? = '-1';
  @Output() public enterPressed = new EventEmitter<KeyboardEvent>();
  @Output() public selectNew = new EventEmitter<Readonly<Category>>();

  public categoryId: string | undefined;

  public onTouched = () => {};
  public onChange = (id: string | undefined) => {};

  constructor(public ups: UserPermissionsService) {}

  public ngOnInit() {}

  public onEnter(e: KeyboardEvent) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.enterPressed.emit(e);
  }

  public writeValue(id: string): void {
    this.categoryId = id;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public updateValue(category: Category) {
    this.categoryId = category.id;
    this.onChange(this.categoryId);

    if (category.id === this.newCategoryId) {
      this.selectNew.next(category);
    }
  }
}
