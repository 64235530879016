import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, filter, map, switchMap, take } from 'rxjs/operators';
import { AuthResponse } from 'src/app/core/models/auth.models';
import { AnalyticsService } from 'src/app/core/services/analytics.service';
import { PortalBackendService } from 'src/app/core/services/backend/portal-backend.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { ThirdPartyScriptsService } from 'src/app/core/services/third-party-scripts.service';
import { WebSocketService } from 'src/app/core/services/web-socket.service';
import { WebStorageService } from 'src/app/core/services/web-storage.service';
import {
  AUTH_CHECKTOKEN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNUP_CONFIRM_SUCCESS,
  AUTH_SIGNUP_SUCCESS,
  AUTH_SUCCESS,
  AUTH_TOKEN_SIGNIN_SUCCESS,
  AuthCheckTokenSuccessAction,
  AuthLogoutSuccessAction,
  AuthResetBackRedirectAction,
  AuthSigninSuccessAction,
  AuthSignupConfirmSuccessAction,
  AuthSignupSuccessAction,
  AuthSuccessAction,
  AuthTokenSigninSuccessAction,
} from 'src/app/core/store/actions/auth';
import { PortalReqGetSuccessAction } from 'src/app/core/store/actions/portal';
import { AuthStoreService } from 'src/app/core/store/services/auth-store.service';
import { toPayload } from 'src/app/core/utils/rx-utils';

type UserAuthSignup =
  | AuthSignupSuccessAction //
  | AuthTokenSigninSuccessAction
  | AuthSignupConfirmSuccessAction;

@Injectable()
export class AuthEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private authStore: AuthStoreService,
    private analytics: AnalyticsService,
    private configService: ConfigService,
    private webStorage: WebStorageService,
    private webSocketService: WebSocketService,
    private portalBackend: PortalBackendService,
    private scriptsService: ThirdPartyScriptsService,
  ) {}

  private get backRedirect(): string | null {
    return this.webStorage.session('cip:redirect');
  }

  private set backRedirect(redirect: string | null) {
    this.webStorage.session('cip:redirect', redirect || null);
  }

  public signUpSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserAuthSignup>(
        AUTH_SIGNUP_SUCCESS, //
        AUTH_TOKEN_SIGNIN_SUCCESS,
        AUTH_SIGNUP_CONFIRM_SUCCESS,
      ),
      map(toPayload),
      filter((res): res is AuthResponse => {
        return !!res;
      }),
      map((res) => {
        return new AuthSigninSuccessAction(res);
      }),
    );
  });

  public signInSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<AuthSigninSuccessAction>(AUTH_SIGNIN_SUCCESS),
      map(toPayload),
      switchMap((authRes) => {
        return this.portalBackend.getPortal().pipe(map((portalRes) => ({ authRes, portalRes })));
      }),
      switchMap(({ authRes, portalRes }) => {
        return this.authStore.backRedirect$.pipe(
          take(1),
          concatMap((redirectUrl) => {
            return [
              new PortalReqGetSuccessAction(portalRes), //
              new AuthResetBackRedirectAction(),
              new AuthSuccessAction({ ...authRes, redirectUrl }),
            ];
          }),
        );
      }),
    );
  });

  public chekTokenSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<AuthCheckTokenSuccessAction>(AUTH_CHECKTOKEN_SUCCESS),
      map(toPayload),
      map((res) => {
        return new AuthSuccessAction(res);
      }),
    );
  });

  public authSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<AuthSuccessAction>(AUTH_SUCCESS),
        map(toPayload),
        map((res) => this.onAuthSuccess(res)),
      );
    },
    { dispatch: false },
  );

  public logoutSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<AuthLogoutSuccessAction>(AUTH_LOGOUT_SUCCESS),
        map(({ payload: backRedirect }) => {
          this.backRedirect = backRedirect || null;
          this.webSocketService.disconnect('user');

          this.router.navigate(['auth', 'signin']);
        }),
      );
    },
    { dispatch: false },
  );

  private onAuthSuccess(res: AuthResponse) {
    const { user, redirectUrl, samlUrl, disableRedirect } = res;
    if (samlUrl) {
      (window as Window).location = samlUrl;
      return;
    }

    const socketUrl = this.configService.getSocketUrl(user.userId);
    this.webSocketService.connect('user', socketUrl);

    if (this.configService.env.production || this.configService.env.preProduction) {
      this.scriptsService.setupIntercom(user);
      this.scriptsService.setupAtatus(user);
      this.analytics.identify(user);
    }

    if (!disableRedirect) {
      const backRedirect = redirectUrl || this.backRedirect || '/';
      this.backRedirect = null;
      this.router.navigateByUrl(backRedirect);
    }
  }
}
