import { Action } from '@ngrx/store';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { CompaniesUsersListRequest, CompaniesUsersListResponse, CompanyUser } from 'src/app/core/models/companies.models';

export const COMPANY_USERS_LIST_CLEAR = '[Company users] users list clear';

export const COMPANY_USERS_LIST = '[Company users] users list';
export const COMPANY_USERS_LIST_ERROR = '[Company users] users list error';
export const COMPANY_USERS_LIST_SUCCESS = '[Company users] users list success';

export const COMPANY_ADD_USER = '[Company-users] add user';
export const COMPANY_ADD_USER_ERROR = '[Company users] add user error';
export const COMPANY_ADD_USER_SUCCESS = '[Company users] add user success';

export const COMPANY_DEL_USER = '[Company users] delete user';
export const COMPANY_DEL_USER_ERROR = '[Company users] delete user error';
export const COMPANY_DEL_USER_SUCCESS = '[Company users] delete user success';

// CLEAR
export class CompanyUsersClearAction implements Action {
  readonly type = COMPANY_USERS_LIST_CLEAR;
  constructor(public payload: string) {}
}

// LIST
export class CompanyReqUsersListAction implements Action {
  readonly type = COMPANY_USERS_LIST;
  constructor(public payload: CompaniesUsersListRequest) {}
}
export class CompanyReqUsersListErrorAction implements Action {
  readonly type = COMPANY_USERS_LIST_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CompanyReqUsersListSuccessAction implements Action {
  readonly type = COMPANY_USERS_LIST_SUCCESS;
  constructor(public payload: CompaniesUsersListResponse) {}
}

// ADD
export class CompanyReqAddUserAction implements Action {
  readonly type = COMPANY_ADD_USER;
  constructor(public payload: CompanyUser) {}
}
export class CompanyReqAddUserErrorAction implements Action {
  readonly type = COMPANY_ADD_USER_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CompanyReqAddUserSuccessAction implements Action {
  readonly type = COMPANY_ADD_USER_SUCCESS;
  constructor(public payload: CompanyUser) {}
}

// DELETE
export class CompanyReqDelUserAction implements Action {
  readonly type = COMPANY_DEL_USER;
  constructor(public payload: CompanyUser) {}
}
export class CompanyReqDelUserErrorAction implements Action {
  readonly type = COMPANY_DEL_USER_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CompanyReqDelUserSuccessAction implements Action {
  readonly type = COMPANY_DEL_USER_SUCCESS;
  constructor(public payload: CompanyUser) {}
}

export type CompanyUsersAction =
  | CompanyUsersClearAction
  //
  | CompanyReqUsersListAction
  | CompanyReqUsersListErrorAction
  | CompanyReqUsersListSuccessAction
  //
  | CompanyReqAddUserAction
  | CompanyReqAddUserErrorAction
  | CompanyReqAddUserSuccessAction
  //
  | CompanyReqDelUserAction
  | CompanyReqDelUserErrorAction
  | CompanyReqDelUserSuccessAction;
