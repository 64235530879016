import { Injectable } from '@angular/core';
import { AccessLevel } from 'src/app/core/enums/AccessLevels';
import { UserRole } from 'src/app/core/enums/UserRole';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';
import { AuthStoreService } from '../store/services/auth-store.service';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsService {
  constructor(private authStore: AuthStoreService, private portalStore: PortalStoreService) {}

  private get currentPortal() {
    return this.portalStore.portalSnapshot;
  }

  private get currentUser() {
    return this.authStore.userSnapshot;
  }

  public authorize(accessLevel: AccessLevel): boolean {
    return this.authStore.authorize(accessLevel, this.currentUser ? this.currentUser.iportalRole : UserRole.Anonymous);
  }

  public authorizeStrict(role: UserRole): boolean {
    return this.currentUser ? this.currentUser.iportalRole === role : false;
  }

  public checkRole(role: UserRole): boolean {
    return this.currentUser ? this.currentUser.iportalRole === role : false;
  }

  public isRestrictedPortal(): boolean {
    return this.currentPortal ? this.currentPortal.restrictedAccess : false;
  }

  public isUserEqualTo(userId?: string): boolean {
    return this.currentUser && userId ? this.currentUser.id === userId : false;
  }

  public get isAnonymous() {
    return !this.currentUser;
  }

  public get isCompanyUser(): boolean {
    return this.authorize(AccessLevel.CompanyUser);
  }

  public get isReviewer(): boolean {
    return this.authorize(AccessLevel.Reviewer);
  }

  public get isAccountOwner(): boolean {
    return this.authorize(AccessLevel.AccountOwner);
  }

  public get isProcessOwner(): boolean {
    return this.authorize(AccessLevel.ProcessOwner);
  }

  public get isContributor(): boolean {
    return this.authorize(AccessLevel.Contributor);
  }

  public get isEditor(): boolean {
    return this.authorize(AccessLevel.Editor);
  }

  public get isAdmin(): boolean {
    return this.authorize(AccessLevel.Admin);
  }

  public get allowCreateCategory(): boolean {
    const user = this.currentUser;
    const portal = this.currentPortal;
    if (!user || !portal) {
      return false;
    }
    return user.iportalRole === UserRole.Editor || user.iportalRole >= portal.createCategoryRole;
  }
}
