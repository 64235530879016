<button class="btn" type="button" [matMenuTriggerFor]="userMenu">
  <craft-icon class="svg-icon text-size-11" name="subnav--more-b-md"></craft-icon>
</button>
<mat-menu #userMenu="matMenu">
  <ng-template matMenuContent="">
    <button type="button" mat-menu-item="" (click)="confirmDeleteUser()" class="d-flex align-items-center">
      <craft-icon class="svg-icon text-size-15 mr-11" name="general--delete-md"></craft-icon>
      <span>Delete user</span>
    </button>
  </ng-template>
</mat-menu>
