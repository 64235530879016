import { Action } from '@ngrx/store';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { PermissionsResponse } from 'src/app/core/models/permissions.models';
import { PortalUser } from 'src/app/core/models/portal.models';

export const PERMISSIONS_LIST = '[Permissions] Request list';
export const PERMISSIONS_LIST_ERROR = '[Permissions] Request list error';
export const PERMISSIONS_LIST_SUCCESS = '[Permissions] Load success';

export const PERMISSIONS_ADD = '[Permissions] Request add';
export const PERMISSIONS_ADD_ERROR = '[Permissions] Request add error';
export const PERMISSIONS_ADD_SUCCESS = '[Permissions] Request add success';

export const PERMISSIONS_SAVE = '[Permissions] Request save';
export const PERMISSIONS_SAVE_ERROR = '[Permissions] Request save error';
export const PERMISSIONS_SAVE_SUCCESS = '[Permissions] Request save success';

export const PERMISSIONS_DEL = '[Permissions] Request del';
export const PERMISSIONS_DEL_ERROR = '[Permissions] Request del error';
export const PERMISSIONS_DEL_SUCCESS = '[Permissions] Request del success';

// LIST
export class PermissionsReqListAction implements Action {
  readonly type = PERMISSIONS_LIST;
  constructor(public payload: string) {}
}
export class PermissionsReqListErrorAction implements Action {
  readonly type = PERMISSIONS_LIST_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class PermissionsReqListSuccessAction implements Action {
  readonly type = PERMISSIONS_LIST_SUCCESS;
  constructor(public payload: PermissionsResponse) {}
}

// ADD
export class PermissionsReqAddAction implements Action {
  readonly type = PERMISSIONS_ADD;
  constructor(public payload: PortalUser) {}
}
export class PermissionsReqAddErrorAction implements Action {
  readonly type = PERMISSIONS_ADD_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class PermissionsReqAddSuccessAction implements Action {
  readonly type = PERMISSIONS_ADD_SUCCESS;
  constructor(public payload: PortalUser) {}
}

// SAVE
export class PermissionsReqSaveAction implements Action {
  readonly type = PERMISSIONS_SAVE;
  constructor(public payload: PortalUser) {}
}
export class PermissionsReqSaveErrorAction implements Action {
  readonly type = PERMISSIONS_SAVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class PermissionsReqSaveSuccessAction implements Action {
  readonly type = PERMISSIONS_SAVE_SUCCESS;
  constructor(public payload: PortalUser) {}
}

// DELETE
export class PermissionsReqDelAction implements Action {
  readonly type = PERMISSIONS_DEL;
  constructor(public payload: PortalUser) {}
}
export class PermissionsReqDelErrorAction implements Action {
  readonly type = PERMISSIONS_DEL_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class PermissionsReqDelSuccessAction implements Action {
  readonly type = PERMISSIONS_DEL_SUCCESS;
  constructor(public payload: PortalUser) {}
}

export type PermissionsAction =
  | PermissionsReqListAction
  | PermissionsReqListErrorAction
  | PermissionsReqListSuccessAction
  //
  | PermissionsReqAddAction
  | PermissionsReqAddSuccessAction
  | PermissionsReqAddErrorAction
  //
  | PermissionsReqSaveAction
  | PermissionsReqSaveErrorAction
  | PermissionsReqSaveSuccessAction
  //
  | PermissionsReqDelAction
  | PermissionsReqDelErrorAction
  | PermissionsReqDelSuccessAction;
