import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserRole } from 'src/app/core/enums/UserRole';
import { AuthUser, PaidStatus } from 'src/app/core/models/auth.models';
import { Category } from 'src/app/core/models/categories.models';
import { Comment } from 'src/app/core/models/comments.models';
import { SortField } from 'src/app/core/models/filters.models';
import { Idea } from 'src/app/core/models/idea.models';
import { Note } from 'src/app/core/models/notes.models';
import { AuthStoreService } from 'src/app/core/store/services/auth-store.service';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { IdeasStoreService } from 'src/app/core/store/services/ideas-store.service';
import { ImportancesStoreService } from 'src/app/core/store/services/importances-store.service';
import { InnerStatusesStoreService } from 'src/app/core/store/services/inner-statuses-store.service';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';
import { ProductsStoreService } from 'src/app/core/store/services/products-store.service';
import { TagsStoreService } from 'src/app/core/store/services/tags-store.service';
import { WorkflowStatusesStoreService } from 'src/app/core/store/services/workflow-statuses-store.service';

export abstract class AnalyticsProviderService {
  public abstract track(eventName: string, data?: any): void;
  public abstract identify(user: AuthUser): void;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    private authStore: AuthStoreService, //
    private tagsStore: TagsStoreService,
    private ideasStore: IdeasStoreService,
    private portalStore: PortalStoreService,
    private provider: AnalyticsProviderService,
    private productsStore: ProductsStoreService,
    private categoriesStore: CategoriesStoreService,
    private importancesStore: ImportancesStoreService,
    private statusesStore: WorkflowStatusesStoreService,
    private innerStatusesStore: InnerStatusesStoreService,
  ) {}

  public track(eventName: string, data?: any): void {
    forkJoin([
      this.authStore.accounts$.pipe(take(1)), //
      this.authStore.currentUser$.pipe(take(1)),
      this.productsStore.selected$.pipe(take(1)),
    ])
      .pipe(
        map(([accounts, currentUser, product]) => {
          const acc = product?.accountId ? accounts[product.accountId] : void 0;
          const paidStatus = PaidStatus[Math.min(...Object.values(accounts).map((a) => PaidStatus[a.paidStatus]))];
          const role = currentUser?.iportalRole ? UserRole[currentUser?.iportalRole] : void 0;

          this.provider.track(eventName, {
            ...data,
            accountName: acc?.name,
            accountRole: acc?.accountRole,
            enterpriseName: acc?.enterpriseName,
            role,
            paidStatus,
            codeSource: 'FeedbackPortal',
          });
        }),
      )
      .subscribe();
  }

  public identify(user: AuthUser) {
    this.provider.identify(user);
  }

  public trackLoadedMainPage(productId: string): void {
    forkJoin([
      this.authStore.currentUser$.pipe(take(1)), //
      this.portalStore.portal$.pipe(take(1)),
    ])
      .pipe(
        map(([user, portal]) => {
          return this.track('fpLoadedMainPage', {
            productId,
            portalId: portal?.id,
            ...this.getUserData(user),
          });
        }),
      )
      .subscribe();
  }

  public trackFilterAdded(filterName: string): void {
    forkJoin([
      this.authStore.currentUser$.pipe(take(1)), //
      this.portalStore.portal$.pipe(take(1)),
      this.productsStore.selectedId$.pipe(take(1)),
    ])
      .pipe(
        map(([user, portal, productId]) => {
          return this.track('fpFilterAdded', {
            portalId: portal?.id,
            productId,
            addedFilter: filterName,
            ...this.getUserData(user),
          });
        }),
      )
      .subscribe();
  }

  public trackExportCSV(): void {
    forkJoin([
      this.authStore.currentUser$.pipe(take(1)), //
      this.portalStore.portal$.pipe(take(1)),
      this.productsStore.selectedId$.pipe(take(1)),
    ])
      .pipe(
        map(([user, portal, productId]) => {
          return this.track('fpCSVExported', {
            portalId: portal?.id,
            productId,
            ...this.getUserData(user),
          });
        }),
      )
      .subscribe();
  }

  public trackIdeasListSorted(sortType: SortField): void {
    forkJoin([
      this.authStore.currentUser$.pipe(take(1)), //
      this.portalStore.portal$.pipe(take(1)),
      this.productsStore.selectedId$.pipe(take(1)),
    ])
      .pipe(
        map(([user, portal, productId]) => {
          return this.track('fpListSorted', {
            portalId: portal?.id,
            productId,
            sortField: sortType.field,
            sortDirection: sortType.revert ? 'desc' : 'asc',
            ...this.getUserData(user),
          });
        }),
      )
      .subscribe();
  }

  public trackCategoryAdded(category: Category): void {
    forkJoin([
      this.authStore.currentUser$.pipe(take(1)), //
      this.portalStore.portal$.pipe(take(1)),
    ])
      .pipe(
        map(([user, portal]) => {
          return this.track('fpCategoryAdded', {
            portalId: portal?.id,
            ...this.getIdeaAndCategoryData(void 0, category),
            ...this.getUserData(user),
          });
        }),
      )
      .subscribe();
  }

  public trackIdea(eventName: string, ideaId: string): void {
    forkJoin([
      this.authStore.currentUser$.pipe(take(1)), //
      this.portalStore.portal$.pipe(take(1)),
      this.ideasStore.map$.pipe(take(1)),
      this.categoriesStore.map$.pipe(take(1)),
    ])
      .pipe(
        map(([user, portal, ideas, categories]) => {
          const idea = ideas[ideaId];
          const category = idea?.categoryId ? categories[idea.categoryId] : void 0;
          return this.track(eventName, {
            portalId: portal?.id,
            ...this.getIdeaAndCategoryData(idea, category),
            ...this.getUserData(user),
          });
        }),
      )
      .subscribe();
  }

  public trackCategory(eventName: string, categoryId: string): void {
    forkJoin([
      this.authStore.currentUser$.pipe(take(1)), //
      this.portalStore.portal$.pipe(take(1)),
      this.categoriesStore.map$.pipe(take(1)),
    ])
      .pipe(
        map(([user, portal, categories]) => {
          const category = categories[categoryId];
          return this.track(eventName, {
            portalId: portal?.id,
            ...this.getIdeaAndCategoryData(void 0, category),
            ...this.getUserData(user),
          });
        }),
      )
      .subscribe();
  }

  public trackCommentAdded(comment: Comment | Note): void {
    forkJoin([
      this.authStore.currentUser$.pipe(take(1)), //
      this.portalStore.portal$.pipe(take(1)),
      this.ideasStore.map$.pipe(take(1)),
      this.categoriesStore.map$.pipe(take(1)),
    ])
      .pipe(
        map(([user, portal, ideas, categories]) => {
          const idea = ideas[comment.ideaId];
          const category = idea?.categoryId ? categories[idea.categoryId] : void 0;
          return this.track('fpCommentAdded', {
            portalId: portal?.id,
            commentText: comment.comment,
            ...this.getIdeaAndCategoryData(idea, category),
            ...this.getUserData(user),
          });
        }),
      )
      .subscribe();
  }

  public trackIdeaStatusChanged(ideaId: string, statusId: string): void {
    forkJoin([
      this.authStore.currentUser$.pipe(take(1)), //
      this.portalStore.portal$.pipe(take(1)),
      this.ideasStore.map$.pipe(take(1)),
      this.categoriesStore.map$.pipe(take(1)),
      this.statusesStore.map$.pipe(take(1)),
    ])
      .pipe(
        map(([user, portal, ideas, categories, statuses]) => {
          const idea = ideas[ideaId];
          const status = statuses[statusId];
          const category = idea?.categoryId ? categories[idea.categoryId] : void 0;
          return this.track('fpStatusChanged', {
            portalId: portal?.id,
            statusId: status?.id,
            statusTitle: status?.name,
            ...this.getIdeaAndCategoryData(idea, category),
            ...this.getUserData(user),
          });
        }),
      )
      .subscribe();
  }

  public trackIdeaInnerStatusChanged(ideaId: string, statusId: string): void {
    forkJoin([
      this.authStore.currentUser$.pipe(take(1)), //
      this.portalStore.portal$.pipe(take(1)),
      this.ideasStore.map$.pipe(take(1)),
      this.categoriesStore.map$.pipe(take(1)),
      this.innerStatusesStore.map$.pipe(take(1)),
    ])
      .pipe(
        map(([user, portal, ideas, categories, statuses]) => {
          const idea = ideas[ideaId];
          const status = statuses[statusId];
          const category = idea?.categoryId ? categories[idea.categoryId] : void 0;
          return this.track('fpInternalStatusChanged', {
            portalId: portal?.id,
            statusId: status?.id,
            statusTitle: status?.name,
            ...this.getIdeaAndCategoryData(idea, category),
            ...this.getUserData(user),
          });
        }),
      )
      .subscribe();
  }

  public trackIdeaImportanceChanged(ideaId: string, importanceId: string): void {
    forkJoin([
      this.authStore.currentUser$.pipe(take(1)), //
      this.portalStore.portal$.pipe(take(1)),
      this.ideasStore.map$.pipe(take(1)),
      this.categoriesStore.map$.pipe(take(1)),
      this.importancesStore.map$.pipe(take(1)),
    ])
      .pipe(
        map(([user, portal, ideas, categories, importances]) => {
          const idea = ideas[ideaId];
          const importance = importances[importanceId];
          const category = idea?.categoryId ? categories[idea.categoryId] : void 0;
          return this.track('fpImportanceChanged', {
            portalId: portal?.id,
            importanceId: importance?.id,
            importanceTitle: importance?.name,
            ...this.getIdeaAndCategoryData(idea, category),
            ...this.getUserData(user),
          });
        }),
      )
      .subscribe();
  }

  public trackIdeaCategoryChanged(ideaId: string, categoryId: string): void {
    forkJoin([
      this.authStore.currentUser$.pipe(take(1)), //
      this.portalStore.portal$.pipe(take(1)),
      this.ideasStore.map$.pipe(take(1)),
      this.categoriesStore.map$.pipe(take(1)),
    ])
      .pipe(
        map(([user, portal, ideas, categories]) => {
          const idea = ideas[ideaId];
          const category = categories[categoryId];
          return this.track('fpCategoryChanged', {
            portalId: portal?.id,
            ...this.getIdeaAndCategoryData(idea, category),
            ...this.getUserData(user),
          });
        }),
      )
      .subscribe();
  }

  public trackIdeaTagAdded(ideaId: string, tagId: string): void {
    forkJoin([
      this.authStore.currentUser$.pipe(take(1)), //
      this.portalStore.portal$.pipe(take(1)),
      this.ideasStore.map$.pipe(take(1)),
      this.categoriesStore.map$.pipe(take(1)),
      this.tagsStore.map$.pipe(take(1)),
    ])
      .pipe(
        map(([user, portal, ideas, categories, tags]) => {
          const tag = tags[tagId];
          const idea = ideas[ideaId];
          const category = idea?.categoryId ? categories[idea.categoryId] : void 0;
          return this.track('fpLabelAdded', {
            portalId: portal?.id,
            labelId: tag?.id,
            labelTitle: tag?.title,
            ...this.getIdeaAndCategoryData(idea, category),
            ...this.getUserData(user),
          });
        }),
      )
      .subscribe();
  }

  private getIdeaAndCategoryData(idea?: Idea, category?: Category) {
    return {
      productId: idea?.productId || category?.productId,
      feedbackId: idea?.id,
      feedbackTitle: idea?.name,
      categoryId: category?.id,
      categoryTitle: category?.name,
    };
  }

  private getUserData(user?: AuthUser) {
    return {
      userEmail: user?.email,
      userId: user?.id,
      isSSOUser: user?.isSSO || false,
      userType: user ? (user.companyId ? 'company' : 'team') : 'anonymous',
      userRole: UserRole[user?.iportalRole || UserRole.Anonymous],
    };
  }
}
