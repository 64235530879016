import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, take } from 'rxjs/operators';
import { CommentsReqAddSuccessAction, CommentsReqDelSuccessAction, COMMENTS_ADD_SUCCESS, COMMENTS_DEL_SUCCESS } from 'src/app/core/store/actions/comments';
import { FiltersResetPageAction } from 'src/app/core/store/actions/filters';
import {
  IdeasBulkUpdateSuccessAction,
  IdeasReqAddSuccessAction,
  IdeasUpdateOneAction,
  IDEAS_ADD_SUCCESS,
  IDEAS_BULK_UPDATE_SUCCESS
} from 'src/app/core/store/actions/ideas';
import { NotesReqAddSuccessAction, NotesReqDelSuccessAction, NOTES_ADD_SUCCESS, NOTES_DEL_SUCCESS } from 'src/app/core/store/actions/notes';
import { ProductFetchDataAction } from 'src/app/core/store/actions/products';
import { FiltersStoreService } from 'src/app/core/store/services/filters-store.service';
import { toPayload } from 'src/app/core/utils/rx-utils';

@Injectable()
export class IdeasEffects {
  constructor(
    private actions$: Actions, //
    private filtersStore: FiltersStoreService,
  ) {}

  public bulkUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<IdeasBulkUpdateSuccessAction>(IDEAS_BULK_UPDATE_SUCCESS),
      map(toPayload),
      switchMap(({ productId }) => {
        return this.filtersStore.state$.pipe(take(1)).pipe(
          map((fitersState) => {
            return { productId, params: fitersState };
          }),
        );
      }),
      map(({ productId, params }) => {
        return new ProductFetchDataAction({ productId, params });
      }),
    );
  });

  public addIdea$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<IdeasReqAddSuccessAction>(IDEAS_ADD_SUCCESS),
      map(toPayload),
      map(() => {
        return new FiltersResetPageAction();
      }),
    );
  });

  public addComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<CommentsReqAddSuccessAction>(COMMENTS_ADD_SUCCESS),
      map(toPayload),
      map(({ comment, commentsCnt }) => {
        return new IdeasUpdateOneAction({ id: comment.ideaId, commentsCnt });
      }),
    );
  });

  public delComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<CommentsReqDelSuccessAction>(COMMENTS_DEL_SUCCESS),
      map(toPayload),
      map(({ comment, commentsCnt }) => {
        return new IdeasUpdateOneAction({ id: comment.ideaId, commentsCnt });
      }),
    );
  });

  public addNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<NotesReqAddSuccessAction>(NOTES_ADD_SUCCESS),
      map(toPayload),
      map(({ note, notesCnt }) => {
        return new IdeasUpdateOneAction({ id: note.ideaId, notesCnt });
      }),
    );
  });

  public delNote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<NotesReqDelSuccessAction>(NOTES_DEL_SUCCESS),
      map(toPayload),
      map(({ note, notesCnt }) => {
        return new IdeasUpdateOneAction({ id: note.ideaId, notesCnt });
      }),
    );
  });
}
