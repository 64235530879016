import { Pipe, PipeTransform } from '@angular/core';
import { getShortName } from 'src/app/core/utils/text-utils';

@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {

  public transform(user: any): string {
    return getShortName(user);
  }

}
