import { AbstractControl, ValidatorFn } from '@angular/forms';
import { StringAnyMap } from 'src/app/core/models/common.models';
import { CustomField } from 'src/app/core/models/custom-fields.models';
import { IdeaCustomFieldValue } from 'src/app/core/models/idea.models';
import { isEmpty } from 'src/app/core/utils/app-utils';

const DEFAULT_MESSAGE = 'Text cannot be longer than @count characters';

export const getMaxlengthValidator = (field: CustomField, message = DEFAULT_MESSAGE): ValidatorFn => {
  return (control: AbstractControl): StringAnyMap | null => {
    if (field.type !== 'TEXT') return null;

    const val: IdeaCustomFieldValue = control.value;
    if (isEmpty(val.value)) return null;

    const str = val.value as string;
    const max = field.maxLength || Number.MAX_SAFE_INTEGER;

    if (str.length <= max) return null;
    return { maxLength: { message: message.replace('@count', String(max)) } };
  };
};
