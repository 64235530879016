import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { ProductFetchDataAction, PRODUCTS_FETCH_DATA, PRODUCTS_SAVE_SUCCESS, ProductsReqSaveSuccessAction } from 'src/app/core/store/actions/products';
import { toPayload } from 'src/app/core/utils/rx-utils';
import { ProductDataService } from 'src/app/core/store/services/product-data.service';
import { ProductsStoreService } from '../services/products-store.service';

@Injectable()
export class ProductEffects {
  constructor(
    private actions$: Actions, //
    private productDataService: ProductDataService,
    private productsStoreService: ProductsStoreService,
  ) {}

  public fetchProductData$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<ProductFetchDataAction>(PRODUCTS_FETCH_DATA),
        map(toPayload),
        switchMap(({ productId, params }) => {
          return this.productDataService.loadProductRelatedData(productId, params);
        }),
      );
    },
    { dispatch: false },
  );

  public productsSaveSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        debounceTime(200),
        ofType<ProductsReqSaveSuccessAction>(PRODUCTS_SAVE_SUCCESS),
        map(toPayload),
        switchMap(() => {
          return this.productsStoreService.loadAll();
        }),
      );
    },
    { dispatch: false },
  );
}
