<button *ngIf="!isOpen && !formControl.value; else desc" type="button" (click)="isOpen = true" class="btn btn-text-blue">
  <craft-icon name="general--plus-sm" class="svg-icon mr-8"></craft-icon>
  Add description
</button>

<ng-template #desc>
  <label class="label">
    <span>Description</span>
    <textarea placeholder="Field description" class="label-input" [formControl]="formControl" maxlength="255" craftAutoresize="" craftAutoFocus></textarea>
  </label>
</ng-template>
