import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringTMap } from 'src/app/core/models/common.models';
import { Product, ProductsListRequest, ProductsListResponse, ProductUpdateLogoRequest } from 'src/app/core/models/products.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';
import { getSortByString } from 'src/app/core/utils/sort-utils';

@Injectable({
  providedIn: 'root',
})
export class ProductsBackendService {
  constructor(private bs: BackendService) {}

  public getProducts(options: ProductsListRequest): Observable<ProductsListResponse> {
    return this.bs.get('portal/product/', options).pipe(
      map((result) => {
        const products: StringTMap<Product> = {};
        const mappedResult = result.list.map((product: Product) => ({ ...product, productId: product.id }));
        const ids = mappedResult.reduce((res: string[], p: Product) => {
          products[p.productId] = this.mapProduct(p);
          res.push(p.productId);
          return res;
        }, []);
        const response: ProductsListResponse = { ids, products };
        return response;
      }),
    );
  }

  public activeList(): Observable<readonly Product[]> {
    return this.bs.get('portal/product/', { onlyActive: true }).pipe(
      map((result) => {
        const mappedResult = result.list.map((product: Product) => ({ ...this.mapProduct(product), productId: product.id }));
        return mappedResult.sort(getSortByString('name'));
      }),
    );
  }

  public update(product: Product): Observable<Product> {
    return this.bs.post(`portal/product/${product.id}`, product).pipe(map(() => product));
  }

  private mapProduct(product: any) {
    product.ideasCnt = Number.parseInt(product.ideasCnt, 10) || 0;
    return product as Product;
  }

  public uploadLogo(portalId: string, file: File): Observable<string> {
    return this.bs.upload({ portalId, file }).pipe(map(({ fileId }) => fileId));
  }

  public updateLogo(req: ProductUpdateLogoRequest): Observable<void> {
    return this.bs.post(`portal/product_file/`, req).pipe(map(() => {}));
  }
}
