<div class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title ellipsis">User Permissions</h1>

  <div mat-dialog-content="" class="dialog-content">
    <img class="img-table" src="assets/img/company-permissions.png" alt="Permissions" />
    <span>* For their own company only</span>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <div class="right-part d-flex align-items-center justify-end">
      <button class="btn btn-blue dialog-btn" type="button" (click)="close()" mat-button="">OK</button>
    </div>
  </div>
</div>
