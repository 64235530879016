<!--Search-->
<div class="search">
  <craft-icon class="svg-icon text-size-13 mr-10" name="search--search"></craft-icon>
  <input type="text" #searchInput placeholder="Search" [formControl]="searchControl" class="w-100" />
</div>

<div class="mt-10 list" [ngClass]="'type-' + itemType">
  <ng-container *ngIf="!isLoading; else loading">
    <!-- Theme -->
    <div
      *ngFor="let theme of items; trackBy: identify"
      class="theme"
      (click)="itemSelect(theme, 'theme', $event)"
      [class.opened]="openedItems.has(theme.id)"
      [class.selected]="seleectedItemId === theme.id"
    >
      <ng-container *ngTemplateOutlet="itemTitle; context: { item: theme }"></ng-container>
      <!-- Epic -->
      <ng-container *ngIf="openedItems.has(theme.id)">
        <div
          *ngFor="let epic of theme.children; trackBy: identify"
          class="epic"
          (click)="itemSelect(epic, 'epic', $event)"
          [class.opened]="openedItems.has(epic.id)"
          [class.selected]="seleectedItemId === epic.id"
        >
          <ng-container *ngTemplateOutlet="itemTitle; context: { item: epic }"></ng-container>

          <!-- story -->
          <ng-container *ngIf="openedItems.has(epic.id)">
            <div
              *ngFor="let story of epic.children; trackBy: identify"
              class="story"
              (click)="itemSelect(story, 'story', $event)"
              [class.opened]="openedItems.has(story.id)"
              [class.selected]="seleectedItemId === story.id"
            >
              <ng-container *ngTemplateOutlet="itemTitle; context: { item: story }"></ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <craft-preloader customClass="mt-40 sm static"></craft-preloader>
</ng-template>

<ng-template #itemTitle let-item="item">
  <div class="title">
    <div class="ellipsis">
      <craft-icon class="svg-icon opened-icon" name="arrows--arrow-right-b-sm"></craft-icon>
      <span class="ellipsis" title="{{ item.title }}">{{ item.title }}</span>
    </div>
    <craft-icon class="svg-icon selected-icon" name="general--check-md"></craft-icon>
  </div>
</ng-template>
