<div class="error-wrap">

  <a href="https://craft.io">
    <img src="assets/logo/craft-logo.svg" alt="Craft">
  </a>

  <div class="error-page">
    <img src="assets/img/empty/empty-404.png" alt="Page not found">
    <h1>Page not found</h1>
    <p>
      This Idea Portal URL is currently unavailable.
      <br>
      You can check your URL or contact the owner of the Idea Portal to find out more.
    </p>

    <div class="contact-button">
      <a href="mailto:support@craft.io">
        Contact Craft.io support
      </a>
      <a href="https://craft.io">
        Visit Craft.io
      </a>
    </div>
  </div>

</div>