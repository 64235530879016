import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogResult } from 'src/app/shared/dialogs/models';

export type DeleteNoteDialogResult = {
  cancel: boolean;
  done: boolean;
};

@Component({
  templateUrl: './delete-note-dialog.component.html',
  styleUrls: ['./delete-note-dialog.component.scss'],
})
export class DeleteNoteDialogComponent {
  constructor(private dialogRef: MatDialogRef<DeleteNoteDialogComponent, DialogResult>) {}

  public close(): void {
    this.dialogRef.close({ done: false });
  }

  public done(): void {
    this.dialogRef.close({ done: true });
  }
}
