import { UserRole } from './UserRole';

export enum AccessLevel {
  Anonymous = UserRole.Anonymous,

  Public = UserRole.Anonymous |
    UserRole.Reviewer |
    UserRole.CompanyUser |
    UserRole.Contributor |
    UserRole.Editor |
    UserRole.AccountOwner |
    UserRole.ProcessOwner |
    UserRole.Admin |
    UserRole.PortalOwner,

  CompanyUser = UserRole.CompanyUser |
    UserRole.Reviewer |
    UserRole.Contributor |
    UserRole.Editor |
    UserRole.AccountOwner |
    UserRole.ProcessOwner |
    UserRole.Admin |
    UserRole.PortalOwner,

  Reviewer = UserRole.Reviewer | UserRole.Contributor | UserRole.Editor | UserRole.AccountOwner | UserRole.ProcessOwner | UserRole.Admin | UserRole.PortalOwner,

  ContributorOrCompanyUser = UserRole.CompanyUser |
    UserRole.Contributor |
    UserRole.Editor |
    UserRole.AccountOwner |
    UserRole.ProcessOwner |
    UserRole.Admin |
    UserRole.PortalOwner,

  Contributor = UserRole.Contributor | UserRole.Editor | UserRole.AccountOwner | UserRole.ProcessOwner | UserRole.Admin | UserRole.PortalOwner,

  Editor = UserRole.Editor | UserRole.AccountOwner | UserRole.ProcessOwner | UserRole.Admin | UserRole.PortalOwner,

  AccountOwner = UserRole.AccountOwner | UserRole.ProcessOwner | UserRole.Admin | UserRole.PortalOwner,

  ProcessOwner = UserRole.ProcessOwner | UserRole.Admin | UserRole.PortalOwner,

  Admin = UserRole.Admin | UserRole.PortalOwner,
}
