import { Action } from '@ngrx/store';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { CompaniesListRequest, CompaniesListResponse, Company, CompanyDelRequest, DBCompany } from 'src/app/core/models/companies.models';
import { CompanyLinksAction } from 'src/app/core/store/actions/company-links';
import { CompanyUsersAction } from 'src/app/core/store/actions/company-users';

export const COMPANY_SELECT = '[Companies] Select';

export const COMPANY_GET_LIST = '[Companies] get list';
export const COMPANY_GET_LIST_ERROR = '[Companies] get list error';
export const COMPANY_GET_LIST_SUCCESS = '[Companies] get list success';

export const COMPANY_GET_ONE = '[Companies] get one';
export const COMPANY_GET_ONE_ERROR = '[Companies] get one error';
export const COMPANY_GET_ONE_SUCCESS = '[Companies] get one success';

export const COMPANY_ADD = '[Companies] add';
export const COMPANY_ADD_ERROR = '[Companies] add error';
export const COMPANY_ADD_SUCCESS = '[Companies] add success';

export const COMPANY_SAVE = '[Companies] save';
export const COMPANY_SAVE_ERROR = '[Companies] save error';
export const COMPANY_SAVE_SUCCESS = '[Companies] save success';

export const COMPANY_DEL = '[Companies] delete';
export const COMPANY_DEL_ERROR = '[Companies] delete error';
export const COMPANY_DEL_SUCCESS = '[Companies] delete success';

// SELECT
export class CompaniesSelectAction implements Action {
  readonly type = COMPANY_SELECT;
  constructor(public payload: string | undefined) {}
}

// LIST
export class CompaniesReqListAction implements Action {
  readonly type = COMPANY_GET_LIST;
  constructor(public payload: CompaniesListRequest) {}
}
export class CompaniesReqListErrorAction implements Action {
  readonly type = COMPANY_GET_LIST_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CompaniesReqListSuccessAction implements Action {
  readonly type = COMPANY_GET_LIST_SUCCESS;
  constructor(public payload: CompaniesListResponse) {}
}

// ONE
export class CompaniesReqOneAction implements Action {
  readonly type = COMPANY_GET_ONE;
  constructor(public payload: string) {}
}
export class CompaniesReqOneErrorAction implements Action {
  readonly type = COMPANY_GET_ONE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CompaniesReqOneSuccessAction implements Action {
  readonly type = COMPANY_GET_ONE_SUCCESS;
  constructor(public payload: DBCompany) {}
}

// ADD
export class CompaniesReqAddAction implements Action {
  readonly type = COMPANY_ADD;
  constructor(public payload: Company) {}
}
export class CompaniesReqAddErrorAction implements Action {
  readonly type = COMPANY_ADD_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CompaniesReqAddSuccessAction implements Action {
  readonly type = COMPANY_ADD_SUCCESS;
  constructor(public payload: DBCompany) {}
}

// SAVE
export class CompaniesReqSaveAction implements Action {
  readonly type = COMPANY_SAVE;
  constructor(public payload: DBCompany) {}
}
export class CompaniesReqSaveErrorAction implements Action {
  readonly type = COMPANY_SAVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CompaniesReqSaveSuccessAction implements Action {
  readonly type = COMPANY_SAVE_SUCCESS;
  constructor(public payload: DBCompany) {}
}

// DELETE
export class CompaniesReqDelAction implements Action {
  readonly type = COMPANY_DEL;
  constructor(public payload: CompanyDelRequest) {}
}
export class CompaniesReqDelErrorAction implements Action {
  readonly type = COMPANY_DEL_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CompaniesReqDelSuccessAction implements Action {
  readonly type = COMPANY_DEL_SUCCESS;
  constructor(public payload: CompanyDelRequest) {}
}

export type CompaniesAction =
  | CompaniesSelectAction
  //
  | CompaniesReqListAction
  | CompaniesReqListErrorAction
  | CompaniesReqListSuccessAction
  //
  | CompaniesReqOneAction
  | CompaniesReqOneErrorAction
  | CompaniesReqOneSuccessAction
  //
  | CompaniesReqAddAction
  | CompaniesReqAddErrorAction
  | CompaniesReqAddSuccessAction
  //
  | CompaniesReqSaveAction
  | CompaniesReqSaveErrorAction
  | CompaniesReqSaveSuccessAction
  //
  | CompaniesReqDelAction
  | CompaniesReqDelErrorAction
  | CompaniesReqDelSuccessAction
  //
  | CompanyLinksAction
  | CompanyUsersAction;
