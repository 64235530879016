import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StringTMap } from 'src/app/core/models/common.models';
import { CompanyUser } from 'src/app/core/models/companies.models';
import { CompaniesAction } from 'src/app/core/store/actions/companies';
import { COMPANY_DEL_USER_SUCCESS, COMPANY_USERS_LIST, COMPANY_USERS_LIST_CLEAR, COMPANY_USERS_LIST_SUCCESS } from 'src/app/core/store/actions/company-users';

type UsersCache = (CompanyUser | undefined)[];

export type CompanyUsersState = {
  readonly cache: StringTMap<UsersCache>;
};

export const initialState: CompanyUsersState = {
  cache: {},
};

export function reducer(state = initialState, action: CompaniesAction): CompanyUsersState {
  switch (action.type) {
    case COMPANY_USERS_LIST_CLEAR: {
      delete state.cache[action.payload];
      return {
        ...state,
        cache: {
          ...state.cache,
        },
      };
    }

    case COMPANY_USERS_LIST: {
      const { companyId, total } = action.payload;
      const cache = state.cache[companyId] || Array.from({ length: total });
      return {
        ...state,
        cache: {
          ...state.cache,
          [companyId]: cache,
        },
      };
    }

    case COMPANY_USERS_LIST_SUCCESS: {
      const { companyId, skip, limit, users } = action.payload;
      const cache = state.cache[companyId];
      cache.splice(skip, limit, ...users);
      return {
        ...state,
        cache: {
          ...state.cache,
          [companyId]: cache,
        },
      };
    }

    case COMPANY_DEL_USER_SUCCESS: {
      const user = action.payload;
      const companyId = user.companyId;
      const cache = state.cache[companyId]?.filter((u) => u !== user) || [];
      return {
        ...state,
        cache: {
          ...state.cache,
          [companyId]: cache,
        },
      };
    }

    default:
      return state;
  }
}

const getUsers = (state: CompanyUsersState) => state.cache;

export const getCompanyUsersStateSelector = createFeatureSelector<CompanyUsersState>('companyUsersState');
export const getCompanyUsersSelector = createSelector(getCompanyUsersStateSelector, getUsers);
