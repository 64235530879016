<div class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title ellipsis">Important Notice: Workspace Connection</h1>

  <div mat-dialog-content="" class="dialog-content">
    <div class="dialog-desc">
      <div class="mb-10">
        <p>It appears that your workspace has feedback items from a previous connection.</p>
        <p>Workspace Name: {{ data.wsName }}</p>
        <p>Feedback Items: {{ data.ideasCnt }}</p>
      </div>

      <p class="mb-10">
        By connecting your workspace to this existing Feedback Portal,
        <span class="bold">all the existing feedback from the previous workspace will be permanently deleted, and it cannot be restored.</span> Your workspace
        will be integrated with the new portal, and the old data will be replaced.
      </p>

      <p class="mb-10">Are you certain you want toproceed with this action?</p>
    </div>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <span class="warning">Warning: this cannot be undone.</span>
    <div class="right-part d-flex align-items-center justify-end">
      <button class="btn btn-cancel dialog-btn" type="button" (click)="close()" [disabled]="isRequestSending" mat-button="">Cancel</button>
      <button class="btn btn-blue dialog-btn" type="button" [disabled]="isRequestSending" (click)="done()" mat-button="">Connect</button>
    </div>
  </div>
</div>
