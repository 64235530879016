import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { UploadOverDirective } from 'src/app/upload/upload-over.directive';
import { UploadWindowComponent } from 'src/app/upload/upload-window/upload-window.component';
import { UploadWindowService } from 'src/app/upload/upload-window/upload-window.service';

@NgModule({
  imports: [CommonModule, SharedModule, MatFormFieldModule, MatProgressBarModule, MatTooltipModule, TranslateModule.forChild()],
  exports: [UploadOverDirective, UploadWindowComponent],
  declarations: [UploadOverDirective, UploadWindowComponent],
  providers: [UploadWindowService],
})
export class UploadModule {}
