import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StringTMap } from 'src/app/core/models/common.models';
import { DBWorkflowStatus } from 'src/app/core/models/workflow-statuses.models';
import {
  WorkflowStatusesAction,
  WORKFLOW_STATUSES_ADD_SUCCESS,
  WORKFLOW_STATUSES_DELETE,
  WORKFLOW_STATUSES_LIST_SUCCESS,
  WORKFLOW_STATUSES_SAVE
} from 'src/app/core/store/actions/workflow-statuses';

export type WorkflowStatusesState = {
  readonly ids: readonly string[];
  readonly workflowStatuses: StringTMap<DBWorkflowStatus>;
};

export const initialState: WorkflowStatusesState = {
  ids: [],
  workflowStatuses: {},
};

export function reducer(state = initialState, action: WorkflowStatusesAction): WorkflowStatusesState {
  switch (action.type) {
    case WORKFLOW_STATUSES_LIST_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case WORKFLOW_STATUSES_ADD_SUCCESS: {
      const workflowStatus = action.payload;
      return {
        ...state,
        ids: [...state.ids, workflowStatus.id],
        workflowStatuses: {
          ...state.workflowStatuses,
          [workflowStatus.id]: { ...workflowStatus },
        },
      };
    }

    case WORKFLOW_STATUSES_SAVE: {
      const workflowStatus = action.payload;
      return {
        ...state,
        workflowStatuses: {
          ...state.workflowStatuses,
          [workflowStatus.id]: { ...workflowStatus },
        },
      };
    }

    case WORKFLOW_STATUSES_DELETE: {
      const workflowStatusId = action.payload.deletedStatusId;
      delete state.workflowStatuses[workflowStatusId];

      return {
        ...state,
        ids: state.ids.filter((id) => id !== workflowStatusId),
        workflowStatuses: {
          ...state.workflowStatuses,
        },
      };
    }
    default:
      return state;
  }
}

const getWorkflowStatuses = (state: WorkflowStatusesState) => state.workflowStatuses;
const getWorkflowStatusesIdsList = (state: WorkflowStatusesState) => state.ids;

export const getWorkflowStatusesStateSelector = createFeatureSelector<WorkflowStatusesState>('workflowStatusesState');
export const getWorkflowStatusesIdsSelector = createSelector(getWorkflowStatusesStateSelector, getWorkflowStatusesIdsList);
export const getWorkflowStatusesSelector = createSelector(getWorkflowStatusesStateSelector, getWorkflowStatuses);
export const getWorkflowStatusesListSelector = createSelector(getWorkflowStatusesStateSelector, getWorkflowStatusesIdsSelector, (state, ids) =>
  ids.map((id) => state.workflowStatuses[id]),
);
