import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { CustomField, CustomFieldOption } from 'src/app/core/models/custom-fields.models';
import { CustomFieldsBackendService } from 'src/app/core/services/backend/custom-fields-backend.service';
import { CoreState } from 'src/app/core/store/reducers';
import { getCustomFieldsEnabledListSelector, getCustomFieldsListSelector, getCustomFieldsMapSelector } from 'src/app/core/store/reducers/custom-fields';
import {
    CustomFieldsDeleteAction,
    CustomFieldsDeleteErrorAction,
    CustomFieldsDeleteSuccessAction,
    CustomFieldsMoveAction,
    CustomFieldsMoveErrorAction,
    CustomFieldsMoveSuccessAction,
    CustomFieldsReqAddAction,
    CustomFieldsReqAddErrorAction,
    CustomFieldsReqAddSuccessAction,
    CustomFieldsUpdateAction,
    CustomFieldsUpdateErrorAction,
    CustomFieldsUpdateSuccessAction
} from '../actions/custom-fields';

@Injectable({ providedIn: 'root' })
export class CustomFieldsStoreService {
  public readonly map$ = this.store$.pipe(select(getCustomFieldsMapSelector));
  public readonly list$ = this.store$.pipe(select(getCustomFieldsListSelector));
  public readonly enabledList$ = this.store$.pipe(select(getCustomFieldsEnabledListSelector));
  public readonly optionsMap$: Observable<Map<string, CustomFieldOption>>;

  constructor(
    private store$: Store<CoreState>, //
    private cfb: CustomFieldsBackendService,
  ) {
    this.optionsMap$ = this.map$.pipe(
      map((fieldsMap) => {
        const result = new Map<string, CustomFieldOption>();
        for (const [, field] of fieldsMap) {
          if (Array.isArray(field.options)) {
            for (const option of field.options) {
              result.set(option.guid, option);
            }
          }
        }
        return result;
      }),
    );
  }

  public create(field: CustomField): Observable<CustomField> {
    this.store$.dispatch(new CustomFieldsReqAddAction(field));

    return this.cfb.add(field).pipe(
      map((res) => {
        this.store$.dispatch(new CustomFieldsReqAddSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CustomFieldsReqAddErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public update(field: CustomField): Observable<CustomField> {
    this.store$.dispatch(new CustomFieldsUpdateAction(field));

    return this.cfb.update(field).pipe(
      map((res) => {
        this.store$.dispatch(new CustomFieldsUpdateSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CustomFieldsUpdateErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public delete(id: string): Observable<void> {
    this.store$.dispatch(new CustomFieldsDeleteAction(id));

    return this.cfb.delete(id).pipe(
      map(() => {
        this.store$.dispatch(new CustomFieldsDeleteSuccessAction(id));
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CustomFieldsDeleteErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public move(field: CustomField): Observable<CustomField> {
    this.store$.dispatch(new CustomFieldsMoveAction(field));

    return this.cfb.save(field).pipe(
      map((res) => {
        this.store$.dispatch(new CustomFieldsMoveSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CustomFieldsMoveErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public save(field: CustomField): Observable<CustomField> {
    return field.id ? this.update(field) : this.create(field);
  }
}
