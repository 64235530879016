import { Action } from '@ngrx/store';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { DBTag, Tag, TagsAddBulkRequest, TagsListRequest, TagsListResponse } from 'src/app/core/models/tags.models';

export const TAGS_PRODUCT_LIST = '[Tags] product list';
export const TAGS_PRODUCT_LIST_ERROR = '[Tags] product list error';
export const TAGS_PRODUCT_LIST_SUCCESS = '[Tags] product list success';

export const TAG_ADD = '[Tag] add';
export const TAG_ADD_ERROR = '[Tag] add error';
export const TAG_ADD_SUCCESS = '[Tag] add success';

export const TAG_ADD_BULK = '[Tags] add bulk';
export const TAG_ADD_BULK_ERROR = '[Tags] add bulk error';
export const TAG_ADD_BULK_SUCCESS = '[Tags] add bulk success';

export const TAG_UPDATE = '[Tags] upd';
export const TAG_UPDATE_ERROR = '[Tags] upd error';
export const TAG_UPDATE_SUCCESS = '[Tags] upd success';

export const TAG_DELETE = '[Tags] del';
export const TAG_DELETE_ERROR = '[Tags] del error';
export const TAG_DELETE_SUCCESS = '[Tags] del success';

// LIST
export class TagsReqProductListAction implements Action {
  readonly type = TAGS_PRODUCT_LIST;
  constructor(public payload: TagsListRequest) {}
}
export class TagsReqProductListErrorAction implements Action {
  readonly type = TAGS_PRODUCT_LIST_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class TagsReqProductListSuccessAction implements Action {
  readonly type = TAGS_PRODUCT_LIST_SUCCESS;
  constructor(public payload: TagsListResponse) {}
}

// ADD
export class TagsReqAddAction implements Action {
  readonly type = TAG_ADD;
  constructor(public payload: Tag) {}
}
export class TagsReqAddErrorAction implements Action {
  readonly type = TAG_ADD_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class TagsReqAddSuccessAction implements Action {
  readonly type = TAG_ADD_SUCCESS;
  constructor(public payload: DBTag) {}
}

// ADD BULK
export class TagsReqAddBulkAction implements Action {
  readonly type = TAG_ADD_BULK;
  constructor(public payload: TagsAddBulkRequest) {}
}
export class TagsReqAddBulkErrorAction implements Action {
  readonly type = TAG_ADD_BULK_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class TagsReqAddBulkSuccessAction implements Action {
  readonly type = TAG_ADD_BULK_SUCCESS;
  constructor(public payload: TagsListResponse) {}
}

// UPDATE
export class TagsReqUpdateAction implements Action {
  readonly type = TAG_UPDATE;
  constructor(public payload: DBTag) {}
}
export class TagsReqUpdateErrorAction implements Action {
  readonly type = TAG_UPDATE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class TagsReqUpdateSuccessAction implements Action {
  readonly type = TAG_UPDATE_SUCCESS;
  constructor(public payload: DBTag) {}
}

// DELETE
export class TagsReqDeleteAction implements Action {
  readonly type = TAG_DELETE;
  constructor(public payload: string) {}
}
export class TagsReqDeleteErrorAction implements Action {
  readonly type = TAG_DELETE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class TagsReqDeleteSuccessAction implements Action {
  readonly type = TAG_DELETE_SUCCESS;
  constructor(public payload: string) {}
}

export type TagsAction =
  | TagsReqProductListAction
  | TagsReqProductListErrorAction
  | TagsReqProductListSuccessAction
  //
  | TagsReqAddAction
  | TagsReqAddErrorAction
  | TagsReqAddSuccessAction
  //
  | TagsReqAddBulkAction
  | TagsReqAddBulkErrorAction
  | TagsReqAddBulkSuccessAction
  //
  | TagsReqUpdateAction
  | TagsReqUpdateErrorAction
  | TagsReqUpdateSuccessAction
  //
  | TagsReqDeleteAction
  | TagsReqDeleteErrorAction
  | TagsReqDeleteSuccessAction;
