import { environment } from 'src/environments/environment';

export function clearElement(el: Node) {
  while (el.firstChild) {
    el.removeChild(el.firstChild);
  }
}

export function makeThumbUrl(fileId?: string | null, width?: number) {
  if (!fileId) return;
  return `${environment.apiRoot}/file/link/${fileId}/thumb/${width}xH`;
}

export function getCookie(name: string) {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : '';
}

export function putToClipboard(text: string) {
  const el = document.createElement('div');
  el.style.position = 'absolute';
  el.style.left = '-10000px';
  el.style.top = '-10000px';
  el.textContent = text;
  el.contentEditable = 'true';
  document.body.appendChild(el);

  const rangeToSelect = document.createRange();
  rangeToSelect.selectNodeContents(el);

  const selection = window.getSelection();
  if (!selection) {
    document.body.removeChild(el);
    return;
  }

  selection.removeAllRanges();
  selection.addRange(rangeToSelect);

  document.execCommand('copy', false);
  document.body.removeChild(el);
}

export function getViewPortWidth() {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

export function openInNewTab(url: string) {
  const wndHandler = window.open(url, '_blank');
  if (wndHandler) wndHandler.focus();
}

const popupWindows: { [url in string]: Window | null } = {};
export function openPopupWindow(url: string) {
  let wnd = popupWindows[url];

  if (wnd) {
    wnd.focus();
    return;
  }

  const params = 'resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=100,top=100';
  wnd = window.open(url, 'craft', params);
  popupWindows[url] = wnd;
}
export function closePopupWindow(url: string) {
  popupWindows[url]?.close();
  delete popupWindows[url];
}
