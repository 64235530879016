import { Component, Input } from '@angular/core';

@Component({
  selector: 'craft-validation-message',
  templateUrl: './craft-validation-message.component.html',
  styleUrls: ['./craft-validation-message.component.scss'],
})
export class CraftValidationMessage {
  @Input() height: number | undefined;
  @Input() isShown = false;
}
