import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringTMap } from 'src/app/core/models/common.models';
import { DBWorkflowStatus, WorkflowStatus, WorkflowStatusDelRequest, WorkflowStatusesListResponse } from 'src/app/core/models/workflow-statuses.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';

@Injectable({
  providedIn: 'root',
})
export class WorkflowStatusesBackendService {
  constructor(private bs: BackendService) {}

  public getList(): Observable<WorkflowStatusesListResponse> {
    return this.bs.get('portal/status/').pipe(
      map((result) => {
        const workflowStatuses: StringTMap<DBWorkflowStatus> = {};

        const ids = result.list.reduce((acc: string[], ws: DBWorkflowStatus) => {
          workflowStatuses[ws.id] = this.mapWorkflowStatus(ws);
          acc.push(ws.id);
          return acc;
        }, []);

        return { ids, workflowStatuses };
      }),
    );
  }

  public update(status: DBWorkflowStatus): Observable<DBWorkflowStatus> {
    return this.bs.put(`portal/status/${status.id}`, status).pipe(
      map((s) => {
        return this.mapWorkflowStatus(s);
      }),
    );
  }

  public add(status: WorkflowStatus): Observable<DBWorkflowStatus> {
    return this.bs.post(`portal/status/`, status).pipe(
      map((imp) => {
        return this.mapWorkflowStatus(imp);
      }),
    );
  }

  public delete(req: WorkflowStatusDelRequest): Observable<string> {
    return this.bs
      .delete(`portal/status/${req.deletedStatusId}`, {
        newIdeasStatusId: req.newIdeasStatusId,
      })
      .pipe(
        map(() => {
          return req.deletedStatusId;
        }),
      );
  }

  public mapWorkflowStatus(ws: any): DBWorkflowStatus {
    ws.orderIndex = Number.parseFloat(ws.orderIndex);
    return ws as DBWorkflowStatus;
  }
}
