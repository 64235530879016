import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IdeasVisibility } from 'src/app/core/enums/IdeaVisibility';
import { StringStringMap } from 'src/app/core/models/common.models';
import { CompaniesStoreService } from 'src/app/core/store/services/companies-store.service';
import { BaseMultiSelectComponent } from 'src/app/shared/components/craft-multi-select/craft-multi-select.component';

@Component({
  selector: 'craft-ideas-visibility-dropdown',
  templateUrl: './ideas-visibility-dropdown.component.html',
  styleUrls: ['./ideas-visibility-dropdown.component.scss'],
})
export class IdeasVisibilityDropdownComponent extends BaseMultiSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public isCategoryPrivate: boolean | undefined;
  @Input() public ideasVisibility: IdeasVisibility | undefined;
  @Input() public disableYesVisibility: boolean | undefined;

  @Output() public vibilityChange = new EventEmitter<IdeasVisibility>();
  @Output() public companiesChange = new EventEmitter<readonly string[]>();

  @ViewChild(MatMenuTrigger) public trigger: MatMenuTrigger;

  public IdeasVisibility = IdeasVisibility;
  public ideasVisibilityControl = new UntypedFormControl();
  public selectedCountMapping: StringStringMap = {
    '=0': 'Yes, specific companies...',
    other: 'Yes, specific companies (#)',
  };

  private destroy$ = new Subject<void>();

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private companiesStoreService: CompaniesStoreService,
  ) {
    super(changeDetectorRef);
    this.idFieldName = 'id';
    this.labelFieldName = 'name';
  }

  public ngOnInit() {
    this.companiesStoreService.map$.pipe(takeUntil(this.destroy$)).subscribe((companies) => {
      this.setItems(companies, this.selectedIds);
    });

    this.ideasVisibilityControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.vibilityChange);
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.ideasVisibility) {
      this.ideasVisibilityControl.setValue(changes.ideasVisibility.currentValue, { emitEvent: false });
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public resetSelected() {
    if (!this.isCategoryPrivate) {
      this.selectedIds = [];
      this.selectedSet.clear();
    }
  }

  public clearSelected(e?: MouseEvent) {
    if (e) {
      e.stopPropagation();
    }
    this.selectedSet.clear();
  }

  public applySelected() {
    this.selectedIds = Array.from(this.selectedSet.keys());
    this.companiesChange.next(this.selectedIds);
    this.trigger.closeMenu();
  }
}
