import { NumberTMap } from 'src/app/core/models/common.models';

export enum UserRole {
  Anonymous = 1,
  CompanyUser = 2,
  Reviewer = 4,
  Contributor = 8,
  Editor = 16,
  AccountOwner = 32,
  ProcessOwner = 64,
  Admin = 128,
  PortalOwner = 256,
}

export type UserRoleView = {
  id: number;
  name: string;
  desc: string;
  display: boolean;
};

export const USER_ROLES_DESCRIPTION: Readonly<NumberTMap<UserRoleView>> = {
  [UserRole.Anonymous]: {
    id: UserRole.Anonymous,
    display: false,
    name: 'userRoles.Anonymous.name',
    desc: 'userRoles.Anonymous.desc',
  },
  [UserRole.CompanyUser]: {
    id: UserRole.CompanyUser,
    display: false,
    name: 'userRoles.CompanyUser.name',
    desc: 'userRoles.CompanyUser.desc',
  },
  [UserRole.Reviewer]: {
    id: UserRole.Reviewer,
    display: true,
    name: 'userRoles.Reviewer.name',
    desc: 'userRoles.Reviewer.desc',
  },
  [UserRole.Contributor]: {
    id: UserRole.Contributor,
    display: true,
    name: 'userRoles.Contributor.name',
    desc: 'userRoles.Contributor.desc',
  },
  [UserRole.Editor]: {
    id: UserRole.Editor,
    display: true,
    name: 'userRoles.Editor.name',
    desc: 'userRoles.Editor.desc',
  },
  [UserRole.AccountOwner]: {
    id: UserRole.AccountOwner,
    display: true,
    name: 'userRoles.AccountOwner.name',
    desc: 'userRoles.AccountOwner.desc',
  },
  [UserRole.ProcessOwner]: {
    id: UserRole.ProcessOwner,
    display: true,
    name: 'userRoles.ProcessOwner.name',
    desc: 'userRoles.ProcessOwner.desc',
  },
  [UserRole.Admin]: {
    id: UserRole.Admin,
    display: true,
    name: 'userRoles.Admin.name',
    desc: 'userRoles.Admin.desc',
  },
  [UserRole.PortalOwner]: {
    id: UserRole.PortalOwner,
    display: false,
    name: 'userRoles.PortalOwner.name',
    desc: 'userRoles.PortalOwner.desc',
  },
};

export const USER_ROLES_DESCRIPTION_LIST: readonly UserRoleView[] = Object.values(USER_ROLES_DESCRIPTION);
export const USER_ROLES_DISPLAYED_LIST: readonly UserRoleView[] = USER_ROLES_DESCRIPTION_LIST.filter((r) => r.display);
export const USER_ROLES_DISPLAYED_MAP: Readonly<NumberTMap<UserRoleView>> = USER_ROLES_DISPLAYED_LIST.reduce((acc, r) => {
  acc[r.id] = r;
  return acc;
}, {} as NumberTMap<UserRoleView>);
