import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CATEGORY_DELETE_EVENT } from 'src/app/core/dictionaries/socket-events';
import { Category } from 'src/app/core/models/categories.models';
import { RouterExtService } from 'src/app/core/services/router-ext.service';
import { WebSocketService } from 'src/app/core/services/web-socket.service';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { DeleteCategoryOptions } from './delete-categories-option-select/delete-categories-option-select.component';

export type DeleteCategoryDialogInput = {
  category: Category;
  disableRedirect?: boolean;
};

@Component({
  templateUrl: './delete-category-dialog.component.html',
  styleUrls: ['./delete-category-dialog.component.scss'],
})
export class DeleteCategoryDialogComponent implements OnInit, OnDestroy {
  public category: Readonly<Category>;
  public deletedOptions: DeleteCategoryOptions | undefined;
  public isRequestSending = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private routerExt: RouterExtService,
    private websocketService: WebSocketService,
    private categoriesStore: CategoriesStoreService,
    @Inject(MAT_DIALOG_DATA) public data: DeleteCategoryDialogInput,
    private dialogRef: MatDialogRef<DeleteCategoryDialogComponent, void>,
  ) {
    this.category = this.data.category;
  }

  public get isSubmitEnabled(): boolean {
    return this.category.ideasCnt === 0 || !!this.deletedOptions;
  }

  public ngOnInit() {
    this.websocketService.message$
      .pipe(
        filter((msg) => msg.event === CATEGORY_DELETE_EVENT && (msg.data ? msg.data.categoryId === this.category.id : false)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        if (!this.data.disableRedirect) {
          this.router.navigate(this.routerExt.getDefaultProductRoute(this.category.productKey));
        }
        this.dialogRef.close();
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public optionsChange(options: DeleteCategoryOptions) {
    this.deletedOptions = options;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public done(): void {
    this.isRequestSending = true;
    this.dialogRef.disableClose = true;

    const newCategory = this.deletedOptions ? this.deletedOptions.category : void 0;
    const newCategoryId = newCategory?.id;

    this.categoriesStore
      .delete(this.category.id!, newCategoryId)
      .pipe(
        takeUntil(this.destroy$), //
      )
      .subscribe();
  }
}
