import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoriesListResponce, CategoriesResponse, Category, CategoryDelRequest, DBCategory } from 'src/app/core/models/categories.models';
import { StringTMap } from 'src/app/core/models/common.models';
import { ServerFilterValue } from 'src/app/core/models/filters.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';

@Injectable({
  providedIn: 'root',
})
export class CategoriesBackendService {
  constructor(private bs: BackendService) {}

  public getFilteredList(productId: string, filters: StringTMap<ServerFilterValue>): Observable<CategoriesListResponce> {
    return this.bs.post('portal/get_categories', { productId, ...filters }).pipe(
      map((result) => {
        const categories = result.list.map((c: any) => this.mapCategory(c));
        const total = Number.parseInt(result.total, 10) || 0;
        return { categories, total };
      }),
    );
  }

  public getCategories(productId: string): Observable<CategoriesResponse> {
    return this.bs.get('portal/category/', { productId }).pipe(
      map((result) => {
        const categories: StringTMap<DBCategory> = {};
        const ids = result.list.reduce((res: string[], c: DBCategory) => {
          categories[c.id] = this.mapCategory(c);
          res.push(c.id!);
          return res;
        }, []);

        const response: CategoriesResponse = { ids, categories };
        return response;
      }),
    );
  }

  public update(category: DBCategory): Observable<DBCategory> {
    return this.bs.put(`portal/category/${category.id}`, category).pipe(map(() => category));
  }

  public add(category: Category): Observable<DBCategory> {
    return this.bs.post(`portal/category`, category).pipe(
      map((res) => {
        return { ...category, id: res.id } as DBCategory;
      }),
    );
  }

  public delete(req: CategoryDelRequest): Observable<any> {
    const data = req.newCategoryId ? { newCategoryId: req.newCategoryId } : void 0;
    return this.bs.delete(`portal/category/${req.deletedCategoryId}`, data);
  }

  private mapCategory(category: any): DBCategory {
    category.ideasCnt = Number.parseInt(category.ideasCnt, 10) || 0;
    category.trend = Number.parseInt(category.trend, 10) || 0;
    category.orderIndex = Number.parseFloat(category.orderIndex) || 0;
    category.ideaVisibility = Number.parseInt(category.ideaVisibility, 10) || 0;
    category.companiesCnt = Number.parseInt(category.companiesCnt, 10) || 0;
    category.companiesNames = Array.isArray(category.companiesNames) ? category.companiesNames : [];
    category.editedDt = new Date(category.editedDt);
    category.createdDt = new Date(category.createdDt);
    category.lastUpdate = category.lastUpdate ? new Date(category.lastUpdate) : category.editedDt;
    return category as DBCategory;
  }
}
