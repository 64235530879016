import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import { Attachment } from 'src/app/core/models/attachments.models';
import { DBIdea } from 'src/app/core/models/idea.models';
import { AttachService } from 'src/app/core/services/backend/attaches-backend.service';
import { BodyComponentRef, DomService } from 'src/app/core/services/dom.service';
import { DeviceSize, MediaQueryService } from 'src/app/core/services/media-query.service';
import { IdeasAttachesStoreService } from 'src/app/core/store/services/ideas-store-attaches.service';
import { UploadWindowComponent } from './upload-window.component';

@Injectable()
export class UploadWindowService {
  private destroySub: Subscription | null;
  private uploadWindow: BodyComponentRef<UploadWindowComponent> | null = null;

  constructor(
    private mq: MediaQueryService, //
    private domService: DomService,
    private attachService: AttachService,
    private ideaAttacheStore: IdeasAttachesStoreService,
  ) {}

  public attachFiles(files: FileList | File[], idea: DBIdea): void {
    if (this.mq.deviceSize <= DeviceSize.XS) {
      return this.attachWithoutWindow(files, idea);
    }
    return this.attachWithWindow(files, idea);
  }

  public hide() {
    if (this.destroySub) {
      this.destroySub.unsubscribe();
    }

    if (this.uploadWindow) {
      this.uploadWindow.destroy();
      this.uploadWindow = null;
      this.destroySub = null;
    }
  }

  public attachWithoutWindow(files: FileList | File[], idea: DBIdea): void {
    this.attachService
      .attachFiles(files, idea)
      .pipe(
        mergeMap((e) => {
          const attachment: Attachment = {
            url: e.url,
            id: e.fileId,
            name: e.fileName,
            guid: e.guid,
          };
          return this.ideaAttacheStore.attachFile(idea.id, attachment);
        }),
      )
      .subscribe();
  }

  public attachWithWindow(files: FileList | File[], idea: DBIdea): void {
    this.uploadWindow = this.uploadWindow || this.register();

    const destroy$ = this.uploadWindow.ref.instance.destroy$;
    if (!this.destroySub) {
      this.destroySub = destroy$.pipe(take(1)).subscribe(() => this.hide());
    }

    this.attachService.attachFiles(files, idea).pipe(takeUntil(destroy$)).subscribe();
  }

  private register(): BodyComponentRef<UploadWindowComponent> {
    return this.domService.appendComponentToBody(UploadWindowComponent);
  }
}
