import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'craft-file-select-button',
  templateUrl: './craft-file-select-button.component.html',
  styleUrls: ['./craft-file-select-button.component.scss'],
})
export class CraftFileSelectButtonComponent {
  @Input() public btnClass = '';
  @Input() public accept = '';
  @Input() public multiple = true;
  @Output() public fileSelect = new EventEmitter<FileList>();

  constructor() {}

  public selectFile(inputEl: HTMLInputElement) {
    if (inputEl.files) {
      this.fileSelect.emit(inputEl.files);
      inputEl.value = '';
    }
  }
}
