import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { DBImportance, Importance, ImportanceDelRequest, ImportancesListResponse } from 'src/app/core/models/importances.models';
import { ImportancesBackendService } from 'src/app/core/services/backend/importances-backend.service';
import { getImportancesListSelector, getImportancesSelector } from 'src/app/core/store/reducers/importances';
import {
    ImportancesReqAddAction,
    ImportancesReqAddErrorAction,
    ImportancesReqAddSuccessAction,
    ImportancesReqDelAction,
    ImportancesReqDelErrorAction,
    ImportancesReqDelSuccessAction,
    ImportancesReqListAction,
    ImportancesReqListErrorAction,
    ImportancesReqListSuccessAction,
    ImportancesReqSaveAction,
    ImportancesReqSaveErrorAction,
    ImportancesReqSaveSuccessAction
} from '../actions/importances';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class ImportancesStoreService {
  public readonly map$ = this.store$.pipe(select(getImportancesSelector));
  public readonly list$ = this.store$.pipe(select(getImportancesListSelector));

  constructor(
    private store$: Store<CoreState>, //
    private bs: ImportancesBackendService,
  ) {}

  public loadList(): Observable<ImportancesListResponse> {
    this.store$.dispatch(new ImportancesReqListAction());

    return this.bs.getList().pipe(
      map((res) => {
        this.store$.dispatch(new ImportancesReqListSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new ImportancesReqListErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public add(importance: Importance): Observable<DBImportance> {
    this.store$.dispatch(new ImportancesReqAddAction(importance));

    return this.bs.add(importance).pipe(
      map((res) => {
        this.store$.dispatch(new ImportancesReqAddSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new ImportancesReqAddErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public update(importance: DBImportance): Observable<DBImportance> {
    this.store$.dispatch(new ImportancesReqSaveAction(importance));

    return this.bs.update(importance).pipe(
      map((res) => {
        this.store$.dispatch(new ImportancesReqSaveSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new ImportancesReqSaveErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public delete(deletedImportanceId: string, newIdeasImportanceId: string): Observable<string> {
    const req: ImportanceDelRequest = { deletedImportanceId, newIdeasImportanceId };
    this.store$.dispatch(new ImportancesReqDelAction(req));

    return this.bs.delete(req).pipe(
      map((res) => {
        this.store$.dispatch(new ImportancesReqDelSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new ImportancesReqDelErrorAction(err));
        return throwError(() => err);
      }),
    );
  }
}
