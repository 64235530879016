<button
  type="button"
  class="btn overflow-hidden select-category"
  [disabled]="disabled"
  (menuOpened)="onMenuOpened()"
  (menuClosed)="onMenuClosed()"
  [matMenuTriggerFor]="singleSelectMenu"
>
  <span [ngSwitch]="!!selectedId" class="d-contents">
    <span *ngSwitchCase="false" class="">Choose</span>
    <span *ngSwitchCase="true" class="ellipsis">{{ items[selectedId!]?.name }}</span>
    <craft-icon class="svg-icon text-size-11 ml-4" name="arrows--arrow-head-down-sm"></craft-icon>
  </span>
</button>

<mat-menu #singleSelectMenu="matMenu">
  <ng-template matMenuContent="">
    <div *ngIf="hasSearch" class="menu-search mat-mdc-menu-item d-flex align-items-center shrink-0">
      <craft-icon class="svg-icon text-size-13 mr-10" name="search--search"></craft-icon>
      <input type="text" #searchInput (click)="$event.stopPropagation()" [placeholder]="searchPlaceholder" [formControl]="searchControl" />
    </div>
    <button
      mat-menu-item=""
      *ngFor="let item of filteredItems | async | slice: 0 : 100; trackBy: identify"
      [ngClass]="{ selected: selectedId === item[idFieldName] }"
      (click)="selectItem(item)"
      craftDisableFocus=""
      [matTooltip]="item[labelFieldName]"
      type="button"
    >
      <span class="ellipsis">
        {{ item[labelFieldName] }}
      </span>
    </button>
  </ng-template>
</mat-menu>
