import { Action } from '@ngrx/store';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { IdeasListRequest } from 'src/app/core/models/idea.models';

export const FILTERS_APPLY = '[Filters] apply';
export const FILTERS_APPLY_ERROR = '[Filters] apply error';

export const FILTERS_NEXT_PAGE = '[Filters] Next page';

export const FILTERS_RESET = '[Filters] Reset';
export const FILTERS_RESET_PAGE = '[Filters] Reset page';

// APPLY
export class FiltersApplyAction implements Action {
  readonly type = FILTERS_APPLY;
  constructor(public payload: IdeasListRequest) {}
}
export class FiltersApplyErrorAction implements Action {
  readonly type = FILTERS_APPLY_ERROR;
  constructor(public payload: ErrorResponse) {}
}

// APPLY NEXT PAGE
export class FiltersApplyNextPageAction implements Action {
  readonly type = FILTERS_NEXT_PAGE;
  constructor(public payload: IdeasListRequest) {}
}

// RESET
export class FiltersResetPageAction implements Action {
  readonly type = FILTERS_RESET_PAGE;
  constructor(public payload?: IdeasListRequest) {}
}
export class FiltersResetAction implements Action {
  readonly type = FILTERS_RESET;
  constructor(public payload?: void) {}
}

export type FiltersAction =
  | FiltersApplyAction
  | FiltersApplyErrorAction
  //
  | FiltersApplyNextPageAction
  //
  | FiltersResetAction
  | FiltersResetPageAction;
