import { Pipe, PipeTransform } from '@angular/core';
import { makeThumbUrl } from 'src/app/core/utils/dom-utils';

@Pipe({
  name: 'thumbBkg',
})
export class ThumbBkgPipe implements PipeTransform {
  public transform(fileId: string, width: number): string | undefined {
    return fileId ? `url(${makeThumbUrl(fileId, width)})` : void 0;
  }
}
