import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNumber, toNumber } from 'src/app/core/utils/app-utils';

@Component({
  selector: 'craft-number-input',
  templateUrl: './craft-number-input.component.html',
  styleUrls: ['./craft-number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CraftNumberInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CraftNumberInputComponent implements ControlValueAccessor, OnInit {
  @Output() public update = new EventEmitter<number | undefined>();

  @Input() public min = 0;
  @Input() public max = Number.MAX_SAFE_INTEGER;
  @Input() public allowSign = false;
  @Input() public allowNull = false;
  @Input() public allowDecimals = false;
  @Input() public showButtons = true;
  @Input() public isDisabled = false;
  @Input() public placeholder = 'Set';
  @Input() public decimalSeparator = '.';
  @Input() public format = '';
  @Input() public value: string;

  public val: string;

  public onTouched = () => {};
  public onChange = (_?: number) => {};

  constructor(private ref: ChangeDetectorRef) {}

  public ngOnInit() {
    this.safeSetVal(this.value);
  }

  public get safeNum(): number {
    return toNumber(this.val, this.min, this.max);
  }

  public writeValue(value?: unknown): void {
    this.safeSetVal(value);
    this.ref.detectChanges();
  }

  public registerOnChange(fn: (_?: number) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public inputChange(inputVal: string): void {
    if (inputVal == '' && this.allowNull) {
      this.val = '';
      this.updateValue(void 0);
    } else {
      const num = toNumber(inputVal, this.min, this.max);
      this.val = String(num);
      this.updateValue(num);
    }
  }

  public plus(): void {
    const num = toNumber(this.safeNum + 1, this.min, this.max);
    this.val = String(num);
    this.updateValue(num);
  }

  public minus(): void {
    const num = toNumber(this.safeNum - 1, this.min, this.max);
    this.val = String(num);
    this.updateValue(num);
  }

  private safeSetVal(value: unknown) {
    if (typeof value === 'string') {
      this.val = value;
    } else if (isNumber(value)) {
      this.val = String(value);
    } else {
      this.val = '';
    }
  }

  private updateValue(value?: number): void {
    this.onTouched();
    this.onChange(value);
    this.update.next(value);
    this.ref.detectChanges();
  }
}
