export const UPLOAD_COMPLETE_EVENT = 'system:upload:complete';

export const CSV_IMPORT_PKG_EVENT = 'portal:integrations:csv:package';
export const CSV_IMPORT_COMPLETE_EVENT = 'portal:integrations:csv:complete';
export const CSV_EXPORT_ERROR_EVENT = 'portal:export:error';
export const CSV_EXPORT_COMPLETE_EVENT = 'portal:export:complete';

export const CATEGORY_DELETE_EVENT = 'portal:category:delete';
export const COMPANY_DELETE_EVENT = 'portal:company:delete';

export const SLACK_COMPLETE_EVENT = 'portal:integrations:slack:complete';
