import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { ErrorResponse, StringBooleanMap } from 'src/app/core/models/common.models';
import { GetPortalResponse, Portal } from 'src/app/core/models/portal.models';
import {
  PortalClearCacheAction,
  PortalReqGetAction,
  PortalReqGetErrorAction,
  PortalReqGetSuccessAction,
  PortalReqSaveAction,
  PortalReqSaveErrorAction,
  PortalSaveReqSuccessAction,
  PortalSetCacheAction as PortalUseDataCacheAction,
} from 'src/app/core/store/actions/portal';
import { getPortalSelector, getRelatedDataCacheSelector } from 'src/app/core/store/reducers/portal';
import { PortalBackendService } from '../../services/backend/portal-backend.service';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class PortalStoreService {
  public readonly portal$ = this.store$.pipe(select(getPortalSelector));
  public readonly relatedDataCache$ = this.store$.pipe(select(getRelatedDataCacheSelector));

  private currentPortal: Readonly<Portal> | undefined;

  constructor(
    private store$: Store<CoreState>, //
    private pbs: PortalBackendService,
  ) {
    this.store$.pipe(select(getPortalSelector)).subscribe((portal) => {
      this.currentPortal = portal;
    });
  }

  public get portalSnapshot(): Readonly<Portal> | undefined {
    return this.currentPortal;
  }

  public load(): Observable<GetPortalResponse> {
    this.store$.dispatch(new PortalReqGetAction());
    return this.pbs.getPortal().pipe(
      map((res) => {
        this.store$.dispatch(new PortalReqGetSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new PortalReqGetErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public save(portal: Portal): Observable<Readonly<Portal>> {
    this.store$.dispatch(new PortalReqSaveAction(portal));

    return this.pbs.savePortal(portal).pipe(
      map((res) => {
        this.store$.dispatch(new PortalSaveReqSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new PortalReqSaveErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public setRelatedDataCache(cache: StringBooleanMap) {
    this.store$.dispatch(new PortalUseDataCacheAction(cache));
  }

  public clearRelatedDataCache() {
    this.store$.dispatch(new PortalClearCacheAction());
  }

  public changeDomain(portal: Portal): Observable<Readonly<Portal>> {
    return this.save(portal);
  }

  public changeLogo(logoId: string | null) {
    return this.portal$.pipe(
      filter((portal): portal is Portal => !!portal),
      take(1),
      switchMap((portal) => {
        return this.save({ ...portal, logoId });
      }),
    );
  }

  public uploadLogo(portalId: string, imgFile: File) {
    return this.pbs.uploadLogo(portalId, imgFile).pipe(
      switchMap((fileId) => {
        return this.changeLogo(fileId);
      }),
    );
  }
}
