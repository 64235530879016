import { Component } from '@angular/core';
import * as FullStory from '@fullstory/browser';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/core/services/config.service';
import { GlobalSocketEventsService } from 'src/app/core/services/global-socket-events.service';
import { IconService } from 'src/app/core/services/icon.service';
import { RouterExtService } from 'src/app/core/services/router-ext.service';
import { ThirdPartyScriptsService } from 'src/app/core/services/third-party-scripts.service';
import { TitleService } from 'src/app/core/services/title.service';
import { DynamicComponentsService } from 'src/app/shared/services/dynamic-components.service';

@Component({
  selector: 'craft-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor(
    configService: ConfigService,
    iconService: IconService,
    titleService: TitleService,
    routerExtService: RouterExtService,
    dynamicComponents: DynamicComponentsService,
    translateService: TranslateService,
    scriptsService: ThirdPartyScriptsService,
    globalSocketEvents: GlobalSocketEventsService,
  ) {
    dynamicComponents.register();
    routerExtService.setBackPoint();
    iconService.fetchDefaultSprite();
    translateService.setDefaultLang('en');
    titleService.subscribe();
    globalSocketEvents.subscribe();

    const env = configService.env;
    if (env.production || env.preProduction) {
      scriptsService.setupIntercom();
      scriptsService.setupSegment();
      scriptsService.setupGoogleTag();
      FullStory.init({ orgId: 'BKY32', devMode: false });
    }
  }
}
