<div class="dlg-wrap">
  <div class="dlg-title">
    <h3 class="title">{{ 'promote.dialog.title.' + promoteItemType | craftTerm }}</h3>
    <button type="button" (click)="close()" class="btn btn-close text-size-15">
      <craft-icon class="svg-icon" name="general--close-md"></craft-icon>
    </button>
  </div>

  <div class="dlg-content">
    <craft-items-app-tree class="tree" [itemType]="promoteItemType" [idea]="idea" [formControl]="itemControl"></craft-items-app-tree>
  </div>

  <div class="dlg-actions">
    <button class="btn dlg-btn cancel-btn" type="button" (click)="close()">Cancel</button>
    <div class="done-actions">
      <button type="button" (click)="done()" [disabled]="itemControl.invalid" class="btn btn-blue dlg-btn done-btn">
        {{ 'promote.dialog.submit.' + promoteItemType | craftTerm }}
      </button>
      <button
        type="button"
        class="btn btn-blue dlg-btn split-menu-btn"
        mat-button=""
        [disabled]="itemControl.invalid"
        [matMenuTriggerFor]="editPromoteItemMenu"
      >
        <craft-icon class="svg-icon" name="arrows--arrow-head-down-md"></craft-icon>
      </button>
    </div>
  </div>
</div>

<mat-menu #editPromoteItemMenu="matMenu">
  <ng-template matMenuContent="">
    <button mat-menu-item="" (click)="editPromotedItem()" type="button">
      <craft-icon class="svg-icon" name="general--editb-md"></craft-icon>
      Edit before promoting
    </button>
  </ng-template>
</mat-menu>
