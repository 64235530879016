import { AuthUser } from 'src/app/core/models/auth.models';
import { AnalyticsProviderService } from 'src/app/core/services/analytics.service';

export class AnalyticsConsoleProviderService extends AnalyticsProviderService {
  constructor() {
    super();
  }

  public track(eventName: string, data?: any): void {
    console.debug(eventName, data);
  }

  public identify(user: AuthUser) {
    console.debug('identify', user);
  }
}
