<form [formGroup]="inviteForm" (submit)="done()" noValidate="" class="invite-user-dialog">
  <button type="button" class="close" (click)="close()" matTooltip="Close" matTooltipPosition="above">
    <craft-icon class="svg-icon" name="general--close-md"></craft-icon>
  </button>
  <h1 class="title">Invite new user</h1>
  <p class="subtitle">
    To invite new users, type a name or an email address.
    <br />
    Users without a Craft account will have access to the portal only.
  </p>

  <div class="invite-user-dialog-input">
    <craft-email-autocomplete formControlName="email" (userSelect)="selectUser($event)" placeholder="Enter email address"></craft-email-autocomplete>
    <mat-error *ngIf="isSubmitted && inviteForm.get('email')?.hasError('email')">Email is not valid.</mat-error>
    <mat-error *ngIf="isSubmitted && inviteForm.get('email')?.hasError('required')">Email is required.</mat-error>
    <mat-error *ngIf="serverError">{{ serverError.message }}</mat-error>
  </div>

  <mat-radio-group class="roles-list" formControlName="iportalRole">
    <div *ngFor="let role of roles" class="role-desc">
      <mat-radio-button [value]="role.id" class="mat-radio-button-md">
        {{ role.name | craftTerm }}
        <p>
          {{ role.desc | craftTerm }}
        </p>
      </mat-radio-button>
    </div>
  </mat-radio-group>

  <div mat-dialog-actions="" class="mt-10 d-flex justify-end">
    <button type="button" (click)="close()" class="btn-cancel">Cancel</button>
    <button type="submit" [disabled]="inviteForm.disabled" class="btn-done">Send</button>
  </div>
</form>
