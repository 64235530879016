import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StringTMap } from 'src/app/core/models/common.models';
import { DBImportance } from 'src/app/core/models/importances.models';
import {
  ImportancesAction,
  IMPORTANCES_ADD_SUCCESS,
  IMPORTANCES_DELETE,
  IMPORTANCES_LIST_SUCCESS,
  IMPORTANCES_SAVE
} from 'src/app/core/store/actions/importances';

export type ImportancesState = {
  readonly ids: readonly string[];
  readonly importances: StringTMap<DBImportance>;
};

export const initialState: ImportancesState = {
  ids: [],
  importances: {},
};

export function reducer(state = initialState, action: ImportancesAction): ImportancesState {
  switch (action.type) {
    case IMPORTANCES_LIST_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case IMPORTANCES_ADD_SUCCESS: {
      const importance = action.payload;
      return {
        ...state,
        ids: [...state.ids, importance.id],
        importances: {
          ...state.importances,
          [importance.id]: { ...importance },
        },
      };
    }

    case IMPORTANCES_SAVE: {
      const importance = action.payload;
      return {
        ...state,
        importances: {
          ...state.importances,
          [importance.id]: { ...importance },
        },
      };
    }

    case IMPORTANCES_DELETE: {
      const importanceId = action.payload.deletedImportanceId;
      delete state.importances[importanceId];

      return {
        ...state,
        ids: state.ids.filter((id) => id !== importanceId),
        importances: {
          ...state.importances,
        },
      };
    }
    default:
      return state;
  }
}

const getImportances = (state: ImportancesState) => state.importances;
const getImportancesIdsList = (state: ImportancesState) => state.ids;

export const getImportancesStateSelector = createFeatureSelector<ImportancesState>('importancesState');
export const getImportancesIdsSelector = createSelector(getImportancesStateSelector, getImportancesIdsList);
export const getImportancesSelector = createSelector(getImportancesStateSelector, getImportances);
export const getImportancesListSelector = createSelector(getImportancesStateSelector, getImportancesIdsSelector, (state, ids) =>
  ids.map((id) => state.importances[id]),
);
