import { StronglyKeyedMap } from 'src/app/core/models/common.models';

export const CF_TYPES = [
  'TEXT', //
  'NUMBER',
  'DATE',
  'SINGLESELECT',
  'MULTISELECT',
  'FORMULA',
  'USER',
] as const;

export const CF_CONTROL_TYPES = [
  'SLIDER', //
  'PLUSMINUS',
  'SINGLESELECT',
  'MULTISELECT',
  'RADIOBUTTONSLIST',
  'CHECKBOXESLIST',
  'STRINGINPUT',
  'NUMBERINPUT',
  'DATEPICKER',
  'USERSELECT',
  'FORMULACONTROL',
] as const;

export const CF_FORMULA_OPERATION_TYPES = [
  'ADDITION', //
  'SUBTRACTION',
  'MULTIPLICATION',
  'DIVISION',
  'MODULO',
  'EXPONENTIATION',
] as const;

export type CustomFieldType = (typeof CF_TYPES)[number];
export type CustomControlType = (typeof CF_CONTROL_TYPES)[number];
export type FormulaOperationType = (typeof CF_FORMULA_OPERATION_TYPES)[number];
export type CustomValidationType = 'required' | 'url';

export type NumberFormat = {
  readonly id: string;
  readonly label: string;
};

export type CustomField = {
  readonly id: string;
  readonly guid: string;
  readonly portalId: string;
  readonly type: CustomFieldType;
  readonly icon: string;
  readonly isEnabled: boolean;
  readonly isReadOnly: boolean;
  readonly isPredefault: boolean;
  readonly label: string;
  readonly description?: string;
  readonly enumName?: string;
  readonly groupName?: string;
  readonly uniqueName?: string;
  readonly placeholder?: string;
  readonly format?: string;
  readonly orderIndex: number;
  readonly formula?: readonly FormulaOption[] | readonly FormulaOperation[];
  readonly options?: CustomFieldOption[];
  readonly min?: number;
  readonly max?: number;
  readonly maxLength?: number;
  readonly controlType: CustomControlType;
  readonly compatibleWith?: number[];
  readonly validation?: readonly CustomFieldValidation[];
  readonly formIds?: string[];
  readonly formNames?: string;
};

export type CustomFieldOption = {
  readonly id?: string;
  readonly fieldId?: string;
  readonly guid: string;
  readonly label: string;
  readonly orderIndex: number;
};

export type FormulaOption = {
  readonly field: string;
  readonly guid: string;
  readonly rate: number;
  readonly percent: number;
  readonly openingBracket?: boolean;
  readonly closingBracket?: boolean;
};

export type CustomFieldValidation = {
  readonly type: CustomValidationType;
  readonly message: string;
};

export type FormulaOperation = {
  readonly operation: FormulaOperationType;
};

export const NUMBER_FORMATS: readonly NumberFormat[] = [
  { id: '', label: 'NA' },
  { id: '#%', label: '%' },
  { id: '$#', label: '$' },
  { id: '€#', label: '€' },
  { id: '£#', label: '£' },
  { id: '¥#', label: '¥' },
];

export const FrendlyCustomFieldTypes: Readonly<StronglyKeyedMap<CustomFieldType, string>> = {
  DATE: 'Date',
  TEXT: 'Text',
  NUMBER: 'Number',
  MULTISELECT: 'Multi Selection',
  SINGLESELECT: 'Single Selection',
  FORMULA: 'Formula',
  USER: 'User',
};

export const CUSTOM_FIELDS_VALIDATIONS: StronglyKeyedMap<CustomValidationType, CustomFieldValidation> = {
  required: { type: 'required', message: 'Please fill required field' },
  url: { type: 'url', message: 'Please put url' },
};
