import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PortalLoadGuard } from 'src/app/core/guards/portal-load.guard';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canLoad: [PortalLoadGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
    canLoad: [PortalLoadGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    canLoad: [PortalLoadGuard],
  },
  {
    path: '',
    loadChildren: () => import('./portal/portal.module').then((m) => m.CraftPortalModule),
    canLoad: [PortalLoadGuard],
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
