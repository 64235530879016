<form class="dialog-wrap" novalidate [formGroup]="form" #formEl="ngForm">
  <div mat-dialog-title class="dialog-title">
    <h1>{{ field?.id ? 'Edit' : 'New' }} multi selection field</h1>
    <div class="title-btns">
      <craft-custom-presets-dropdown class="presets" [filterByType]="'MULTISELECT'" (select)="selectPreset($event)"></craft-custom-presets-dropdown>
      <craft-icon class="svg-icon" name="general--close-md" (click)="close()"></craft-icon>
    </div>
  </div>

  <div mat-dialog-content="" class="dialog-content">
    <textarea placeholder="Field name" class="label-input" formControlName="label" maxlength="255" craftAutoresize=""></textarea>
    <craft-validation-message [isShown]="formEl.submitted && !!form.get('label')?.invalid" class="err-msg">Please enter field name</craft-validation-message>
    <craft-custom-fields-add-description-button [control]="form.get('description')" class="d-block"></craft-custom-fields-add-description-button>

    <craft-custom-field-options-list-form
      class="d-block mt-40"
      [formArray]="form.get('options')"
      [isSubmitted]="formEl.submitted"
    ></craft-custom-field-options-list-form>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <div class="right-part d-flex align-items-center justify-end">
      <button class="btn-cancel" type="button" (click)="close()">Cancel</button>
      <button class="btn-save" type="submit" (click)="done()" [disabled]="form.invalid">Save</button>
    </div>
  </div>
</form>
