<div class="dialog-wrap change-workspace-dialog">
  <h1 mat-dialog-title class="dialog-title ellipsis">{{ 'settings.changeWorkspaceDialog.title' | craftTerm }}</h1>

  <div mat-dialog-content="" class="dialog-content">
    <div class="dialog-subtitle dialog-text">{{ 'settings.changeWorkspaceDialog.contentHeader' | craftTerm }}{{ workspaces.newWorkspace.name }}?</div>
    <div class="dialog-subtitle dialog-text">
      {{ 'settings.changeWorkspaceDialog.contentBody' | craftTerm }}
      <ul>
        <li>
          {{ 'settings.changeWorkspaceDialog.contentBodyListItem1' | craftTerm }}
        </li>
        <li>
          {{ 'settings.changeWorkspaceDialog.contentBodyListItem2' | craftTerm }}
        </li>
      </ul>
    </div>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <div class="right-part d-flex align-items-center justify-end">
      <button class="btn btn-cancel dialog-btn" type="button" (click)="close()" mat-button="">
        {{ 'settings.changeWorkspaceDialog.closeButton' | craftTerm }}
      </button>
      <button class="btn btn-blue dialog-btn" type="button" (click)="done()" mat-button="">
        {{ 'settings.changeWorkspaceDialog.acceptButton' | craftTerm }}
      </button>
    </div>
  </div>
</div>
