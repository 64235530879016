import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRole } from 'src/app/core/enums/UserRole';
import { CustomField } from 'src/app/core/models/custom-fields.models';
import { GetPortalResponse, Portal } from 'src/app/core/models/portal.models';
import { AuthBackendService } from 'src/app/core/services/backend/auth-backend.service';
import { BackendService } from 'src/app/core/services/backend/backend.service';
import { mapPossibleAuthResponce } from 'src/app/core/utils/auth-utils';

@Injectable({
  providedIn: 'root',
})
export class PortalBackendService {
  constructor(
    private bs: BackendService, //
    private abs: AuthBackendService,
  ) {}

  public getPortal(): Observable<GetPortalResponse> {
    return this.bs.get('iportal/hello').pipe(
      map((portal) => {
        return this.mapPortalResponce(portal);
      }),
    );
  }

  public savePortal(portal: Portal): Observable<Portal> {
    return this.bs.put(`portal/${portal.id}`, portal).pipe(
      map(() => {
        return portal;
      }),
    );
  }

  public uploadLogo(portalId: string, imgFile: File) {
    return this.bs.upload({ portalId, file: imgFile }).pipe(map(({ fileId }) => fileId));
  }

  private mapPortalResponce(raw: any): GetPortalResponse {
    if (raw.createdDt) {
      raw.createdDt = new Date(raw.createdDt);
    }

    if (!raw.defaultProductPage) {
      raw.defaultProductPage = 'ideas';
    }

    if (raw.showCategoriesList === void 0) {
      raw.showCategoriesList = true;
    }

    if (raw.removeDataWhenDeleteCompany === void 0) {
      raw.removeDataWhenDeleteCompany = false;
    }

    if (raw.categoryDescriptionMandatory === void 0) {
      raw.categoryDescriptionMandatory = false;
    }

    let createCategoryRole = raw.createCategoryRole;
    if (typeof createCategoryRole !== 'number' || UserRole[createCategoryRole]) {
      createCategoryRole = typeof createCategoryRole === 'string' ? Number.parseInt(createCategoryRole, 10) || 1 : UserRole.Editor;
      raw.createCategoryRole = UserRole[createCategoryRole] ? createCategoryRole : UserRole.Editor;
    }

    const authRes = mapPossibleAuthResponce({ data: { ...raw } });
    if (authRes) {
      delete raw.userInfo;
      delete raw.accounts;
    }

    raw.custom = (raw.custom || []).map((cf: CustomField, i: any) => {
      return {
        ...cf,
        validation: cf.validation || [],
        min: typeof cf.min === 'string' ? Number.parseFloat(cf.min) : cf.min,
        max: typeof cf.max === 'string' ? Number.parseFloat(cf.max) : cf.max,
        orderIndex:
          typeof cf.orderIndex === 'string' //
            ? Number.parseFloat(cf.orderIndex)
            : Number.MIN_SAFE_INTEGER / 2 + i,
      };
    });

    return { portal: raw as Portal, authRes };
  }
}
