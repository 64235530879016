import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AuthResponse,
  CheckResetPasswordRequest,
  CheckUsernameRequest,
  GoogleSigninRequest,
  ResetPasswordRequest,
  SigninRequest,
  SigninTokenRequest,
  SignupRequest,
} from 'src/app/core/models/auth.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';
import { mapAuthResponse, mapPossibleAuthResponce } from 'src/app/core/utils/auth-utils';

@Injectable({
  providedIn: 'root',
})
export class AuthBackendService {
  constructor(private bs: BackendService) {}

  public checkUserName(req: CheckUsernameRequest): Observable<string | undefined> {
    return this.bs.post('iportal/check_domain', { email: req.username, inviteId: req.inviteId, samlId: req.samlId }, true).pipe(
      map((res: any) => {
        return res?.data?.url;
      }),
    );
  }

  public signin(req: SigninRequest): Observable<AuthResponse> {
    return this.bs.post('iportal/login', req, true).pipe(
      map((res: any) => {
        return mapAuthResponse(res);
      }),
    );
  }

  public signinByToken(req: SigninTokenRequest): Observable<AuthResponse> {
    return this.bs.post(`iportal/onetimetoken`, req, true).pipe(
      map((res: any) => {
        return mapAuthResponse(res);
      }),
    );
  }

  public signinByGoogle(req: GoogleSigninRequest): Observable<AuthResponse> {
    return this.bs.post(`iportal/googleauth`, req, true).pipe(
      map((res: any) => {
        return mapAuthResponse(res);
      }),
    );
  }

  public signup(req: SignupRequest) {
    return this.bs.post('iportal/signup', req, true).pipe(
      map((res: any) => {
        return mapPossibleAuthResponce(res);
      }),
    );
  }

  public confirmSignup(sid: string): Observable<AuthResponse | undefined> {
    return this.bs.post(`iportal/confirm/${sid}`, { sid }, true).pipe(
      map((res: any) => {
        return mapPossibleAuthResponce(res);
      }),
    );
  }

  public checkInvite(inviteId: string): Observable<string> {
    return this.bs.get(`iportal/invite/${inviteId}`, void 0, true).pipe(
      map((res: any) => {
        return res.data.email;
      }),
    );
  }

  public signout(): Observable<void> {
    return this.bs.post('iportal/logout');
  }

  public resetPassword(req: ResetPasswordRequest): Observable<void> {
    return this.bs
      .post('iportal/resetpassword', {
        email: req.username,
        gRecaptchaResponse: req.captcha,
      })
      .pipe(map(() => {}));
  }

  public checkResetPassword(req: CheckResetPasswordRequest): Observable<void> {
    return this.bs
      .post('iportal/resetpassword', {
        password: req.password,
        sid: req.resetId,
      })
      .pipe(map(() => {}));
  }
}
