import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyUser } from 'src/app/core/models/companies.models';
import { CompaniesUsersStoreService } from 'src/app/core/store/services/companies-store-users.service';

export class CompanyUsersDataSource extends DataSource<CompanyUser | undefined> {
  public isLoading = false;

  private readonly pageSize = 100;
  private readonly fetchedPages = new Set<number>();
  private readonly subscription = new Subscription();

  constructor(
    private readonly companyId: string, //
    private readonly usersStore: CompaniesUsersStoreService,
  ) {
    super();
  }

  public connect(collectionViewer: CollectionViewer): Observable<ReadonlyArray<CompanyUser | undefined>> {
    this.fetchPage(0);

    this.subscription.add(
      collectionViewer.viewChange.subscribe((range) => {
        const startPage = this.getPageForIndex(range.start);
        const endPage = this.getPageForIndex(range.end - 1);
        for (let i = startPage; i <= endPage; i++) {
          this.fetchPage(i);
        }
      }),
    );

    return this.usersStore.users$.pipe(
      map((users) => {
        return users[this.companyId] || [];
      }),
    );
  }

  public disconnect(): void {
    this.subscription.unsubscribe();
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / this.pageSize);
  }

  private fetchPage(page: number) {
    if (this.fetchedPages.has(page)) return;
    this.fetchedPages.add(page);

    const limit = this.pageSize;
    const skip = page * this.pageSize;

    this.isLoading = true;
    this.subscription.add(
      this.usersStore.loadUsers(this.companyId, skip, limit).subscribe(() => {
        this.isLoading = false;
      }),
    );
  }
}
