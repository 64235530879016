import { Action } from '@ngrx/store';
import { CommentStoreReq, DBComment } from 'src/app/core/models/comments.models';
import { ErrorResponse } from 'src/app/core/models/common.models';

export const COMMENTS_LIST = '[Comments] list';
export const COMMENTS_LIST_ERROR = '[Comments] list error';
export const COMMENTS_LIST_SUCCESS = '[Comments] list success';

export const COMMENTS_ADD = '[Comments] add';
export const COMMENTS_ADD_ERROR = '[Comments] add error';
export const COMMENTS_ADD_SUCCESS = '[Comments] add success';

export const COMMENTS_SAVE = '[Comments] save';
export const COMMENTS_SAVE_ERROR = '[Comments] save error';
export const COMMENTS_SAVE_SUCCESS = '[Comments] save success';

export const COMMENTS_DEL = '[Comments] del';
export const COMMENTS_DEL_ERROR = '[Comments] del error';
export const COMMENTS_DEL_SUCCESS = '[Comments] del success';

// LIST
export class CommentsReqListAction implements Action {
  readonly type = COMMENTS_LIST;
  constructor(public payload: string) {}
}
export class CommentsReqListErrorAction implements Action {
  readonly type = COMMENTS_LIST_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CommentsReqListSuccessAction implements Action {
  readonly type = COMMENTS_LIST_SUCCESS;
  constructor(public payload: readonly DBComment[]) {}
}

// ADD
export class CommentsReqAddAction implements Action {
  readonly type = COMMENTS_ADD;
  constructor(public payload: CommentStoreReq) {}
}
export class CommentsReqAddErrorAction implements Action {
  readonly type = COMMENTS_ADD_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CommentsReqAddSuccessAction implements Action {
  readonly type = COMMENTS_ADD_SUCCESS;
  constructor(public payload: CommentStoreReq) {}
}

// SAVE
export class CommentsReqSaveAction implements Action {
  readonly type = COMMENTS_SAVE;
  constructor(public payload: CommentStoreReq) {}
}
export class CommentsReqSaveErrorAction implements Action {
  readonly type = COMMENTS_SAVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CommentsReqSaveSuccessAction implements Action {
  readonly type = COMMENTS_SAVE_SUCCESS;
  constructor(public payload: CommentStoreReq) {}
}

// DEL
export class CommentsReqDelAction implements Action {
  readonly type = COMMENTS_DEL;
  constructor(public payload: CommentStoreReq) {}
}
export class CommentsReqDelErrorAction implements Action {
  readonly type = COMMENTS_DEL_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CommentsReqDelSuccessAction implements Action {
  readonly type = COMMENTS_DEL_SUCCESS;
  constructor(public payload: CommentStoreReq) {}
}

export type CommentsAction =
  | CommentsReqListAction
  | CommentsReqListErrorAction
  | CommentsReqListSuccessAction
  //
  | CommentsReqAddAction
  | CommentsReqAddErrorAction
  | CommentsReqAddSuccessAction
  //
  | CommentsReqSaveAction
  | CommentsReqSaveErrorAction
  | CommentsReqSaveSuccessAction
  //
  | CommentsReqDelAction
  | CommentsReqDelErrorAction
  | CommentsReqDelSuccessAction;
