<button
  type="button"
  class="btn overflow-hidden"
  [disabled]="disabled"
  (menuOpened)="onMenuOpened()"
  (menuClosed)="onMenuClosed()"
  [matMenuTriggerFor]="singleSelectMenu"
>
  <span [ngSwitch]="!!selectedItem" class="d-contents">
    <span *ngSwitchCase="false" class="ml-8 shrink-0">Select user</span>
    <span *ngSwitchCase="true" class="ml-8 d-flex align-items-center overflow-hidden">
      <craft-user-icon [user]="selectedItem" class="mr-4 shrink-0"></craft-user-icon>
      <span class="ellipsis">{{ selectedItem | mediumName }}</span>
    </span>
  </span>
</button>

<mat-menu #singleSelectMenu="matMenu">
  <ng-template matMenuContent="">
    <div class="menu-search mat-mdc-menu-item d-flex align-items-center shrink-0">
      <craft-icon class="svg-icon text-size-13 mr-10" name="search--search"></craft-icon>
      <input type="text" #searchInput (click)="$event.stopPropagation()" [placeholder]="searchPlaceholder" [formControl]="searchControl" />
    </div>

    <div class="menu-scroll menu-content">
      <button mat-menu-item="" *ngFor="let user of filteredItems | async; trackBy: identify" (click)="selectItem(user)" type="button">
        <craft-user-icon class="d-flex shrink-0 mr-8" [user]="user"></craft-user-icon>
        <span class="ellipsis">{{ user.fullName }}</span>
        <span class="ellipsis company-name" *ngIf="user.companyName">{{ user.companyName || portalCompanyName }}</span>
      </button>

      <craft-preloader *ngIf="isPreloaderShow"></craft-preloader>
    </div>
  </ng-template>
</mat-menu>
