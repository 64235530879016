<ng-container *ngIf="!isLoading; else loading">
  <ul class="categories" [ngSwitch]="showTypes">
    <li *ngFor="let preset of presets; trackBy: identify" class="category">
      <div class="cat-content">{{ preset.groupName }}</div>

      <ul class="presets">
        <ng-container *ngSwitchCase="false">
          <li *ngFor="let field of preset.items" (click)="select.next(field)" [title]="field.label" class="mat-mdc-menu-item ellipsis d-block">
            {{ field.label }}
          </li>
        </ng-container>
        <ng-container *ngSwitchCase="true">
          <li *ngFor="let field of preset.items" (click)="select.next(field)" class="mat-mdc-menu-item preset-full">
            <span class="ellipsis label" [title]="field.label">{{ field.label }}</span>
            <span class="ellipsis type" [title]="humanTypes[field.type!]">({{ humanTypes[field.type!] }})</span>
          </li>
        </ng-container>
      </ul>
    </li>
  </ul>
</ng-container>

<ng-template #loading>
  <craft-preloader customClass="mt-20 sm"></craft-preloader>
</ng-template>
