import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StringTMap } from 'src/app/core/models/common.models';
import { DBCompany } from 'src/app/core/models/companies.models';
import {
  CompaniesAction,
  COMPANY_ADD_SUCCESS,
  COMPANY_DEL_SUCCESS,
  COMPANY_GET_LIST_SUCCESS,
  COMPANY_GET_ONE_SUCCESS,
  COMPANY_SAVE,
  COMPANY_SAVE_SUCCESS,
  COMPANY_SELECT
} from 'src/app/core/store/actions/companies';
import { COMPANY_ADD_INVITE_LINK_SUCCESS, COMPANY_DEL_INVITE_LINK_SUCCESS } from 'src/app/core/store/actions/company-links';
import { COMPANY_ADD_USER_SUCCESS, COMPANY_DEL_USER } from 'src/app/core/store/actions/company-users';

export type CompaniesState = {
  readonly savingId?: string;
  readonly loadingId?: string;
  readonly selectedId?: string;
  readonly ids: readonly string[];
  readonly companies: StringTMap<DBCompany>;
};

export const initialState: CompaniesState = {
  savingId: void 0,
  loadingId: void 0,
  selectedId: void 0,
  ids: [],
  companies: {},
};

export function reducer(state = initialState, action: CompaniesAction): CompaniesState {
  switch (action.type) {
    case COMPANY_SELECT: {
      const id = action.payload;
      return {
        ...state,
        selectedId: id,
      };
    }

    case COMPANY_GET_LIST_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case COMPANY_GET_ONE_SUCCESS: {
      const company = action.payload;
      return {
        ...state,
        companies: {
          ...state.companies,
          [company.id]: {
            ...company,
          },
        },
      };
    }

    // save
    case COMPANY_SAVE: {
      const company = action.payload;
      return {
        ...state,
        savingId: company.id,
        companies: {
          ...state.companies,
          [company.id]: {
            ...company,
          },
        },
      };
    }
    case COMPANY_SAVE_SUCCESS: {
      return {
        ...state,
        savingId: void 0,
      };
    }

    // add
    case COMPANY_ADD_SUCCESS: {
      const company = action.payload;
      return {
        ...state,
        ids: [...state.ids, company.id],
        selectedId: state.selectedId || company.id,
        companies: {
          ...state.companies,
          [company.id]: company,
        },
      };
    }

    // delete
    case COMPANY_DEL_SUCCESS: {
      const companyId = action.payload.companyId;
      delete state.companies[companyId];

      return {
        ...state,
        savingId: companyId,
        selectedId: state.selectedId === companyId ? void 0 : state.selectedId,
        ids: state.ids.filter((id) => id !== companyId),
        companies: {
          ...state.companies,
        },
      };
    }

    // users
    case COMPANY_ADD_USER_SUCCESS: {
      const user = action.payload;
      const company = state.companies[user.companyId];

      return {
        ...state,
        companies: {
          ...state.companies,
          [company.id]: { ...company, usersCount: company.usersCount + 1 },
        },
      };
    }
    case COMPANY_DEL_USER: {
      const user = action.payload;
      const company = state.companies[user.companyId];

      return {
        ...state,
        companies: {
          ...state.companies,
          [company.id]: { ...company, usersCount: company.usersCount - 1 },
        },
      };
    }

    // links
    case COMPANY_ADD_INVITE_LINK_SUCCESS: {
      const link = action.payload;
      const company = state.companies[link.companyId];

      return {
        ...state,
        companies: {
          ...state.companies,
          [company.id]: {
            ...company,
            invitationLinksCount: company.invitationLinksCount + 1,
            invitationLinks: { ...company.invitationLinks, [link.id!]: link },
          },
        },
      };
    }
    case COMPANY_DEL_INVITE_LINK_SUCCESS: {
      const { companyId, linkId } = action.payload;
      const company = state.companies[companyId];

      delete company.invitationLinks[linkId];

      return {
        ...state,
        companies: {
          ...state.companies,
          [company.id]: {
            ...company,
            invitationLinksCount: company.invitationLinksCount - 1,
            invitationLinks: { ...company.invitationLinks },
          },
        },
      };
    }

    default:
      return state;
  }
}

const getCompanies = (state: CompaniesState) => state.companies;
const getCompaniesIdsList = (state: CompaniesState) => state.ids;
const getCompaniesSavingId = (state: CompaniesState) => state.savingId;
const getCompaniesLoadingId = (state: CompaniesState) => state.loadingId;
const getCompaniesSelectedId = (state: CompaniesState) => state.selectedId;

export const getCompaniesStateSelector = createFeatureSelector<CompaniesState>('companiesState');
export const getCompaniesIdsSelector = createSelector(getCompaniesStateSelector, getCompaniesIdsList);
export const getCompaniesSavingIdSelector = createSelector(getCompaniesStateSelector, getCompaniesSavingId);
export const getCompaniesLoadingIdSelector = createSelector(getCompaniesStateSelector, getCompaniesLoadingId);
export const getCompaniesSelector = createSelector(getCompaniesStateSelector, getCompanies);
export const getSelectedCompanyIdSelector = createSelector(getCompaniesStateSelector, getCompaniesSelectedId);
export const getSelectedCompanySelector = createSelector(getCompaniesStateSelector, getSelectedCompanyIdSelector, (state, id) =>
  id !== void 0 ? state.companies[id] : void 0,
);
export const getCompaniesListSelector = createSelector(getCompaniesStateSelector, getCompaniesIdsSelector, (state, ids) =>
  ids.map((id) => state.companies[id]),
);
