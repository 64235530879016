import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StaticFilterType } from 'src/app/core/enums/StaticFilterType';
import { StringTMap, StronglyKeyedMap } from 'src/app/core/models/common.models';
import { DBIdea } from 'src/app/core/models/idea.models';
import {
  IdeaAction,
  IDEAS_ADD_SUCCESS,
  IDEAS_ADD_TAG,
  IDEAS_ARCHIVE,
  IDEAS_ATTACH_FILE,
  IDEAS_DEL_SUCCESS,
  IDEAS_DEL_TAG,
  IDEAS_DEL_VOTE,
  IDEAS_DEL_VOTE_SUCCESS,
  IDEAS_DETACH_FILE,
  IDEAS_LINK_ITEM,
  IDEAS_SAVE,
  IDEAS_SAVE_IMPORTANCE,
  IDEAS_SAVE_INNER_STATUS,
  IDEAS_SAVE_WORKFLOW_STATUS,
  IDEAS_SELECT_BY_ID,
  IDEAS_SET_LIST,
  IDEAS_SET_PAGE,
  IDEAS_SET_STATUS,
  IDEAS_TRACK,
  IDEAS_UNARCHIVE,
  IDEAS_UNLINK_ITEM,
  IDEAS_UNTRACK,
  IDEAS_UPDATE_LINKED_ITEMS_COUNT,
  IDEAS_UPDATE_ONE,
  IDEAS_VOTE,
  IDEAS_VOTE_SUCCESS
} from 'src/app/core/store/actions/ideas';

export enum IdeasStateStatus {
  notDefined,
  loading,
  loaded,
}

export type IdeasState = {
  readonly ids: readonly string[];
  readonly total: number;
  readonly ideas: StringTMap<DBIdea>;
  readonly status: IdeasStateStatus;
  readonly selectedId: string | undefined;
  readonly totals: Partial<StronglyKeyedMap<StaticFilterType, number>>;
};

export const initialState: IdeasState = {
  ids: [],
  ideas: {},
  total: 0,
  totals: {},
  status: IdeasStateStatus.notDefined,
  selectedId: void 0,
};

export function reducer(state = initialState, action: IdeaAction): IdeasState {
  switch (action.type) {
    // MISC
    case IDEAS_SET_LIST: {
      const { ideas, ids, total, totals } = action.payload;
      return {
        ...state,
        ideas,
        ids,
        total,
        totals,
        selectedId: void 0,
        status: IdeasStateStatus.loaded,
      };
    }
    case IDEAS_SET_PAGE: {
      const { ideas, ids, total, totals } = action.payload;
      return {
        ...state,
        ids: [...state.ids, ...ids],
        ideas: { ...state.ideas, ...ideas },
        total,
        totals,
        status: IdeasStateStatus.loaded,
      };
    }
    case IDEAS_SET_STATUS: {
      const status = action.payload;
      return { ...state, status };
    }
    case IDEAS_UPDATE_ONE: {
      const newIdea = action.payload;
      const oldIdea = state.ideas[newIdea.id];
      const resultIdea = { ...oldIdea, ...newIdea };

      return {
        ...state,
        ideas: {
          ...state.ideas,
          [resultIdea.id]: resultIdea,
        },
      };
    }
    case IDEAS_SELECT_BY_ID: {
      return {
        ...state,
        selectedId: action.payload,
      };
    }
    case IDEAS_UPDATE_LINKED_ITEMS_COUNT: {
      const idea = state.ideas[action.payload.ideaId];
      const newLinkedCount = action.payload.linkedItemsCount;
      
      return {
        ...state,
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, linkedCnt: newLinkedCount },
        },
      };
    }

    // ADD
    case IDEAS_ADD_SUCCESS: {
      const idea = action.payload;
      return {
        ...state,
        total: state.total + 1,
        ids: [idea.id, ...state.ids],
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea },
        },
      };
    }

    // SAVE
    case IDEAS_SAVE: {
      const idea = action.payload;
      return {
        ...state,
        ideas: {
          ...state.ideas,
          [idea.id]: {
            ...idea,
            labels: [...idea.labels],
            attaches: [...idea.attaches],
            custom: [...idea.custom],
          },
        },
      };
    }

    // DELETE
    case IDEAS_DEL_SUCCESS: {
      const ideaId = action.payload;
      delete state.ideas[ideaId];

      return {
        ...state,
        ids: state.ids.filter((id) => id !== ideaId),
        total: state.total - 1,
        selectedId: state.selectedId === ideaId ? void 0 : state.selectedId,
        ideas: {
          ...state.ideas,
        },
      };
    }

    // ARCHIVE
    case IDEAS_ARCHIVE: {
      const idea = state.ideas[action.payload];
      return {
        ...state,
        totals: {
          ...state.totals,
          archived: (state.totals.archived || 0) + 1,
        },
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, isArchived: true },
        },
      };
    }
    case IDEAS_UNARCHIVE: {
      const idea = state.ideas[action.payload];
      return {
        ...state,
        totals: {
          ...state.totals,
          archived: Math.max((state.totals.archived || 0) - 1, 0),
        },
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, isArchived: false },
        },
      };
    }

    // TAG
    case IDEAS_ADD_TAG: {
      const { ideaId, tagId } = action.payload;
      const idea = state.ideas[ideaId];
      const labels = [...idea.labels, tagId];

      return {
        ...state,
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, labels },
        },
      };
    }
    case IDEAS_DEL_TAG: {
      const { ideaId, tagId } = action.payload;
      const idea = state.ideas[ideaId];
      const labels = idea.labels.filter((id) => id !== tagId);

      return {
        ...state,
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, labels },
        },
      };
    }

    // LIKE
    case IDEAS_VOTE: {
      const idea = state.ideas[action.payload];

      return {
        ...state,
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, isVoted: true, votes: idea.votes + 1 },
        },
      };
    }
    case IDEAS_DEL_VOTE: {
      const idea = state.ideas[action.payload];

      return {
        ...state,
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, isVoted: false, votes: idea.votes - 1 },
        },
      };
    }
    case IDEAS_VOTE_SUCCESS:
    case IDEAS_DEL_VOTE_SUCCESS: {
      const { id, votes } = action.payload;
      const idea = state.ideas[id];
      return {
        ...state,
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, votes },
        },
      };
    }

    // TRACK
    case IDEAS_TRACK: {
      const idea = state.ideas[action.payload];
      return {
        ...state,
        totals: {
          ...state.totals,
          tracked: (state.totals.tracked || 0) + 1,
        },
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, isTracked: true },
        },
      };
    }
    case IDEAS_UNTRACK: {
      const idea = state.ideas[action.payload];
      return {
        ...state,
        totals: {
          ...state.totals,
          tracked: Math.max((state.totals.tracked || 0) - 1, 0),
        },
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, isTracked: false },
        },
      };
    }

    // LINK
    case IDEAS_LINK_ITEM: {
      const idea = state.ideas[action.payload.ideaId];
      return {
        ...state,
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, linkedCnt: idea.linkedCnt + 1 },
        },
      };
    }
    case IDEAS_UNLINK_ITEM: {
      const idea = state.ideas[action.payload.ideaId];
      return {
        ...state,
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, linkedCnt: idea.linkedCnt - 1 },
        },
      };
    }

    // CAHNGE PROPERTY
    case IDEAS_SAVE_IMPORTANCE: {
      const { ideaId, importanceId } = action.payload;
      const idea = state.ideas[ideaId];

      return {
        ...state,
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, importanceId },
        },
      };
    }
    case IDEAS_SAVE_WORKFLOW_STATUS: {
      const { ideaId, statusId } = action.payload;
      const idea = state.ideas[ideaId];

      return {
        ...state,
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, statusId },
        },
      };
    }
    case IDEAS_SAVE_INNER_STATUS: {
      const { ideaId, innerStatusId } = action.payload;
      const idea = state.ideas[ideaId];

      return {
        ...state,
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, innerStatusId },
        },
      };
    }

    // ATTACHES
    case IDEAS_ATTACH_FILE: {
      const { ideaId, attachment } = action.payload;
      const idea = state.ideas[ideaId];
      const attaches = [...idea.attaches, attachment];

      return {
        ...state,
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, attaches },
        },
      };
    }
    case IDEAS_DETACH_FILE: {
      const { ideaId, fileId } = action.payload;
      const idea = state.ideas[ideaId];
      const attaches = idea.attaches.filter((attachment) => attachment.id !== fileId);

      return {
        ...state,
        ideas: {
          ...state.ideas,
          [idea.id]: { ...idea, attaches },
        },
      };
    }

    default:
      return state;
  }
}

const getTotal = (state: IdeasState) => state.total;
const getIdeas = (state: IdeasState) => state.ideas;
const getTotals = (state: IdeasState) => state.totals;
const getIdeasIdsList = (state: IdeasState) => state.ids;
const getIdeasStatus = (state: IdeasState) => state.status;
const getIdeasSelectedId = (state: IdeasState) => state.selectedId;

export const getIdeasStateSelector = createFeatureSelector<IdeasState>('ideasState');
export const getIdeasTotal = createSelector(getIdeasStateSelector, getTotal);
export const getIdeasSelector = createSelector(getIdeasStateSelector, getIdeas);
export const getIdeasTotalsSelector = createSelector(getIdeasStateSelector, getTotals);
export const getIdeasIdsSelector = createSelector(getIdeasStateSelector, getIdeasIdsList);
export const getIdeasStatusSelector = createSelector(getIdeasStateSelector, getIdeasStatus);
export const getIdeasSelectedIdSelector = createSelector(getIdeasStateSelector, getIdeasSelectedId);
export const getIdeasListSelector = createSelector(getIdeasStateSelector, getIdeasIdsSelector, (state, ids) => {
  return ids.map((id) => state.ideas[id]);
});
export const getSelectedIdeaSelector = createSelector(getIdeasStateSelector, getIdeasSelectedIdSelector, (state, id) => {
  return id !== void 0 ? state.ideas[id] : void 0;
});
