import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EditorsModule } from 'src/app/editors/editors.module';
import { CraftAttachSelectButtonComponent } from 'src/app/shared/components/craft-attach-select-button/craft-attach-select-button.component';
import { CraftCategoriesAutocompleteComponent } from 'src/app/shared/components/craft-categories-autocomplete/craft-categories-autocomplete.component';
import { CraftCategoriesSelectComponent } from 'src/app/shared/components/craft-categories-select/craft-categories-select.component';
import { CraftCategoryCompaniesComponent } from 'src/app/shared/components/craft-category-companies/craft-category-companies.component';
import { CraftCategoryLabelComponent } from 'src/app/shared/components/craft-category-label/craft-category-label.component';
import { CraftCategoryPrivacyIconComponent } from 'src/app/shared/components/craft-category-privacy-icon/craft-category-privacy-icon.component';
import { CraftColorSelectorComponent } from 'src/app/shared/components/craft-color-selector/craft-color-selector.component';
import { CraftEmailAutocompleteComponent } from 'src/app/shared/components/craft-email-autocomplete/craft-email-autocomplete.component';
import { CraftFileSelectButtonComponent } from 'src/app/shared/components/craft-file-select-button/craft-file-select-button.component';
import { CraftFilterLinkedItemsSelectComponent } from 'src/app/shared/components/craft-filter-select/craft-filter-linked-items-select.component';
import { CraftFilterPeriodSelectComponent } from 'src/app/shared/components/craft-filter-select/craft-filter-period-select.component';
import { CraftFilterSelectComponent } from 'src/app/shared/components/craft-filter-select/craft-filter-select.component';
import { CraftFilterUsersSelectComponent } from 'src/app/shared/components/craft-filter-select/craft-filter-users-select.component';
import { CraftIconComponent } from 'src/app/shared/components/craft-icon/craft-icon.component';
import { CraftImportanceLabelComponent } from 'src/app/shared/components/craft-importance-label/craft-importance-label.component';
import { CraftImportancesSelectComponent } from 'src/app/shared/components/craft-importances-select/craft-importances-select.component';
import { CraftInnerStatusesSelectComponent } from 'src/app/shared/components/craft-inner-statuses-select/craft-inner-statuses-select.component';
import { CraftInputBlockComponent } from 'src/app/shared/components/craft-input-block/craft-input-block.component';
import { CraftInputComponent } from 'src/app/shared/components/craft-input/craft-input.component';
import { CraftInvitedUserComponent } from 'src/app/shared/components/craft-invited-user/craft-invited-user.component';
import { CraftItemMappingComponent } from 'src/app/shared/components/craft-item-mapping/craft-item-mapping.component';
import { CraftLineLoadingComponent } from 'src/app/shared/components/craft-line-loading/craft-line-loading.component';
import { CraftManageUserMenuComponent } from 'src/app/shared/components/craft-manage-user-menu/craft-manage-user-menu.component';
import { DeleteCompanyUserDialogComponent } from 'src/app/shared/components/craft-manage-user-menu/delete-company-user-dialog/delete-company-user-dialog.component';
import { CraftMultiSelectComponent } from 'src/app/shared/components/craft-multi-select/craft-multi-select.component';
import { PorlalAccountMenuComponent } from 'src/app/shared/components/craft-porlal-account-menu/porlal-account-menu.component';
import { PorlalHelpMenuComponent } from 'src/app/shared/components/craft-porlal-help-menu/porlal-help-menu.component';
import { CraftPortalLogoComponent } from 'src/app/shared/components/craft-portal-logo/craft-portal-logo.component';
import { CraftPreloaderComponent } from 'src/app/shared/components/craft-preloader/craft-preloader.component';
import { CraftProductLogoComponent } from 'src/app/shared/components/craft-product-logo/craft-product-logo.component';
import { CraftSearchInputComponent } from 'src/app/shared/components/craft-search-input/craft-search-input.component';
import { CraftSingleSelectComponent } from 'src/app/shared/components/craft-single-select/craft-single-select.component';
import { CraftSlidePanelComponent } from 'src/app/shared/components/craft-slide-panel/craft-slide-panel.component';
import { CraftTagsBulkCreateComponent } from 'src/app/shared/components/craft-tags-bulk-create/craft-tags-bulk-create.component';
import { CraftTagsListComponent } from 'src/app/shared/components/craft-tags-list/craft-tags-list.component';
import { CraftTagsComponent } from 'src/app/shared/components/craft-tags/craft-tags.component';
import { CraftTrackButtonComponent } from 'src/app/shared/components/craft-track-button/craft-track-button.component';
import { CraftUserIconListComponent } from 'src/app/shared/components/craft-user-icon-list/craft-user-icon-list.component';
import { CraftUserIconComponent } from 'src/app/shared/components/craft-user-icon/craft-user-icon.component';
import { CraftUserInfoDropdownComponent } from 'src/app/shared/components/craft-user-info-dropdown/craft-user-info-dropdown.component';
import { CraftUserInfoComponent } from 'src/app/shared/components/craft-user-info/craft-user-info.component';
import { CraftUsersSearchSelectTranscludeComponent } from 'src/app/shared/components/craft-users-search-select/craft-users-search-select-transclude/craft-users-search-select-transclude.component';
import { CraftUsersSearchSelectComponent } from 'src/app/shared/components/craft-users-search-select/craft-users-search-select.component';
import { CraftValidationMessage } from 'src/app/shared/components/craft-validation-message/craft-validation-message.component';
import { CraftVotesButtonComponent } from 'src/app/shared/components/craft-votes-button/craft-votes-button.component';
import { CraftVotesCounterComponent } from 'src/app/shared/components/craft-votes-button/craft-votes-counter/craft-votes-counter.component';
import { CraftWorkflowStatusLabelComponent } from 'src/app/shared/components/craft-workflow-status-label/craft-workflow-status-label.component';
import { CraftWorkflowStatusesSelectComponent } from 'src/app/shared/components/craft-workflow-statuses-select/craft-workflow-statuses-select.component';
import { AddCustomFieldMenuComponent } from 'src/app/shared/components/custom-fileds/add-menu/add-menu.component';
import { CreateCustomDateFieldComponent } from 'src/app/shared/components/custom-fileds/custom-field-dialog/date-field/date-field.component';
import { CreateCustomNumberFieldComponent } from 'src/app/shared/components/custom-fileds/custom-field-dialog/number-field/number-field.component';
import { CreateCustomTextFieldComponent } from 'src/app/shared/components/custom-fileds/custom-field-dialog/text-field/text-field.component';
import { CustomPresetsDropdownComponent } from 'src/app/shared/components/custom-fileds/custom-presets/dropdown/custom-presets-dropdown.component';
import { DeleteCustomFieldDialogComponent } from 'src/app/shared/components/custom-fileds/delete-custom-field-dialog/delete-custom-field-dialog.component';
import { IdeaCustomControlComponent } from 'src/app/shared/components/custom-fileds/idea-custom-controls/idea-custom-control/idea-custom-control.component';
import { IdeaCustomControlsComponent } from 'src/app/shared/components/custom-fileds/idea-custom-controls/idea-custom-controls.component';
import { IdeaAttachmentsProgressComponent } from 'src/app/shared/components/idea-attachments-progress/idea-attachments-progress.component';
import { AlertDialogComponent } from 'src/app/shared/dialogs/alert-dialog/alert-dialog.component';
import { CancelUploadDialogComponent } from 'src/app/shared/dialogs/cancel-upload-dialog/cancel-upload-dialog.component';
import { CategoryCreateComponent } from 'src/app/shared/dialogs/category-create/category-create.component';
import { CategoryDialogMenuComponent } from 'src/app/shared/dialogs/category-dialog-menu/category-dialog-menu.component';
import { CategoryEditComponent } from 'src/app/shared/dialogs/category-edit/category-edit.component';
import { CategoryPrivacyDropdownComponent } from 'src/app/shared/dialogs/category-privacy-settings/category-privacy-dropdown/category-privacy-dropdown.component';
import { CategoryPrivacySettingsComponent } from 'src/app/shared/dialogs/category-privacy-settings/category-privacy-settings.component';
import { IdeasVisibilityDropdownComponent } from 'src/app/shared/dialogs/category-privacy-settings/ideas-visibility-dropdown/ideas-visibility-dropdown.component';
import { ChangePortalDomainAlertDialogComponent } from 'src/app/shared/dialogs/change-portal-domain-dialog/change-portal-domain-dialog.component';
import { CompanyUserRowComponent } from 'src/app/shared/dialogs/company-users-dialog/company-user-row/company-user-row.component';
import { CompanyUsersDialogComponent } from 'src/app/shared/dialogs/company-users-dialog/company-users-dialog.component';
import { DeleteAttachmentDialogComponent } from 'src/app/shared/dialogs/delete-attachment-dialog/delete-attachment-dialog.component';
import { DeleteCategoriesOptionSelectComponent } from 'src/app/shared/dialogs/delete-category-dialog/delete-categories-option-select/delete-categories-option-select.component';
import { DeleteCategoryDialogComponent } from 'src/app/shared/dialogs/delete-category-dialog/delete-category-dialog.component';
import { DeleteCommentDialogComponent } from 'src/app/shared/dialogs/delete-comment-dialog/delete-comment-dialog.component';
import { DeleteCompanyAdminDialogComponent } from 'src/app/shared/dialogs/delete-company-admin-dialog/delete-company-admin-dialog.component';
import { DeleteCompanyDialogComponent } from 'src/app/shared/dialogs/delete-company-dialog/delete-company-dialog.component';
import { DeleteCompanyInviteLinkDialogComponent } from 'src/app/shared/dialogs/delete-company-invite-link-dialog/delete-company-invite-link-dialog.component';
import { DeleteIdeaDialogComponent } from 'src/app/shared/dialogs/delete-idea-dialog/delete-idea-dialog.component';
import { DeleteImportanceDialogComponent } from 'src/app/shared/dialogs/delete-importance-dialog/delete-importance-dialog.component';
import { DeleteInnerStatusDialogComponent } from 'src/app/shared/dialogs/delete-inner-status-dialog/delete-inner-status-dialog.component';
import { DeleteNoteDialogComponent } from 'src/app/shared/dialogs/delete-note-dialog/delete-note-dialog.component';
import { DeleteTagDialogComponent } from 'src/app/shared/dialogs/delete-tag-dialog/delete-tag-dialog.component';
import { DeleteWorkflowStatusDialogComponent } from 'src/app/shared/dialogs/delete-workflow-status-dialog/delete-workflow-status-dialog.component';
import { EmptyCategoriesDialogComponent } from 'src/app/shared/dialogs/empty-categories-dialog/empty-categories-dialog.component';
import { IdeaCategoryDropdownComponent } from 'src/app/shared/dialogs/idea-category-dropdown/idea-category-dropdown.component';
import { IdeaCreateDialogComponent } from 'src/app/shared/dialogs/idea-create/idea-create.component';
import { IdeaEditDialogComponent } from 'src/app/shared/dialogs/idea-edit/idea-edit.component';
import { NotificationOverlayComponent } from 'src/app/shared/dialogs/notifications/notification-overlay/notification-overlay.component';
import { NotificationItemComponent } from 'src/app/shared/dialogs/notifications/notifications/notification/notification.component';
import { NotificationsListComponent } from 'src/app/shared/dialogs/notifications/notifications/notifications.component';
import { SelectCraftItemDialogComponent } from 'src/app/shared/dialogs/select-craft-item/select-craft-item-dialog/select-craft-item-dialog.component';
import { CRAFT_DATE_FORMATS } from 'src/app/shared/dictionaries/craft-date-format';
import { CraftAutoFocusDirective } from 'src/app/shared/directives/auto-focus.directive';
import { CraftAutoresizeDirective } from 'src/app/shared/directives/autoresize.directive';
import { CraftPermissionsDirective } from 'src/app/shared/directives/craft-permissions.directive';
import { CraftRoleDirective } from 'src/app/shared/directives/craft-role.directive';
import { CraftDisableFocusDirective } from 'src/app/shared/directives/disable-focus.directive';
import { CraftNumbersOnlyDirective } from 'src/app/shared/directives/numbers-only.directive';
import { AppUrlPipe } from 'src/app/shared/pipes/app-url.pipe';
import { BigNumPipe } from 'src/app/shared/pipes/big-num.pipe';
import { FormatPeriodPipe } from 'src/app/shared/pipes/format-period.pipe';
import { FullNamePipe } from 'src/app/shared/pipes/full-name.pipe';
import { LinkifyPipe } from 'src/app/shared/pipes/linkify.pipe';
import { MathAbsPipe } from 'src/app/shared/pipes/math-abs.pipe';
import { MediumNamePipe } from 'src/app/shared/pipes/medium-name.pipe';
import { PropsSizePipe } from 'src/app/shared/pipes/props-size.pipe';
import { SafePipe } from 'src/app/shared/pipes/safe.pipe';
import { ShortNamePipe } from 'src/app/shared/pipes/short-name.pipe';
import { StripTagsPipe } from 'src/app/shared/pipes/strip-tags.pipe';
import { ThumbBkgPipe } from 'src/app/shared/pipes/thumb-bkg.pipe';
import { ThumbPipe } from 'src/app/shared/pipes/thumb.pipe';
import { TimeAgoPipe } from 'src/app/shared/pipes/time-ago.pipe';
import { ChangeWorkspaceDialogComponent } from '../settings/pages/general/change-workspace-dialog/change-workspace-dialog.component';
import { CraftCalendarComponent } from './components/craft-calendar/calendar.component';
import { CraftCopyTextButtonComponent } from './components/craft-copy-text-button/craft-copy-text-button.component';
import { CraftNumberInputComponent } from './components/craft-number-input/craft-number-input.component';
import { CustomFieldsAddDescriptionButtonComponent } from './components/custom-fileds/custom-field-dialog/add-description-button/add-description-button.component';
import { CreateCustomMultiSelectFieldComponent } from './components/custom-fileds/custom-field-dialog/multi-select-field/multi-select.component';
import { CustomFieldOptionItemFormComponent } from './components/custom-fileds/custom-field-dialog/options-list/option-item/option-item.component';
import { CustomFieldOptionsListFormComponent } from './components/custom-fileds/custom-field-dialog/options-list/options-list.component';
import { CreateCustomSingleSelectFieldComponent } from './components/custom-fileds/custom-field-dialog/single-select-field/single-select.component';
import { CustomPresetsMenuComponent } from './components/custom-fileds/custom-presets/menu/custom-presets-menu.component';
import { CustomValidationToggleComponent } from './components/custom-fileds/custom-validation-toggle/custom-validation-toggle.component';
import { IdeaCustomItemComponent } from './components/custom-fileds/idea-custom-list/idea-custom-item/idea-custom-item.component';
import { IdeaCustomListComponent } from './components/custom-fileds/idea-custom-list/idea-custom-list.component';
import { CraftItemEditComponent } from './dialogs/craft-item-edit/craft-item-edit.component';
import { DeleteFormDialogComponent } from './dialogs/delete-form-dialog/delete-form-dialog.component';
import { CraftItemsAppTreeComponent } from './dialogs/select-craft-item/craft-items-app-tree/craft-items-app-tree.component';
import { TagsCreateDialogComponent } from './dialogs/tags-create/tags-create.component';
import { CraftTerminologyPipe } from './pipes/craft-term.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FormatNumPipe } from './pipes/format-num.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    EditorsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTooltipModule,
    OverlayModule,
    PortalModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    AddCustomFieldMenuComponent,
    AlertDialogComponent,
    AppUrlPipe,
    BigNumPipe,
    CancelUploadDialogComponent,
    CategoryCreateComponent,
    CategoryDialogMenuComponent,
    CategoryEditComponent,
    CategoryPrivacyDropdownComponent,
    CategoryPrivacySettingsComponent,
    ChangePortalDomainAlertDialogComponent,
    CompanyUserRowComponent,
    CompanyUsersDialogComponent,
    CraftAttachSelectButtonComponent,
    CraftAutoFocusDirective,
    CraftAutoresizeDirective,
    CraftCalendarComponent,
    CraftCategoriesAutocompleteComponent,
    CraftCategoriesSelectComponent,
    CraftCategoryCompaniesComponent,
    CraftCategoryLabelComponent,
    CraftCategoryPrivacyIconComponent,
    CraftColorSelectorComponent,
    CraftCopyTextButtonComponent,
    CraftDisableFocusDirective,
    CraftEmailAutocompleteComponent,
    CraftFileSelectButtonComponent,
    CraftFilterLinkedItemsSelectComponent,
    CraftFilterPeriodSelectComponent,
    CraftFilterSelectComponent,
    CraftFilterUsersSelectComponent,
    CraftIconComponent,
    CraftImportanceLabelComponent,
    CraftImportancesSelectComponent,
    CraftInnerStatusesSelectComponent,
    CraftInputBlockComponent,
    CraftInputComponent,
    CraftInvitedUserComponent,
    CraftItemEditComponent,
    CraftItemMappingComponent,
    CraftItemsAppTreeComponent,
    CraftLineLoadingComponent,
    CraftManageUserMenuComponent,
    CraftMultiSelectComponent,
    CraftNumberInputComponent,
    CraftNumbersOnlyDirective,
    CraftPermissionsDirective,
    CraftPortalLogoComponent,
    CraftPreloaderComponent,
    CraftProductLogoComponent,
    CraftRoleDirective,
    CraftSearchInputComponent,
    CraftSingleSelectComponent,
    CraftSlidePanelComponent,
    CraftTagsBulkCreateComponent,
    CraftTagsComponent,
    CraftTagsListComponent,
    CraftTerminologyPipe,
    CraftTrackButtonComponent,
    CraftUserIconComponent,
    CraftUserIconListComponent,
    CraftUserInfoComponent,
    CraftUserInfoDropdownComponent,
    CraftUsersSearchSelectComponent,
    CraftUsersSearchSelectTranscludeComponent,
    CraftValidationMessage,
    CraftVotesButtonComponent,
    CraftVotesCounterComponent,
    CraftWorkflowStatusesSelectComponent,
    CraftWorkflowStatusLabelComponent,
    CreateCustomDateFieldComponent,
    CreateCustomMultiSelectFieldComponent,
    CreateCustomNumberFieldComponent,
    CreateCustomSingleSelectFieldComponent,
    CreateCustomTextFieldComponent,
    CustomFieldOptionItemFormComponent,
    CustomFieldOptionsListFormComponent,
    CustomFieldsAddDescriptionButtonComponent,
    CustomPresetsDropdownComponent,
    CustomPresetsMenuComponent,
    CustomValidationToggleComponent,
    ChangeWorkspaceDialogComponent,
    DeleteAttachmentDialogComponent,
    DeleteCategoriesOptionSelectComponent,
    DeleteCategoryDialogComponent,
    DeleteCommentDialogComponent,
    DeleteCompanyAdminDialogComponent,
    DeleteCompanyDialogComponent,
    DeleteCompanyInviteLinkDialogComponent,
    DeleteCompanyUserDialogComponent,
    DeleteCustomFieldDialogComponent,
    DeleteIdeaDialogComponent,
    DeleteImportanceDialogComponent,
    DeleteInnerStatusDialogComponent,
    DeleteNoteDialogComponent,
    DeleteTagDialogComponent,
    DeleteWorkflowStatusDialogComponent,
    DeleteFormDialogComponent,
    EmptyCategoriesDialogComponent,
    FilterPipe,
    FormatNumPipe,
    FormatPeriodPipe,
    FullNamePipe,
    IdeaAttachmentsProgressComponent,
    IdeaCategoryDropdownComponent,
    IdeaCreateDialogComponent,
    IdeaCustomControlComponent,
    IdeaCustomControlsComponent,
    IdeaCustomItemComponent,
    IdeaCustomListComponent,
    IdeaEditDialogComponent,
    IdeasVisibilityDropdownComponent,
    LinkifyPipe,
    MathAbsPipe,
    MediumNamePipe,
    NotificationItemComponent,
    NotificationOverlayComponent,
    NotificationsListComponent,
    PorlalAccountMenuComponent,
    PorlalHelpMenuComponent,
    PropsSizePipe,
    SafePipe,
    SelectCraftItemDialogComponent,
    ShortNamePipe,
    StripTagsPipe,
    TagsCreateDialogComponent,
    ThumbBkgPipe,
    ThumbPipe,
    TimeAgoPipe,
    TruncatePipe,
  ],
  exports: [
    AddCustomFieldMenuComponent,
    AlertDialogComponent,
    AppUrlPipe,
    BigNumPipe,
    CancelUploadDialogComponent,
    CategoryCreateComponent,
    CategoryDialogMenuComponent,
    CategoryEditComponent,
    CategoryPrivacyDropdownComponent,
    CategoryPrivacySettingsComponent,
    ChangePortalDomainAlertDialogComponent,
    CompanyUserRowComponent,
    CompanyUsersDialogComponent,
    CraftAttachSelectButtonComponent,
    CraftAutoFocusDirective,
    CraftAutoresizeDirective,
    CraftCalendarComponent,
    CraftCategoriesAutocompleteComponent,
    CraftCategoriesSelectComponent,
    CraftCategoryCompaniesComponent,
    CraftCategoryLabelComponent,
    CraftCategoryPrivacyIconComponent,
    CraftColorSelectorComponent,
    CraftCopyTextButtonComponent,
    CraftDisableFocusDirective,
    CraftEmailAutocompleteComponent,
    CraftFileSelectButtonComponent,
    CraftFilterLinkedItemsSelectComponent,
    CraftFilterPeriodSelectComponent,
    CraftFilterSelectComponent,
    CraftFilterUsersSelectComponent,
    CraftIconComponent,
    CraftImportanceLabelComponent,
    CraftImportancesSelectComponent,
    CraftInnerStatusesSelectComponent,
    CraftInputBlockComponent,
    CraftInputComponent,
    CraftInvitedUserComponent,
    CraftItemMappingComponent,
    CraftItemsAppTreeComponent,
    CraftLineLoadingComponent,
    CraftManageUserMenuComponent,
    CraftMultiSelectComponent,
    CraftNumberInputComponent,
    CraftNumbersOnlyDirective,
    CraftPermissionsDirective,
    CraftPortalLogoComponent,
    CraftPreloaderComponent,
    CraftProductLogoComponent,
    CraftRoleDirective,
    CraftSearchInputComponent,
    CraftSingleSelectComponent,
    CraftSlidePanelComponent,
    CraftTagsBulkCreateComponent,
    CraftTagsComponent,
    CraftTagsListComponent,
    CraftTerminologyPipe,
    CraftTrackButtonComponent,
    CraftUserIconComponent,
    CraftUserIconListComponent,
    CraftUserInfoComponent,
    CraftUserInfoDropdownComponent,
    CraftUsersSearchSelectComponent,
    CraftUsersSearchSelectTranscludeComponent,
    CraftValidationMessage,
    CraftVotesButtonComponent,
    CraftVotesCounterComponent,
    CraftWorkflowStatusesSelectComponent,
    CraftWorkflowStatusLabelComponent,
    CreateCustomDateFieldComponent,
    CreateCustomMultiSelectFieldComponent,
    CreateCustomNumberFieldComponent,
    CreateCustomSingleSelectFieldComponent,
    CreateCustomTextFieldComponent,
    CustomFieldOptionItemFormComponent,
    CustomFieldOptionsListFormComponent,
    CustomFieldsAddDescriptionButtonComponent,
    CustomPresetsDropdownComponent,
    CustomPresetsMenuComponent,
    CustomValidationToggleComponent,
    ChangeWorkspaceDialogComponent,
    DeleteAttachmentDialogComponent,
    DeleteCategoriesOptionSelectComponent,
    DeleteCategoryDialogComponent,
    DeleteCommentDialogComponent,
    DeleteCompanyAdminDialogComponent,
    DeleteCompanyDialogComponent,
    DeleteCompanyInviteLinkDialogComponent,
    DeleteCompanyUserDialogComponent,
    DeleteCustomFieldDialogComponent,
    DeleteIdeaDialogComponent,
    DeleteImportanceDialogComponent,
    DeleteInnerStatusDialogComponent,
    DeleteNoteDialogComponent,
    DeleteTagDialogComponent,
    DeleteWorkflowStatusDialogComponent,
    DeleteFormDialogComponent,
    EmptyCategoriesDialogComponent,
    FilterPipe,
    FormatNumPipe,
    FormatPeriodPipe,
    FullNamePipe,
    IdeaAttachmentsProgressComponent,
    IdeaCategoryDropdownComponent,
    IdeaCreateDialogComponent,
    IdeaCustomControlComponent,
    IdeaCustomControlsComponent,
    IdeaCustomItemComponent,
    IdeaCustomListComponent,
    IdeaEditDialogComponent,
    IdeasVisibilityDropdownComponent,
    LinkifyPipe,
    MathAbsPipe,
    MediumNamePipe,
    NotificationItemComponent,
    NotificationOverlayComponent,
    NotificationsListComponent,
    PorlalAccountMenuComponent,
    PorlalHelpMenuComponent,
    PropsSizePipe,
    SafePipe,
    SelectCraftItemDialogComponent,
    ShortNamePipe,
    StripTagsPipe,
    TagsCreateDialogComponent,
    ThumbBkgPipe,
    ThumbPipe,
    TimeAgoPipe,
    TruncatePipe,
  ],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: CRAFT_DATE_FORMATS }],
})
export class SharedModule {}
