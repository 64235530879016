<div class="dialog-wrap">
  <button type="button"
          (click)="close()"
          class="btn btn-close text-size-15">
    <craft-icon class="svg-icon"
                name="general--close-md"></craft-icon>
  </button>

  <h1 mat-dialog-title
      class="dialog-title">Delete note</h1>

  <div mat-dialog-content=""
       class="dialog-content">
    <div class="dialog-desc">Are you sure you want to delete this note?</div>
  </div>

  <div mat-dialog-actions=""
       class="dialog-actions d-flex justify-end">
    <button class="btn btn-cancel dialog-btn"
            type="button"
            (click)="close()"
            mat-button="">Cancel</button>
    <button class="btn btn-blue dialog-btn"
            type="button"
            (click)="done()"
            mat-button="">Remove</button>
  </div>
</div>
