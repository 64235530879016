import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DBCategory } from 'src/app/core/models/categories.models';
import { StringTMap } from 'src/app/core/models/common.models';
import {
  CategoriesAction,
  CATEGORIES_ADD,
  CATEGORIES_ADD_SUCCESS,
  CATEGORIES_DEL,
  CATEGORIES_LIST_SUCCESS,
  CATEGORIES_SAVE,
  CATEGORIES_SAVE_SUCCESS,
  CATEGORIES_SELECT_BY_ID,
  CATEGORIES_UPDATE_ONE
} from 'src/app/core/store/actions/categories';

export enum CategoriesStateStatus {
  notDefined,
  loaded,
}

export type CategoriesState = {
  readonly ids: readonly string[];
  readonly categories: StringTMap<DBCategory>;
  readonly status: CategoriesStateStatus;
  readonly savingId: string | undefined;
  readonly selectedId: string | undefined;
};

export const initialState: CategoriesState = {
  ids: [],
  categories: {},
  status: CategoriesStateStatus.notDefined,
  savingId: void 0,
  selectedId: void 0,
};

export function reducer(state = initialState, action: CategoriesAction): CategoriesState {
  switch (action.type) {
    case CATEGORIES_LIST_SUCCESS: {
      const { categories, ids } = action.payload;
      return {
        ...state,
        categories,
        ids,
        status: CategoriesStateStatus.loaded,
      };
    }

    case CATEGORIES_ADD: {
      return {
        ...state,
        savingId: void 0,
      };
    }

    case CATEGORIES_ADD_SUCCESS: {
      const category = action.payload;
      return {
        ...state,
        savingId: void 0,
        ids: [...state.ids, category.id],
        categories: {
          ...state.categories,
          [category.id as string]: category,
        },
      };
    }

    case CATEGORIES_SAVE: {
      const category = action.payload;

      return {
        ...state,
        savingId: category.id,
        categories: {
          ...state.categories,
          [category.id as string]: category,
        },
      };
    }

    case CATEGORIES_SAVE_SUCCESS: {
      return {
        ...state,
        savingId: void 0,
      };
    }

    case CATEGORIES_DEL: {
      const categoryId = action.payload.deletedCategoryId;
      delete state.categories[categoryId];

      return {
        ...state,
        ids: state.ids.filter((id) => id !== categoryId),
        selectedId: categoryId === state.selectedId ? void 0 : state.selectedId,
        categories: {
          ...state.categories,
        },
      };
    }

    case CATEGORIES_UPDATE_ONE: {
      const category = state.categories[action.payload.id!];

      return {
        ...state,
        categories: {
          ...state.categories,
          [category.id!]: { ...category, ...action.payload },
        },
      };
    }

    case CATEGORIES_SELECT_BY_ID: {
      return {
        ...state,
        selectedId: action.payload,
      };
    }

    default:
      return state;
  }
}

const getCategoriesIds = (state: CategoriesState) => state.ids;
const getCategories = (state: CategoriesState) => state.categories;
const getCategoriesStatus = (state: CategoriesState) => state.status;
const getCategoriesSavingId = (state: CategoriesState) => state.savingId;
const getCategoriesSelectedId = (state: CategoriesState) => state.selectedId;

export const getCategoriesStateSelector = createFeatureSelector<CategoriesState>('categoriesState');
export const getCategoriesIdsSelector = createSelector(getCategoriesStateSelector, getCategoriesIds);
export const getCategoriesStatusSelector = createSelector(getCategoriesStateSelector, getCategoriesStatus);
export const getCategoriesSavingIdSelector = createSelector(getCategoriesStateSelector, getCategoriesSavingId);
export const getCategoriesSelector = createSelector(getCategoriesStateSelector, getCategories);
export const getCategoriesSelectedIdSelector = createSelector(getCategoriesStateSelector, getCategoriesSelectedId);

export const getCategoriesListSelector = createSelector(getCategoriesStateSelector, getCategoriesIdsSelector, (state, ids) =>
  ids.map((id) => state.categories[id]),
);

export const getSelectedCategorySelector = createSelector(getCategoriesStateSelector, getCategoriesSelectedIdSelector, (state, id) =>
  id !== void 0 ? state.categories[id] : void 0,
);
