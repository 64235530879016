import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, Type } from '@angular/core';

export type BodyComponentRef<C> = {
  ref: ComponentRef<C>;
  destroy: () => void;
};

@Injectable({
  providedIn: 'root',
})
export class DomService {
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) {}

  public appendComponentToBody<C>(component: Type<C>, instanceParams?: any): BodyComponentRef<C> {
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);

    Object.assign(componentRef.instance as any, instanceParams);

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);

    componentRef.changeDetectorRef.detectChanges();

    return {
      ref: componentRef,
      destroy: () => {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
      },
    };
  }
}
