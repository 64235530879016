import { Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subject, forkJoin, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Category } from 'src/app/core/models/categories.models';
import { Idea, IdeaCustomFieldValue } from 'src/app/core/models/idea.models';
import { AttachEvent, AttachService } from 'src/app/core/services/backend/attaches-backend.service';
import { RouterExtService } from 'src/app/core/services/router-ext.service';
import { UserPermissionsService } from 'src/app/core/services/user-permissions.service';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { EntityFactory } from 'src/app/core/store/services/entity.factory';
import { IdeasStoreService } from 'src/app/core/store/services/ideas-store.service';
import { ImportancesStoreService } from 'src/app/core/store/services/importances-store.service';
import { getFormValidationErrors } from 'src/app/core/utils/form-utils';
import { CustomFieldsFormsService } from 'src/app/shared/services/custom-fields-forms.service';

export type CreateIdeaDialogInput = {
  categoryId?: string;
  importanceId?: string;
};

@Component({
  templateUrl: './idea-create.component.html',
  styleUrls: ['./idea-create.component.scss'],
})
export class IdeaCreateDialogComponent implements OnInit, OnDestroy {
  @ViewChild('scrollConteiner') public scrollConteinerRef: ElementRef;

  public form: UntypedFormGroup;
  public isSending = false;
  public isSubmitted = false;
  public newIdea: Readonly<Idea>;
  public newCategory?: Readonly<Category>;

  private destroy$ = new Subject<void>();
  private selectedCategoryId?: string;

  constructor(
    public ups: UserPermissionsService,

    private router: Router,
    private attachService: AttachService,
    private entityFactory: EntityFactory,
    private ideasStore: IdeasStoreService,
    private routerExService: RouterExtService,
    private categoriesStore: CategoriesStoreService,
    private customFieldsForms: CustomFieldsFormsService,
    private importancesStore: ImportancesStoreService,
    private dialogRef: MatDialogRef<IdeaCreateDialogComponent, void>,
    @Inject(MAT_DIALOG_DATA) @Optional() public data?: CreateIdeaDialogInput,
  ) {}

  public get isAnyUploading() {
    return this.attachService.uploadingSize > 0;
  }

  public get formErrors(): number {
    if (!this.isSubmitted) return 0;
    if (this.form.valid) return 0;

    return getFormValidationErrors(this.form).length;
  }

  public ngOnInit() {
    this.buildNewIdea()
      .pipe(
        switchMap((idea) =>
          this.customFieldsForms.buildForm(idea).pipe(
            map((form) => ({ idea, form })), //
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(({ idea, form }) => {
        this.selectedCategoryId = form.controls.categoryId.value;
        this.form = form;
        this.newIdea = idea;
      });

    this.attachService.attach$
      .pipe(
        takeUntil(this.destroy$), //
      )
      .subscribe(this.onAttachAdded);

    this.subscribeToCategoryIdChanged();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public close() {
    this.dialogRef.close();
  }

  public subscribeToCategoryIdChanged() {
    this.form
      .get('categoryId')
      ?.valueChanges.pipe(
        filter((categoryId) => this.selectedCategoryId !== categoryId),
        switchMap((categoryId) => this.customFieldsForms.buildForm({ ...this.newIdea, categoryId })),
        takeUntil(this.destroy$),
      )
      .subscribe((form) => {
        const oldValues = this.form.value;
        const { custom, categoryId } = form.value;

        const patch = custom.map((value: IdeaCustomFieldValue) => {
          const oldValue = oldValues?.custom?.find((oldValue: IdeaCustomFieldValue) => oldValue.id === value.id);
          return oldValue ? { ...value, value: oldValue.value } : value;
        });

        this.form = form;
        this.selectedCategoryId = categoryId;
        this.form.controls.custom.patchValue(patch);

        this.subscribeToCategoryIdChanged();
      });
  }

  public submit() {
    this.isSubmitted = true;
    if (this.form.invalid) return;

    this.isSending = true;
    this.saveNewCategory()
      .pipe(
        map((categoryId): Idea => {
          return {
            ...this.newIdea,
            ...this.customFieldsForms.formToIdea(this.form),
            categoryId,
          };
        }),
        switchMap((idea: Idea) => {
          return this.ideasStore.add(idea);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((savedIdea) => {
        this.isSending = false;
        this.newIdea = savedIdea;
      });
  }

  public redirectToIdea() {
    this.close();
    this.router.navigate(['/', this.newIdea.shortId]);
  }

  public redirectToIdeas() {
    this.close();
    this.router.navigate(this.routerExService.getIdeasListRoute(this.newIdea.productKey));
  }

  public selectNewCategory(cat: Readonly<Category>) {
    this.newCategory = cat;
  }

  private saveNewCategory() {
    const categoryId = this.form.value.categoryId as string;

    if (this.newCategory && this.newCategory.id === categoryId) {
      return this.categoriesStore
        .add({
          ...this.newCategory,
          id: void 0,
        })
        .pipe(map((category) => category.id));
    } else if (!categoryId) {
      return throwError(() => new Error('categoryId is empty!'));
    }

    return of(categoryId);
  }

  public attachFiles(files: FileList) {
    this.attachService.attachFiles(files, this.newIdea).pipe(takeUntil(this.destroy$)).subscribe();
  }

  private onAttachAdded = (e: AttachEvent) => {
    const formAttaches = this.form.get('attaches')!.value || [];
    this.form.get('attaches')!.setValue([...formAttaches, e.attachment]);

    setTimeout(() => this.scrollToEnd(), 0);
  };

  private scrollToEnd() {
    const scrollConteiner: HTMLDivElement | null = this.scrollConteinerRef ? this.scrollConteinerRef.nativeElement : null;

    if (scrollConteiner) {
      scrollConteiner.scrollTop = scrollConteiner.scrollHeight;
    }
  }

  private buildNewIdea(): Observable<Readonly<Idea>> {
    return forkJoin([
      this.categoriesStore.selectedId$.pipe(take(1)), //
      this.categoriesStore.list$.pipe(take(1)),
      this.importancesStore.list$.pipe(take(1)),
    ]).pipe(
      switchMap(([selectedCategoryId, categories, importances]) => {
        let { importanceId, categoryId } = this.data || ({} as CreateIdeaDialogInput);

        categoryId = categoryId || selectedCategoryId || categories.find((c) => c.isDefault)?.id;
        importanceId = importanceId || importances.length > 0 ? importances[0].id : void 0;

        return this.entityFactory.createIdea({
          categoryId,
          importanceId,
        });
      }),
    );
  }
}
