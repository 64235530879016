import { Action } from '@ngrx/store';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { DBWorkflowStatus, WorkflowStatus, WorkflowStatusDelRequest, WorkflowStatusesListResponse } from 'src/app/core/models/workflow-statuses.models';

export const WORKFLOW_STATUSES_LIST = '[WorkflowStatuses] list';
export const WORKFLOW_STATUSES_LIST_ERROR = '[WorkflowStatuses] list error';
export const WORKFLOW_STATUSES_LIST_SUCCESS = '[WorkflowStatuses] list success';

export const WORKFLOW_STATUSES_ADD = '[WorkflowStatuses] add';
export const WORKFLOW_STATUSES_ADD_ERROR = '[WorkflowStatuses] add error';
export const WORKFLOW_STATUSES_ADD_SUCCESS = '[WorkflowStatuses] add success';

export const WORKFLOW_STATUSES_SAVE = '[WorkflowStatuses] save';
export const WORKFLOW_STATUSES_SAVE_ERROR = '[WorkflowStatuses] save error';
export const WORKFLOW_STATUSES_SAVE_SUCCESS = '[WorkflowStatuses] save success';

export const WORKFLOW_STATUSES_DELETE = '[WorkflowStatuses] delete';
export const WORKFLOW_STATUSES_DELETE_ERROR = '[WorkflowStatuses] delete error';
export const WORKFLOW_STATUSES_DELETE_SUCCESS = '[WorkflowStatuses] delete success';

// LIST
export class WorkflowStatusesReqListAction implements Action {
  readonly type = WORKFLOW_STATUSES_LIST;
  constructor(public payload?: void) {}
}
export class WorkflowStatusesReqListErrorAction implements Action {
  readonly type = WORKFLOW_STATUSES_LIST_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class WorkflowStatusesReqListSuccessAction implements Action {
  readonly type = WORKFLOW_STATUSES_LIST_SUCCESS;
  constructor(public payload: WorkflowStatusesListResponse) {}
}

// ADD
export class WorkflowStatusesReqAddAction implements Action {
  readonly type = WORKFLOW_STATUSES_ADD;
  constructor(public payload: WorkflowStatus) {}
}
export class WorkflowStatusesReqAddErrorAction implements Action {
  readonly type = WORKFLOW_STATUSES_ADD_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class WorkflowStatusesReqAddSuccessAction implements Action {
  readonly type = WORKFLOW_STATUSES_ADD_SUCCESS;
  constructor(public payload: DBWorkflowStatus) {}
}

// SAVE
export class WorkflowStatusesReqSaveAction implements Action {
  readonly type = WORKFLOW_STATUSES_SAVE;
  constructor(public payload: DBWorkflowStatus) {}
}
export class WorkflowStatusesReqSaveErrorAction implements Action {
  readonly type = WORKFLOW_STATUSES_SAVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class WorkflowStatusesReqSaveSuccessAction implements Action {
  readonly type = WORKFLOW_STATUSES_SAVE_SUCCESS;
  constructor(public payload: DBWorkflowStatus) {}
}

// DELETE
export class WorkflowStatusesReqDelAction implements Action {
  readonly type = WORKFLOW_STATUSES_DELETE;
  constructor(public payload: WorkflowStatusDelRequest) {}
}
export class WorkflowStatusesReqDelErrorAction implements Action {
  readonly type = WORKFLOW_STATUSES_DELETE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class WorkflowStatusesReqDelSuccessAction implements Action {
  readonly type = WORKFLOW_STATUSES_DELETE_SUCCESS;
  constructor(public payload: string) {}
}

export type WorkflowStatusesAction =
  | WorkflowStatusesReqListAction
  | WorkflowStatusesReqListErrorAction
  | WorkflowStatusesReqListSuccessAction
  //
  | WorkflowStatusesReqAddAction
  | WorkflowStatusesReqAddErrorAction
  | WorkflowStatusesReqAddSuccessAction
  //
  | WorkflowStatusesReqSaveAction
  | WorkflowStatusesReqSaveErrorAction
  | WorkflowStatusesReqSaveSuccessAction
  //
  | WorkflowStatusesReqDelAction
  | WorkflowStatusesReqDelErrorAction
  | WorkflowStatusesReqDelSuccessAction;
