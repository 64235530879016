import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IDEAS_REQ_TO_QUERY_PARAMS_TABLE, QUERY_PARAMS_TO_IDEAS_REQ_TABLE } from 'src/app/core/dictionaries/ideas-filters-table';
import { IDEAS_SORT_TYPES } from 'src/app/core/dictionaries/ideas-sort-fields';
import { STATIC_FILTERS } from 'src/app/core/dictionaries/static-filters';
import { StringAnyMap, StringStringMap } from 'src/app/core/models/common.models';
import { ServerFilterValue, SortField, StaticFilter } from 'src/app/core/models/filters.models';
import { IdeasListRequest } from 'src/app/core/models/idea.models';
import { UserPermissionsService } from 'src/app/core/services/user-permissions.service';
import { FiltersStoreService } from 'src/app/core/store/services/filters-store.service';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';
import { WorkflowStatusesStoreService } from 'src/app/core/store/services/workflow-statuses-store.service';

@Injectable({
  providedIn: 'root',
})
export class IdeasSortFiltersService {
  public readonly sort$: Observable<SortField | undefined>;
  public readonly sortFields$: Observable<readonly SortField[]>;
  public readonly workflowStatuses$ = this.workflowStatusesStore.map$;

  constructor(
    private ups: UserPermissionsService,
    private portalStore: PortalStoreService,
    private workflowStatusesStore: WorkflowStatusesStoreService,
    private filtersStore: FiltersStoreService,
  ) {
    this.sort$ = this.filtersStore.sort$.pipe(
      map((sortType) => {
        const isRevert = sortType[0] === '-';
        if (isRevert) sortType = sortType.substr(1);
        const res = IDEAS_SORT_TYPES.find((st) => st.field === sortType);
        return res ? { ...res, revert: isRevert } : void 0;
      }),
    );

    this.sortFields$ = of(void 0).pipe(
      map(() => {
        const res = IDEAS_SORT_TYPES.filter((st) => !st.permission || this.ups.authorize(st.permission));
        return this.hideVotes ? res.filter((st) => !st.hideVotes) : res;
      }),
    );
  }

  public getStaticFilters(): StaticFilter[] {
    return STATIC_FILTERS.reduce((res, sn) => {
      if (this.isValidStaticFilter(sn)) {
        res.push(sn);
      }
      return res;
    }, [] as StaticFilter[]);
  }

  public isValidStaticFilter(node: StaticFilter): boolean {
    return (!this.hideTrack || !node.hideTrack) && this.ups.authorize(node.accessLevel);
  }

  public routeParamsToIdeasReq(queryParams: StringStringMap): Partial<IdeasListRequest> {
    return Object.keys(QUERY_PARAMS_TO_IDEAS_REQ_TABLE).reduce((res, key) => {
      const tableRow = QUERY_PARAMS_TO_IDEAS_REQ_TABLE[key];
      const routeValue: string = queryParams[key];

      let filterVal: ServerFilterValue;

      if (routeValue) {
        if (tableRow.type === 'array') {
          filterVal = routeValue.split(',');
        } else if (tableRow.transform) {
          filterVal = tableRow.transform(routeValue);
        } else {
          filterVal = routeValue;
        }
      } else {
        filterVal = tableRow.defaultValue;
      }

      (res as any)[tableRow.key] = filterVal;
      return res;
    }, {} as Partial<IdeasListRequest>);
  }

  public ideasReqToRouteParams(req: Partial<IdeasListRequest>): StringAnyMap {
    return Object.keys(req).reduce((res, key) => {
      const tableRow = IDEAS_REQ_TO_QUERY_PARAMS_TABLE[key];
      const filterVal: ServerFilterValue = (req as any)[key];

      if (Array.isArray(filterVal) && filterVal.length > 0) {
        res[tableRow.key] = filterVal.join(',');
      } else if ((typeof filterVal === 'string' && filterVal) || typeof filterVal === 'number') {
        res[tableRow.key] = filterVal;
      } else {
        res[tableRow.key] = void 0;
      }

      return res;
    }, {} as StringAnyMap);
  }

  public makeIdeasRequest(req: IdeasListRequest): Observable<Readonly<IdeasListRequest>> {
    return this.filtersStore.state$.pipe(take(1)).pipe(
      map((state) => {
        return {
          ...state,
          ...req,
          page: req.page || state.page,
          limit: req.limit || state.limit,
        };
      }),
    );
  }

  private get hideTrack(): boolean {
    return !!this.portalStore.portalSnapshot?.hideTrack;
  }

  private get hideVotes(): boolean {
    return !!this.portalStore.portalSnapshot?.hideVotes;
  }
}
