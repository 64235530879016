import { ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StringAnyMap } from 'src/app/core/models/common.models';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { BaseSingleSelectComponent } from '../craft-single-select/craft-single-select.component';

@Component({
  selector: 'craft-categories-select',
  templateUrl: './craft-categories-select.component.html',
  styleUrls: ['./craft-categories-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CraftCategoriesSelectComponent),
      multi: true,
    },
  ],
})
export class CraftCategoriesSelectComponent extends BaseSingleSelectComponent implements OnInit, OnDestroy {
  @Input() public items: Readonly<StringAnyMap>;

  private destroy$ = new Subject<void>();

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private categoriesStore: CategoriesStoreService,
  ) {
    super(changeDetectorRef);
    this.idFieldName = 'id';
    this.labelFieldName = 'name';
  }

  public ngOnInit() {
    this.categoriesStore.map$.pipe(takeUntil(this.destroy$)).subscribe((categories) => {
      this.setItems(categories, this.selectedId);
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
