<div class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title">Delete importance</h1>

  <div mat-dialog-content="" class="dialog-content">
    <div class="dialog-subtitle">Choose a different importance for the items:</div>
    <craft-single-select [items]="importances" labelFieldName="name" idFieldName="id" (select)="selectedImp = $event">
      <span [ngSwitch]="!!selectedImp">
        <span *ngSwitchCase="false">Choose</span>
        <span *ngSwitchCase="true" class="ml-8 ellipsis">{{ selectedImp.name }}</span>
        <craft-icon class="svg-icon text-size-11 ml-4" name="arrows--arrow-head-down-sm"></craft-icon>
      </span>
    </craft-single-select>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <button class="btn btn-cancel dialog-btn" type="button" (click)="close()" mat-button="">Cancel</button>
    <button class="btn btn-blue dialog-btn" type="button" [disabled]="!selectedImp" (click)="done()" mat-button="">Done</button>
  </div>
</div>
