import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  FiltersState, getCategoryIdSelector,
  getFiltersLimitSelector,
  getFiltersPageSelector,
  getFiltersSortSelector,
  getFiltersStateSelector,
  getStaticFilterSelector
} from 'src/app/core/store/reducers/filters';
import * as actions from '../actions/filters';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class FiltersStoreService {
  public readonly state$ = this.store$.pipe(select(getFiltersStateSelector));
  public readonly sort$ = this.store$.pipe(select(getFiltersSortSelector));
  public readonly page$ = this.store$.pipe(select(getFiltersPageSelector));
  public readonly limit$ = this.store$.pipe(select(getFiltersLimitSelector));
  public readonly categoryId$ = this.store$.pipe(select(getCategoryIdSelector));
  public readonly staticFilter$ = this.store$.pipe(select(getStaticFilterSelector));

  public readonly isDynamicFiltersExists$: Observable<boolean>;

  constructor(private store$: Store<CoreState>) {
    this.isDynamicFiltersExists$ = this.store$.pipe(
      select(getFiltersStateSelector),
      map((state) => this.isDynamicFiltersExists(state)),
    );
  }

  public isDynamicFiltersExists(state: FiltersState) {
    return (Object.keys(state) as (keyof FiltersState)[]).some((key) => {
      const value = state[key];
      if ((key === 'search' || key === 'createdStart' || key === 'createdEnd') && value) return true;
      return Array.isArray(value) && value.length > 0;
    });
  }

  public reset() {
    this.store$.dispatch(new actions.FiltersResetAction());
  }
}
