<button
  type="button"
  class="btn dialog-btn"
  mat-button=""
  (menuOpened)="onMenuOpened()"
  (menuClosed)="onMenuClosed()"
  [matMenuTriggerFor]="multiSelectMenu"
  [ngSwitch]="ideasVisibilityControl.value"
>
  <ng-container *ngSwitchCase="IdeasVisibility.visibleToCreators">
    <ng-container *ngTemplateOutlet="visibleToCreatorsTmpl"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="IdeasVisibility.visibleToAllUsers">
    <ng-container *ngTemplateOutlet="visibleToAllUsersTmpl"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="IdeasVisibility.visibleToCompanies">
    <ng-container *ngTemplateOutlet="visibleToCompaniesTmpl"> </ng-container>
  </ng-container>

  <craft-icon class="svg-icon text-size-15" name="arrows--arrow-head-down-md"></craft-icon>
</button>

<mat-menu #multiSelectMenu="matMenu">
  <ng-template matMenuContent="">
    <mat-radio-group [formControl]="ideasVisibilityControl" class="d-contents">
      <mat-radio-button
        [value]="IdeasVisibility.visibleToCreators"
        (click)="resetSelected()"
        class="d-flex align-items-center mat-mdc-menu-item radio-btn-blue"
      >
        <ng-container *ngTemplateOutlet="visibleToCreatorsTmpl"></ng-container>
      </mat-radio-button>

      <mat-radio-button
        [disabled]="isCategoryPrivate || disableYesVisibility"
        [value]="IdeasVisibility.visibleToAllUsers"
        (click)="resetSelected()"
        class="d-flex align-items-center mat-mdc-menu-item radio-btn-blue"
      >
        <ng-container *ngTemplateOutlet="visibleToAllUsersTmpl"></ng-container>
      </mat-radio-button>

      <mat-radio-button
        *ngIf="!isCategoryPrivate && !disableYesVisibility"
        [value]="IdeasVisibility.visibleToCompanies"
        (click)="$event.stopPropagation()"
        [matMenuTriggerFor]="companiesSubMenu"
        class="d-flex align-items-center mat-mdc-menu-item radio-btn-blue"
      >
        <div class="d-flex align-items-center gap-10" (click)="$event.stopPropagation()">
          <ng-container *ngTemplateOutlet="visibleToCompaniesTmpl"></ng-container>
        </div>
        <craft-icon class="svg-icon text-size-15 shrink-0" name="arrows--arrow-head-right-md"></craft-icon>
      </mat-radio-button>

      <mat-radio-button
        *ngIf="isCategoryPrivate || disableYesVisibility"
        [disabled]="disableYesVisibility"
        [value]="IdeasVisibility.visibleToCompanies"
        class="d-flex align-items-center mat-mdc-menu-item radio-btn-blue"
      >
        <ng-container *ngTemplateOutlet="visibleToCompaniesTmpl"></ng-container>
      </mat-radio-button>
    </mat-radio-group>
  </ng-template>
</mat-menu>

<mat-menu #companiesSubMenu="matMenu" xPosition="before">
  <ng-template matMenuContent="">
    <div *ngIf="hasSearch" class="menu-search mat-mdc-menu-item d-flex align-items-center shrink-0">
      <craft-icon class="svg-icon text-size-13 mr-10" name="search--search"></craft-icon>
      <input type="text" #searchInput (click)="$event.stopPropagation()" [placeholder]="searchPlaceholder" [formControl]="searchControl" />
    </div>

    <div class="menu-scroll">
      <div
        mat-menu-item=""
        class="item-checkbox"
        *ngFor="let item of filteredItems | async | slice: 0 : 100; trackBy: identify"
        (click)="$event.stopPropagation()"
        craftDisableFocus=""
      >
        <mat-checkbox [checked]="selectedSet.has(item[idFieldName])" (change)="selectItem(item)" class="checkbox-black small">
          {{ item[labelFieldName] }}
        </mat-checkbox>
      </div>
    </div>

    <div class="dropdown-footer">
      <button (click)="clearSelected($event)" type="button" class="btn">Clear</button>
      <button (click)="applySelected()" type="button" class="btn btn-blue">Apply</button>
    </div>
  </ng-template>
</mat-menu>

<ng-template #visibleToCreatorsTmpl>
  <craft-icon class="svg-icon text-size-15" name="general--hidden-md"></craft-icon>
  <span class="mr-8">No</span>
</ng-template>

<ng-template #visibleToAllUsersTmpl>
  <craft-icon class="svg-icon text-size-15" name="general--hide-md"></craft-icon>
  <span class="mr-8">Yes, all portal users</span>
</ng-template>

<ng-template #visibleToCompaniesTmpl>
  <craft-icon class="svg-icon text-size-15" name="essentials--target-audience-md"></craft-icon>
  <span class="mr-8">{{ selectedIds.length | i18nPlural: selectedCountMapping }} </span>
</ng-template>
