import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CompanyUser } from 'src/app/core/models/companies.models';
import { PortalUser } from 'src/app/core/models/portal.models';

@Component({
  selector: 'craft-user-info',
  templateUrl: './craft-user-info.component.html',
  styleUrls: ['./craft-user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CraftUserInfoComponent {
  @Input() public user: PortalUser | CompanyUser;
  constructor() {}
}
