export const SYSTEM_COLORS: readonly string[] = [
  '#F59D00',
  '#36BB60',
  '#E91E63',
  '#9D00EF',
  '#FF00C6',
  '#FF6E40',
  '#1EC933',
  '#26A69A',
  '#00c6c5',
  '#1EA1FF',
  '#536DFE',
  '#65778B',
  '#00D744',
] as const;
