import { AccessLevel } from 'src/app/core/enums/AccessLevels';
import { StaticFilterType } from 'src/app/core/enums/StaticFilterType';
import { StaticFilter } from 'src/app/core/models/filters.models';

const DEFAULT_STATIC_FILTER: StaticFilter = {
  label: 'common.text9',
  id: StaticFilterType.all,
  isDefault: true,
  accessLevel: AccessLevel.Public,
};

export const STATIC_FILTERS: readonly Readonly<StaticFilter>[] = [
  DEFAULT_STATIC_FILTER,
  {
    label: 'common.text10',
    id: StaticFilterType.my,
    accessLevel: AccessLevel.ContributorOrCompanyUser,
  },
  {
    label: 'common.text11',
    id: StaticFilterType.tracked,
    accessLevel: AccessLevel.ContributorOrCompanyUser,
    hideTrack: true,
  },
  {
    label: 'common.text50',
    id: StaticFilterType.archived,
    accessLevel: AccessLevel.Editor,
    icon: 'general--archive-md',
  },
];

export function findStaticFilterById(id: string): Readonly<StaticFilter> | undefined {
  if (!id) return;
  id = id.toLowerCase();
  return STATIC_FILTERS.find((n) => n.id === id);
}

export function getDeafultStaticFilter(): Readonly<StaticFilter> | undefined {
  return DEFAULT_STATIC_FILTER;
}
