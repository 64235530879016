import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { share, switchMap, take, tap } from 'rxjs/operators';
import { StringAnyMap, StringStringMap } from 'src/app/core/models/common.models';
import { TerminologyBackendService } from 'src/app/core/services/backend/terminology-backend.service';
import { ProductsStoreService } from 'src/app/core/store/services/products-store.service';

@Injectable({
  providedIn: 'root',
})
export class TerminologyService {
  private cache = new Map<string, StringStringMap>();
  private loaging$: Observable<StringStringMap> | void;

  constructor(
    private bs: TerminologyBackendService, //
    private productStore: ProductsStoreService,
    private translateService: TranslateService,
  ) {}

  public translate(translationId: string | string[], interpolateParams?: StringAnyMap): Observable<any> {
    return this.productStore.selectedId$.pipe(
      take(1),
      switchMap((productId) => {
        return this.load(productId);
      }),
      switchMap((params) => {
        return this.translateService.get(translationId, { ...interpolateParams, ...params });
      }),
    );
  }

  public load(productId?: string): Observable<StringStringMap> {
    const terms = this.getCacheValue(productId);

    if (terms) return of(terms);
    if (this.loaging$) return this.loaging$;

    const lang = this.translateService.currentLang;
    this.loaging$ = this.bs.getTerms(lang, productId).pipe(
      share(),
      tap((terms) => {
        this.loaging$ = void 0;
        this.cache.set(this.getCaheKey(productId), terms);
      }),
    );

    return this.loaging$;
  }

  private getCaheKey(productId?: string) {
    const lang = this.translateService.currentLang;
    return lang + (productId || '');
  }

  private getCacheValue(productId?: string) {
    return this.cache.get(this.getCaheKey(productId));
  }
}
