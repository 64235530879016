import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Destroyer } from 'src/app/core/abstract/destroyer';
import { DBTag } from 'src/app/core/models/tags.models';
import { TagsStoreService } from 'src/app/core/store/services/tags-store.service';

@Component({
  selector: 'craft-delete-tag-dialog',
  templateUrl: './delete-tag-dialog.component.html',
  styleUrls: ['./delete-tag-dialog.component.scss'],
})
export class DeleteTagDialogComponent extends Destroyer {
  public isRequestSending = false;

  constructor(
    private tagsStore: TagsStoreService, //
    @Inject(MAT_DIALOG_DATA) public tag: DBTag,
    private dialogRef: MatDialogRef<DeleteTagDialogComponent, DBTag>,
  ) {
    super();
  }

  public close() {
    this.dialogRef.close();
  }

  public done() {
    this.isRequestSending = true;
    this.dialogRef.disableClose = true;

    this.tagsStore
      .delete(this.tag)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef.close(this.tag);
      });
  }
}
