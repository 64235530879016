export enum PeriodEnum {
  lastYear = 'lastYear',
  last3Months = 'last3Months',
  lastMonth = 'lastMonth',
  lastWeek = 'lastWeek',
  today = 'today',
  custom = 'custom',
}

export type DateRange = {
  readonly start: Date;
  readonly end: Date;
};

export type PeriodMenuItem = {
  readonly id: PeriodEnum;
  readonly name: string;
};
