import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Destroyer } from 'src/app/core/abstract/destroyer';
import { CustomField } from 'src/app/core/models/custom-fields.models';
import { CustomFieldsStoreService } from 'src/app/core/store/services/custom-fields-store.service';
import { EntityFactory } from 'src/app/core/store/services/entity.factory';
import { CreateCustomDateFieldComponent } from 'src/app/shared/components/custom-fileds/custom-field-dialog/date-field/date-field.component';
import { CustomFieldDialogResult } from 'src/app/shared/components/custom-fileds/custom-field-dialog/types';
import { CustomFieldsFormsService } from 'src/app/shared/services/custom-fields-forms.service';

@Component({
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss'],
})
export class CreateCustomSingleSelectFieldComponent extends Destroyer implements OnInit {
  public form: UntypedFormGroup;

  constructor(
    private entityFactory: EntityFactory,
    private formsService: CustomFieldsFormsService,
    private customFieldsStore: CustomFieldsStoreService,
    private dialogRef: MatDialogRef<CreateCustomDateFieldComponent, CustomFieldDialogResult>,
    @Inject(MAT_DIALOG_DATA) public field: Partial<CustomField> | undefined,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({
      label: new UntypedFormControl(void 0, { validators: [Validators.required] }),
      description: new UntypedFormControl(void 0),
      validation: new UntypedFormControl(void 0),
      orderIndex: new UntypedFormControl(void 0),
      options: this.formsService.createOptionsFormArray(this.field?.options || []),
    });

    if (this.field) {
      this.form.patchValue(this.field);
    }
  }

  public close() {
    this.dialogRef.close({ done: false });
  }

  public done(): void {
    if (!this.form.valid) return;

    this.form.disable();

    this.getOrCreate()
      .pipe(
        map((field) => ({ ...field, ...this.form.value })),
        switchMap((field) => this.customFieldsStore.save(field)),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (field) => {
          this.dialogRef.close({ done: true, field });
        },
        error: () => {
          this.form.enable();
        },
      });
  }

  public selectPreset(field: Partial<CustomField>) {
    const options = field.options || [];
    this.form.patchValue({ ...this.form.value, ...field, options });
    this.form.setControl('options', this.formsService.createOptionsFormArray(options));
  }

  private getOrCreate(): Observable<Partial<CustomField>> {
    const field = this.field;

    if (field && field.id) {
      return of(field);
    }

    return this.entityFactory.createCustomField(field);
  }
}
