import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { concatWith, debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CraftItem, PromoteItemType } from 'src/app/core/models/craft.models';
import { DBIdea } from 'src/app/core/models/idea.models';
import { CraftItemsBackendService } from 'src/app/core/services/backend/craft-items.backend.service';

@Component({
  selector: 'craft-items-app-tree',
  templateUrl: './craft-items-app-tree.component.html',
  styleUrls: ['./craft-items-app-tree.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CraftItemsAppTreeComponent),
      multi: true,
    },
  ],
})
export class CraftItemsAppTreeComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() public idea: DBIdea;
  @Input() public itemType: PromoteItemType = 'theme';

  public isLoading = false;
  public items: readonly CraftItem[];
  public openedItems = new Set<string>();
  public seleectedItemId?: string;
  public searchControl = new UntypedFormControl();

  private destroy$ = new Subject<void>();
  private disabled = false;
  private onTouched: () => void = () => {};
  private onChange: (_: CraftItem | undefined) => void = (_) => {};

  constructor(private cibs: CraftItemsBackendService) {}

  public ngOnInit(): void {
    of('')
      .pipe(
        concatWith(this.searchControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged())),
        switchMap((searchText) => {
          this.isLoading = !searchText;

          return this.cibs.getItemsTree(this.idea.productId, searchText).pipe(
            tap((items) => {
              this.items = items;
              this.isLoading = false;

              if (searchText) {
                items.forEach((item) => {
                  this.openedItems.add(item.id);
                });
              } else {
                this.openedItems.clear();
              }
            }),
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: string): void {
    this.seleectedItemId = value;
  }

  public registerOnChange(fn: (_: CraftItem | undefined) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public identify(index: number, item?: CraftItem): string | number {
    return item?.guid || index;
  }

  public itemSelect(item: CraftItem, itemType: PromoteItemType, event: MouseEvent) {
    this.onTouched();
    event.stopPropagation();
    if (this.disabled) return;

    if (this.itemType === itemType) {
      if (this.seleectedItemId === item.id) {
        this.seleectedItemId = void 0;
        this.onChange(void 0);
      } else {
        this.seleectedItemId = item.id;
        this.onChange(item);
      }
    } else if (this.openedItems.has(item.id)) {
      this.openedItems.delete(item.id);
      item.children.forEach((c) => this.openedItems.delete(c.guid));
    } else {
      this.openedItems.add(item.id);
      if (item.children.length === 0) {
        this.loadChildren(item);
      }
    }
  }

  private loadChildren(parent: CraftItem) {
    this.cibs
      .getItemsList(this.idea.productId, parent.guid)
      .pipe(takeUntil(this.destroy$))
      .subscribe((children) => {
        parent.children = children;
      });
  }
}
