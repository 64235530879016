import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PortalUser } from 'src/app/core/models/portal.models';
import { UsersBackendService } from 'src/app/core/services/backend/users-backend.service';

@Component({
  selector: 'craft-email-autocomplete',
  templateUrl: './craft-email-autocomplete.component.html',
  styleUrls: ['./craft-email-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CraftEmailAutocompleteComponent),
      multi: true,
    },
  ],
})
export class CraftEmailAutocompleteComponent implements OnInit, OnDestroy, ControlValueAccessor {
  public isPreloaderShow = false;
  public filteredUsers$: Observable<any[]>;
  public searchControl = new UntypedFormControl();

  @Input() public placeholder: string;
  @Input() public otherParams:
    | {
        onlyAccountOwners: boolean;
        companyId: string;
      }
    | undefined = undefined;
  @Output() public userSelect = new EventEmitter<PortalUser>();

  protected destroy$ = new Subject<void>();
  protected onTouched: () => void = () => {};
  protected onChange: (val: string) => void = (_) => {};

  constructor(protected usersService: UsersBackendService) {}

  public ngOnInit() {
    this.filteredUsers$ = this.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.isPreloaderShow = true)),
      switchMap((searchText) => this.usersService.searchInAdmins(searchText, this.otherParams)),
      tap(() => (this.isPreloaderShow = false)),
    );

    this.searchControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => this.onChange(val));
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public displayFn(user?: Readonly<PortalUser> | string) {
    if (typeof user === 'string') {
      return user;
    }
    return user ? user.email : '';
  }

  public identify(index: number, user?: PortalUser): string | number {
    return user?.email || index;
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(user?: PortalUser) {
    this.searchControl.setValue(user ? user.email : void 0, { emitEvent: false });
  }

  public selectUser(user: PortalUser) {
    this.userSelect.emit(user);
    this.onChange(user.email);
  }
}
