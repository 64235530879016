import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationAction, NotificationMessage } from 'src/app/shared/dialogs/notifications/notifications.service';

@Component({
  selector: 'craft-notification-item',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [
    trigger('flyInOut', [
      transition(':enter', [style({ transform: 'translateY(-100%)' }), animate(100)]), //
    ]),
  ],
})
export class NotificationItemComponent implements OnInit {
  @Input() public item: NotificationMessage;
  @Output() public close = new EventEmitter<void>();
  @Output() public action = new EventEmitter<NotificationAction>();

  constructor() {}

  public ngOnInit(): void {}
}
