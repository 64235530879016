<form class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title">Labels</h1>
  <div class="sub-title">Add multiple labels using comma</div>

  <div mat-dialog-content="" class="dialog-content">
    <craft-tags-bulk-create [existsTags]="data.existsTags" [disabled]="isSaving" #tagsInput></craft-tags-bulk-create>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <button class="btn btn-cancel dialog-btn" type="button" (click)="close()" mat-button="">Cancel</button>
    <button class="btn btn-blue dialog-btn" type="button" (click)="done(tagsInput)" mat-button="">Add Labels</button>
  </div>
</form>
