<div class="item">
  <input
    type="text"
    placeholder="Enter label"
    maxlength="255"
    craftAutoFocus
    [focusToggle]="isEditMode"
    [readonly]="!isEditMode"
    [formControl]="control"
    (keydown.enter)="onKeyEnter($event)"
    (keydown.esc)="onKeyEsc($event)"
  />
  <mat-error *ngIf="isSubmitted && isEditMode && control.invalid"> Missing label </mat-error>
  <div class="buttons" [ngSwitch]="isEditMode">
    <div class="edit controls" *ngSwitchCase="true">
      <craft-icon class="icon-btn" name="general--close-sm" (click)="cancel()"></craft-icon>
      <craft-icon class="icon-btn" name="general--check-sm" (click)="apply()"></craft-icon>
    </div>
    <div class="manage controls" *ngSwitchDefault>
      <craft-icon class="icon-btn" name="general--editb-md" (click)="enableEditMode()"></craft-icon>
      <craft-icon class="icon-btn" name="general--delete-sm" (click)="remove.next()"></craft-icon>
    </div>
  </div>
</div>
