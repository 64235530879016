<mat-radio-group [formControl]="optionControl">
  <div class="d-inline-flex radio-button-wrap">
    <mat-radio-button class="radio-btn-blue mr-5" [value]="DeleteOption.moveTo">
      {{ deletedCategory.ideasCnt | i18nPlural: { '=1': 'common.text32', other: 'common.text33' } | craftTerm: { count: deletedCategory.ideasCnt } }}
    </mat-radio-button>

    <button class="btn" (menuOpened)="onMenuOpened()" (menuClosed)="onMenuClosed()" [matMenuTriggerFor]="menuCategories">
      <span class="ellipsis" [matTooltip]="selectedItem?.name" matTooltipPosition="above" matTooltipShowDelay="1000">{{ selectedItem?.name }}</span>
      <craft-icon class="svg-icon text-size-11 ml-4" name="arrows--arrow-head-down-sm"></craft-icon>
    </button>
  </div>
  <br />
  <mat-radio-button class="radio-btn-blue mt-5" [value]="DeleteOption.deleteAll">
    {{ 'common.text34' | craftTerm }}
  </mat-radio-button>
</mat-radio-group>

<mat-menu #menuCategories="matMenu">
  <ng-template matMenuContent="">
    <div class="menu-search mat-mdc-menu-item d-flex align-items-center shrink-0">
      <craft-icon class="svg-icon text-size-13 mr-10" name="search--search"></craft-icon>
      <input type="text" #searchInput (click)="$event.stopPropagation()" [placeholder]="searchPlaceholder" [formControl]="searchControl" />
    </div>

    <button
      mat-menu-item=""
      *ngFor="let item of filteredItems | async | slice: 0 : 100; trackBy: identify"
      [ngClass]="{ selected: selectedId === item[idFieldName] }"
      (click)="selectItem(item)"
      craftDisableFocus=""
      type="button"
    >
      {{ item[labelFieldName] }}
    </button>
  </ng-template>
</mat-menu>
