import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CustomField, CustomFieldOption, CustomFieldType } from 'src/app/core/models/custom-fields.models';
import { createGuid } from 'src/app/core/utils/crypto-utils';

export type PresetItem = {
  readonly id: number;
  readonly groupName: string;
  readonly items: readonly Partial<CustomField>[];
};
type JsonPresetItem = Omit<PresetItem, 'id'>;

@Injectable({ providedIn: 'root' })
export class CustomPresetsService {
  private cache: PresetItem[];

  constructor(private http: HttpClient) {}

  public load(filterByType?: CustomFieldType): Observable<PresetItem[]> {
    if (this.cache) return of(this.filterByFieldType(this.cache, filterByType));

    return this.http.get('./json/shared/components/custom-fileds/custom-presets/presets.json').pipe(
      filter((data): data is JsonPresetItem[] => !!data),
      map((data: JsonPresetItem[]) => {
        return data.map((preset, id) => {
          const items = preset.items.map((field) => {
            let options: CustomFieldOption[] | undefined;
            if (Array.isArray(field.options)) {
              const now = Date.now();
              options = field.options.map((option, idx) => {
                return { ...option, guid: createGuid(), orderIndex: idx + now };
              });
            }
            return { ...field, options };
          });
          return { ...preset, id, items };
        });
      }),
      tap((presets) => {
        this.cache = presets;
      }),
      map((presets) => {
        return this.filterByFieldType(presets, filterByType);
      }),
    );
  }

  private filterByFieldType(presets: PresetItem[], type?: CustomFieldType): PresetItem[] {
    if (!type) return presets;

    return presets.reduce((acc, p) => {
      const items = p.items.filter((f) => f.type === type);
      if (items.length === 0) return acc;

      acc.push({ ...p, items });
      return acc;
    }, [] as PresetItem[]);
  }
}
