import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomField } from 'src/app/core/models/custom-fields.models';
import {
  CustomFieldsAction,
  CUSTOM_FIELDS_ADD_SUCCESS,
  CUSTOM_FIELDS_DELETE,
  CUSTOM_FIELDS_MOVE,
  CUSTOM_FIELDS_UPDATE,
  PORTAL_REQ_GET_SUCCESS
} from 'src/app/core/store/actions/custom-fields';
import { indexBy } from 'src/app/core/utils/app-utils';

export type CustomFieldsState = {
  readonly customFields: Map<string, CustomField>;
};

export const initialState: CustomFieldsState = {
  customFields: new Map(),
};

export function reducer(state = initialState, action: CustomFieldsAction): CustomFieldsState {
  switch (action.type) {
    case PORTAL_REQ_GET_SUCCESS: {
      return {
        ...state,
        customFields: indexBy<CustomField>(action.payload.portal.custom || [], 'id'),
      };
    }

    case CUSTOM_FIELDS_UPDATE:
    case CUSTOM_FIELDS_ADD_SUCCESS: {
      const field = action.payload;
      const customFields = new Map(state.customFields);
      customFields.set(field.id, field);

      return {
        ...state,
        customFields,
      };
    }

    case CUSTOM_FIELDS_MOVE: {
      const field = action.payload;

      state.customFields.set(field.id, field);
      const sortedList = Array.from(state.customFields.values()).sort((a, b) => a.orderIndex - b.orderIndex);
      const customFields = indexBy<CustomField>(sortedList, 'id');

      return {
        ...state,
        customFields,
      };
    }

    case CUSTOM_FIELDS_DELETE: {
      const id = action.payload;
      const customFields = new Map<string, CustomField>(state.customFields);
      customFields.delete(id);

      return {
        ...state,
        customFields,
      };
    }

    default:
      return state;
  }
}

const getCustomFieldsMap = (state: CustomFieldsState) => state.customFields;

export const getCustomFieldsStateSelector = createFeatureSelector<CustomFieldsState>('customFieldsState');
export const getCustomFieldsMapSelector = createSelector(getCustomFieldsStateSelector, getCustomFieldsMap);
export const getCustomFieldsListSelector = createSelector(getCustomFieldsMapSelector, (map) => [...map.values()]);
export const getCustomFieldsEnabledListSelector = createSelector(getCustomFieldsListSelector, (list) => list.filter((cf) => cf.isEnabled));
