import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { FORM_ADD_SUCCESS, FORM_SAVE_SUCCESS } from 'src/app/core/store/actions/forms';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';

@Injectable()
export class FormsEffects {
  constructor(
    private actions$: Actions,
    private portalStoreService: PortalStoreService,
  ) {}

  public saveFormSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FORM_ADD_SUCCESS, FORM_SAVE_SUCCESS),
        switchMap(() => this.portalStoreService.load()),
      );
    },
    { dispatch: false },
  );
}
