import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { createAnalyticsProvider } from 'src/app/core/factories/create-analytics-provider';
import { AnalyticsProviderService } from 'src/app/core/services/analytics.service';
import { ConfigService } from 'src/app/core/services/config.service';

@NgModule({
  imports: [
    CommonModule, //
  ],
  providers: [
    {
      provide: AnalyticsProviderService,
      useFactory: createAnalyticsProvider,
      deps: [ConfigService],
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
