import { Action } from '@ngrx/store';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { CompanyAddInviteLinkRequest, CompanyDelInviteLinkRequest, CompanyInviteLink } from 'src/app/core/models/companies.models';

export const COMPANY_ADD_INVITE_LINK = '[Company-users] add invite link';
export const COMPANY_ADD_INVITE_LINK_ERROR = '[Company-users] add invite link error';
export const COMPANY_ADD_INVITE_LINK_SUCCESS = '[Company-users] add invite link success';

export const COMPANY_DEL_INVITE_LINK = '[Company-users] delete invite link';
export const COMPANY_DEL_INVITE_LINK_ERROR = '[Company-users] delete invite link error';
export const COMPANY_DEL_INVITE_LINK_SUCCESS = '[Company-users] delete invite link success';

// ADD
export class CompanyReqAddInviteLinkAction implements Action {
  readonly type = COMPANY_ADD_INVITE_LINK;
  constructor(public payload: CompanyAddInviteLinkRequest) {}
}
export class CompanyReqAddInviteLinkErrorAction implements Action {
  readonly type = COMPANY_ADD_INVITE_LINK_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CompanyReqAddInviteLinkSuccessAction implements Action {
  readonly type = COMPANY_ADD_INVITE_LINK_SUCCESS;
  constructor(public payload: CompanyInviteLink) {}
}

// DELETE
export class CompanyReqDeleteInviteLinkAction implements Action {
  readonly type = COMPANY_DEL_INVITE_LINK;
  constructor(public payload: CompanyDelInviteLinkRequest) {}
}
export class CompanyReqDeleteInviteLinkErrorAction implements Action {
  readonly type = COMPANY_DEL_INVITE_LINK_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CompanyReqDeleteInviteLinkSuccessAction implements Action {
  readonly type = COMPANY_DEL_INVITE_LINK_SUCCESS;
  constructor(public payload: CompanyDelInviteLinkRequest) {}
}

export type CompanyLinksAction =
  | CompanyReqAddInviteLinkAction
  | CompanyReqAddInviteLinkErrorAction
  | CompanyReqAddInviteLinkSuccessAction
  //
  | CompanyReqDeleteInviteLinkAction
  | CompanyReqDeleteInviteLinkErrorAction
  | CompanyReqDeleteInviteLinkSuccessAction;
