import * as FullStory from '@fullstory/browser';
import { AuthUser } from 'src/app/core/models/auth.models';
import { AnalyticsProviderService } from 'src/app/core/services/analytics.service';

export class AnalyticsSegmentProviderService extends AnalyticsProviderService {
  private readonly segmentOptions: any;
  constructor() {
    super();
    this.segmentOptions = { integrations: { All: true, Intercom: false } };
  }

  public track(eventName: string, data?: any): void {
    const segment = (window as any).analytics;
    segment?.track?.(eventName, data, this.segmentOptions);
  }

  public identify(user: AuthUser) {
    FullStory?.identify?.(user.id, user);

    const segment = (window as any).analytics;
    segment?.identify?.(user.id, user, this.segmentOptions);
  }
}
