import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { CompanyAddInviteLinkRequest, CompanyDelInviteLinkRequest, CompanyInviteLink } from 'src/app/core/models/companies.models';
import { CompaniesBackendService } from 'src/app/core/services/backend/companies-backend.service';
import {
    CompanyReqAddInviteLinkAction,
    CompanyReqAddInviteLinkErrorAction,
    CompanyReqAddInviteLinkSuccessAction,
    CompanyReqDeleteInviteLinkAction,
    CompanyReqDeleteInviteLinkErrorAction,
    CompanyReqDeleteInviteLinkSuccessAction
} from 'src/app/core/store/actions/company-links';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class CompaniesLinksStoreService {
  constructor(
    private store$: Store<CoreState>, //
    private cbs: CompaniesBackendService,
  ) {}

  public addInvite(req: CompanyAddInviteLinkRequest): Observable<CompanyInviteLink> {
    this.store$.dispatch(new CompanyReqAddInviteLinkAction(req));

    return this.cbs.addInvite(req).pipe(
      map((res) => {
        this.store$.dispatch(new CompanyReqAddInviteLinkSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CompanyReqAddInviteLinkErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public deleteInvite(req: CompanyDelInviteLinkRequest): Observable<void> {
    this.store$.dispatch(new CompanyReqDeleteInviteLinkAction(req));

    return this.cbs.deleteInviteLink(req).pipe(
      map(() => {
        this.store$.dispatch(new CompanyReqDeleteInviteLinkSuccessAction(req));
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CompanyReqDeleteInviteLinkErrorAction(err));
        return throwError(() => err);
      }),
    );
  }
}
