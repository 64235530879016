import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { CraftItem, PromoteItemType } from 'src/app/core/models/craft.models';
import { DBIdea } from 'src/app/core/models/idea.models';
import { DialogResult } from 'src/app/shared/dialogs/models';

export type CraftItemDialogInput = {
  promoteItemType: PromoteItemType;
  idea: DBIdea;
};

export type CraftItemDialogResult = DialogResult & {
  item?: CraftItem;
  editItem?: boolean;
  promoteItemType?: PromoteItemType;
};

@Component({
  templateUrl: './select-craft-item-dialog.component.html',
  styleUrls: ['./select-craft-item-dialog.component.scss'],
})
export class SelectCraftItemDialogComponent implements OnDestroy {
  public readonly title: string;
  public readonly promoteItemType: PromoteItemType;
  public readonly idea: DBIdea;
  public itemControl = new UntypedFormControl(void 0, Validators.required);

  private destroy$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: CraftItemDialogInput, //
    private readonly dialogRef: MatDialogRef<SelectCraftItemDialogComponent, CraftItemDialogResult>,
  ) {
    this.idea = data.idea;
    this.promoteItemType = data.promoteItemType;
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public close(): void {
    this.dialogRef.close({
      done: false,
    });
  }

  public done(): void {
    this.dialogRef.close({
      done: true,
      item: this.itemControl.value,
    });
  }

  public editPromotedItem(): void {
    this.dialogRef.close({
      done: true,
      editItem: true,
      item: this.itemControl.value,
      promoteItemType: this.promoteItemType,
    });
  }
}
