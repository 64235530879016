import { Action } from '@ngrx/store';
import { ErrorResponse, StringBooleanMap } from 'src/app/core/models/common.models';
import { GetPortalResponse, Portal } from 'src/app/core/models/portal.models';

export const PORTAL_GET = '[Portal] get';
export const PORTAL_GET_ERROR = '[Portal] get error';
export const PORTAL_GET_SUCCESS = '[Portal] get success';
export const PORTAL_GET_AFTER_LOGIN = '[Portal] get after signin';

export const PORTAL_SAVE = '[Portal] save';
export const PORTAL_SAVE_ERROR = '[Portal] save error';
export const PORTAL_SAVE_SUCCESS = '[Portal] save success';

export const PORTAL_SET_DATA_CACHE = '[Portal] set data cache';
export const PORTAL_CLEAR_DATA_CACHE = '[Portal] clear data cache';

// COMMON
export class PortalSetCacheAction implements Action {
  readonly type = PORTAL_SET_DATA_CACHE;
  constructor(public payload: StringBooleanMap) {}
}
export class PortalClearCacheAction implements Action {
  readonly type = PORTAL_CLEAR_DATA_CACHE;
  constructor(public payload: void) {}
}

// GET
export class PortalReqGetAction implements Action {
  readonly type = PORTAL_GET;
  constructor(public payload?: void) {}
}
export class PortalReqGetErrorAction implements Action {
  readonly type = PORTAL_GET_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class PortalReqGetSuccessAction implements Action {
  readonly type = PORTAL_GET_SUCCESS;
  constructor(public payload: GetPortalResponse) {}
}

// SAVE
export class PortalReqSaveAction implements Action {
  readonly type = PORTAL_SAVE;
  constructor(public payload: Portal) {}
}
export class PortalReqSaveErrorAction implements Action {
  readonly type = PORTAL_SAVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class PortalSaveReqSuccessAction implements Action {
  readonly type = PORTAL_SAVE_SUCCESS;
  constructor(public payload: Portal) {}
}

export type PortalAction =
  | PortalSetCacheAction
  | PortalClearCacheAction
  //
  | PortalReqGetAction
  | PortalReqGetErrorAction
  | PortalReqGetSuccessAction
  //
  | PortalReqSaveAction
  | PortalReqSaveErrorAction
  | PortalSaveReqSuccessAction;
