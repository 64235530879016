import { Action } from '@ngrx/store';
import { CategoriesResponse, Category, CategoryDelRequest, DBCategory } from 'src/app/core/models/categories.models';
import { ErrorResponse } from 'src/app/core/models/common.models';

export const CATEGORIES_SELECT_BY_ID = '[Categories] Set selected by id';

export const CATEGORIES_UPDATE_ONE = '[Categories] Update one';

export const CATEGORIES_LIST = '[Categories] list';
export const CATEGORIES_LIST_ERROR = '[Categories] list error';
export const CATEGORIES_LIST_SUCCESS = '[Categories] list success';

export const CATEGORIES_ADD = '[Categories] add';
export const CATEGORIES_ADD_ERROR = '[Categories] add error';
export const CATEGORIES_ADD_SUCCESS = '[Categories] add success';

export const CATEGORIES_DEL = '[Categories] delete';
export const CATEGORIES_DEL_ERROR = '[Categories] delete error';
export const CATEGORIES_DEL_SUCCESS = '[Categories] delete success';

export const CATEGORIES_SAVE = '[Categories] save';
export const CATEGORIES_SAVE_ERROR = '[Categories] save error';
export const CATEGORIES_SAVE_SUCCESS = '[Categories] save success';

// SELECT BY ID
export class CategoriesSelectByIdAction implements Action {
  readonly type = CATEGORIES_SELECT_BY_ID;
  constructor(public payload?: string) {}
}

// UPDATE
export class CategoriesUpdateOneAction implements Action {
  readonly type = CATEGORIES_UPDATE_ONE;
  constructor(public payload: Partial<Category>) {}
}

// LIST
export class CategoriesReqListAction implements Action {
  readonly type = CATEGORIES_LIST;
  constructor(public payload: string) {}
}
export class CategoriesReqListErrorAction implements Action {
  readonly type = CATEGORIES_LIST_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CategoriesReqListSuccessAction implements Action {
  readonly type = CATEGORIES_LIST_SUCCESS;
  constructor(public payload: CategoriesResponse) {}
}

// ADD
export class CategoriesReqAddAction implements Action {
  readonly type = CATEGORIES_ADD;
  constructor(public payload: Category) {}
}
export class CategoriesReqAddErrorAction implements Action {
  readonly type = CATEGORIES_ADD_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CategoriesReqAddSuccessAction implements Action {
  readonly type = CATEGORIES_ADD_SUCCESS;
  constructor(public payload: DBCategory) {}
}

// SAVE
export class CategoriesReqSaveAction implements Action {
  readonly type = CATEGORIES_SAVE;
  constructor(public payload: DBCategory) {}
}
export class CategoriesReqSaveErrorAction implements Action {
  readonly type = CATEGORIES_SAVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CategoriesReqSaveSuccessAction implements Action {
  readonly type = CATEGORIES_SAVE_SUCCESS;
  constructor(public payload: DBCategory) {}
}

// DELETE
export class CategoriesReqDelAction implements Action {
  readonly type = CATEGORIES_DEL;
  constructor(public payload: CategoryDelRequest) {}
}
export class CategoriesReqDelErrorAction implements Action {
  readonly type = CATEGORIES_DEL_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class CategoriesReqDelSuccessAction implements Action {
  readonly type = CATEGORIES_DEL_SUCCESS;
  constructor(public payload: string) {}
}

export type CategoriesAction =
  | CategoriesSelectByIdAction
  //
  | CategoriesUpdateOneAction
  //
  | CategoriesReqAddAction
  | CategoriesReqAddErrorAction
  | CategoriesReqAddSuccessAction
  //
  | CategoriesReqDelAction
  | CategoriesReqDelErrorAction
  | CategoriesReqDelSuccessAction
  //
  | CategoriesReqListAction
  | CategoriesReqListErrorAction
  | CategoriesReqListSuccessAction
  //
  | CategoriesReqSaveAction
  | CategoriesReqSaveErrorAction
  | CategoriesReqSaveSuccessAction;
