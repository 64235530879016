import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { CompanyDelInviteLinkRequest, CompanyInviteLink } from 'src/app/core/models/companies.models';
import { CompaniesLinksStoreService } from 'src/app/core/store/services/companies-store-links.service';

@Component({
  selector: 'craft-delete-company-invite-link-dialog',
  templateUrl: './delete-company-invite-link-dialog.component.html',
  styleUrls: ['./delete-company-invite-link-dialog.component.scss'],
})
export class DeleteCompanyInviteLinkDialogComponent implements OnInit, OnDestroy {
  public isRequestSending = false;
  public deleteUsersControl = new UntypedFormControl(false);

  private readonly destroy$ = new Subject<void>();

  constructor(
    private linksStore: CompaniesLinksStoreService,
    @Inject(MAT_DIALOG_DATA) public link: CompanyInviteLink,
    private dialogRef: MatDialogRef<DeleteCompanyInviteLinkDialogComponent, void>,
  ) {}

  public ngOnInit() {}

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public close() {
    this.dialogRef.close();
  }

  public done() {
    this.isRequestSending = true;

    const req: CompanyDelInviteLinkRequest = {
      linkId: this.link.id!,
      companyId: this.link.companyId,
      deleteUsers: this.deleteUsersControl.value,
    };

    this.linksStore.deleteInvite(req).subscribe(
      () => {
        this.dialogRef.close();
      },
      () => {
        this.isRequestSending = false;
      },
    );
  }
}
