import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, takeUntil } from 'rxjs/operators';
import { Destroyer } from 'src/app/core/abstract/destroyer';
import { CompanyUser } from 'src/app/core/models/companies.models';
import { PortalUser } from 'src/app/core/models/portal.models';
import { DeleteCompanyUserDialogComponent } from 'src/app/shared/components/craft-manage-user-menu/delete-company-user-dialog/delete-company-user-dialog.component';
import { DialogResult } from 'src/app/shared/dialogs/models';

@Component({
  selector: 'craft-manage-user-menu',
  templateUrl: './craft-manage-user-menu.component.html',
  styleUrls: ['./craft-manage-user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CraftManageUserMenuComponent extends Destroyer {
  @Input() public user: PortalUser | CompanyUser;
  @Output() public deleteUser = new EventEmitter<void>();

  constructor(private dialog: MatDialog) {
    super();
  }

  public confirmDeleteUser() {
    this.dialog
      .open(DeleteCompanyUserDialogComponent, { data: this.user })
      .afterClosed()
      .pipe(
        filter((res): res is DialogResult => !!res?.done),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.deleteUser.next();
      });
  }
}
