<div class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title">Portal Settings</h1>
  <div mat-dialog-content class="dialog-content">
    <div class="dialog-desc">
      The domain name has been changed successfully.<br>
      You will be signed out and redirected to a new domain.<br>
      Please do not forget to notify your users about this change.
    </div>
  </div>
  <div mat-dialog-actions class="dialog-actions d-flex justify-end">
    <button mat-button=""
            class="btn btn-blue dialog-btn"
            cdkFocusInitial=""
            (click)="close()">OK</button>
  </div>
</div>
