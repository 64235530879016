import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryCompany } from 'src/app/core/models/categories.models';
import { StringTMap } from 'src/app/core/models/common.models';
import {
  CompaniesListRequest,
  CompaniesListResponse,
  Company,
  CompanyAddInviteLinkRequest,
  CompanyDelInviteLinkRequest,
  CompanyDelRequest,
  CompanyInviteLink,
  DBCompany
} from 'src/app/core/models/companies.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';

@Injectable({
  providedIn: 'root',
})
export class CompaniesBackendService {
  constructor(private bs: BackendService) {}

  public getList(options: CompaniesListRequest): Observable<CompaniesListResponse> {
    return this.bs.get('company', options).pipe(
      map((result) => {
        const response: CompaniesListResponse = {
          ids: [],
          companies: {},
        };

        result.list.forEach((c: DBCompany) => {
          response.ids.push(c.id);
          response.companies[c.id] = this.mapCompany(c);
        });

        return response;
      }),
    );
  }

  public getDetails(id: string): Observable<DBCompany> {
    return this.bs.get(`company/${id}`).pipe(
      map((company) => {
        return this.mapCompany(company);
      }),
    );
  }

  public getCategoryCompanies(productId: string, categoryId?: string): Observable<CategoryCompany[]> {
    return this.bs.get('portal/category_companies/', { productId, categoryId }).pipe(
      map((result) => result.list), //
    );
  }

  public update(company: DBCompany): Observable<DBCompany> {
    return this.bs.put(`company/${company.id}`, company).pipe(map(() => company));
  }

  public add(company: Company): Observable<DBCompany> {
    return this.bs.post(`company`, company).pipe(
      map((res) => {
        return this.mapCompany({ ...company, ...res });
      }),
    );
  }

  public delete(req: CompanyDelRequest): Observable<void> {
    return this.bs.delete(`company/${req.companyId}`, req.isArchival ? { isArchival: true } : {}).pipe(map(() => {}));
  }

  public addInvite(req: CompanyAddInviteLinkRequest): Observable<CompanyInviteLink> {
    return this.bs.post(`company/link`, req).pipe(map((link) => link));
  }

  public deleteInviteLink(req: CompanyDelInviteLinkRequest): Observable<void> {
    return this.bs.delete(`company/link/${req.linkId}`, { deleteUsers: req.deleteUsers }).pipe(map(() => {}));
  }

  private mapCompany(company: any): DBCompany {
    company.usersCount = Number.parseInt(company.usersCount, 10) || 0;

    if (company.createdDt) {
      company.createdDt = new Date(company.createdDt);
    }

    const links: CompanyInviteLink[] = Array.isArray(company.invitationLinks) ? company.invitationLinks : [];
    company.invitationLinksCount = links.length;
    company.invitationLinks = links.reduce((acc, link: any) => {
      acc[link.id] = {
        ...link,
        createdDt: new Date(link.createdDt),
        count: Number.parseInt(link.count, 10) || 0,
      };
      return acc;
    }, {} as StringTMap<CompanyInviteLink>);

    return company as DBCompany;
  }
}
