import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogResult } from 'src/app/shared/dialogs/models';

@Component({
  templateUrl: './delete-attachment-dialog.component.html',
  styleUrls: ['./delete-attachment-dialog.component.scss'],
})
export class DeleteAttachmentDialogComponent {
  constructor(private dialogRef: MatDialogRef<DeleteAttachmentDialogComponent, DialogResult>) {}

  public close(): void {
    this.dialogRef.close({ done: false });
  }

  public done(): void {
    this.dialogRef.close({ done: true });
  }
}
