import { DBTag, Tag } from 'src/app/core/models/tags.models';

export function filterTagsByTitle<T extends Tag>(allTags: readonly T[], excludeSet: Set<string>, tagName: string): readonly T[] {
  if (tagName) {
    const name = tagName.toLowerCase();
    return allTags.filter((t) => {
      const title = t.title.toLocaleLowerCase();
      return !excludeSet.has(title) && title.includes(name);
    });
  }

  return allTags.filter((t) => {
    const title = t.title.toLocaleLowerCase();
    return !excludeSet.has(title);
  });
}
