import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { PortalStoreService } from '../store/services/portal-store.service';

@Injectable({
  providedIn: 'root',
})
export class PortalLoadGuard  {
  constructor(
    private router: Router, //
    private portalStore: PortalStoreService,
  ) {}

  public canLoad(_route: Route, _segments: UrlSegment[]): Observable<boolean> | boolean {
    return this.portalStore.portal$.pipe(
      take(1),
      switchMap((portal) => {
        if (portal) return of(true);
        return this.portalStore.load().pipe(map(() => true));
      }),
    );
  }
}
