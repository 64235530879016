import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { DBInnerStatus, InnerStatus, InnerStatusDelRequest, InnerStatusesListResponse } from 'src/app/core/models/inner-statuses.models';
import { InnerStatusesBackendService } from 'src/app/core/services/backend/inner-statuses-backend.service';
import {
    InnerStatusesReqAddAction,
    InnerStatusesReqAddErrorAction,
    InnerStatusesReqAddSuccessAction,
    InnerStatusesReqDelAction,
    InnerStatusesReqDelErrorAction,
    InnerStatusesReqDelSuccessAction,
    InnerStatusesReqListAction,
    InnerStatusesReqListErrorAction,
    InnerStatusesReqListSuccessAction,
    InnerStatusesReqSaveAction,
    InnerStatusesReqSaveErrorAction,
    InnerStatusesReqSaveSuccessAction
} from 'src/app/core/store/actions/inner-statuses';
import { getInnerStatusesListSelector, getInnerStatusesSelector } from 'src/app/core/store/reducers/inner-statuses';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class InnerStatusesStoreService {
  public readonly map$ = this.store$.pipe(select(getInnerStatusesSelector));
  public readonly list$ = this.store$.pipe(select(getInnerStatusesListSelector));

  constructor(
    private store$: Store<CoreState>, //
    private bs: InnerStatusesBackendService,
  ) {}

  public loadList(): Observable<InnerStatusesListResponse> {
    this.store$.dispatch(new InnerStatusesReqListAction());

    return this.bs.getList().pipe(
      map((res) => {
        this.store$.dispatch(new InnerStatusesReqListSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new InnerStatusesReqListErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public add(status: InnerStatus): Observable<DBInnerStatus> {
    this.store$.dispatch(new InnerStatusesReqAddAction(status));

    return this.bs.add(status).pipe(
      map((res) => {
        this.store$.dispatch(new InnerStatusesReqAddSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new InnerStatusesReqAddErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public update(status: DBInnerStatus): Observable<DBInnerStatus> {
    this.store$.dispatch(new InnerStatusesReqSaveAction(status));

    return this.bs.update(status).pipe(
      map((res) => {
        this.store$.dispatch(new InnerStatusesReqSaveSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new InnerStatusesReqSaveErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public delete(deletedStatusId: string, newIdeasStatusId: string): Observable<string> {
    const req: InnerStatusDelRequest = { deletedStatusId, newIdeasStatusId };
    this.store$.dispatch(new InnerStatusesReqDelAction(req));

    return this.bs.delete(req).pipe(
      map((res) => {
        this.store$.dispatch(new InnerStatusesReqDelSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new InnerStatusesReqDelErrorAction(err));
        return throwError(() => err);
      }),
    );
  }
}
