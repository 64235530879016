import { Component, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogResult } from 'src/app/shared/dialogs/models';

@Component({
  templateUrl: './alert-error-dialog.component.html',
  styleUrls: ['./alert-error-dialog.component.scss'],
})
export class AlertErrorDialogComponent {
  constructor(
    private ngZone: NgZone,
    private dialogRef: MatDialogRef<AlertErrorDialogComponent, DialogResult>, //
  ) {}

  public close(): void {
    this.ngZone.run(() => {
      this.dialogRef.close({ done: true });
    });
  }
}
