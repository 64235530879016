import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Attachment } from 'src/app/core/models/attachments.models';
import { AttachEvent, AttachmentErrorEvent, AttachmentSuccessEvent, AttachService } from 'src/app/core/services/backend/attaches-backend.service';
import { UploadProgressEvent, UploadService } from 'src/app/core/services/upload.service';
import { DialogManagerService } from 'src/app/shared/dialogs/dialog-manager.service';

@Component({
  selector: 'craft-idea-attachments-progress',
  templateUrl: './idea-attachments-progress.component.html',
  styleUrls: ['./idea-attachments-progress.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IdeaAttachmentsProgressComponent),
      multi: true,
    },
  ],
})
export class IdeaAttachmentsProgressComponent implements OnInit, ControlValueAccessor {
  public disabled = false;
  public attachments: Attachment[] = [];
  public progress = new Map<string, number>();
  public errors = new Map<string, AttachmentErrorEvent>();

  private destroy$ = new Subject<void>();
  private onChange = (_: Attachment[]) => {};
  private onTouched = () => {};

  constructor(private uploadService: UploadService, private attachService: AttachService, private dialogManagerService: DialogManagerService) {}

  public ngOnInit() {
    this.uploadService.progressUpload$.pipe(takeUntil(this.destroy$)).subscribe(this.onUploadProgress);

    this.attachService.success$.pipe(takeUntil(this.destroy$)).subscribe(this.onAttachFileSuccess);

    this.attachService.error$.pipe(takeUntil(this.destroy$)).subscribe(this.onAttachFileError);

    this.attachService.cancel$.pipe(takeUntil(this.destroy$)).subscribe(this.onAttachCancel);
  }

  public remove(attach: Attachment) {
    this.onTouched();

    if (attach.id || (attach.guid && this.errors.has(attach.guid))) {
      this.removeAttach(attach);
    } else if (attach.guid) {
      this.cancelUpload(attach);
    }
  }

  public writeValue(attachments: Attachment[]): void {
    attachments = attachments || [];
    this.attachments = attachments;

    attachments.forEach((a) => {
      if (a.guid && !this.progress.has(a.guid)) {
        this.progress.set(a.guid, 0);
      }
    });
  }

  public registerOnChange(fn: (_: Attachment[]) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public identify(index: number, attach?: Attachment): string | number {
    return attach?.id || attach?.guid || index;
  }

  private cancelUpload(attach: Attachment) {
    this.dialogManagerService
      .showCancelUpload()
      .pipe(
        filter((res) => !!res?.done),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        if (attach.id) {
          this.removeAttachUnsafe(attach);
        } else if (attach.guid) {
          this.attachService.cancel(attach.guid);
        }
      });
  }

  private removeAttach(attach: Attachment) {
    this.dialogManagerService
      .showAttachmentDelete()
      .pipe(
        filter((res) => !!res?.done),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.removeAttachUnsafe(attach);
      });
  }

  private removeAttachUnsafe(attach: Partial<Attachment>) {
    this.attachments = attach.id ? this.attachments.filter((a) => a.id !== attach.id) : this.attachments.filter((a) => a.guid !== attach.guid);

    if (attach.guid) {
      this.errors.delete(attach.guid);
      this.progress.delete(attach.guid);
    }

    this.onChange(this.attachments);
  }

  private onUploadProgress = (e: UploadProgressEvent) => {
    for (const guid of e.guids) {
      this.progress.set(guid, e.progress);
      this.errors.delete(guid);
    }
  };

  private onAttachFileSuccess = (e: AttachmentSuccessEvent) => {
    const attachment: Attachment = { url: e.url, id: e.fileId, name: e.fileName, guid: e.guid };
    const attach = this.attachments.find((a) => a.guid === e.guid);
    if (attach) {
      Object.assign(attach, attachment);
    }
  };

  private onAttachFileError = (e: AttachmentErrorEvent) => {
    this.errors.set(e.guid, e);
  };

  private onAttachCancel = (e: AttachEvent) => {
    this.removeAttachUnsafe({ guid: e.guid });
  };
}
