import { Injectable } from '@angular/core';
import { omit, orderBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CraftForm, CraftServerForm, CraftSystemFormField, DBCraftForm, FormsListResponse } from 'src/app/core/models/forms.models';
import { IdeaSystemField } from 'src/app/core/models/idea.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';
import { DEFAULT_SYSTEM_FIELDS, SystemFieldNames } from 'src/app/core/store/constants/system-fields';

@Injectable({
  providedIn: 'root',
})
export class FormsBackendService {
  constructor(private bs: BackendService) {}

  public getList(): Observable<FormsListResponse> {
    return this.bs.get('portal/form').pipe(
      map((result) => {
        const response: FormsListResponse = {
          ids: [],
          forms: {},
        };

        result.list.forEach((c: CraftServerForm) => {
          response.ids.push(c.id);
          response.forms[c.id] = this.transformFromServerForm(c);
        });

        return response;
      }),
    );
  }

  public getDetails(id: string): Observable<DBCraftForm> {
    return this.bs.get(`portal/form/${id}`).pipe(
      map((form) => {
        return this.transformFromServerForm(form);
      }),
    );
  }

  public update(form: DBCraftForm): Observable<DBCraftForm> {
    return this.bs.post(`portal/form/${form.id}`, this.transformToServerForm(form)).pipe(map(() => form));
  }

  public add(form: CraftForm): Observable<DBCraftForm> {
    return this.bs.post(`portal/form`, this.transformToServerForm(form)).pipe(
      map((res) => {
        return this.transformFromServerForm({ ...form, ...res });
      }),
    );
  }

  public delete(formId: string): Observable<void> {
    return this.bs.delete(`portal/form/${formId}`).pipe(map(() => {}));
  }

  private transformFromServerForm(form: CraftServerForm): DBCraftForm {
    const systemFields =
      form.systemFields?.length > 0
        ? form.systemFields //
        : DEFAULT_SYSTEM_FIELDS.map((field) => ({ id: field, isRequired: false, orderIndex: Number.MIN_SAFE_INTEGER }));

    const fields = orderBy(
      (form.fields || []).concat(systemFields).map((f) => ({ ...f, orderIndex: Number(f.orderIndex) })),
      'orderIndex',
    );

    return {
      ...omit(form, 'systemFields'),
      fields,
      createdDt: form.createdDt ? new Date(form.createdDt) : new Date(),
    };
  }

  private transformToServerForm(form: CraftForm): CraftServerForm {
    const fields = (form.fields || []).filter((f) => !SystemFieldNames[f.id as IdeaSystemField]);
    const systemFields = (form.fields || []).filter((f) => !!SystemFieldNames[f.id as IdeaSystemField]) as CraftSystemFormField[];

    return {
      ...form,
      id: form.id as string,
      fields,
      systemFields,
    };
  }
}
