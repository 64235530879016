<ng-container *ngIf="customFieldsService.map$ | async as fieldsMap">
  <ng-container *ngFor="let control of controls">
    <ng-container
      *ngTemplateOutlet="
        fieldsMap.has(control.value.id) ? cutomControl : systemControl;
        context: { control: control, errorMessage: getErrorMessage(control), fieldsMap }
      "
    ></ng-container>
  </ng-container>
</ng-container>

<ng-template #cutomControl let-control="control" let-errorMessage="errorMessage" let-fieldsMap="fieldsMap">
  <craft-validation-message [isShown]="!!errorMessage">
    {{ errorMessage }}
  </craft-validation-message>
  <craft-idea-custom-control
    [field]="fieldsMap.get(control.value.id)"
    [formControl]="control"
    [isRequired]="control.value.isRequired"
    class="control"
  ></craft-idea-custom-control>
</ng-template>

<ng-template #systemControl let-control="control" let-errorMessage="errorMessage">
  <craft-validation-message [isShown]="!!errorMessage">
    {{ errorMessage }}
  </craft-validation-message>
  <p class="c-label">
    {{ getSystemFieldName(control.value.id) }}
    <span class="red" *ngIf="control.value.isRequired">*</span>
  </p>
  <craft-text-editor class="i-desc" [formControl]="control" [placeholder]="'common.text51' | craftTerm"> </craft-text-editor>
</ng-template>
