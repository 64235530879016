import { Injectable } from '@angular/core';
import { BodyComponentRef, DomService } from 'src/app/core/services/dom.service';
import { TextToolbarComponent } from 'src/app/editors/components/text-toolbar/text-toolbar.component';

@Injectable()
export class TextToolbarService {
  constructor(private domService: DomService) {}

  public register(): BodyComponentRef<TextToolbarComponent> {
    return this.domService.appendComponentToBody(TextToolbarComponent);
  }

  public show(toolbar: TextToolbarComponent, selection: Selection): void {
    toolbar.state = true;
    toolbar.updatePosition(selection);
  }

  public hide(toolbar: TextToolbarComponent): void {
    toolbar.state = false;
  }
}
