import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { keyBy } from 'lodash';
import { Observable, forkJoin, map, take } from 'rxjs';
import { StringTMap } from 'src/app/core/models/common.models';
import {
  CUSTOM_FIELDS_VALIDATIONS,
  CustomField,
  CustomFieldOption,
  CustomFieldValidation,
  CustomValidationType,
} from 'src/app/core/models/custom-fields.models';
import { CraftForm, CraftSystemFormField } from 'src/app/core/models/forms.models';
import { Idea, IdeaCustomFieldValue, IdeaCustomValueType, IdeaSystemField } from 'src/app/core/models/idea.models';
import { SystemFieldNames } from 'src/app/core/store/constants/system-fields';
import { CustomFieldsStoreService } from 'src/app/core/store/services/custom-fields-store.service';
import { FormsStoreService } from 'src/app/core/store/services/forms-store.service';
import { customFieldOptionValidator } from 'src/app/shared/validators/custom-field-option.validator';
import { geFieldValidator } from 'src/app/shared/validators/custom-field.validator';
import { getMaxlengthValidator } from 'src/app/shared/validators/maxlength.validator';
import { getNumberValidator } from 'src/app/shared/validators/number.validator';

@Injectable({ providedIn: 'root' })
export class CustomFieldsFormsService {
  constructor(
    private formsStore: FormsStoreService,
    private customFieldsStore: CustomFieldsStoreService,
  ) {}

  public findIdeaForm(idea: Idea) {
    return forkJoin([
      this.formsStore.mapByCategory$.pipe(take(1)), //
      this.formsStore.defaultForm$.pipe(take(1)),
    ]).pipe(
      map(([forms, defaultForm]) => {
        return forms[idea.categoryId!] || defaultForm;
      }),
    );
  }

  public getUpdatedValidations(type: CustomValidationType, isEnabled: boolean, validations?: readonly CustomFieldValidation[]): CustomFieldValidation[] {
    let old = Array.isArray(validations) ? validations.filter((v) => v.type !== type) : [];
    if (isEnabled) {
      return [...old, { ...CUSTOM_FIELDS_VALIDATIONS[type] }];
    } else {
      return old;
    }
  }

  public buildForm(idea: Idea): Observable<UntypedFormGroup> {
    return forkJoin([this.customFieldsStore.map$.pipe(take(1)), this.findIdeaForm(idea)]).pipe(
      map(([customFieldsMap, form]) => {
        return new UntypedFormGroup({
          name: new UntypedFormControl(idea.name, { validators: [Validators.required] }),
          categoryId: new UntypedFormControl(idea.categoryId, { validators: [Validators.required] }),
          creator: new UntypedFormControl(idea.creator, { validators: [Validators.required] }),
          importanceId: new UntypedFormControl(idea.importanceId),
          isPublic: new UntypedFormControl(idea.isPublic),
          attaches: new UntypedFormControl(idea.attaches),
          custom: this.createFormArray(idea, form, customFieldsMap),
        });
      }),
    );
  }

  public buildIdeaCustomValuesList(idea: Idea): Observable<{ id: string; value: IdeaCustomValueType }[]> {
    return this.findIdeaForm(idea).pipe(
      map((form) => {
        const ideaCustomValues = this.mapIdeaCustomValues(idea);
        return (form?.fields || []).map((field) => {
          return { id: field.id, value: ideaCustomValues[field.id] || idea[field.id as IdeaSystemField] };
        });
      }),
    );
  }

  public formToIdea(form: UntypedFormGroup): Partial<Idea> {
    const custom: IdeaCustomFieldValue[] = (form.value.custom || [])
      .filter((cv: IdeaCustomFieldValue) => {
        return Array.isArray(cv.value) ? cv.value.length > 0 : cv.value !== '' && cv.value !== null;
      })
      .map((cv: IdeaCustomFieldValue) => ({ id: cv.id, value: cv.value }));

    const customValues = custom.filter((cv: any) => !this.isSystemField(cv));
    const systemValues = custom.filter((cv: any) => this.isSystemField(cv));

    return {
      ...form.value,
      custom: customValues,
      ...systemValues.reduce<{ [field in IdeaSystemField]?: IdeaCustomFieldValue['value'] }>((acc, cv) => {
        acc[cv.id as IdeaSystemField] = cv.value;
        return acc;
      }, {}),
    };
  }

  public createOptionsFormArray(options: readonly CustomFieldOption[]): UntypedFormArray {
    const controls = options.map((opt) => this.createOptionFormControl(opt));
    return new UntypedFormArray(controls);
  }

  public createOptionFormControl(option: CustomFieldOption): AbstractControl {
    return new UntypedFormControl(option, customFieldOptionValidator);
  }

  public isSystemField(field: { id: string }): field is CraftSystemFormField {
    return field && field.id ? !!SystemFieldNames[field.id as IdeaSystemField] : false;
  }

  private createFormArray(idea: Idea, form: CraftForm | undefined, customFieldsMap: Map<string, CustomField>): UntypedFormArray {
    const formFieldsMap = keyBy(form?.fields || [], (f) => f.id);
    const ideaFieldsMap = keyBy(idea.custom || [], (f) => f.id);

    const fields = (
      idea.id //
        ? Array.from(new Set([...Object.keys(ideaFieldsMap), ...Object.keys(formFieldsMap)]))
        : Object.keys(formFieldsMap)
    ).map((id) => ({
      id,
      isRequired: !!formFieldsMap[id]?.isRequired,
      value: ideaFieldsMap[id]?.value || null,
    }));

    const controls = fields.map((field) => {
      const ideaCustomValue = { id: field.id, isRequired: field.isRequired };

      if (this.isSystemField(field)) {
        return new FormControl<IdeaCustomFieldValue>({ ...ideaCustomValue, value: idea[field.id] }, geFieldValidator(field));
      }

      const customField = customFieldsMap.get(field.id);
      return new FormControl<IdeaCustomFieldValue>({ ...ideaCustomValue, value: field.value }, [
        geFieldValidator(field), //
        getNumberValidator(customField!),
        getMaxlengthValidator(customField!, 'Answer cannot be longer than @count characters'),
      ]);
    });

    return new FormArray(controls);
  }

  private mapIdeaCustomValues(idea: Idea) {
    return idea.custom.reduce<StringTMap<IdeaCustomFieldValue['value']>>((acc, v) => {
      acc[v.id] = v.value;
      return acc;
    }, {});
  }
}
