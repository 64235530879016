import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/core/services/analytics.service';
import { CategoriesReqAddSuccessAction, CATEGORIES_ADD_SUCCESS } from 'src/app/core/store/actions/categories';
import { CommentsReqAddSuccessAction, COMMENTS_ADD_SUCCESS } from 'src/app/core/store/actions/comments';
import { FiltersApplyAction, FILTERS_APPLY } from 'src/app/core/store/actions/filters';
import {
  IdeasReqAddSuccessAction,
  IdeasReqAddTagAction,
  IdeasReqArchiveSuccessAction,
  IdeasReqAttachFileSuccessAction,
  IdeasReqDetachFileSuccessAction,
  IdeasReqVoteAction,
  IdeasReqSaveImportanceAction,
  IdeasReqSaveInnerStatusAction,
  IdeasReqSaveWorkflowStatusAction,
  IdeasReqTrackSuccessAction,
  IdeasReqUnArchiveSuccessAction,
  IdeasReqUntrackSuccessAction,
  IdeasSetSelectedByIdAction,
  IDEAS_ADD_SUCCESS,
  IDEAS_ADD_TAG,
  IDEAS_ARCHIVE_SUCCESS,
  IDEAS_ATTACH_FILE_SUCCESS,
  IDEAS_DETACH_FILE_SUCCESS,
  IDEAS_DEL_VOTE,
  IDEAS_VOTE,
  IDEAS_SAVE_IMPORTANCE,
  IDEAS_SAVE_INNER_STATUS,
  IDEAS_SAVE_WORKFLOW_STATUS,
  IDEAS_SELECT_BY_ID,
  IDEAS_TRACK_SUCCESS,
  IDEAS_UNARCHIVE_SUCCESS,
  IDEAS_UNTRACK_SUCCESS
} from 'src/app/core/store/actions/ideas';
import { NotesReqSaveSuccessAction, NOTES_SAVE_SUCCESS } from 'src/app/core/store/actions/notes';
import { ProductsSelectByIdAction, PRODUCTS_SELECT_BY_ID } from 'src/app/core/store/actions/products';
import { toPayload } from 'src/app/core/utils/rx-utils';

@Injectable()
export class AnalyticsEffects {
  constructor(
    private actions$: Actions, //
    private analytics: AnalyticsService,
  ) {}

  public addIdea$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasReqAddSuccessAction>(IDEAS_ADD_SUCCESS),
        map(toPayload),
        map((idea) => {
          this.analytics.trackIdea('fpFeedbackAdded', idea.id!);
        }),
      );
    },
    { dispatch: false },
  );

  public addCategory$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<CategoriesReqAddSuccessAction>(CATEGORIES_ADD_SUCCESS),
        map(toPayload),
        map((category) => {
          return this.analytics.trackCategoryAdded(category);
        }),
      );
    },
    { dispatch: false },
  );

  public selectCategoryFilter$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<FiltersApplyAction>(FILTERS_APPLY),
        map(toPayload),
        map((req) => {
          if (req.categoryId) {
            this.analytics.trackCategory('fpCategorySelected', req.categoryId);
          }
        }),
      );
    },
    { dispatch: false },
  );

  public addComment$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<CommentsReqAddSuccessAction>(COMMENTS_ADD_SUCCESS),
        map(toPayload),
        map((req) => {
          this.analytics.trackCommentAdded(req.comment);
        }),
      );
    },
    { dispatch: false },
  );

  public addNote$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<NotesReqSaveSuccessAction>(NOTES_SAVE_SUCCESS),
        map(toPayload),
        map((req) => {
          this.analytics.trackCommentAdded(req.note);
        }),
      );
    },
    { dispatch: false },
  );

  public selectProduct$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<ProductsSelectByIdAction>(PRODUCTS_SELECT_BY_ID),
        map(toPayload),
        map((productId) => {
          return this.analytics.trackLoadedMainPage(productId);
        }),
      );
    },
    { dispatch: false },
  );

  public selectIdeadById$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasSetSelectedByIdAction>(IDEAS_SELECT_BY_ID),
        map(toPayload),
        map((ideadId) => {
          if (ideadId) {
            this.analytics.trackIdea('fpFeedbackViewed', ideadId);
          }
        }),
      );
    },
    { dispatch: false },
  );

  public changeImportance$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasReqSaveImportanceAction>(IDEAS_SAVE_IMPORTANCE),
        map(toPayload),
        map((req) => {
          this.analytics.trackIdeaImportanceChanged(req.ideaId, req.importanceId);
        }),
      );
    },
    { dispatch: false },
  );

  public changeWorkflowStatus$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasReqSaveWorkflowStatusAction>(IDEAS_SAVE_WORKFLOW_STATUS),
        map(toPayload),
        map(({ ideaId, statusId }) => {
          this.analytics.trackIdeaStatusChanged(ideaId, statusId);
        }),
      );
    },
    { dispatch: false },
  );

  public changeInnerStatus$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasReqSaveInnerStatusAction>(IDEAS_SAVE_INNER_STATUS),
        map(toPayload),
        map(({ ideaId, innerStatusId }) => {
          this.analytics.trackIdeaInnerStatusChanged(ideaId, innerStatusId);
        }),
      );
    },
    { dispatch: false },
  );

  public setLike$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasReqVoteAction>(IDEAS_VOTE),
        map(toPayload),
        map((id) => {
          this.analytics.trackIdea('fpFeedbackLiked', id);
        }),
      );
    },
    { dispatch: false },
  );

  public setDislike$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasReqVoteAction>(IDEAS_DEL_VOTE),
        map(toPayload),
        map((id) => {
          this.analytics.trackIdea('fpFeedbackDisliked', id);
        }),
      );
    },
    { dispatch: false },
  );

  public addTag$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasReqAddTagAction>(IDEAS_ADD_TAG),
        map(toPayload),
        map(({ ideaId, tagId }) => {
          this.analytics.trackIdeaTagAdded(ideaId, tagId);
        }),
      );
    },
    { dispatch: false },
  );

  public archiveIdea$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasReqArchiveSuccessAction>(IDEAS_ARCHIVE_SUCCESS),
        map(toPayload),
        map((ideaId) => {
          this.analytics.trackIdea('fpFeedbackArchived', ideaId);
        }),
      );
    },
    { dispatch: false },
  );

  public unarchiveIdea$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasReqUnArchiveSuccessAction>(IDEAS_UNARCHIVE_SUCCESS),
        map(toPayload),
        map((ideaId) => {
          this.analytics.trackIdea('fpFeedbackUnarchived', ideaId);
        }),
      );
    },
    { dispatch: false },
  );

  public setTrack$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasReqTrackSuccessAction>(IDEAS_TRACK_SUCCESS),
        map(toPayload),
        map((ideaId) => {
          this.analytics.trackIdea('fpFeedbackTracked', ideaId);
        }),
      );
    },
    { dispatch: false },
  );

  public setUntrack$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasReqUntrackSuccessAction>(IDEAS_UNTRACK_SUCCESS),
        map(toPayload),
        map((ideaId) => {
          this.analytics.trackIdea('fpFeedbackUnTracked', ideaId);
        }),
      );
    },
    { dispatch: false },
  );

  public attachFile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasReqAttachFileSuccessAction>(IDEAS_ATTACH_FILE_SUCCESS),
        map(toPayload),
        map(({ ideaId }) => {
          this.analytics.trackIdea('fpAttachmentAdded', ideaId);
        }),
      );
    },
    { dispatch: false },
  );

  public detachFile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<IdeasReqDetachFileSuccessAction>(IDEAS_DETACH_FILE_SUCCESS),
        map(toPayload),
        map(({ ideaId, fileId }) => {
          this.analytics.trackIdea('fpAttachmentDeleted', ideaId);
        }),
      );
    },
    { dispatch: false },
  );
}
