import { UserRole } from 'src/app/core/enums/UserRole';
import { ErrorResponse, StringBooleanMap } from 'src/app/core/models/common.models';

export type AuthUser = {
  readonly id: string;
  readonly userId: string;
  readonly email: string;
  readonly iportalRole: UserRole;
  readonly firstName: string;
  readonly lastName: string;
  readonly created: Date | string;
  readonly personId?: string;
  readonly isSSO?: boolean;
  readonly companyId?: string;
  readonly fileId?: string;
  readonly color?: string;
  readonly features?: StringBooleanMap;
};

export type SigninRequest = {
  readonly username: string;
  readonly password: string;
  readonly samlId?: string;
  readonly gRecaptchaResponse?: string;
};

export type SigninTokenRequest = {
  readonly tokenId: string;
  readonly samlId?: string;
};

export type GoogleSigninRequest = {
  readonly token: string;
  readonly samlId?: string;
  readonly inviteId?: string;
  readonly signin?: boolean;
  readonly signup?: boolean;
};

export type CheckUsernameRequest = {
  readonly username: string;
  readonly inviteId?: string;
  readonly samlId?: string;
};

export type ResetPasswordRequest = {
  readonly username: string;
  readonly captcha: string;
};

export type CheckResetPasswordRequest = {
  readonly password: string;
  readonly resetId: string;
};

export type SignupRequest = {
  readonly username: string;
  readonly password: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly inviteId?: string;
  readonly companyId?: string;
  readonly samlId?: string;
  readonly gRecaptchaResponse?: string;
};

export type AuthResponse = {
  readonly user: AuthUser;
  readonly accounts: Record<string, AuthAccount>;
  readonly redirectUrl?: string;
  readonly samlUrl?: string;
  readonly disableRedirect?: boolean;
};

export type AuthErrorData = { readonly needCaptcha?: boolean };
export type AuthErrorResponse = Omit<ErrorResponse, 'data'> & { readonly data?: AuthErrorData };

export enum PaidStatus {
  'paid' = 1,
  'non-paid' = 2,
  'trial-paid' = 3,
  'trial-non-paid' = 4,
  'ex-paid' = 5,
  'ex-non-paid' = 6,
  'ex-trial-paid' = 7,
  'ex-trial-non-paid' = 8,
}

export type PaidStatusString = keyof typeof PaidStatus;

export type AuthAccount = {
  readonly id: string;
  readonly name: string;
  readonly enterpriseName: string;
  readonly accountRole: 'regular' | 'admin' | 'owner';
  readonly paidStatus: PaidStatusString;
};
