import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { IdeaSystemField } from 'src/app/core/models/idea.models';
import { SystemFieldNames } from 'src/app/core/store/constants/system-fields';
import { CustomFieldsStoreService } from 'src/app/core/store/services/custom-fields-store.service';

@Component({
  selector: 'craft-idea-custom-controls',
  templateUrl: './idea-custom-controls.component.html',
  styleUrls: ['./idea-custom-controls.component.scss'],
})
export class IdeaCustomControlsComponent {
  @Input() public formArray: AbstractControl | null;
  @Input() public isFormSubmitted = false;

  constructor(public readonly customFieldsService: CustomFieldsStoreService) {}

  public get fa() {
    return this.formArray as UntypedFormArray;
  }

  public get controls() {
    return this.fa.controls as UntypedFormControl[];
  }

  public getSystemFieldName(fieldId: IdeaSystemField) {
    return SystemFieldNames[fieldId];
  }

  public getErrorMessage(control: UntypedFormControl): string | undefined {
    if (this.isFormSubmitted && control.errors?.fieldValidation) {
      return control.errors.fieldValidation.message;
    } else if (control.touched) {
      return control.errors?.number?.message || control.errors?.maxLength?.message;
    }

    return;
  }
}
