import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthCheckTokenSuccessAction } from 'src/app/core/store/actions/auth';
import { PortalReqGetErrorAction, PortalReqGetSuccessAction, PORTAL_GET_ERROR, PORTAL_GET_SUCCESS } from 'src/app/core/store/actions/portal';
import { AuthStoreService } from 'src/app/core/store/services/auth-store.service';
import { toPayload } from 'src/app/core/utils/rx-utils';

@Injectable()
export class PortalEffects {
  constructor(
    private router: Router, //
    private actions$: Actions,
    private authStore: AuthStoreService,
    private translateService: TranslateService,
  ) {}

  public fetchPortalSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<PortalReqGetSuccessAction>(PORTAL_GET_SUCCESS),
      map(toPayload),
      switchMap((res) => {
        this.translateService.currentLang = res.portal.lang;
        if (!res.authRes) return EMPTY;

        return this.authStore.isAuthenticated$.pipe(
          take(1),
          filter((isAuthenticated) => !isAuthenticated),
          map(() => {
            return new AuthCheckTokenSuccessAction({ ...res.authRes!, disableRedirect: true });
          }),
        );
      }),
    );
  });

  public fetchPortalError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<PortalReqGetErrorAction>(PORTAL_GET_ERROR),
        tap(() => {
          this.router.navigate(['404']);
        }),
      );
    },
    { dispatch: false },
  );
}
