import { AuthAccount, AuthResponse, AuthUser } from 'src/app/core/models/auth.models';
import { StringBooleanMap } from 'src/app/core/models/common.models';
import { indexByToStringMap } from 'src/app/core/utils/app-utils';

type RawAuthResponse = {
  readonly data: {
    readonly userInfo: any;
    readonly samlUrl?: string;
    readonly accounts?: readonly AuthAccount[];
  };
};

export const mapUserInfo = (userInfo: any): AuthUser => {
  const featuresArr = (Array.isArray(userInfo.features) ? userInfo.features : []) as string[];
  const features = featuresArr.reduce<StringBooleanMap>((acc, key) => {
    acc[key] = true;
    return acc;
  }, {});

  return {
    ...userInfo,
    personId: userInfo.person_id,
    created: new Date(userInfo.created),
    iportalRole: Number.parseInt(userInfo.iportalRole, 10),
    features,
  };
};

export const mapAuthResponse = (res: RawAuthResponse): AuthResponse => {
  const user = mapUserInfo(res.data.userInfo);
  const accounts = indexByToStringMap(res.data.accounts || [], 'id');

  return { user, accounts, samlUrl: res.data.samlUrl };
};

export const mapPossibleAuthResponce = (res?: RawAuthResponse): AuthResponse | undefined => {
  if (!res?.data?.userInfo) return;
  return mapAuthResponse(res);
};
