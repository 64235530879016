import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export interface IFormError {
  control: string;
  error: string;
  value: any;
}

export function getFormValidationErrors(form: UntypedFormGroup | UntypedFormArray) {
  const result: IFormError[] = [];

  Object.keys(form.controls).forEach((key) => {
    const control = form.get(key);

    if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
      result.push(...getFormValidationErrors(control));
    }

    const controlErrors = control?.errors;
    if (controlErrors) {
      Object.keys(controlErrors).forEach((keyError) => {
        result.push({
          control: key,
          error: keyError,
          value: controlErrors[keyError],
        });
      });
    }
  });

  return result;
}
