<p class="c-label">
  {{ field?.label }}
  <span class="red" *ngIf="isRequired">*</span>
</p>
<p class="c-desc" *ngIf="field?.description">
  {{ field?.description }}
</p>

<ng-container [ngSwitch]="field?.controlType">
  <ng-container *ngSwitchCase="'STRINGINPUT'">
    <textarea
      #stringEl
      class="textarea-input"
      maxlength="1000000"
      [placeholder]="$any(field).placeholder"
      [value]="icfv?.value"
      (keyup)="updateSingleValue(stringEl.value)"
      craftAutoresize=""
    ></textarea>
  </ng-container>

  <ng-container *ngSwitchCase="'NUMBERINPUT'">
    <craft-number-input
      [value]="$any(icfv?.value)"
      (update)="updateSingleValue($event)"
      [format]="$any(field).format"
      [placeholder]="$any(field).placeholder"
      [showButtons]="false"
      [allowSign]="$any(field).min > 0"
      [allowNull]="true"
    ></craft-number-input>
  </ng-container>

  <ng-container *ngSwitchCase="'DATEPICKER'">
    <craft-calendar class="mt-15" [value]="$any(icfv?.value)" (change)="updateSingleValue($event)"></craft-calendar>
  </ng-container>

  <ng-container *ngSwitchCase="'SINGLESELECT'">
    <ng-container *ngTemplateOutlet="radiobuttons"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'RADIOBUTTONSLIST'">
    <ng-container *ngTemplateOutlet="radiobuttons"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'MULTISELECT'">
    <ng-container *ngTemplateOutlet="checkboxbuttons"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'CHECKBOXESLIST'">
    <ng-container *ngTemplateOutlet="checkboxbuttons"></ng-container>
  </ng-container>
</ng-container>

<ng-template #checkboxbuttons>
  <div class="checkboxes-group">
    <mat-checkbox
      class="checkbox-button mat-checkbox-button-md"
      *ngFor="let opt of $any(field).options; trackBy: identifyOption"
      [checked]="checked.has($any(opt).guid)"
      (change)="updateMultiValue($any(opt).guid, $event.checked)"
    >
      {{ $any(opt).label }}
    </mat-checkbox>
  </div>
</ng-template>

<ng-template #radiobuttons>
  <mat-radio-group class="radio-group mat-radio-group-md" [value]="singleValue">
    <mat-radio-button
      class="radio-button mat-radio-button-md"
      *ngFor="let opt of $any(field).options; trackBy: identifyOption"
      [value]="$any(opt).guid"
      (change)="updateSingleValue([$event.value])"
    >
      {{ $any(opt).label }}
    </mat-radio-button>
  </mat-radio-group>
</ng-template>
