<div class="users-autocomplete-wrap">
  <input type="text" [formControl]="searchControl" [placeholder]="placeholder" [matAutocomplete]="auto" />

  <mat-autocomplete
    #auto="matAutocomplete"
    autoActiveFirstOption=""
    [displayWith]="displayFn"
    (optionSelected)="selectUser($event.option.value)"
    class="users-autocomplete"
  >
    <mat-option
      *ngFor="let user of filteredUsers$ | async | slice: 0:100; trackBy: identify"
      [value]="user"
      [matTooltip]="user?.fullName || ''"
      matTooltipPosition="above"
      matTooltipShowDelay="1000"
    >
      <craft-user-icon [user]="user"></craft-user-icon>
      <span>{{ user?.fullName }}</span>
    </mat-option>
  </mat-autocomplete>
</div>
