<div class="roles-helper-dialog">
  <button type="button" class="close" (click)="close()" matTooltip="Close" matTooltipPosition="above">
    <craft-icon class="svg-icon" name="general--close-md"></craft-icon>
  </button>
  <h1>Users permissions</h1>

  <div class="roles-list">
    <div *ngFor="let role of roles" class="role-desc">
      <div>
        {{ role.name | craftTerm }}
      </div>
      <p>
        {{ role.desc | craftTerm }}
      </p>
    </div>
  </div>
</div>
