import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccessLevel } from 'src/app/core/enums/AccessLevels';
import { UserPermissionsService } from 'src/app/core/services/user-permissions.service';

type Operator = 'or' | 'and';

/**
 * @example
 * // item will be shown to users with a role AccountOwner or higher
 * *craftPermissions="'AccountOwner'"
 *
 * // item will be shown to users with Admin role (or higher) or if current user is author of item
 * *craftPermissions="'Admin';authorId:idea.creator.id"
 *
 * // item will be shown to users with Admin role (or higher) or
 * // if current user is author of item and has Contributor role (or higher)
 * *craftPermissions="'Admin';authorId:note.creator.id;authorLevel:'Contributor'"
 *
 * // if current user has role CompanyUser role (or higher) item will be shown
 * // in other case will be shown template #notLoggedIn
 * *craftPermissions="'CompanyUser';else notLoggedIn"
 *
 * //item will be shown to users with ProcessOwner role (or higher) AND if portal is in restricted mode
 * *craftPermissions="'ProcessOwner';restricted:true;operator:'and'"
 */

@Directive({ selector: '[craftPermissions]' })
export class CraftPermissionsDirective {
  private accessLevel: AccessLevel;
  private authorId: string | null = null;
  private authorLevel: AccessLevel | null = null;
  private restricted: boolean | null = null;
  private operator: Operator = 'or';

  private elseTemplateRef: TemplateRef<any> | null;
  private thenViewRef: EmbeddedViewRef<any> | null = null;
  private elseViewRef: EmbeddedViewRef<any> | null = null;

  constructor(private ups: UserPermissionsService, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  @Input()
  public set craftPermissionsOperator(operator: Operator) {
    this.operator = operator === 'and' ? operator : 'or';
    this.checkPermission();
  }

  @Input()
  public set craftPermissionsRestricted(restricted: boolean | null | undefined) {
    this.restricted = typeof restricted === 'boolean' ? restricted : null;
    this.checkPermission();
  }

  @Input()
  public set craftPermissionsAuthorId(id: string | null | undefined) {
    this.authorId = id ? id : null;
    this.checkPermission();
  }

  @Input()
  public set craftPermissionsAuthorLevel(authorLevel: string | null | undefined) {
    this.authorLevel = authorLevel ? (AccessLevel as any)[authorLevel] : null;
    this.checkPermission();
  }

  @Input()
  public set craftPermissions(accessLevel: string) {
    this.accessLevel = (AccessLevel as any)[accessLevel];
    this.thenViewRef = null; // clear previous view if any.
    this.checkPermission();
  }

  @Input()
  set craftPermissionsElse(templateRef: TemplateRef<any> | null) {
    this.elseTemplateRef = templateRef;
    this.elseViewRef = null; // clear previous view if any.
    this.checkPermission();
  }

  private checkPermission() {
    let isPermitted = this.ups.authorize(this.accessLevel);

    if (this.authorId !== null) {
      let isAuthorPermitted = this.ups.isUserEqualTo(this.authorId);

      if (this.authorLevel !== null) {
        isAuthorPermitted = isAuthorPermitted && this.ups.authorize(this.authorLevel);
      }

      isPermitted = this.combineBoolean(isPermitted, isAuthorPermitted);
    }

    if (this.restricted !== null) {
      isPermitted = this.combineBoolean(isPermitted, this.restricted === this.ups.isRestrictedPortal());
    }

    this.updateTemplate(isPermitted);
  }

  private combineBoolean(a: boolean, b: boolean) {
    return this.operator === 'and' ? a && b : a || b;
  }

  private updateTemplate(isPermitted: boolean) {
    if (isPermitted) {
      if (!this.thenViewRef) {
        this.elseViewRef = null;
        this.viewContainer.clear();
        this.thenViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      if (!this.elseViewRef) {
        this.thenViewRef = null;
        this.viewContainer.clear();
        if (this.elseTemplateRef) {
          this.elseViewRef = this.viewContainer.createEmbeddedView(this.elseTemplateRef);
        }
      }
    }
  }
}
