<form novalidate="" [formGroup]="form" #formRef="ngForm" (submit)="submit()" class="craft-item-edit-dialog">
  <h1 class="dialog-title">Edit item for promotion</h1>

  <div mat-dialog-content="" class="dialog-content">
    <craft-validation-message [isShown]="formRef.submitted && form.controls['name'].invalid"> Please title item before submission </craft-validation-message>
    <textarea class="title-input" placeholder="Title" formControlName="name" maxlength="255" craftAutoresize="" [disableLineBreak]="true"></textarea>
    <craft-text-editor class="desc-input" placeholder="Description" formControlName="description"></craft-text-editor>
  </div>

  <div mat-dialog-actions="" class="dialog-actions">
    <button class="btn dialog-btn shrink-0" type="button" (click)="close()" mat-button="">Cancel</button>
    <button class="btn btn-blue dialog-btn shrink-0" type="submit" mat-button="">
      {{ 'promote.dialog.submit.' + promoteItemType | craftTerm }}
    </button>
  </div>
</form>
