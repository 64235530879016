import { StringTMap } from 'src/app/core/models/common.models';
import { ParamsTableRow } from 'src/app/core/models/filters.models';
import { PeriodEnum } from 'src/app/core/models/period';

export const QUERY_PARAMS_TO_IDEAS_REQ_TABLE: Readonly<StringTMap<ParamsTableRow>> = {
  productKey: {
    key: 'productKey',
    type: 'string',
    defaultValue: void 0,
  },
  ideaShortId: {
    key: 'ideaShortId',
    type: 'string',
    defaultValue: void 0,
  },
  staticFilter: {
    key: 'staticFilter',
    type: 'string',
    defaultValue: void 0,
  },
  categoryId: {
    key: 'categoryId',
    type: 'string',
    defaultValue: void 0,
  },
  search: {
    key: 'search',
    type: 'string',
    defaultValue: void 0,
  },
  status: {
    key: 'workflowStatuses',
    type: 'array',
    defaultValue: [],
  },
  istatus: {
    key: 'innerStatuses',
    type: 'array',
    defaultValue: [],
  },
  importance: {
    key: 'importances',
    type: 'array',
    defaultValue: [],
  },
  item: {
    key: 'items',
    type: 'array',
    defaultValue: [],
  },
  category: {
    key: 'categories',
    type: 'array',
    defaultValue: [],
  },
  company: {
    key: 'companies',
    type: 'array',
    defaultValue: [],
  },
  tag: {
    key: 'labels',
    type: 'array',
    defaultValue: [],
  },
  user: {
    key: 'postedBy',
    type: 'array',
    defaultValue: [],
  },
  period: {
    key: 'period',
    type: 'string',
    transform: (val: string) => {
      return val in PeriodEnum ? val : void 0;
    },
    defaultValue: void 0,
  },
  cstart: {
    key: 'createdStart',
    type: 'number',
    transform: (val: string) => {
      return Number.parseFloat(val) || void 0;
    },
    defaultValue: void 0,
  },
  cend: {
    key: 'createdEnd',
    type: 'number',
    transform: (val: string) => {
      return Number.parseFloat(val) || void 0;
    },
    defaultValue: void 0,
  },
};

export const IDEAS_REQ_TO_QUERY_PARAMS_TABLE: Readonly<StringTMap<ParamsTableRow>> = {
  workflowStatuses: {
    key: 'status',
    type: 'array',
    defaultValue: [],
  },
  innerStatuses: {
    key: 'istatus',
    type: 'array',
    defaultValue: [],
  },
  importances: {
    key: 'importance',
    type: 'array',
    defaultValue: [],
  },
  items: {
    key: 'item',
    type: 'array',
    defaultValue: [],
  },
  categories: {
    key: 'category',
    type: 'array',
    defaultValue: [],
  },
  companies: {
    key: 'company',
    type: 'array',
    defaultValue: [],
  },
  labels: {
    key: 'tag',
    type: 'array',
    defaultValue: [],
  },
  postedBy: {
    key: 'user',
    type: 'array',
    defaultValue: [],
  },
  search: {
    key: 'search',
    type: 'string',
    defaultValue: void 0,
  },
  period: {
    key: 'period',
    type: 'string',
    defaultValue: void 0,
  },
  createdStart: {
    key: 'cstart',
    type: 'number',
    defaultValue: void 0,
  },
  createdEnd: {
    key: 'cend',
    type: 'number',
    defaultValue: void 0,
  },
};
