import { Action } from '@ngrx/store';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { DBNote, NoteStoreReq } from 'src/app/core/models/notes.models';

export const NOTES_LIST = '[Notes] list';
export const NOTES_LIST_ERROR = '[Notes] list error';
export const NOTES_LIST_SUCCESS = '[Notes] list success';

export const NOTES_ADD = '[Notes] add';
export const NOTES_ADD_ERROR = '[Notes] add error';
export const NOTES_ADD_SUCCESS = '[Notes] add success';

export const NOTES_SAVE = '[Notes] save';
export const NOTES_SAVE_ERROR = '[Notes] save error';
export const NOTES_SAVE_SUCCESS = '[Notes] save success';

export const NOTES_DEL = '[Notes] del';
export const NOTES_DEL_ERROR = '[Notes] del error';
export const NOTES_DEL_SUCCESS = '[Notes] del success';

// LIST
export class NotesReqListAction implements Action {
  readonly type = NOTES_LIST;
  constructor(public payload: string) {}
}
export class NotesReqListErrorAction implements Action {
  readonly type = NOTES_LIST_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class NotesReqListSuccessAction implements Action {
  readonly type = NOTES_LIST_SUCCESS;
  constructor(public payload: readonly DBNote[]) {}
}

// ADD
export class NotesReqAddAction implements Action {
  readonly type = NOTES_ADD;
  constructor(public payload: NoteStoreReq) {}
}
export class NotesReqAddErrorAction implements Action {
  readonly type = NOTES_ADD_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class NotesReqAddSuccessAction implements Action {
  readonly type = NOTES_ADD_SUCCESS;
  constructor(public payload: NoteStoreReq) {}
}

// SAVE
export class NotesReqSaveAction implements Action {
  readonly type = NOTES_SAVE;
  constructor(public payload: NoteStoreReq) {}
}
export class NotesReqSaveErrorAction implements Action {
  readonly type = NOTES_SAVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class NotesReqSaveSuccessAction implements Action {
  readonly type = NOTES_SAVE_SUCCESS;
  constructor(public payload: NoteStoreReq) {}
}

// DEL
export class NotesReqDelAction implements Action {
  readonly type = NOTES_DEL;
  constructor(public payload: NoteStoreReq) {}
}
export class NotesReqDelErrorAction implements Action {
  readonly type = NOTES_DEL_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class NotesReqDelSuccessAction implements Action {
  readonly type = NOTES_DEL_SUCCESS;
  constructor(public payload: NoteStoreReq) {}
}

export type NotesAction =
  | NotesReqListAction
  | NotesReqListErrorAction
  | NotesReqListSuccessAction
  //
  | NotesReqAddAction
  | NotesReqAddErrorAction
  | NotesReqAddSuccessAction
  //
  | NotesReqSaveAction
  | NotesReqSaveErrorAction
  | NotesReqSaveSuccessAction
  //
  | NotesReqDelAction
  | NotesReqDelErrorAction
  | NotesReqDelSuccessAction;
