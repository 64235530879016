import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, tap } from 'rxjs';
import { CATEGORIES_ADD_ERROR, CATEGORIES_DEL_ERROR, CATEGORIES_SAVE_ERROR } from 'src/app/core/store/actions/categories';
import { COMMENTS_ADD_ERROR, COMMENTS_DEL_ERROR } from 'src/app/core/store/actions/comments';
import { COMPANY_ADD_ERROR, COMPANY_DEL_ERROR, COMPANY_SAVE_ERROR } from 'src/app/core/store/actions/companies';
import { COMPANY_DEL_INVITE_LINK_ERROR } from 'src/app/core/store/actions/company-links';
import { COMPANY_DEL_USER_ERROR } from 'src/app/core/store/actions/company-users';
import {
  CUSTOM_FIELDS_ADD_ERROR,
  CUSTOM_FIELDS_DELETE_ERROR,
  CUSTOM_FIELDS_MOVE_ERROR,
  CUSTOM_FIELDS_UPDATE_ERROR
} from 'src/app/core/store/actions/custom-fields';
import { FILTERS_APPLY_ERROR } from 'src/app/core/store/actions/filters';
import {
  IDEAS_ADD_ERROR,
  IDEAS_ADD_TAG_ERROR,
  IDEAS_ARCHIVE_ERROR,
  IDEAS_ATTACH_FILE_ERROR,
  IDEAS_BULK_UPDATE_ERROR,
  IDEAS_DEL_ERROR,
  IDEAS_DEL_TAG_ERROR,
  IDEAS_DEL_VOTE_ERROR,
  IDEAS_DETACH_FILE_ERROR,
  IDEAS_LINK_ITEM_ERROR,
  IDEAS_SAVE_ERROR,
  IDEAS_SAVE_IMPORTANCE_ERROR,
  IDEAS_SAVE_INNER_STATUS_ERROR,
  IDEAS_SAVE_WORKFLOW_STATUS_ERROR,
  IDEAS_TRACK_ERROR,
  IDEAS_UNARCHIVE_ERROR,
  IDEAS_UNLINK_ITEM_ERROR,
  IDEAS_UNTRACK_ERROR,
  IDEAS_VOTE_ERROR
} from 'src/app/core/store/actions/ideas';
import { IMPORTANCES_ADD_ERROR, IMPORTANCES_DELETE_ERROR, IMPORTANCES_SAVE_ERROR } from 'src/app/core/store/actions/importances';
import { INNER_STATUSES_ADD_ERROR, INNER_STATUSES_DELETE_ERROR, INNER_STATUSES_SAVE_ERROR } from 'src/app/core/store/actions/inner-statuses';
import { NOTES_ADD_ERROR, NOTES_DEL_ERROR, NOTES_SAVE_ERROR } from 'src/app/core/store/actions/notes';
import { PERMISSIONS_DEL_ERROR, PERMISSIONS_SAVE_ERROR } from 'src/app/core/store/actions/permissions';
import { PRODUCTS_SAVE_ERROR } from 'src/app/core/store/actions/products';
import { TAG_ADD_BULK_ERROR, TAG_ADD_ERROR, TAG_DELETE_ERROR, TAG_UPDATE_ERROR } from 'src/app/core/store/actions/tags';
import { WORKFLOW_STATUSES_ADD_ERROR, WORKFLOW_STATUSES_DELETE_ERROR, WORKFLOW_STATUSES_SAVE_ERROR } from 'src/app/core/store/actions/workflow-statuses';
import { DialogManagerService } from 'src/app/shared/dialogs/dialog-manager.service';

@Injectable()
export class ErrorsEffects {
  private isErrMsgShown = false;

  constructor(
    private actions$: Actions, //
    private dialogManager: DialogManagerService,
  ) {}

  public oopsMessage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          CATEGORIES_ADD_ERROR,
          CATEGORIES_DEL_ERROR,
          CATEGORIES_SAVE_ERROR,
          COMMENTS_ADD_ERROR,
          COMMENTS_DEL_ERROR,
          COMPANY_ADD_ERROR,
          // COMPANY_ADD_INVITE_LINK_ERROR,
          // COMPANY_ADD_USER_ERROR,
          COMPANY_DEL_ERROR,
          COMPANY_DEL_INVITE_LINK_ERROR,
          COMPANY_DEL_USER_ERROR,
          COMPANY_SAVE_ERROR,
          CUSTOM_FIELDS_ADD_ERROR,
          CUSTOM_FIELDS_DELETE_ERROR,
          CUSTOM_FIELDS_MOVE_ERROR,
          CUSTOM_FIELDS_UPDATE_ERROR,
          FILTERS_APPLY_ERROR,
          IDEAS_ADD_ERROR,
          IDEAS_DEL_ERROR,
          IDEAS_BULK_UPDATE_ERROR,
          IDEAS_ADD_TAG_ERROR,
          IDEAS_DEL_TAG_ERROR,
          IDEAS_ARCHIVE_ERROR,
          IDEAS_UNARCHIVE_ERROR,
          IDEAS_ATTACH_FILE_ERROR,
          IDEAS_DETACH_FILE_ERROR,
          IDEAS_VOTE_ERROR,
          IDEAS_DEL_VOTE_ERROR,
          IDEAS_LINK_ITEM_ERROR,
          IDEAS_UNLINK_ITEM_ERROR,
          IDEAS_SAVE_ERROR,
          IDEAS_SAVE_IMPORTANCE_ERROR,
          IDEAS_SAVE_INNER_STATUS_ERROR,
          IDEAS_SAVE_WORKFLOW_STATUS_ERROR,
          IDEAS_TRACK_ERROR,
          IDEAS_UNTRACK_ERROR,
          IMPORTANCES_ADD_ERROR,
          IMPORTANCES_DELETE_ERROR,
          IMPORTANCES_SAVE_ERROR,
          INNER_STATUSES_ADD_ERROR,
          INNER_STATUSES_DELETE_ERROR,
          INNER_STATUSES_SAVE_ERROR,
          NOTES_ADD_ERROR,
          NOTES_DEL_ERROR,
          NOTES_SAVE_ERROR,
          // PERMISSIONS_ADD_ERROR,
          PERMISSIONS_DEL_ERROR,
          PERMISSIONS_SAVE_ERROR,
          PRODUCTS_SAVE_ERROR,
          TAG_ADD_BULK_ERROR,
          TAG_ADD_ERROR,
          TAG_DELETE_ERROR,
          TAG_UPDATE_ERROR,
          WORKFLOW_STATUSES_ADD_ERROR,
          WORKFLOW_STATUSES_DELETE_ERROR,
          WORKFLOW_STATUSES_SAVE_ERROR,
        ),
        map((action: any) => action?.payload?.status),
        filter((status?: string | number) => !this.isErrMsgShown && status != 401 && status != 403),
        tap(() => {
          this.isErrMsgShown = true;
        }),
        switchMap(() => this.dialogManager.showErrorAlert()),
        tap(() => {
          this.isErrMsgShown = false;
        }),
      );
    },
    { dispatch: false },
  );
}
