import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomFieldOption } from 'src/app/core/models/custom-fields.models';

@Component({
  selector: 'craft-custom-field-option-item-form',
  templateUrl: './option-item.component.html',
  styleUrls: ['./option-item.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldOptionItemFormComponent),
      multi: true,
    },
  ],
})
export class CustomFieldOptionItemFormComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() public isSubmitted = false;
  @Output() public remove = new EventEmitter<void>();

  public isEditMode = false;
  public option: CustomFieldOption;
  public control = new UntypedFormControl(void 0, Validators.required);

  public onTouched = () => {};
  public onChange = (_: CustomFieldOption) => {};

  private destroy$ = new Subject<void>();

  constructor() {}

  public ngOnInit() {
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((label) => {
      this.onChange({ ...this.option, label });
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: CustomFieldOption): void {
    this.option = value;
    this.isEditMode = !value?.id && !value?.label;
    this.control.setValue(this.option?.label, { emitEvent: false });
  }

  public registerOnChange(fn: (_: CustomFieldOption) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  public enableEditMode() {
    this.onTouched();
    this.isEditMode = true;
  }

  public onKeyEnter(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.apply();
  }

  public onKeyEsc(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.cancel();
  }

  public cancel(): void {
    this.isEditMode = false;

    if (!this.option.id) {
      this.remove.next();
    } else {
      this.control.setValue(this.option.label);
    }
  }

  public apply(): void {
    this.isSubmitted = true;
    if (this.control.invalid) return;

    this.isEditMode = false;
    this.option = { ...this.option, label: this.control.value };

    this.onChange(this.option);
  }
}
