<div class="notification" [ngClass]="item.classNames" @flyInOut>
  <div class="text">
    <craft-icon class="svg-icon check-icon" name="general--check-md"></craft-icon>
    <p class="msg">{{ item.message }}</p>
  </div>

  <div class="controls">
    <button class="btn action" mat-button *ngFor="let a of item.actions" (click)="action.next(a)">{{ a.text }}</button>
    <craft-icon class="svg-icon text-size-11 pointer" (click)="close.next()" name="general--close-sm"></craft-icon>
  </div>
</div>
