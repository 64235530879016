import { Action } from '@ngrx/store';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { DBImportance, Importance, ImportanceDelRequest, ImportancesListResponse } from 'src/app/core/models/importances.models';

export const IMPORTANCES_LIST = '[Importances] list';
export const IMPORTANCES_LIST_ERROR = '[Importances] list error';
export const IMPORTANCES_LIST_SUCCESS = '[Importances] list success';

export const IMPORTANCES_ADD = '[Importances] add';
export const IMPORTANCES_ADD_ERROR = '[Importances] add error';
export const IMPORTANCES_ADD_SUCCESS = '[Importances] add success';

export const IMPORTANCES_SAVE = '[Importances] save';
export const IMPORTANCES_SAVE_ERROR = '[Importances] save error';
export const IMPORTANCES_SAVE_SUCCESS = '[Importances] save success';

export const IMPORTANCES_DELETE = '[Importances] delete';
export const IMPORTANCES_DELETE_ERROR = '[Importances] delete error';
export const IMPORTANCES_DELETE_SUCCESS = '[Importances] delete success';

// LIST
export class ImportancesReqListAction implements Action {
  readonly type = IMPORTANCES_LIST;
  constructor(public payload?: void) {}
}
export class ImportancesReqListErrorAction implements Action {
  readonly type = IMPORTANCES_LIST_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class ImportancesReqListSuccessAction implements Action {
  readonly type = IMPORTANCES_LIST_SUCCESS;
  constructor(public payload: ImportancesListResponse) {}
}

// ADD
export class ImportancesReqAddAction implements Action {
  readonly type = IMPORTANCES_ADD;
  constructor(public payload: Importance) {}
}
export class ImportancesReqAddErrorAction implements Action {
  readonly type = IMPORTANCES_ADD_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class ImportancesReqAddSuccessAction implements Action {
  readonly type = IMPORTANCES_ADD_SUCCESS;
  constructor(public payload: DBImportance) {}
}

// SAVE
export class ImportancesReqSaveAction implements Action {
  readonly type = IMPORTANCES_SAVE;
  constructor(public payload: DBImportance) {}
}
export class ImportancesReqSaveErrorAction implements Action {
  readonly type = IMPORTANCES_SAVE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class ImportancesReqSaveSuccessAction implements Action {
  readonly type = IMPORTANCES_SAVE_SUCCESS;
  constructor(public payload: DBImportance) {}
}

// DELETE
export class ImportancesReqDelAction implements Action {
  readonly type = IMPORTANCES_DELETE;
  constructor(public payload: ImportanceDelRequest) {}
}
export class ImportancesReqDelErrorAction implements Action {
  readonly type = IMPORTANCES_DELETE_ERROR;
  constructor(public payload: ErrorResponse) {}
}
export class ImportancesReqDelSuccessAction implements Action {
  readonly type = IMPORTANCES_DELETE_SUCCESS;
  constructor(public payload: string) {}
}

export type ImportancesAction =
  | ImportancesReqListAction
  | ImportancesReqListErrorAction
  | ImportancesReqListSuccessAction
  //
  | ImportancesReqAddAction
  | ImportancesReqAddErrorAction
  | ImportancesReqAddSuccessAction
  //
  | ImportancesReqSaveAction
  | ImportancesReqSaveErrorAction
  | ImportancesReqSaveSuccessAction
  //
  | ImportancesReqDelAction
  | ImportancesReqDelErrorAction
  | ImportancesReqDelSuccessAction;
