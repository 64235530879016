import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'craft-custom-fields-add-description-button',
  templateUrl: './add-description-button.component.html',
  styleUrls: ['./add-description-button.component.scss'],
})
export class CustomFieldsAddDescriptionButtonComponent implements OnInit {
  @Input() public control: AbstractControl | null;

  public isOpen = false;

  public get formControl(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }

  public ngOnInit(): void {}
}
