<div class="category-privacy-dialog">
  <button type="button" (click)="close()" class="btn btn-close text-size-15">
    <craft-icon class="svg-icon" name="general--close-md"></craft-icon>
  </button>

  <h1 class="dialog-title">
    {{ 'common.text25' | craftTerm }}
  </h1>

  <div mat-dialog-content="" class="dialog-content">
    <section class="users-wrap">
      <p class="dialog-desc">
        {{ 'common.text26' | craftTerm }}
      </p>
      <craft-category-privacy-dropdown
        [isPrivate]="category.isPrivate"
        [selectedIds]="category.companies || []"
        (privateChange)="privateChange($event)"
        (companiesChange)="companiesChange($event)"
      >
      </craft-category-privacy-dropdown>
    </section>

    <section class="companies-wrap">
      <p class="dialog-desc">
        {{ 'common.text27' | craftTerm }}
      </p>
      <craft-ideas-visibility-dropdown
        [ideasVisibility]="category.ideaVisibility"
        [isCategoryPrivate]="category.isPrivate"
        [disableYesVisibility]="!portal?.crossCompany"
        (vibilityChange)="visibilityChange($event)"
        [selectedIds]="category.companies || []"
        (companiesChange)="companiesChange($event)"
      >
      </craft-ideas-visibility-dropdown>
    </section>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex align-items-center justify-end">
    <button class="btn dialog-btn" type="button" (click)="close()">Cancel</button>
    <button type="button" (click)="done()" class="btn btn-blue dialog-btn ml-12">Save</button>
  </div>
</div>
