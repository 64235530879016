import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Destroyer } from 'src/app/core/abstract/destroyer';
import { CustomField, CustomFieldType } from 'src/app/core/models/custom-fields.models';
import { CustomPresetsService, PresetItem } from 'src/app/shared/components/custom-fileds/custom-presets/custom-presets.service';

@Component({
  selector: 'craft-custom-presets-dropdown',
  templateUrl: './custom-presets-dropdown.component.html',
  styleUrls: ['./custom-presets-dropdown.component.scss'],
})
export class CustomPresetsDropdownComponent extends Destroyer implements OnInit {
  @Input() public filterByType: CustomFieldType | undefined;
  @Output() public select = new EventEmitter<Partial<CustomField>>();

  public presets: PresetItem[] = [];

  constructor(private presetsStore: CustomPresetsService) {
    super();
  }

  public ngOnInit(): void {
    this.presetsStore
      .load(this.filterByType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((presets) => {
        this.presets = presets;
      });
  }
}
