import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfigService } from './config.service';

export enum SocketState {
  connected = 0,
  open = 1,
  closing = 2,
  closed = 3,
}

export type SocketMessage = {
  data?: any;
  event: string;
  socketName: string;
  isServer?: boolean;
  clientId?: string;
  isMine?: boolean;
  userId?: string;
};

export type SocketConfig = {
  pingInterval: number;
  reconnectInterval: number;
};

const DEFAULT_OPTIONS: SocketConfig = {
  pingInterval: 3 * 1000,
  reconnectInterval: 3 * 1000,
};

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private sockets = new Map<string, WebSocket>();
  private reconnectTimerId: number;
  private options: SocketConfig;
  private isManualDisconnect = false;
  private readonly msg$ = new Subject<SocketMessage>();

  public readonly message$ = this.msg$.asObservable();

  constructor(private configService: ConfigService) {
    this.options = DEFAULT_OPTIONS;
  }

  public getState(socketName: string): SocketState {
    const socket = this.sockets.get(socketName);
    return socket !== void 0 ? socket.readyState : SocketState.closed;
  }

  public send(socketName: string, data: any) {
    const socket = this.sockets.get(socketName);
    if (socket !== void 0 && socket.readyState === SocketState.open) {
      socket.send(JSON.stringify(data));
    }
    return this;
  }

  public connect(socketName: string, url: string, protocols: string[] = []) {
    const socket = this.sockets.get(socketName);
    if (socket !== void 0 && socket.readyState === SocketState.open) {
      socket.close();
    }

    try {
      const ws = new WebSocket(url, protocols);
      this.sockets.set(socketName, ws);
      this.isManualDisconnect = false;

      ws.onmessage = (e: MessageEvent) => this.onMessage(socketName, e);
      ws.onopen = () => this.onOpen(socketName);
      ws.onclose = () => this.onClose(socketName, url, protocols);
      ws.onerror = (e: Event) => this.msg$.error({ ws, event: e });

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public disconnect(socketName: string) {
    const socket = this.sockets.get(socketName);
    if (socket !== void 0 && socket.readyState === SocketState.open) {
      this.sockets.delete(socketName);
      this.isManualDisconnect = true;
      socket.close();
    }
  }

  private onMessage(socketName: string, e: MessageEvent) {
    const message = JSON.parse(e.data) as SocketMessage;
    message.isServer = true;
    message.isMine = message.clientId === this.configService.clientId;
    message.socketName = socketName;

    this.notify(message);
  }

  private onOpen(socketName: string) {
    window.clearTimeout(this.reconnectTimerId);
    this.reconnectTimerId = 0;
    this.notify({ socketName, event: 'socket:open' });
  }

  private onClose(socketName: string, url: string, protocols: string[] = []) {
    window.clearTimeout(this.reconnectTimerId);
    this.reconnectTimerId = 0;

    if (!this.isManualDisconnect) {
      this.reconnectTimerId = window.setTimeout(() => {
        if (this.getState(socketName) === SocketState.closed) {
          this.connect(socketName, url, protocols);
        }
      }, this.options.reconnectInterval);
    }

    this.notify({ socketName, event: 'socket:close' });
  }

  private notify(message: SocketMessage) {
    if (!this.configService.env.production) {
      console.debug(message.event, message);
    }

    if (!message.isMine) {
      this.msg$.next(message);
    }
  }
}
