import { Action } from '@ngrx/store';
import {
  AuthErrorResponse,
  AuthResponse,
  CheckResetPasswordRequest,
  CheckUsernameRequest,
  ResetPasswordRequest,
  SigninRequest,
  SigninTokenRequest,
  SignupRequest,
} from 'src/app/core/models/auth.models';

export const AUTH_SIGNIN = '[Auth] Login';
export const AUTH_SIGNIN_ERROR = '[Auth] Login error';
export const AUTH_SIGNIN_SUCCESS = '[Auth] Login success';
export const AUTH_CHECKTOKEN_SUCCESS = '[Auth] Check token success';
export const AUTH_SUCCESS = '[Auth] success';

export const AUTH_TOKEN_SIGNIN = '[Auth] Login by token';
export const AUTH_TOKEN_SIGNIN_ERROR = '[Auth] Login by token error';
export const AUTH_TOKEN_SIGNIN_SUCCESS = '[Auth] Login by token success';

export const AUTH_SIGNUP = '[Auth] Signup';
export const AUTH_SIGNUP_ERROR = '[Auth] Signup error';
export const AUTH_SIGNUP_SUCCESS = '[Auth] Signup success';

export const AUTH_SIGNUP_CONFIRM = '[Auth] Signup confirm';
export const AUTH_SIGNUP_CONFIRM_ERROR = '[Auth] Signup confirm error';
export const AUTH_SIGNUP_CONFIRM_SUCCESS = '[Auth] Signup confirm success';

export const AUTH_CHECK_USERNAME = '[Auth] check user name';
export const AUTH_CHECK_USERNAME_ERROR = '[Auth] check user name error';
export const AUTH_CHECK_USERNAME_SUCCESS = '[Auth] check user name success';

export const AUTH_CHECK_INVITE = '[Auth] check invite';
export const AUTH_CHECK_INVITE_ERROR = '[Auth] check invite error';
export const AUTH_CHECK_INVITE_SUCCESS = '[Auth] check invite success';

export const AUTH_RESET_PASSWORD = '[Auth] reset password';
export const AUTH_RESET_PASSWORD_ERROR = '[Auth] reset password error';
export const AUTH_RESET_PASSWORD_SUCCESS = '[Auth] reset password success';

export const AUTH_CHECK_RESET_PASSWORD = '[Auth] check reset password';
export const AUTH_CHECK_RESET_PASSWORD_ERROR = '[Auth] check reset password error';
export const AUTH_CHECK_RESET_PASSWORD_SUCCESS = '[Auth] check reset password success';

export const AUTH_LOGOUT = '[Auth] Logout';
export const AUTH_LOGOUT_SUCCESS = '[Auth] Logout success';

export const AUTH_RESET_REDIRECT = '[Auth] Reset back redirect';

// SIGN IN
export class AuthSigninAction implements Action {
  readonly type = AUTH_SIGNIN;
  constructor(public payload: SigninRequest) {}
}
export class AuthSigninErrorAction implements Action {
  readonly type = AUTH_SIGNIN_ERROR;
  constructor(public payload: AuthErrorResponse) {}
}
export class AuthSigninSuccessAction implements Action {
  readonly type = AUTH_SIGNIN_SUCCESS;
  constructor(public payload: AuthResponse) {}
}
export class AuthCheckTokenSuccessAction implements Action {
  readonly type = AUTH_CHECKTOKEN_SUCCESS;
  constructor(public payload: AuthResponse) {}
}
export class AuthSuccessAction implements Action {
  readonly type = AUTH_SUCCESS;
  constructor(public payload: AuthResponse) {}
}

// SIGN UP
export class AuthSignupAction implements Action {
  readonly type = AUTH_SIGNUP;
  constructor(public payload: SignupRequest) {}
}
export class AuthSignupErrorAction implements Action {
  readonly type = AUTH_SIGNUP_ERROR;
  constructor(public payload: AuthErrorResponse) {}
}
export class AuthSignupSuccessAction implements Action {
  readonly type = AUTH_SIGNUP_SUCCESS;
  constructor(public payload: AuthResponse | undefined) {}
}

// CONFIRM SIGN UP
export class AuthSignupConfirmAction implements Action {
  readonly type = AUTH_SIGNUP_CONFIRM;
  constructor(public payload: string) {}
}
export class AuthSignupConfirmErrorAction implements Action {
  readonly type = AUTH_SIGNUP_CONFIRM_ERROR;
  constructor(public payload: AuthErrorResponse) {}
}
export class AuthSignupConfirmSuccessAction implements Action {
  readonly type = AUTH_SIGNUP_CONFIRM_SUCCESS;
  constructor(public payload: AuthResponse | undefined) {}
}

// CHECK USER NAME
export class AuthChekUsernameAction implements Action {
  readonly type = AUTH_CHECK_USERNAME;
  constructor(public payload: CheckUsernameRequest) {}
}
export class AuthChekUsernameErrorAction implements Action {
  readonly type = AUTH_CHECK_USERNAME_ERROR;
  constructor(public payload: AuthErrorResponse) {}
}
export class AuthChekUsernameSucessAction implements Action {
  readonly type = AUTH_CHECK_USERNAME_SUCCESS;
  constructor(public payload: string | undefined) {}
}

// CHECK INVITE
export class AuthChekInviteAction implements Action {
  readonly type = AUTH_CHECK_INVITE;
  constructor(public payload: string) {}
}
export class AuthChekInviteErrorAction implements Action {
  readonly type = AUTH_CHECK_INVITE_ERROR;
  constructor(public payload: AuthErrorResponse) {}
}
export class AuthChekInviteSucessAction implements Action {
  readonly type = AUTH_CHECK_INVITE_SUCCESS;
  constructor(public payload: string | undefined) {}
}

// SIGN IN BY TOKEN
export class AuthTokenSigninAction implements Action {
  readonly type = AUTH_TOKEN_SIGNIN;
  constructor(public payload: SigninTokenRequest) {}
}
export class AuthTokenSigninErrorAction implements Action {
  readonly type = AUTH_TOKEN_SIGNIN_ERROR;
  constructor(public payload: AuthErrorResponse) {}
}
export class AuthTokenSigninSuccessAction implements Action {
  readonly type = AUTH_TOKEN_SIGNIN_SUCCESS;
  constructor(public payload: AuthResponse) {}
}

// RESET PASSWORD
export class AuthResetPasswordAction implements Action {
  readonly type = AUTH_RESET_PASSWORD;
  constructor(public payload: ResetPasswordRequest) {}
}
export class AuthResetPasswordErrorAction implements Action {
  readonly type = AUTH_RESET_PASSWORD_ERROR;
  constructor(public payload: AuthErrorResponse) {}
}
export class AuthResetPasswordSuccessAction implements Action {
  readonly type = AUTH_RESET_PASSWORD_SUCCESS;
  constructor(public payload: void) {}
}

// CHECK RESET PASSWORD
export class AuthCheckResetPasswordAction implements Action {
  readonly type = AUTH_CHECK_RESET_PASSWORD;
  constructor(public payload: CheckResetPasswordRequest) {}
}
export class AuthCheckResetPasswordErrorAction implements Action {
  readonly type = AUTH_CHECK_RESET_PASSWORD_ERROR;
  constructor(public payload: AuthErrorResponse) {}
}
export class AuthCheckResetPasswordSuccessAction implements Action {
  readonly type = AUTH_CHECK_RESET_PASSWORD_SUCCESS;
  constructor(public payload: void) {}
}

// LOGOUT
export class AuthLogoutAction implements Action {
  readonly type = AUTH_LOGOUT;
  constructor(public payload?: string) {}
}
export class AuthLogoutSuccessAction implements Action {
  readonly type = AUTH_LOGOUT_SUCCESS;
  constructor(public payload?: string) {}
}

export class AuthResetBackRedirectAction implements Action {
  readonly type = AUTH_RESET_REDIRECT;
  constructor(public payload: void) {}
}

export type AuthAction =
  | AuthSigninAction
  | AuthSigninErrorAction
  | AuthSigninSuccessAction
  | AuthCheckTokenSuccessAction
  | AuthSuccessAction
  //
  | AuthSignupAction
  | AuthSignupErrorAction
  | AuthSignupSuccessAction
  //
  | AuthSignupConfirmAction
  | AuthSignupConfirmErrorAction
  | AuthSignupConfirmSuccessAction
  //
  | AuthChekUsernameAction
  | AuthChekUsernameErrorAction
  | AuthChekUsernameSucessAction
  //
  | AuthResetPasswordAction
  | AuthResetPasswordErrorAction
  | AuthResetPasswordSuccessAction
  //
  | AuthCheckResetPasswordAction
  | AuthCheckResetPasswordErrorAction
  | AuthCheckResetPasswordSuccessAction
  //
  | AuthChekInviteAction
  | AuthChekInviteErrorAction
  | AuthChekInviteSucessAction
  //
  | AuthTokenSigninAction
  | AuthTokenSigninErrorAction
  | AuthTokenSigninSuccessAction
  //
  | AuthLogoutAction
  | AuthLogoutSuccessAction
  //
  | AuthResetBackRedirectAction;
