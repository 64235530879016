<div class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title">Delete Company</h1>

  <div mat-dialog-content="" [ngSwitch]="!portal.removeDataWhenDeleteCompany" class="dialog-content">
    <div class="dialog-desc" *ngSwitchCase="true">What would you like to do with the company Ideas, Comments and Votes?</div>

    <div class="dialog-desc" *ngSwitchCase="false">All company Ideas, Comments and Votes will be deleted</div>

    <mat-radio-group *ngSwitchCase="true" [formControl]="optionControl">
      <mat-radio-button class="radio-btn-blue mr-5" [value]="DeleteOption.dontDeleteItems"> Don't delete them </mat-radio-button>
      <br />
      <mat-radio-button class="radio-btn-blue mt-5" [value]="DeleteOption.deleteItems"> Delete permanently </mat-radio-button>
    </mat-radio-group>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <span class="warning">Warning: this cannot be undone.</span>
    <div class="right-part d-flex align-items-center justify-end">
      <button class="btn btn-cancel dialog-btn" type="button" (click)="close()" [disabled]="isRequestSending" mat-button="">Cancel</button>
      <div matTooltip="Choose one of the two options above" [matTooltipDisabled]="!!optionControl.value" matTooltipPosition="above">
        <button class="btn btn-blue dialog-btn" type="button" [disabled]="!optionControl.value || isRequestSending" (click)="done()" mat-button="">
          Delete
        </button>
      </div>
    </div>
  </div>
</div>
