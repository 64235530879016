import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StringTMap } from 'src/app/core/models/common.models';
import { DBInnerStatus } from 'src/app/core/models/inner-statuses.models';
import {
  InnerStatusesAction,
  INNER_STATUSES_ADD_SUCCESS,
  INNER_STATUSES_DELETE,
  INNER_STATUSES_LIST_SUCCESS,
  INNER_STATUSES_SAVE
} from 'src/app/core/store/actions/inner-statuses';

export type InnerStatusesState = {
  readonly ids: readonly string[];
  readonly innerStatuses: StringTMap<DBInnerStatus>;
};

export const initialState: InnerStatusesState = {
  ids: [],
  innerStatuses: {},
};

export function reducer(state = initialState, action: InnerStatusesAction): InnerStatusesState {
  switch (action.type) {
    case INNER_STATUSES_LIST_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case INNER_STATUSES_ADD_SUCCESS: {
      const innerStatus = action.payload;

      return {
        ...state,
        ids: [...state.ids, innerStatus.id],
        innerStatuses: {
          ...state.innerStatuses,
          [innerStatus.id]: { ...innerStatus },
        },
      };
    }

    case INNER_STATUSES_SAVE: {
      const innerStatus = action.payload;

      return {
        ...state,
        innerStatuses: {
          ...state.innerStatuses,
          [innerStatus.id]: { ...innerStatus },
        },
      };
    }

    case INNER_STATUSES_DELETE: {
      const innerStatusId = action.payload.deletedStatusId;
      delete state.innerStatuses[innerStatusId];

      return {
        ...state,
        ids: state.ids.filter((id) => id !== innerStatusId),
        innerStatuses: {
          ...state.innerStatuses,
        },
      };
    }
    default:
      return state;
  }
}

const getInnerStatuses = (state: InnerStatusesState) => state.innerStatuses;
const getInnerStatusesIdsList = (state: InnerStatusesState) => state.ids;

export const getInnerStatusesStateSelector = createFeatureSelector<InnerStatusesState>('innerStatusesState');
export const getInnerStatusesIdsSelector = createSelector(getInnerStatusesStateSelector, getInnerStatusesIdsList);
export const getInnerStatusesSelector = createSelector(getInnerStatusesStateSelector, getInnerStatuses);
export const getInnerStatusesListSelector = createSelector(getInnerStatusesStateSelector, getInnerStatusesIdsSelector, (state, ids) =>
  ids.map((id) => state.innerStatuses[id]),
);
