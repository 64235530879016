import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DBWorkflowStatus } from 'src/app/core/models/workflow-statuses.models';
import { WorkflowStatusesStoreService } from 'src/app/core/store/services/workflow-statuses-store.service';

@Component({
  templateUrl: './delete-workflow-status-dialog.component.html',
  styleUrls: ['./delete-workflow-status-dialog.component.scss'],
})
export class DeleteWorkflowStatusDialogComponent implements OnInit, OnDestroy {
  public selectedStatus: DBWorkflowStatus;
  public statuses: DBWorkflowStatus[];

  private destroy$ = new Subject<void>();

  constructor(
    private workflowStatusesStore: WorkflowStatusesStoreService,
    @Inject(MAT_DIALOG_DATA) public status: DBWorkflowStatus,
    private dialogRef: MatDialogRef<DeleteWorkflowStatusDialogComponent, DBWorkflowStatus>,
  ) {}

  public ngOnInit() {
    this.workflowStatusesStore.list$
      .pipe(
        takeUntil(this.destroy$), //
      )
      .subscribe((statuses) => {
        this.statuses = statuses
          .filter((s) => s.id !== this.status.id) //
          .sort((a, b) => a.orderIndex - b.orderIndex);
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public identify(index: number, item?: DBWorkflowStatus): string | number {
    return item?.id || index;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public done(): void {
    this.workflowStatusesStore
      .delete(this.status.id, this.selectedStatus.id)
      .pipe(
        takeUntil(this.destroy$), //
      )
      .subscribe(() => {
        this.dialogRef.close(this.selectedStatus);
      });
  }
}
