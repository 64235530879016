import { AccessLevel } from 'src/app/core/enums/AccessLevels';
import { SortField } from 'src/app/core/models/filters.models';

export const IDEAS_SORT_TYPES: readonly SortField[] = [
  {
    field: 'createdDt',
    fieldType: 'date',
    revert: true,
    label: 'Date created',
  },
  {
    field: 'updatedDt',
    fieldType: 'date',
    revert: true,
    label: 'Date updated',
  },
  {
    field: 'votes',
    fieldType: 'number',
    revert: true,
    label: 'Number of votes',
    hideVotes: true,
  },
  // {
  //   field: 'commentsCount',
  //   fieldType: 'number',
  //   revert: true,
  //   label: 'Number of comments',
  // },
  {
    field: 'linkedItems',
    fieldType: 'number',
    revert: false,
    label: 'Number of connected items',
  },
  {
    field: 'trend',
    fieldType: 'number',
    revert: true,
    label: 'Trending',
  },
  {
    field: 'status',
    fieldType: 'number',
    revert: true,
    label: 'Status',
  },
  {
    field: 'internalStatus',
    fieldType: 'string',
    revert: true,
    label: 'Internal status',
    permission: AccessLevel.Reviewer,
  },
  {
    field: 'importance',
    fieldType: 'string',
    revert: true,
    label: 'Importance',
    permission: AccessLevel.Reviewer,
  },
  // {
  //   field: 'lastCommentedDate',
  //   fieldType: 'number',
  //   revert: true,
  //   label: 'Date last commented',
  // },
  {
    field: 'name',
    fieldType: 'string',
    revert: false,
    label: 'Title',
  },
] as const;
