import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'craft-category-dialog-menu',
  templateUrl: './category-dialog-menu.component.html',
  styleUrls: ['./category-dialog-menu.component.scss']
})
export class CategoryDialogMenuComponent {
  @Output() public privacySelect = new EventEmitter<void>();

  constructor() {

  }
}
