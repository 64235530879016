import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { CustomFieldOption } from 'src/app/core/models/custom-fields.models';
import { getOrderIndex } from 'src/app/core/utils/app-utils';
import { createGuid } from 'src/app/core/utils/crypto-utils';
import { CustomFieldsFormsService } from 'src/app/shared/services/custom-fields-forms.service';

@Component({
  selector: 'craft-custom-field-options-list-form',
  templateUrl: './options-list.component.html',
  styleUrls: ['./options-list.component.scss'],
})
export class CustomFieldOptionsListFormComponent implements OnInit {
  @Input() public isSubmitted = false;
  @Input() public formArray: AbstractControl | null;

  constructor(private customFieldsFormsService: CustomFieldsFormsService) {}

  public get fa() {
    return this.formArray as UntypedFormArray;
  }

  public get controls() {
    return this.fa.controls as UntypedFormControl[];
  }

  public ngOnInit(): void {}

  public add(): void {
    const last = this.controls[this.controls.length - 1];
    const lastOption = last?.value;

    const guid = createGuid();
    const orderIndex = getOrderIndex(lastOption);
    const option: CustomFieldOption = { guid, orderIndex, label: '' };

    this.fa.push(this.customFieldsFormsService.createOptionFormControl(option));
  }

  public dropOption(event: CdkDragDrop<any>) {
    const to = event.currentIndex;
    const from = event.previousIndex;
    const control = this.controls[from];

    moveItemInArray(this.controls, from, to);

    const prev = this.controls[to - 1];
    const next = this.controls[to + 1];

    const orderIndex = getOrderIndex(prev?.value, next?.value);

    control.value.orderIndex = orderIndex;
  }

  public remove(control: AbstractControl) {
    const idx = this.fa.controls.findIndex((c) => c === control);
    this.fa.removeAt(idx);
  }
}
