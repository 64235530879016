<div class="number-control" [ngClass]="{ clear: !showButtons }">
  <craft-icon *ngIf="showButtons" name="components--radio-minus-off-md" class="control minus" [ngClass]="{ disabled: $any(val) <= min }" (click)="minus()">
  </craft-icon>

  <input
    type="text"
    #input
    maxlength="20"
    craftNumbersOnly
    [allowDecimals]="allowDecimals"
    [allowSign]="allowSign"
    [allowNull]="allowNull"
    [decimalSeparator]="decimalSeparator"
    [placeholder]="placeholder"
    [value]="val"
    [min]="min"
    [max]="max"
    (keyup)="inputChange(input.value)"
  />

  <craft-icon *ngIf="showButtons" name="components--radio-add-off-md" class="control plus" [ngClass]="{ disabled: $any(val) >= max }" (click)="plus()">
  </craft-icon>
</div>
