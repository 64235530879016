<div class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title">Delete workflow status</h1>

  <div mat-dialog-content="" class="dialog-content">
    <div class="dialog-subtitle">Choose a different status for the items:</div>

    <button type="button"
            class="btn pl-0"
            [matMenuTriggerFor]="statusesMenu"
            [ngSwitch]="!!selectedStatus">
      <span *ngSwitchCase="false">Choose</span>
      <span *ngSwitchCase="true"
            class="color-circle shrink-0"
            [style.backgroundColor]="selectedStatus.color">
        <craft-icon class="svg-icon text-size-11"
                    name="arrows--arrow-head-down-sm"></craft-icon>
    </span>
      <span *ngSwitchCase="true"
            class="ml-8 ellipsis">{{selectedStatus.name}}</span>
      <craft-icon class="svg-icon text-size-11 ml-4"
                  name="arrows--arrow-head-down-sm"></craft-icon>
    </button>

    <mat-menu #statusesMenu="matMenu">
      <ng-template matMenuContent="">
        <button *ngFor="let status of statuses; trackBy: identify"
                type="button"
                (click)="selectedStatus = status"
                mat-menu-item=""
                class="d-flex align-items-center">
        <span class="color-circle shrink-0"
              [style.backgroundColor]="status.color">
            <craft-icon class="svg-icon text-size-11"
                        name="arrows--arrow-head-down-sm"></craft-icon>
        </span>
          <span class="ml-8 ellipsis">{{status.name}}</span>
        </button>
      </ng-template>
    </mat-menu>
  </div>

  <div mat-dialog-actions=""
       class="dialog-actions d-flex justify-end">
    <button class="btn btn-cancel dialog-btn"
            type="button"
            (click)="close()"
            mat-button="">Cancel</button>
    <button class="btn btn-blue dialog-btn"
            type="button"
            [disabled]="!selectedStatus"
            (click)="done()"
            mat-button="">Done</button>
  </div>
</div>
