import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { COMPANY_DELETE_EVENT } from 'src/app/core/dictionaries/socket-events';
import { CompanyDelRequest, DBCompany } from 'src/app/core/models/companies.models';
import { Portal } from 'src/app/core/models/portal.models';
import { WebSocketService } from 'src/app/core/services/web-socket.service';
import { CompaniesStoreService } from 'src/app/core/store/services/companies-store.service';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';

export enum DeleteOption {
  dontDeleteItems = 1,
  deleteItems = 2,
}

@Component({
  selector: 'craft-delete-company-dialog',
  templateUrl: './delete-company-dialog.component.html',
  styleUrls: ['./delete-company-dialog.component.scss'],
})
export class DeleteCompanyDialogComponent implements OnInit, OnDestroy {
  public isRequestSending = false;
  public DeleteOption = DeleteOption;
  public portal: Portal;
  public optionControl = new UntypedFormControl(void 0, { validators: Validators.required });

  private readonly destroy$ = new Subject<void>();

  constructor(
    private potalStore: PortalStoreService,
    private websocketService: WebSocketService,
    private companyStore: CompaniesStoreService,
    @Inject(MAT_DIALOG_DATA) public company: DBCompany,
    private dialogRef: MatDialogRef<DeleteCompanyDialogComponent, DBCompany>,
  ) {}

  public ngOnInit() {
    this.portal = this.potalStore.portalSnapshot!;

    if (this.portal.removeDataWhenDeleteCompany) {
      this.optionControl.setValue(DeleteOption.deleteItems, { emitEvent: false });
    }

    this.websocketService.message$
      .pipe(
        filter((msg) => msg.event === COMPANY_DELETE_EVENT && (msg.data ? msg.data.companyId === this.company.id : false)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.dialogRef.close(this.company);
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public close() {
    this.dialogRef.close();
  }

  public done() {
    this.isRequestSending = true;
    this.dialogRef.disableClose = true;

    const req: CompanyDelRequest = {
      companyId: this.company.id!,
      isArchival: this.optionControl.value === DeleteOption.dontDeleteItems,
    };

    this.companyStore.delete(req).pipe(takeUntil(this.destroy$)).subscribe();
  }
}
