import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Product } from 'src/app/core/models/products.models';

@Component({
  templateUrl: './change-workspace-dialog.component.html',
  styleUrls: ['./change-workspace-dialog.component.scss'],
})
export class ChangeWorkspaceDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public workspaces: { oldWorkspace: Product; newWorkspace: Product },
    private dialogRef: MatDialogRef<ChangeWorkspaceDialogComponent, boolean>,
  ) {}

  public close() {
    this.dialogRef.close(false);
  }

  public done() {
    this.dialogRef.close(true);
  }
}
