import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { CategoriesResponse, Category, CategoryDelRequest, DBCategory } from 'src/app/core/models/categories.models';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { CategoriesBackendService } from 'src/app/core/services/backend/categories-backend.service';
import {
    CategoriesReqAddAction,
    CategoriesReqAddErrorAction,
    CategoriesReqAddSuccessAction,
    CategoriesReqDelAction,
    CategoriesReqDelErrorAction,
    CategoriesReqDelSuccessAction,
    CategoriesReqListAction,
    CategoriesReqListErrorAction,
    CategoriesReqListSuccessAction,
    CategoriesReqSaveAction,
    CategoriesReqSaveErrorAction,
    CategoriesReqSaveSuccessAction,
    CategoriesSelectByIdAction,
    CATEGORIES_ADD_SUCCESS,
    CATEGORIES_DEL_SUCCESS,
    CATEGORIES_SAVE_SUCCESS
} from 'src/app/core/store/actions/categories';
import {
    CategoriesStateStatus,
    getCategoriesIdsSelector,
    getCategoriesListSelector,
    getCategoriesSelectedIdSelector,
    getCategoriesSelector,
    getCategoriesStateSelector,
    getCategoriesStatusSelector,
    getSelectedCategorySelector
} from 'src/app/core/store/reducers/categories';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class CategoriesStoreService {
  public readonly isEmpty$: Observable<boolean>;
  public readonly changed$: Observable<string>;

  public readonly map$ = this.store$.pipe(select(getCategoriesSelector));
  public readonly ids$ = this.store$.pipe(select(getCategoriesIdsSelector));
  public readonly list$ = this.store$.pipe(select(getCategoriesListSelector));
  public readonly status$ = this.store$.pipe(select(getCategoriesStatusSelector));
  public readonly selected$ = this.store$.pipe(select(getSelectedCategorySelector));
  public readonly selectedId$ = this.store$.pipe(select(getCategoriesSelectedIdSelector));

  constructor(
    private actions$: Actions, //
    private store$: Store<CoreState>,
    private categoriesService: CategoriesBackendService,
  ) {
    this.isEmpty$ = this.store$.pipe(
      select(getCategoriesStateSelector),
      filter((state) => state.status === CategoriesStateStatus.loaded),
      map(({ ids, categories }) => {
        return !ids.some((id) => !categories[id].isDefault);
      }),
    );

    this.changed$ = this.actions$.pipe(
      ofType<CategoriesReqDelSuccessAction | CategoriesReqAddSuccessAction | CategoriesReqSaveSuccessAction>(
        CATEGORIES_DEL_SUCCESS,
        CATEGORIES_ADD_SUCCESS,
        CATEGORIES_SAVE_SUCCESS,
      ),
      map((action) => action.type),
    );
  }

  public selectById(id: string | undefined) {
    this.store$.dispatch(new CategoriesSelectByIdAction(id));
  }

  public loadList(productId: string): Observable<CategoriesResponse> {
    this.store$.dispatch(new CategoriesReqListAction(productId));

    return this.categoriesService.getCategories(productId).pipe(
      map((res) => {
        this.store$.dispatch(new CategoriesReqListSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CategoriesReqListErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public add(category: Category): Observable<DBCategory> {
    this.store$.dispatch(new CategoriesReqAddAction(category));

    return this.categoriesService.add(category).pipe(
      map((res) => {
        this.store$.dispatch(new CategoriesReqAddSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CategoriesReqAddErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public update(category: DBCategory): Observable<DBCategory> {
    this.store$.dispatch(new CategoriesReqSaveAction(category));

    return this.categoriesService.update(category).pipe(
      map((res) => {
        this.store$.dispatch(new CategoriesReqSaveSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CategoriesReqSaveErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public delete(deletedCategoryId: string, newCategoryId?: string): Observable<void> {
    const req: CategoryDelRequest = { deletedCategoryId, newCategoryId };
    this.store$.dispatch(new CategoriesReqDelAction(req));

    return this.categoriesService.delete(req).pipe(
      map(() => {
        this.store$.dispatch(new CategoriesReqDelSuccessAction(deletedCategoryId));
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CategoriesReqDelErrorAction(err));
        return throwError(() => err);
      }),
    );
  }
}
