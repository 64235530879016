import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, of, throwError } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Category } from 'src/app/core/models/categories.models';
import { DBIdea, IdeaCustomFieldValue } from 'src/app/core/models/idea.models';
import { AttachEvent, AttachService } from 'src/app/core/services/backend/attaches-backend.service';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { CustomFieldsStoreService } from 'src/app/core/store/services/custom-fields-store.service';
import { FormsStoreService } from 'src/app/core/store/services/forms-store.service';
import { IdeasStoreService } from 'src/app/core/store/services/ideas-store.service';
import { getFormValidationErrors } from 'src/app/core/utils/form-utils';
import { CustomFieldsFormsService } from 'src/app/shared/services/custom-fields-forms.service';

@Component({
  templateUrl: './idea-edit.component.html',
  styleUrls: ['./idea-edit.component.scss'],
})
export class IdeaEditDialogComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('nameInput') public nameInputRef: ElementRef;
  @ViewChild('scrollConteiner') public scrollConteinerRef: ElementRef;

  public form: UntypedFormGroup;
  public isSending = false;
  public isSubmitted = false;
  public newCategory?: Readonly<Category>;

  private destroy$ = new Subject<void>();
  private selectedCategoryId?: string;

  constructor(
    private ideasStore: IdeasStoreService,
    private attachService: AttachService,
    private formsStore: FormsStoreService,
    private categoriesStore: CategoriesStoreService,
    private customFieldsStore: CustomFieldsStoreService,
    private customFieldsForms: CustomFieldsFormsService,
    private dialogRef: MatDialogRef<IdeaEditDialogComponent, DBIdea>,
    @Inject(MAT_DIALOG_DATA) public idea: DBIdea,
  ) {}

  public get isAnyUploading() {
    return this.attachService.uploadingSize > 0;
  }

  public get formErrors(): number {
    if (!this.isSubmitted) return 0;
    if (this.form.valid) return 0;

    return getFormValidationErrors(this.form).length;
  }

  public ngOnInit() {
    this.customFieldsForms
      .buildForm(this.idea)
      .pipe(takeUntil(this.destroy$))
      .subscribe((form) => {
        this.form = form;
      });

    this.attachService.attach$
      .pipe(
        takeUntil(this.destroy$), //
      )
      .subscribe(this.onAttachAdded);

    this.subscribeToCategoryIdChanged();
  }

  public ngAfterViewInit() {
    this.setNameFocus();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public subscribeToCategoryIdChanged() {
    this.form
      .get('categoryId')
      ?.valueChanges.pipe(
        filter((categoryId) => this.selectedCategoryId !== categoryId),
        switchMap((categoryId) => this.customFieldsForms.buildForm({ ...this.idea, categoryId })),
        takeUntil(this.destroy$),
      )
      .subscribe((form) => {
        const oldValues = this.form.value;
        const { custom, categoryId } = form.value;

        const patch = custom.map((value: IdeaCustomFieldValue) => {
          const oldValue = oldValues?.custom?.find((oldValue: IdeaCustomFieldValue) => oldValue.id === value.id);
          return oldValue ? { ...value, value: oldValue.value } : value;
        });

        this.form = form;
        this.selectedCategoryId = categoryId;
        this.form.controls.custom.patchValue(patch);

        this.subscribeToCategoryIdChanged();
      });
  }

  public attachFiles(files: FileList) {
    this.attachService.attachFiles(files, this.idea).pipe(takeUntil(this.destroy$)).subscribe();
  }

  public selectNewCategory(cat: Readonly<Category>) {
    this.newCategory = cat;
  }

  public close() {
    this.dialogRef.close();
  }

  public submit() {
    this.isSubmitted = true;
    if (this.form.invalid) return;

    this.isSending = true;
    this.saveNewCategory()
      .pipe(
        map((categoryId): DBIdea => {
          return {
            ...this.idea,
            ...this.customFieldsForms.formToIdea(this.form),
            categoryId,
          };
        }),
        switchMap((idea: DBIdea) => {
          return this.ideasStore.update(idea);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((idea) => {
        this.isSending = false;
        this.dialogRef.close(idea);
      });
  }

  private setNameFocus() {
    if (this.nameInputRef) {
      this.nameInputRef.nativeElement.focus();
    }
  }

  private onAttachAdded = (e: AttachEvent) => {
    const formAttaches = this.form.get('attaches')!.value || [];
    this.form.get('attaches')!.setValue([...formAttaches, e.attachment]);

    setTimeout(() => this.scrollToEnd(), 0);
  };

  private scrollToEnd() {
    const scrollConteiner: HTMLDivElement | null = this.scrollConteinerRef ? this.scrollConteinerRef.nativeElement : null;

    if (scrollConteiner) {
      scrollConteiner.scrollTop = scrollConteiner.scrollHeight;
    }
  }

  private saveNewCategory() {
    const categoryId = this.form.value.categoryId as string;

    if (this.newCategory && this.newCategory.id === categoryId) {
      return this.categoriesStore
        .add({
          ...this.newCategory,
          id: void 0,
        })
        .pipe(
          map((category) => {
            return category.id as string;
          }),
        );
    } else if (!categoryId) {
      return throwError(() => new Error('categoryId is empty!'));
    }

    return of(categoryId);
  }
}
