import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringTMap } from 'src/app/core/models/common.models';
import { DBImportance, Importance, ImportanceDelRequest, ImportancesListResponse } from 'src/app/core/models/importances.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';

@Injectable({
  providedIn: 'root',
})
export class ImportancesBackendService {
  constructor(private bs: BackendService) {}

  public getList(): Observable<ImportancesListResponse> {
    return this.bs.get('portal/importance/').pipe(
      map((result) => {
        const importances: StringTMap<DBImportance> = {};
        const ids = result.list.reduce((acc: string[], imp: DBImportance) => {
          importances[imp.id] = this.mapImportance(imp);
          acc.push(imp.id);
          return acc;
        }, []);
        return { ids, importances };
      }),
    );
  }

  public update(importance: DBImportance): Observable<DBImportance> {
    return this.bs.put(`portal/importance/${importance.id}`, importance).pipe(
      map((imp) => {
        return this.mapImportance(imp);
      }),
    );
  }

  public add(importance: Importance): Observable<DBImportance> {
    return this.bs.post(`portal/importance/`, importance).pipe(
      map((imp) => {
        return this.mapImportance(imp);
      }),
    );
  }

  public delete(req: ImportanceDelRequest): Observable<string> {
    return this.bs
      .delete(`portal/importance/${req.deletedImportanceId}`, {
        newIdeasImportanceId: req.newIdeasImportanceId,
      })
      .pipe(
        map(() => {
          return req.deletedImportanceId;
        }),
      );
  }

  public mapImportance(imp: any): DBImportance {
    imp.orderIndex = Number.parseFloat(imp.orderIndex);
    return imp as DBImportance;
  }
}
