import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, race, timer } from 'rxjs';
import { filter, finalize, map, take, tap } from 'rxjs/operators';
import { PickRequired } from 'src/app/core/models/common.models';
import { NotificationOverlayComponent } from 'src/app/shared/dialogs/notifications/notification-overlay/notification-overlay.component';

export type NotificationAction = {
  readonly text: string;
  readonly command?: string;
  readonly autoClose?: boolean;
};

export type NotificationMessage = {
  readonly message: string;
  readonly duration: number;
  readonly classNames: readonly string[] | string;
  readonly actions?: readonly NotificationAction[];
};

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private overlayRef: OverlayRef | null;
  private readonly actions$ = new Subject<NotificationAction>();
  private readonly close$ = new Subject<NotificationMessage>();
  private readonly messages$ = new BehaviorSubject<NotificationMessage[]>([]);

  public readonly notifications$ = this.messages$.asObservable();

  constructor(private readonly overlay: Overlay) {
    this.messages$.subscribe((queue) => {
      if (queue.length === 0 && this.overlayRef) {
        this.overlayRef.dispose();
        this.overlayRef = null;
      }
    });
  }

  public show(message: PickRequired<NotificationMessage, 'message'>): Observable<NotificationAction | undefined> {
    const msg: NotificationMessage = {
      duration: 5000,
      classNames: '',
      ...message,
    };

    this.messages$.next(this.messages$.value.concat(msg));
    this.createOverlay();

    const timer$ = timer(msg.duration).pipe(map(() => void 0));

    const close$ = this.close$.pipe(
      filter((m) => m === msg),
      map(() => void 0),
    );

    const action$ = this.actions$.pipe(
      filter((action) => {
        if (!msg.actions) return true;
        return msg.actions.some((a) => a === action);
      }),
    );

    return race(timer$, close$, action$).pipe(
      take(1),
      tap((action) => {
        if (action?.autoClose) {
          this.close(msg);
        }
      }),
      finalize(() => {
        this.close(msg);
      }),
    );
  }

  public fire(message: PickRequired<NotificationMessage, 'message'>): Subscription {
    return this.show(message).subscribe();
  }

  public close(msg: NotificationMessage): void {
    const messages = this.messages$.value.filter((m) => m !== msg);

    if (messages.length < this.messages$.value.length) {
      this.messages$.next(messages);
      this.close$.next(msg);
    }
  }

  public action(action: NotificationAction): void {
    this.actions$.next(action);
  }

  private createOverlay() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
      const componentPortal = new ComponentPortal(NotificationOverlayComponent);
      this.overlayRef.attach(componentPortal);
    }
  }
}
